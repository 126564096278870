body,
.braintree-dropin {
  font-family: 'Josefin Sans', sans-serif !important;
  color: $text-color  !important;
  // background: #F0F8FF;
}

.MuiTypography-body1,
.MuiTypography-root,
.MuiFormControlLabel-label,
.MuiButton-root,
.MuiMenuItem-root,
.MuiFormLabel-root,
.MuiInputBase-root {
  font-family: "Lato", sans-serif !important;
}

.MuiMenuItem-root {
  font-size: 0.91rem !important;
  // padding-top: 0 !important;
}

.font-weight-bold-900 {
  font-weight: 900 !important;
}

.show-error {
  display: block;
  width: 100%;
  max-height: 60px;
  overflow-y: auto;
  color: red;
}


@import url("https://p.typekit.net/p.css?s=1&k=voy6jsx&ht=tk&f=45679.45680.45681.45682.45683.45684.45685&a=82260988&app=typekit&e=css");

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/304696/00000000000000007735c4cf/30/l?subset_id=2&fvd=n1&v=3") format("woff2"), url("https://use.typekit.net/af/304696/00000000000000007735c4cf/30/d?subset_id=2&fvd=n1&v=3") format("woff"), url("https://use.typekit.net/af/304696/00000000000000007735c4cf/30/a?subset_id=2&fvd=n1&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/c48b51/00000000000000007735c4d2/30/l?subset_id=2&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/c48b51/00000000000000007735c4d2/30/d?subset_id=2&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/c48b51/00000000000000007735c4d2/30/a?subset_id=2&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/808773/00000000000000007735c4d4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/808773/00000000000000007735c4d4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/808773/00000000000000007735c4d4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/56eb36/00000000000000007735c4d5/30/l?subset_id=2&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/56eb36/00000000000000007735c4d5/30/d?subset_id=2&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/56eb36/00000000000000007735c4d5/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/2bd7e2/00000000000000007735c4d6/30/l?subset_id=2&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/2bd7e2/00000000000000007735c4d6/30/d?subset_id=2&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/2bd7e2/00000000000000007735c4d6/30/a?subset_id=2&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/2a4cdd/00000000000000007735c4d7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/2a4cdd/00000000000000007735c4d7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/2a4cdd/00000000000000007735c4d7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: $kansasnew-family;
  src: url("https://use.typekit.net/af/3affff/00000000000000007735c4d8/30/l?subset_id=2&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/3affff/00000000000000007735c4d8/30/d?subset_id=2&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/3affff/00000000000000007735c4d8/30/a?subset_id=2&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}