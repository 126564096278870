%btn-shared {
  border-radius: 11px;
  height: 50px;
  color: $white-color;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.login-btn {
  @extend %btn-shared;
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}

.full-btn {
  width: 100%;
  padding: 0.6rem 1rem !important;
  box-shadow: 0px 15px 22px #00000029 !important;
  border-radius: 20px !important;

  &.green {
    background-color: $green-color;
    color: #fff;
    font-weight: 600;

    &:hover {
      background-color: $green-color;
      color: #fff;
    }
  }
}

.custom-btn {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
  padding: 0.5rem 2rem 0.5rem !important;

  &.green {
    background-color: $green-color;
    color: $white-color;

    &:hover {
      background-color: #ffb739;
    }
  }

  &.yellow {
    background-color: #ffb739;
    color: $white-color;

    &:hover {
      background-color: #ffb739;
    }
  }
}

#paypal-login {
  .pillClass {
    border-radius: 20px !important;
    padding: 6px 6px;
    width: 100%;
    box-shadow: 0px 15px 22px #0000004d;
    transition: all ease-out 0.6s;

    @include breakpoint(phone) {
      padding: 3px 6px;
    }

    background: #0070ba;
    /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #0070ba 0%,
      #0070ba 18%,
      #003087 100%
    ) !important;
    background: -webkit-linear-gradient(
      top,
      #0070ba 0%,
      #0070ba 18%,
      #003087 100%
    ) !important;
    background: linear-gradient(
      to bottom,
      #0070ba 0%,
      #0070ba 18%,
      #003087 100%
    ) !important;

    &:hover,
    &:active {
      transform: scale(1.05);
      background-color: transparent !important;
      text-decoration: none;
    }
  }

  //  Play btn
  .play-btn,
  .blue-btn {
    background-color: $primary-color;
    border-radius: 30px;
    font-weight: 500;
    font-size: 1.125rem;
    padding: 0.5rem 0.75rem 0.5rem 1.5rem;
    border: 0 none;
    color: $white-color;
    display: flex;
    align-items: center;

    svg {
      max-width: 20px;
      max-height: 20px;
      margin: 0px 2px 0px 0px;

      @include breakpoint(phone) {
        max-width: 14px;
        max-height: 14px;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-color;
      border: 0 none;
      color: $white-color;
    }
  }
}

//  Play btn
.play-btn,
.blue-btn,
.blue-btn-outline {
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.3rem 0.5rem 0.3rem 1.5rem;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:disabled {
    opacity: 0.65;
  }

  img {
    display: inline-block;
    margin-left: 1rem;
  }
}

.play-btn-loader {
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.3rem 0.5rem 0.3rem 1.5rem;
  display: flex;
  &:focus,
  &:active,
  &:hover {
    background-color: #0048a1  !important;
  }
}

.play-btn-loader:hover > span 
.play-btn-loader:focus > span 
.play-btn-loader:active > span {
  border-color: #0048a1 !important;
  border-bottom-color: transparent !important;
}

.blue-btn-outline {
  background-color: $white-color;
  color: $primary-color;
  border: 2px solid $primary-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color !important;
    border: 2px solid $primary-color;
    color: $white-color;
  }
}

.play-btn {
  background-color: $primary-color;
  color: $white-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $white-color;
    border: 2px solid $primary-color;
    color: $primary-color !important;
  }
}
.play-btn1 {
  background-color: $primary-color;
  color: $white-color;
  border-radius: 30px;
  height: 46px;
  padding: 0.5rem 0.75rem 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &:hover,
  &:focus,
  &:active {
    background-color: orangered;
  }
}
.creditImg {
  vertical-align: sub;
}

p.button1 {
  height: 100%;
  background-color: orangered;
  display: inline-block;
  padding: 0.55em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 30px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.125rem;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  align-items: center;
  padding-left: 30px;
  min-height: 50px;
}
p.button1:hover {
  background-color: $primary-color;
}
@media all and (max-width: 30em) {
  p.button1 {
    display: block;
    margin: 0.4em auto;
  }
}

.blue-btn {
  background-color: $primary-color;
  color: $white-color;
  padding: 0.6rem 1.5rem;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: $white-color !important;
  }
}

.certificate-buttons {
  @include breakpoint(tablet) {
    display: block !important;

    .survey {
      a {
        padding-left: 0rem;
      }
    }
  }

  .blue-btn {
    padding: 0.4rem 0.5rem 0.4rem 1.5rem;

    @include breakpoint(tablet) {
      width: 100%;
    }
  }
  .blue-btn-2 {
   text-align: center;
    padding: 0.4rem;

    @include breakpoint(tablet) {
      width: 100%;
    }
  }
}

// Certificate btn
.certificate-btn,
.outline-btn {
  background-color: $white-color;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: $primary-color;
  border-color: $primary-color;
  border-width: 2px;
  padding: 0.35rem 0.75rem;

  img {
    display: inline-block;
    margin-right: 0.5rem;
    height: 21px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $white-color !important;
    color: $primary-color !important;
    border-color: $primary-color !important;
    border-width: 2px !important;
  }
}

.certificate-btn {
  width: 155px;
  & img {
    margin: 0;
  }
}

// Save btn
.save-btn {
  border-radius: 25px;
  height: 50px;
  padding: 0 2.125rem;
  font-size: 1.125rem;
  border: 0;
  font-weight: 600;
  color: $primary-color;
  min-width: 120px;

  &.dark {
    background-color: $primary-color;
    color: $white-color;
  }

  &.lite {
    background-color: rgba($color: $primary-color, $alpha: 0.1);
    color: $primary-color;
  }
}

// discard btn
.discard-btn {
  border: 0;
  background-color: transparent;
  font-size: 1.125rem;
  color: $primary-color;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 1.625rem;
  cursor: pointer;
}

// btn link
.btn-link {
  color: $primary-color;
  font-weight: 700;
}

// Outline btn
.outline-btn {
  font-weight: 700;
  text-transform: none;
  padding: 0.7rem 1.5rem;
  border-radius: 30px;
  background-color: transparent;
  font-size: 1rem;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    color: $primary-color !important;
    border-color: $primary-color !important;
    border-width: 2px !important;
  }
}

// lang-btn
.lang-btn {
  background: none !important;
  color: $primary-color;
  border: 0 none !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &.dropdown-toggle {
    padding-left: 0;
  }

  img {
    padding-right: 5px;
  }

  &:hover,
  &:focus,
  &:active,
  &.dropdown-toggle {
    background: none;
    color: $primary-color;
    border: 0 none;
  }
}

%button-base {
  padding: 11px 44px;

  @include breakpoint(phone) {
    padding: 10px 30px;
  }

  border-radius: 56px;
  font-size: 1rem;
  font-weight: 500;
}

%button-standard {
  background-color: $primary-color;
  color: $white-color;
  border: 2px solid $primary-color;
}

%button-outline {
  background-color: transparent;
  color: $primary-color;
  border: 2px solid $primary-color;
}

.v2-btn {
  @extend %button-base;
  width: 100%;

  &.standard {
    @extend %button-standard;
  }

  &.outline {
    @extend %button-outline;
  }
}
@media (max-width: 520px) {
  .v2-btn {
    padding: 10px 20px;
  }
}
.v2-btn1 {
  @extend %button-base;
  width: 90%;
  &.standard {
    @extend %button-standard;
    background-color: #5451e0;
  }

  &.outline {
    @extend %button-outline;
  }
}

.payment-btn {
  padding: 11px 17px;
  @extend %button-base;

  &.orange {
    border: 2px solid #ff6600;
    background-color: #ff6600;
    color: $white-color;
    margin: inherit;
    min-width: 216px;

    &:hover,
    &:active,
    &:focus {
      @include orange-btn-hover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.246' height='16.33' viewBox='0 0 12.246 16.33'%3E%3Cpath id='lock' d='M15.846 21.83H6.217a.14.14 0 0 0-.038-.025A1.529 1.529 0 0 1 4.91 20.193V13.229a1.532 1.532 0 0 1 1.6-1.6H6.95c0-.662-.006-1.3 0-1.945a5.635 5.635 0 0 1 .064-.809 4.079 4.079 0 0 1 8.1.762v1.994c.2 0 .372 0 .547 0a1.518 1.518 0 0 1 1.49 1.488q.013 3.609 0 7.219a1.5 1.5 0 0 1-1 1.4C16.047 21.77 15.946 21.8 15.846 21.83Zm-2.1-10.21c0-.53 0-1.05 0-1.569a6.93 6.93 0 0 0-.043-.937 2.716 2.716 0 0 0-5.391.51c0 .537 0 1.073 0 1.609v.387Zm-3.4 6.1v.271c0 .164-.005.33 0 .494a.673.673 0 0 0 .667.623.665.665 0 0 0 .677-.627c.013-.468.009-.934 0-1.4a.325.325 0 0 1 .145-.3 1.329 1.329 0 0 0 .441-1.58 1.36 1.36 0 0 0-2.572.155 1.323 1.323 0 0 0 .506 1.437.273.273 0 0 1 .13.262C10.348 17.277 10.354 17.5 10.354 17.723Z' transform='translate(-4.91 -5.5)' fill='%23FF6600'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 17px 14px;

      .lockWhite {
        opacity: 0;
      }
    }

    img {
      position: relative;
      top: -2px;
    }
  }
}

.mobile-full-width {
  @include breakpoint(tablet-large) {
    width: 100%;
  }
}

.MuiButton-containedSecondary {
  background-color: $primary-color !important;
  border-radius: 30px !important;
  width: 100%;
  text-transform: capitalize !important;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 1.1rem !important;
}

.yellow-btn-outline {
  background-color: #fff9e5 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #fbf0cc !important;
    color: $primary-color !important;
  }
}

.duplicate-certificate-btn {
  padding: 10px !important;
  margin-left: 5px;
  border: 1px solid #0048a1 !important;
  background-color: #ffffff !important;
  color: #0048a1 !important;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
}
