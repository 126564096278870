.typing {
    .font-size-inc {
        font-size: 1.1rem;
    }

    &-title {
        color: #1e80cf;
        font-size: 1.75rem;
        font-weight: 900;
    }

    &-width {
        max-width: 100%;
        // background-color: #00000029;
    }

    &-check {
        max-width: 571px;
        margin: 0 auto;
    }

    &-message {
        background-color: #f5f5f5;

        p {
            font-size: 1.125rem !important;
            font-weight: 600;
        }
    }

    &-button {
        .orange {
            background-color: #f27930;
        }

        .blue {
            background-color: #1e80cf;
        }

        .btn {
            box-shadow: 0px 3px 6px #00000029 !important;
            padding: 0.8rem 1.8rem;

            &:hover {
                transform: inherit !important;
            }
        }
    }

    &-container {
        max-width: 765px;
        width: 100%;

        .documents__card {
            max-width: 100%;
        }
    }

    &-input {
        .TextInputField .MuiOutlinedInput-input {
            padding: 22px 10px;
            font-size: 1.2rem !important;
            text-align: center;

            &::placeholder {
                font-size: 1.2rem !important;
                text-align: center;
            }
        }

        .inputLabel {
            input {
                &::placeholder {
                    font-size: 1rem !important;
                    text-align: center;
                    color: $text-color  !important;
                }
            }

            .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
                border-color: inherit !important;
                box-shadow: none;
            }

            .TextInputField .MuiOutlinedInput-input {
                &::placeholder {
                    font-size: 1rem !important;
                    text-align: center;
                    color: $text-color  !important;
                }
            }
        }
    }

    &-footer {
        .count {
            color: $text-color;
            font-size: 1.25rem;
            font-weight: 900;
        }
    }

    p {
        color: $text-color;
        font-size: 1rem;
        line-height: 1.2;
        line-height: 1.5;
    }
}

.verification-v-center {
    // min-height: calc(100vh - 138px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}