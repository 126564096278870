.sidebar-toggle {

  @media (min-width : 1366px) {
    .sidebar-container {
      width: 60px;

      .logo {
        visibility: hidden;
      }

      .sidebar {
        .arrow {
          transform: rotate(0deg);
        }
      }

      .menu {
        li {
          border-radius: 1rem;
          width: 38px;
          height: 25px;
        }
      }

      .menu-name,
      .language-menu,
      .elmsLogo {
        width: 0;
        overflow: hidden;
        opacity: 0;
      }
    }

    .main-container {
      padding-left: 60px;
    }

  }

  .sidebar-container {
    left: 0%;
  }

  @media(min-width: 1366px) {
    .sidebar-container {
      width: 60px;

      .logo {
        visibility: hidden;
      }

      .sidebar {
        .arrow {
          transform: rotate(0deg);
        }
      }

      .menu {
        li {
          border-radius: 1rem;
          width: 38px;
        }
      }

      .menu-name,
      .language-menu,
      .elmsLogo {
        width: 0;
        overflow: hidden;
        opacity: 0;
      }
    }

    .main-container {
      padding-left: 60px;
    }

  }

  @include breakpoint(tablet) {
    .elmsLogoLarge {
      margin-bottom: 1rem;
    }
  }

  .timecard {
    width: 40px;
    height: 40px;
    text-align: center;
    justify-content: center;

    .timerImage {
      width: 18px;
      margin-right: 0;
      margin-left: -4px;
    }

    .item {
      display: none;
    }
  }

}

.menu-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-height: calc(100vh - 130px);

  @include breakpoint(min-desktop) {
    // min-height: 610px !important;
    padding-top: 3.5rem;
    padding-bottom: 1rem;

    .top {
      width: 100%;
    }

    .bottom {
      .menu-item {
        height: 26px !important;
      }

      .menu {

        &-name,
        &-icon {
          opacity: .6 !important;
        }

      }
    }
  }

  .elmsLogo {
    max-width: 140px;
    padding-left: 1.2rem;
  }

  @include breakpoint(desktop) {
    min-height: calc(100vh - 130px);
  }
}

.sidebar-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 220px;
  height: 100vh;
  background: $primary-color;
  z-index: 1000;
  transform: translate3d(0, 0, 0);
  transition: width 0.3s, left 0.3s;

  @include breakpoint(desktop) {
    width: 200px;
  }

  @include breakpoint(min-desktop) {
    left: -100%;
    max-width: 300px;

  }

  @include breakpoint(tablet-land) {
    width: 100%;
  }

  .dropdown {
    .btn {
      padding: 0;
    }
  }

  .lang-btn {
    color: $white-color;
    opacity: 0.6;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    color: $white-color;
    opacity: 0.6;
  }

  .sidebar {
    height: 100%;
    overflow: hidden;

    .arrow {
      position: absolute;
      top: 0.4rem;
      right: 0.8rem;
      transform: rotate(180deg);
      cursor: pointer;
    }

    .logo {
      @include padding(30, 9, 0, 9);
      margin-bottom: 0.5rem;

      img {
        max-width: 180px;
        max-height: 52px;

        @include breakpoint(desktop) {
          max-height: px;
        }

        margin: 0 auto;
        display: block;
      }
    }

    .menu {
      padding: 0 0.5rem 0.5rem;

      .menu-item {
        display: flex;
        padding: 0.3rem 0.7rem;
        text-decoration: none;
        transition: all 0.2s ease;
        cursor: pointer;
        align-items: center;
        border-radius: 4rem;
        margin: 0.95rem 0;

        &.language-menu {
          .dropdown-menu {
            width: 200px;
            .dropdown-item {
              white-space: normal;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        span {
          line-height: 1;
        }

        height: max-content;

        @include breakpoint(desktop) {
          margin: 0.7rem 0;
        }

        .dropdown-toggle {
          &::after {
            background-image: url(./../../images/icons/whiteDownArrow.svg);
            background-repeat: no-repeat;
            width: 24px;
            height: 12px;
            border: 0;
            margin-top: 0.3rem;
            margin-left: 0.65em;
          }
        }

        & .dropdown-toggle {
          white-space: normal;
          padding: 0 .5rem;
        }

        .menu-name {
          color: $white-color;
          opacity: 0.6;
          line-height: 1;
          padding-top: 0.1rem;
          margin-left: 0.5rem;

          @include breakpoint(tablet) {
            opacity: 1;
          }
          
        }

        &.active .menu-name {
          opacity: 1;
          text-decoration: underline;
        }

        .menu {
          padding: 0 0.5rem 0.5rem;

          .menu-item {
            display: flex;
            padding: 0.3rem .7rem;
            text-decoration: none;
            transition: all 0.2s ease;
            cursor: pointer;
            align-items: center;
            border-radius: 4rem;
            margin: .75rem 0;

            @include breakpoint(desktop) {
              margin: .75rem 0 !important;
            }

            @include breakpoint(large-then-desktop) {
              margin: .95rem 0;
            }

            span {
              line-height: 1;
            }

            height: 32px;

            .menu-name {
              color: $white-color;
              opacity: .6;
              line-height: 1;
              padding-top: 0.1rem;
              margin-left: .5rem;
            }

            &:hover,
            &.active {
              background: rgba($color: $white-color, $alpha: 0.1);

            }

            &.active {
              color: $white-color;

              svg {
                opacity: 1;
              }
            }

            .menu-icon {
              margin-top: -3px;

              &.content {
                img {
                  max-width: 16px;

                }
              }

              svg {
                opacity: 0.6;
              }
            }



            // &.logout {
            //     position: absolute;
            //     bottom: 0.5rem;
            //     left: 0;
            //     width: 100%;
            // }

            // &.language {
            //     position: absolute;
            //     bottom: 0.5rem;
            //     left: 0;
            //     width: 100%;
            // }

            &.settings,
            &.profile,
            &.language {
              display: none;
            }
          }
        }

        &.active {
          color: $white-color;

          svg {
            opacity: 1;
          }
        }

        .menu-icon {
          margin-top: -3px;

          &.content {
            img {
              max-width: 16px;
            }
          }

          svg {
            opacity: 0.6;

            @include breakpoint(tablet) {
              opacity: 1;
            }
          }
        }

        // &.logout {
        //     position: absolute;
        //     bottom: 0.5rem;
        //     left: 0;
        //     width: 100%;
        // }

        // &.language {
        //     position: absolute;
        //     bottom: 0.5rem;
        //     left: 0;
        //     width: 100%;
        // }

        &.settings,
        &.profile,
        &.language {
          display: none;
        }
      }
    }
  }

  .close-sidebar {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    text-align: end;
  }
}

.timecard {
  width: 200px;

  @include breakpoint(desktop) {
    width: 180px;
  }

  transform: translate3d(0, 0, 0);
  transition: width 0.3s,
  left 0.3s;
  height: 48px;
  background-color: #ff6600;
  border-radius: 8px;
  @include fontSize(10);
  color: $white-color;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.2rem 0.5rem 0.5rem;
  margin: 0.2rem 0.6rem;

  p {
    color: $white-color;
  }

  .displayTime {
    font-weight: 600;
    color: $white-color;

    @include breakpoint(large-phone) {
      @include fontSize(14);
      font-weight: 400;
    }
  }

  .timerImage {
    margin-right: 0.4rem;
  }

  &.green {
    background-color: #228b22;
  }
}

.sidebarOverlay {
  width: 220px;
  background-color: RGBA(255, 255, 255, 0.3);
  min-height: calc(100vh);
  backdrop-filter: blur(.5px);
  position: fixed;
  left: 0;
  z-index: 999;
  cursor: not-allowed;

  @media(max-width: 1199px) {

    min-height: 60px;
    top: 0;
    width: 100%;
  }
}
.saving-progress-modal{
height: 100vh;
width: 100vw;
background-color: rgba(0, 0, 0, 0.247);
color: white;
display: flex;
justify-content: center;
font-size: 30px;
.saving-progress-modal__content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 680px;
height: fit-content;
padding: 2rem;
gap: 1.5rem;
background-color: white;
text-align: center;
border-radius: 16px;
margin-top: 10rem;
  .saving-progress-modal__header{
    display: flex;
    align-items: center;
    gap: 1rem;
  
    h1{
      font-size: 30px;
      color:#ff6600;
      margin: 0 !important;
      padding: 0 !important;
      line-height: 1;
    }
   }
 }
 p{
  color: #28639d ;
  font-size: 22px;
  line-height: 1.5rem;
 }
}