$midnight: #2c3e50;
$clouds: #ecf0f1;

.custom-accordion {

  .tab {
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid $silver-color;

    input {
      position: absolute;
      visibility: hidden;
    }

    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      cursor: pointer;
      color: $text-lite;
      font-size: 0.95rem;
      margin-bottom: 0;
      font-weight: 600;
      /* Icon */

      &::after {
        content: "";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        background-image: url("../../images/icons/down-arrow.svg");
        background-repeat: no-repeat;
        background-position: right center;
        transform: rotate(-90deg);
      }
    }

    &-content {
      max-height: 0;

      font-size: .85rem;
      color: $text-lite;

      transition: ease-in .4s;
      line-height: 1.5;
    }

    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $midnight;
      cursor: pointer;

    }
  }

  // :checked
  input:checked {
    +.tab-label {

      &::after {
        transform: rotate(00deg);
      }
    }

    ~.tab-content {
      max-height: 20vh;
      padding: 0em 0rem 1rem;
    }
  }
}