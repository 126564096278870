@include breakpoint(tablet-land) {
    .flashDealCardMobileView {
        .rating {
            @include fontSize(12);
        }

        .star-svg {
            max-width: 17px !important;
            max-height: 17px !important;
            path 
            {
                color: #ffbc0b;
            }
        }

        .details {
            @include fontSize(14);
        }
    }
}