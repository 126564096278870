.chapter {
  margin-top: 3rem;

  &__list {
    margin: 0 0 1.7rem;

    &--question {
      border-bottom: 2px solid #a1a1a1;

      .question-text {
        display: flex;
        align-items: center;

        .number {
          background-color: $silver-color;
          display: flex;
          height: 20px;
          width: 20px;
          justify-content: center;
          align-items: center;
          font-size: 0.88rem;
          color: $text-lite;
          border-radius: 4px;
          margin-right: 0.7rem;
        }
      }
    }

    &--answer {
      .answer {
        margin-bottom: 0.7rem !important;
        padding-left: 0;

        &__list {
          padding: 0rem 0.5rem 0rem;
          background: $ash-color 0% 0% no-repeat padding-box;
          border-radius: 15px;
          border: 1px solid;
          border-color: #dddddd;
          margin: 0.6rem 0;
          list-style: none;

          &:last-child {
            margin: 0.6rem 0 0.6rem;
          }

          .MuiFormControlLabel-root {
            min-height: 30px;
            display: flex;
            margin: 0 !important;

            .MuiIconButton-root {
              padding: 0px 4px 0px 0;
            }

            .MuiCheckbox-root {
              padding: 6px;
            }

            .MuiSvgIcon-root {
              width: 1.2rem;
              height: 1.2rem;
              fill: $Blue-color;
            }

            .MuiTypography-root {
              font-size: 0.85rem;
              color: #424242;
            }
          }

          &.select,
          &:active {
            background-color: $Blue-color;
            border-color: $Blue-color;
            box-shadow: 0px 3px 15px #00000040;

            .MuiTypography-root {
              color: $white-color;
            }

            .MuiCheckbox-root {
              color: $white-color;
            }

            .MuiSvgIcon-root {
              fill: $white-color;
            }
          }

          &.correct {
            background-color: $white-color;
            border-color: $green-color;
            box-shadow: 0px 3px 15px #00000040;

            .MuiTypography-root {
              color: $green-color;
            }

            .MuiCheckbox-root {
              color: $green-color;
            }

            .MuiSvgIcon-root {
              fill: $green-color;
            }
          }

          &.incorrect {
            background-color: $white-color;
            border-color: $red-color;
            box-shadow: 0px 3px 15px #00000040;

            .MuiTypography-root {
              color: $red-color;
            }

            .MuiCheckbox-root {
              color: $red-color;
            }

            .MuiSvgIcon-root {
              fill: #ff5353;
            }
          }
        }
      }

      .img {
        margin-top: 0.5rem;
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000029;
      }
    }
  }

  display: flex;
  flex-wrap: wrap;

  &__left {
    flex: 0 0 calc(100% - 390px);
    max-width: calc(100% - 390px);
    padding-right: 1.5rem;
    padding-left: 15px;
    width: 100%;

    @include breakpoint(tablet-land) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &__right {
    padding-left: 1.5rem;

    @include breakpoint(tablet-land) {
      display: none;
    }
  }

  &__left,
  &__right {
    .custom-btn {
      &.MuiButtonBase-root {
        margin-top: 0.6rem;
        border-radius: 20px !important;
        padding: 0.5rem 2rem !important;

        .MuiButton-label {
          font-weight: 900;
          font-size: 1.1rem;
        }
      }

      &.green {
        &:hover {
          background-color: $green-color;
        }
      }
    }
  }

  @include breakpoint(tablet-land) {
    &.retry {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 3;
      }

      .chapter__right {
        display: block !important;

        .review {
          z-index: 6;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 330px;
          padding: 1.5rem;
        }
      }
    }
  }
}

.review {
  background: $white-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 20px;
  padding: 2rem;
  max-width: 350px;
  width: 100%;
  position: fixed;

  .chapterName {
    font-size: 1.2rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-bottom: 0;
  }

  &__title {
    h5 {
      font-size: 1.2rem;
      font-weight: 900;
      text-align: center;
      text-transform: capitalize;
    }

    h6 {
      text-align: center;
      font-weight: 600;
      margin-top: 1rem;
      letter-spacing: 0.36px;
      font-size: 1.1rem;
      line-height: 1.5;

      @include breakpoint(tablet-land) {
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 1.3;
        margin-top: 1.2rem;
      }
    }
  }

  .answer {

    .col-md-7,
    .col-md-5,
    .col-7,
    .col-5 {
      padding: 0 0.5rem;
    }

    &__text,
    &__value {
      background: $silver-color 0% 0% no-repeat padding-box;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-lite;
      padding: 0.5rem 1rem;
      text-align: center;
      line-height: 1.2;
      font-weight: bolder;
      min-height: 75px;
    }

    &__text {
      font-size: 1.1rem;
      color: $text-lite;
      font-weight: 600;
    }

    &__value {
      font-size: 3rem;
      font-weight: 900;
    }

    p {
      font-size: 0.95rem;
      color: $text-lite;
    }
  }
}

.chapterSpace {
  padding-left: 1rem !important;
}

.requiredCorrect {
  margin: 1.4rem 0 0.6rem;
  font-size: 1.1rem !important;
}

.explanationTitle {
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 0.9rem !important;
  font-weight: 900 !important;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &.green {
    color: $Blue-color  !important;
  }

  &.yellow {
    color: $yellow-color  !important;
  }
}

.explanation {
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.85rem;

  .icon {
    padding-right: 0.6rem;
    padding-left: 0.1rem;
    position: relative;
    top: -2px;
  }

  &.green {
    color: $Blue-color;

    .green {
      display: block;
    }

    .yellow {
      display: none;
    }
  }

  &.yellow {
    color: $yellow-color;

    .green {
      display: none;
    }

    .yellow {
      display: block;
    }
  }
}

.messageModalContainer {
  .modal-dialog {
    max-width: 430px;
  }
}

.sec-modal-container {
  .modal-dialog {
    max-width: 535px !important;
  }
}

.react-fancybox {
  .thumbnail {
    max-width: 150px;
    margin-top: 0.5rem;

    img {
      max-width: 150px !important;
      box-shadow: 0px 3px 6px #00000029 !important;
      border-radius: 8px;
    }
  }
}

.chapterQuizMessage {
  margin: auto;
}


.liveTimer {
  font-family: $kansasnew-family;
  letter-spacing: 1.15px;
  font-weight: bold;
  font-size: 1rem;
}

.chapterTimerLabel {
  margin-left: 7rem;
}

.faceRecognition {
  .modal {
    &-content {
      border-radius: 8px !important;
      background-color: $lite-blue  !important;
      border: none;
      box-shadow: 0px 12px 22px #00000029;

    }

    &-dialog {
      max-width: 1130px;
      width: 100%;

    }
  }


  &-body {
    display: flex;
    align-items: center;
    padding: 2rem;
  }

  &-view {
    max-width: 370px;
    padding: 1.5rem;
    width: 100%;
    box-shadow: 0px 12px 22px #00000029;
    background-color: $white-color;

    .btn {
      width: 146px;
      height: 50px;
      border-radius: 25px;

      &-outlined {
        border: 1px solid #003F97 !important;
        font-weight: 700;
        color: $primary-color;

        &:hover {
          background-color: $primary-color  !important;
          color: $white-color;
        }
      }

      &.green-btn {
        background-color: #228B22;
        border: 1px solid #228B22 !important;

        &:hover {
          background-color: transparent !important;
          color: #228B22;
        }
      }
    }

    h4 {
      @include fontSize(24);
      color: $primary-color;
      font-weight: 700;
      text-align: center;
      padding-top: 6px;
      margin-bottom: 1.313rem;
    }

    p {
      color: $primary-color;
      text-align: center;
      padding-top: .2rem;
      margin-bottom: 1.813rem;
    }
  }

  &-notes {
    padding-left: 4rem;

    h5 {
      font-weight: 700;
      margin-bottom: .6rem;
    }

    p {
      line-height: 1.3;
    }
  }

  &-video {
    max-width: 320px;
    width: 100%;
    max-height: 320px;
    position: relative;
  }

  &-border {
    position: relative;
    display: block;

    &-top {
      &-left {
        &::before {
          content: "";
          position: absolute;
          top: 14px;
          left: 14px;
          width: 24px;
          height: 24px;
          z-index: 1;
          border-top: 4px solid $primary-color;
          border-left: 4px solid $primary-color;
        }
      }

      &-right {
        &::before {
          content: "";
          position: absolute;
          top: 14px;
          right: 14px;
          width: 24px;
          height: 24px;
          z-index: 1;
          border-top: 4px solid $primary-color;
          border-right: 4px solid $primary-color;
        }
      }
    }

    &-bottom {
      &-left {
        &::before {
          content: "";
          position: absolute;
          bottom: 14px;
          left: 14px;
          width: 24px;
          height: 24px;
          z-index: 1;
          border-bottom: 4px solid $primary-color;
          border-left: 4px solid $primary-color;
        }
      }

      &-right {
        &::before {
          content: "";
          position: absolute;
          bottom: 14px;
          right: 14px;
          width: 24px;
          height: 24px;
          z-index: 1;
          border-bottom: 4px solid $primary-color;
          border-right: 4px solid $primary-color;
        }
      }
    }
  }
}

.face-alert-modal {
  
  .modal-dialog {
    max-width: 800px;

    .modal-content {
      border: 0 none;

      .modal-body {
        .face-alert {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 1rem 1rem 0;

          @include breakpoint(phone) {
            padding: 0;
          }

          .btn-block {
            display: flex;
            justify-content: space-between;
            width: 380px;
          }

          .btn {
            position: relative;
            margin-top: 1rem;

            img {
              margin-left: 0 !important;
              position: relative;
              left: -7px;
              max-width: 29px;
            }
          }
        }
      }
    }
  }
}