.course-landing {
    min-height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .flashDealCard {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        max-width: 1000px;

        .dealsImage {
            min-width: 200px;

            img {
                max-width: 200px;
                height: auto;
                width: 100%;
            }

        }
    }
}