.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClockPointer-pointer,
.MuiPickersClock-pin,
.MuiPickersClockPointer-noPoint {
    background-color: $primary-color  !important;
}

.MuiPickersClockPointer-thumb {
    border-color: $primary-color  !important;
}

.MuiDialogActions-root {
    margin-bottom: 1rem;
    padding-right: 1.4rem !important;

    .MuiButtonBase-root {
        background-color: #27324A;

        &:hover {
            background-color: #27324A;
        }

        .MuiButton-label {
            color: $white-color;
            text-transform: initial !important;
        }
    }
}

.MuiPickersToolbarButton-toolbarBtn {
    min-width: 120px;
}

.MuiPickersBasePicker-pickerView {
    max-width: 404px !important;
}

.MuiPopover-paper {
    transform: scale(.8) !important;

}