.billing {
  &__card {
    background: $ash-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000029;
    border: 2px solid $Blue-color;
    border-radius: 20px;
    padding: 2.5rem 4.7rem;
    margin: 2rem 0 0;
    margin-top: 1rem;

    @include breakpoint(tablet) {
      padding: 1.5rem 1.5rem;
      margin: 1rem 0 3rem;
    }

    h5 {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    .bill {
      display: flex;
      flex-wrap: wrap;
      margin: 0.9rem 0;

      &__name {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        color: $text-color;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 600;

        @include breakpoint(tablet-port) {
          font-size: 0.8rem;
        }

        h5 {
          font-size: 1.5rem;
        }
      }

      &__value {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        font-size: 0.9rem;
        color: $Blue-color;
        font-weight: 600;

        @include breakpoint(tablet-port) {
          font-size: 0.8rem;
        }

        h5 {
          font-size: 1.5rem;
        }
      }
    }

    .mobile-margin {
      @include breakpoint(tablet) {
        margin-top: 0;
      }
    }
  }

  .buttons {
    display: flex;

    &__item {
      font-size: 0.8rem;
      text-transform: uppercase;
      padding-right: 1.4rem;
      color: #424242;
      letter-spacing: 0.28px;
      display: flex;
      align-items: center;

      .icon {
        padding-right: 0.8rem;
      }
    }
  }
}

// Billing Section
.billing-sec {
  color: $primary-color;

  h5 {
    margin: 0rem 0 1rem;
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
    text-transform: capitalize;
  }

  .table {
    color: $primary-color;
    min-width: 600px;
    width: auto;

    thead {
      th {
        border: 0 none;
        padding: 0;
        color: rgba(0, 72, 161, 0.6);
        font-weight: 400;
        font-size: 0.875rem;

        &.chapter-number {
          max-width: 70px;
          min-width: 70px;
          width: 70px;
        }

        &.chapter-status {
          max-width: 130px;
          min-width: 130px;
          width: 130px;
        }

        &.chapter-read-time {
          max-width: 80px;
          min-width: 80px;
          width: 80px;
        }
      }
    }

    td,
    th {
      border: 0 none;
      border-bottom: 1px solid #dee2e6;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }

    td {
      span {
        opacity: 0.6;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .orders-table {
    @media only screen and (max-width: 1000px) {
      min-width: 1000px;
    }
  }

  .orders {
    margin-bottom: 2rem;
    @media screen and (max-width: 1199px) {
      margin-bottom: 3rem;
      /* padding-right: 5rem; */
    }
  }

  .table-responsive {
    background: #ffffff8c;
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid #cccbf6;
  }

  .payment-table {
    margin-bottom: 3rem;
  }
}
.billing-sec-responsive {
  max-width: 850px;
  @media screen and(max-width:1199px) {
    max-width: auto;
  }
}
.payment-view-modal {
  &.right {
    .modal-dialog {
      max-width: 500px !important;
    }
  }

  .payment-receipt {
    background-color: #fff9e5;
    padding: 2rem;
    border-radius: 30px;
    border: 2px dashed $primary-color;
    position: relative;

    ul {
      margin: 0;

      li {
        margin-bottom: 0.6rem;

        span {
          display: block;

          &.label {
            opacity: 0.6;
            font-size: 0.875rem;
          }
        }

        &.first {
          display: inline-block;
          margin-right: 4rem;
        }

        &.second {
          display: inline-block;
        }

        &.receipt-actions {
          margin: 0;

          span {
            cursor: pointer;
            display: inline-flex;
            margin-right: 1.2rem;
            font-size: 0.9rem;

            &:last-child {
              margin-right: 0;
            }

            i {
              margin-right: 0.3rem;
              position: relative;
              top: -3px;
            }
          }
        }

        .total-lite {
          padding-top: 0.6rem;
          padding-bottom: 0rem;
          border-top: #e5ecf5 1px solid;
          border-bottom: 1px solid #e5ecf5;

          li {
            margin-bottom: 0.3rem !important;

            span {
              font-size: 0.875rem !important;
            }
          }
        }

        ul.desc-list {
          &.small-font {
            li {
              span {
                @include fontSize(14);
              }
            }
          }

          li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            &.total {
              padding: 0.25rem 0 0.15rem;
              border-top: 1px solid $primary-color;
              border-bottom: 1px solid $primary-color;

              span {
                font-weight: 900;
                text-transform: uppercase;
              }
            }

            span {
              font-size: 1rem;
              opacity: 1;
              display: inline-block;

              &.value {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  .blue-btn {
    margin-top: 2rem;
  }
}

// Add payment modal
.add-payment-modal {
  &.right {
    .modal-dialog {
      max-width: 500px !important;
    }
  }
}

// Support section
.support-sec {
  color: $primary-color;
  .form-control{
		max-width: 100%;
	}
  .support-form {
    h2 {
      @include padding-top(55);
      @include fontSize(31);
    }
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
    @include breakpoint(tablet) {
      padding-left: 1rem;
      padding-right: 1rem;

      .support-form {
        padding-left: 0;
        padding-right: 0;
      }

      img {
        max-width: 40px;
      }
    }

    .title {
      @include breakpoint(min-desktop) {
        margin-top: 0rem;
        padding-top: 1.6rem;
        @include fontSize(22);
      }
    }

    h4 {
      @include fontSize(22);
    }

    h2,
    h4 {
      font-weight: 600;
      font-family: $kansasnew-family !important;
    }

    p {
      @include breakpoint(tablet) {
        @include fontSize(15);
      }
    }

    .MuiInputLabel-outlined {
      color: $primary-color !important;
      font-size: 1rem !important;
      opacity: 1 !important;
    }

    .MuiSelect-selectMenu {
      min-height: 0.7rem !important;
    }

    .MuiOutlinedInput-input {
      padding: 16.5px 14px;
    }

    .MuiSelect-root,
    .MuiInputBase-inputMultiline {
      background-color: $white-color;
    }

    .MuiOutlinedInput-inputMultiline {
      padding: 18.5px 14px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-width: 2px;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color !important;
      box-shadow: 0px 3px 6px #00000029;
    }

    .MuiSelect-icon {
      color: $primary-color;
    }

    .PrivateNotchedOutline-legendLabelled-3 > span {
      color: $primary-color !important;
      font-size: 1rem !important;
    }

    .MuiInputBase-input {
      color: $primary-color;
    }

    .MuiInputLabel-animated.MuiInputLabel-shrink {
      opacity: 0.7 !important;
    }

    .input-file {
      .form-label {
        span.upload-btn {
          padding: 0.45rem 1.75rem;
          border-radius: 30px;
          border: 2px solid $primary-color;
          cursor: pointer;
          display: inline-flex;
          margin-top: 1rem;
          font-weight: bold;
        }
      }

      .form-control-file {
        visibility: hidden;
        opacity: 0;
        position: absolute;
      }
    }
  }

  .blue-btn {
    width: 140px;
  }

  .blue-chat-btn {
    width: auto !important;
    margin-bottom: 2.5rem;
  }
}

.live-chat-container {
  @media screen and (max-width: 1199px) {
    flex-direction: column;
    padding: 1rem;
    background: #fafafe;
    border: 1px solid #cccbf6;
    border-radius: 12px;
    margin-top: 12px;
  }

  .live-chat-info {
    @media screen and (max-width: 1199px) {
      order: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .live-chat-img {
    @media screen and (max-width: 1199px) {
      order: 1;
    }

    .image-support {
      width: 75px;
      height: 100px;
      border-radius: 14px;
      @media screen and (max-width: 1199px) {
        width: 150px;
        height: auto;
        max-width: 150px;
      }
    }
  }
}
.send-us-container {
  @media screen and (max-width: 1199px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.row-support-sec-chat {
  @media screen and (max-width: 1199px) {
    justify-content: center;
  }
}

.footer-support {
  @media screen and (max-width: 1199px) {
    display: flex;
    column-gap: 13px;
    justify-content: center;
  }
}
.profile-modal {
  .TextInputField {
    margin-bottom: 1rem;
  }

  .personalInfo {
    .TextInputField {
      margin-bottom: 1rem;
    }

    @include breakpoint(tablet) {
      margin-bottom: 0;
    }
  }
}

.savedBlod {
  font-weight: 600;
}

.payment-status {
  &.gray-color {
    color: #6a6a6a;
  }
}

.surveyModal {
  .modal-content {
    border: none;
  }

  .modal-dialog {
    max-width: 600px;
  }
}

.custom-temp {
  h1 {
    font-size: 1.63rem;
    font-weight: bold;
    color: $primary-color;
    font-family: "Josefin Sans", sans-serif;
  }

  h3 {
    font-size: 1.31rem;
    font-weight: 500 !important;
    color: $primary-color;
    font-family: "Josefin Sans", sans-serif;
    margin-bottom: 1rem;

    @include breakpoint(tablet) {
      font-size: 1.21rem !important;
    }
  }
}

.underLine {
  text-decoration: underline;
  cursor: pointer;

  &.blueLink {
    color: $primary-color;
  }
}

.cardDetails {
  .cardDetailsItem {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    p,
    h6 {
      width: 100%;
      color: #0048a1;
    }
    h6 {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}
