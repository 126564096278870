.azRegistration {
    &-status {
        h6 {
            color: #1E1E1E;
            text-align: left;
            padding-top: 1.25rem;
        }
    }

    &-items {
        color: #1E1E1E;
        @include fontSize(14);

    }

    &-item {
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 0 1rem;

        &:nth-child(odd) {
            background-color: rgba($color: #B6D8F3, $alpha: 0.1);
        }

        .date {
            min-width: 94px;
        }

        .name {
            text-align: left;
            width: 100%;
        }

        .status {
            min-width: 20px;
        }

    }

    &-nextSteps {
        padding: 0.938rem 1.25rem;

        &.warning {
            background-color: rgba($color: #FFB413, $alpha: 0.1);

        }

        p {
            margin-bottom: 0;
            text-align: left;
            @include fontSize(14);
        }
    }

}