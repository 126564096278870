.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;

  @include breakpoint(phone) {
    height: 60px;

    .nav {
      height: 61px !important;
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
    width: 100%;
    flex-wrap: wrap;
    height: 79px;

    &__logo {
      .logo {
        // max-width: 161px;
        max-height: 61px;

        @include breakpoint(phone) {
          max-width: 100px;
          flex: 0 0 100px;
        }
      }

      .spiderlmsLogo {
        max-height: 13px;

        @include breakpoint(tablet-large) {
          display: none;
        }
      }
    }

    &__menu {
      display: flex;
      align-items: center;

      // @include breakpoint(tablet) {
      //   .loginUser
      //   {
      //     display: none;
      //   }
      //   &--list {
      //     .choose_language {
      //       display: none;
      //     }

      //   }
      // }

      // @include breakpoint(phone) {
      //   flex: 0 0 calc(100% - 100px);
      // }1
      .loginUser {
        padding-right: 1.5rem;

        @include breakpoint(tablet) {
          padding-right: 1rem;

          img {
            max-width: 18px;
            margin-top: -.1rem;
          }
        }
      }

      &--list {
        margin-left: 0rem;
        cursor: pointer;

        @include breakpoint(tablet) {
          margin-left: 0.5rem;

          &:first-child {
            margin-left: 0rem;
          }

          &:nth-child(2) {
            margin-left: 0rem;
          }
        }

        .icon {

          svg {
            fill: #828282;
            font-size: 1.2rem;
          }

          img {
            max-width: 22px;
            position: relative;
            top: -1px;

            @include breakpoint(tablet) {
              max-width: 14px;
            }
          }
        }

        .dropdown-toggle::after {
          background-image: url("./../../images/icons/downArrow.svg");
          background-repeat: no-repeat;
          height: 20px;
          width: 20px;
          border: none;
          position: relative;
          top: 6px;
          margin-left: .5rem;
        }

        .dropdown {
          display: flex;
          align-items: center;

          background-repeat: no-repeat;

          .btn-primary {
            // background-color: transparent !important;
            // border: none;
            // color: $text-lite !important;
            // padding: 0 !important;

            i {
              position: relative;
              top: 1px;
            }

            @include breakpoint(phone) {
              font-size: 0.85rem;
              display: flex;
            }

            .username {
              color: $Blue-color  !important;
              display: inline-block !important;

              @include breakpoint(phone) {
                @include ellipsis(1);
                margin-left: 0.1rem;
                width: 40px;
              }
            }

            // &::after {
            //   display: none;
            // }
          }

          // .dropdown-menu {
          //   box-shadow: 0px 3px 6px #00000029;
          //   border: 1px solid $silver-color;
          //   border-radius: 15px;

          //   .dropdown-item {
          //     padding: 0.15rem 1rem;
          //     font-size: 0.95rem;
          //     color: $text-lite;

          //     &:active {
          //       background-color: transparent;
          //     }

          //     a {
          //       font-size: 0.95rem;
          //       color: $text-lite;

          //       &:hover {
          //         text-decoration: none;
          //       }
          //     }
          //   }
          // }
        }

        p {
          color: $text-color;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-size: 0.95rem;
        }
      }
    }
  }
}

.timer {
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  margin-left: 1rem;



  .timer-heading {
    font-weight: 600;
    padding: 0 !important;
    font-size: .95rem;
    cursor: default !important;
  }

  .timer__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include breakpoint(tablet-port) {
    position: absolute;
    right: 15px;
    top: 60px;
  }

  @include breakpoint(large-phone) {
    position: absolute;
    right: 15px;
    top: 40px;

    .timer__number {
      font-size: 0.9rem;
    }

    .MuiSvgIcon-root {
      fill: $primary-color;
    }

    .timer__icon img {
      max-width: 9px;
      top: -3px;
    }
  }

  &__icon {
    padding-right: 0.3rem;

    img {
      max-width: 22px;
      position: relative;
      top: -1px;

      @include breakpoint(tablet-port) {
        max-width: 18px;
      }
    }

    svg {
      fill: $primary-color;
      font-size: 1rem;
      margin-right: .25rem;
    }
  }

  &__number {
    // font-weight: 600;
    color: $primary-color;
    font-size: .75rem;
    min-width: 68px;
    line-height: 1;

    @include breakpoint(tablet-port) {
      font-size: 1.2rem;
    }
  }
}

.headerContainer {
  height: 80px;

  @include breakpoint(phone) {
    height: 60px;
  }

  &.active {
    .nav-bar {
      background-color: $lite-blue  !important;

    }
  }
}

.login-text {

  font-size: 1rem;

  a {
    color: $primary-color;
  }

  @include breakpoint(phone) {
    font-size: 0.85rem;

    a {
      position: relative;
      top: .2rem;
    }
  }
}