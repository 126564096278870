.dashboard {
  h2 {
    @include breakpoint(tablet-port) {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600 !important;

      span {
        font-weight: 900;
      }
    }

    @include breakpoint(phone) {
      padding: 0 2rem;
    }
  }

  &.expired {
    .dashboard__row {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 0;
      }
    }

    .dashboard__content--card {
      display: none;
    }

    .expired__course {
      display: block;

      @include breakpoint(phone) {
        h4 {
          justify-content: center;
        }

        p {
          width: 100% !important;
          text-align: center;
        }
      }
    }
  }

  &__content {
    position: relative;

    .td-icon {
      svg {
        fill: #9a9a9a;
      }
    }

    .syllabus {
      min-height: auto;
      max-width: 100%;

      .chapter {
        padding-left: 1rem !important;
        padding-right: 1rem !important;

        // background-color: #F5F5F5 !important;
        &__round {
          margin-right: 1.5rem !important;
        }
      }

      .chapter__round--number,
      .chapter__name,
      .border-table {
        color: #9a9a9a !important;
      }

      .school-icon {
        padding-left: 0rem !important;
        padding-right: 1rem !important;
      }

      &__table {
        h4 {
          @include breakpoint(phone) {
            color: $Blue-color;
          }
        }

        h5 {
          @include breakpoint(large-phone) {
            font-weight: 900 !important;
          }
        }

        @include breakpoint(phone) {
          .table {
            margin-bottom: 0;
          }
        }
      }
    }

    h3 {
      font-weight: 600;
    }

    &--card {
      .register__button {
        .btn {
          font-weight: 600 !important;
          line-height: 1.3;
        }

        @include breakpoint(large-phone) {
          .btn {
            display: block !important;
          }
        }
      }

      background: $lite-blue;

      p {
        color: $text-color;
        font-size: 0.95rem;
        margin-bottom: 0.3rem;
        line-height: 1.2;
      }

      h4 {
        color: $Blue-color;
        font-weight: 600;

        @include breakpoint(large-phone) {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      box-shadow: 0px 5px 25px #00000026;
      border: 2px solid $Blue-color;
      border-radius: 20px;
      padding: 2rem;

      @include breakpoint(tablet) {
        padding: 1rem;
      }

      .CustomLinearProgress {
        position: relative;

        .line {
          position: absolute;
          top: -6px;
          width: 100%;
          z-index: 1;
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
          margin-bottom: 0;

          li {
            max-width: 10%;
            width: 100%;
            list-style: none;
            position: relative;
            height: 21px;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 21px;
              width: 1px;
              background-color: $Blue-color;
              opacity: 0.5;
            }
          }
        }

        .MuiLinearProgress-colorPrimary {
          background-color: $silver-color;
          height: 8px;
        }
      }
    }
  }
}

.certificateProgress {
  background: $lite-blue 0% 0% no-repeat padding-box;
  border: 1px solid $silver-color;
  border-radius: 20px;
  padding: 1.4rem 1.4rem 2rem;

  .MuiCircularProgress-root {
    width: 110px !important;
    height: 110px !important;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 110px !important;
      height: 110px !important;
      border: 13px solid $silver-color;
      border-radius: 50%;
      z-index: -1;
    }
  }

  @include breakpoint(large-phone) {
    box-shadow: 0px 5px 15px #00000026;
  }

  &.days {
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;

    .due__description {
      font-weight: 600;
    }
  }

  h5 {
    text-align: center;
    font-weight: 900;
    font-size: 1.1rem;
    text-transform: uppercase;

    @include breakpoint(phone) {
      font-size: 1.2rem;
    }
  }

  &__list {
    padding-left: 0;

    .progress {
      height: auto;
      overflow: inherit !important;
      line-height: 1.8;
      background-color: transparent;
      justify-content: space-between;

      &__name {
        font-size: 0.9rem;
        color: $text-lite;

        &.blue-color {
          color: $Blue-color !important;
        }

        @include breakpoint(phone) {
          font-size: 1rem;
        }
      }

      &__action {
        font-size: 1rem;

        &--icon {
          .MuiSvgIcon-root {
            fill: $green-color;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .circularProgress {
    display: flex;
    justify-content: center;
    position: relative;

    &__value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $text-lite;

      &--icon {
        text-align: center;

        svg {
          fill: $green-color;
        }
      }
    }
  }

  .due {
    display: flex;
    align-items: center;

    &__date {
      font-size: 1.5rem;
      font-weight: 900;
      max-width: 40px;

      width: 100%;
    }

    &__description {
      color: $text-color;
      line-height: 1.1;
      padding-left: 0.5rem;
    }
  }
}

.certificate-border {
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #ffb739;
  border-radius: 3px;
}

.expired {
  &__course {
    position: relative;
    z-index: 1;
    box-shadow: 0px 5px 15px #00000026;
    border: 2px solid $Blue-color;
    border-radius: 20px;
    padding: 2rem;
    background-color: $ash-color;
    margin-top: 1rem;
    display: none;

    h4 {
      color: #7f7f7f;
      font-weight: 600;
    }

    p {
      color: $text-color;
      font-size: 0.95rem;
    }

    &.active {
      display: block;
    }
  }
}

.bar-line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 15px;

  .bar-line-left,
  .bar-line-right {
    width: 100%;
  }

  .bar-line-left {
    flex: 0 0 calc(100% - 66px);
    max-width: calc(100% - 66px);
  }

  .bar-line-right {
    flex: 0 0 calc(66px);
    max-width: calc(66px);
    overflow: hidden;

    img {
      border: 2px solid $yellow-color;
      border-radius: 5px;
    }
  }
}

.course-challenge {
  @include breakpoint(tablet-port) {
    font-weight: 600;
  }
}

.pageNotFound {
  &__img {
    @include breakpoint(large-phone) {
      width: 100%;
    }
  }

  #info {
    h3 {
      font-size: 1.3rem;
    }
  }
}

#upsellModal {
  max-width: 672px;

  .modal-content {
    border: none;
  }

  //   .upsellModalContainer
  //   {

  //   margin: 0 auto;
  //  }
  .register__button .btn {
    padding: 0.6rem 1.7rem;
  }

  .continue {
    display: flex;
    justify-content: center;
    margin-top: 1.6rem;

    @include breakpoint(tablet-large) {
      margin-top: 0.8rem;
    }

    &__container {
      max-width: 80%;
      width: 100%;
      border: 2px solid #9a9a9a;
      border-radius: 20px;
      background-color: $text-lite;
      display: flex;
      justify-content: center;

      @include breakpoint(tablet-large) {
        background-color: #f5f5f5;
        flex-wrap: wrap;
      }

      &--text {
        letter-spacing: 0.08px;
        color: #dddddd;
        margin: 0;
        text-align: center;
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .color-text {
          color: $orange-color;
          font: normal normal 900 1.125rem "Lato", sans-serif;
          margin-left: 1.8rem;
          display: flex;
          align-items: center;

          i {
            padding-left: 0.4rem;
          }
        }

        @include breakpoint(tablet-large) {
          color: $text-color !important;
          max-width: 100%;
          width: 100%;
          padding: 0 !important;
          text-align: center;

          &.color-text {
            margin-left: 0 !important;
          }
        }

        &.color-text {
          color: $orange-color !important;
          font: normal normal 900 1.125rem "Lato", sans-serif;
          margin-left: 1.8rem;
          display: flex;
          align-items: center;

          i {
            padding-left: 0.4rem;
          }
        }
      }
    }
  }

  .special-offers {
    background-color: $white-color;
    border-radius: 20px 20px;
    width: 100%;
    flex-wrap: wrap;

    .items {
      max-width: 33.33%;
      width: 100%;
    }
  }

  .offers {
    border-radius: 20px 20px;
    padding: 0 0.3rem;

    &__header {
      padding: 0.8rem 3.8rem;
      min-height: 40px;
      width: 100%;

      @include breakpoint(tablet-large) {
        padding: 0.8rem 1.8rem;
      }

      .title {
        letter-spacing: 0.36px;
        font-size: 1.125rem;

        @include breakpoint(tablet-large) {
          font-size: 1.1rem;
          line-height: 1.3;
          text-align: left;
        }
      }
    }

    &__body {
      padding: 1.5rem 3.8rem;

      @include breakpoint(tablet-large) {
        padding: 0.8rem 1.8rem;
      }

      min-height: 120px;

      p {
        font-size: 0.95rem;
        letter-spacing: 0.08px;
        color: $text-color;
        line-height: 1.6;
      }

      border-left: 3px solid $orange-color;
      border-right: 3px solid $orange-color;

      @include breakpoint(tablet-large) {
        padding-bottom: 3rem;
      }
    }

    &__footer {
      padding: 1rem 3.8rem;

      @include breakpoint(tablet-large) {
        padding: 0.8rem 1.8rem;
      }

      position: relative;

      &--left {
        width: 100%;
      }

      &--left,
      &--right {
        @include breakpoint(tablet-large) {
          padding-top: 1rem;
        }

        .items {
          position: relative;

          h5 {
            font-size: 1.4rem;
          }

          @include breakpoint(tablet-large) {
            h5 {
              font-size: 1.3rem;
            }

            .icon {
              left: -27px;
            }

            &:nth-child(3) {
              position: absolute;
              max-width: 90%;
              width: 100%;
              top: -4rem;
            }
          }
        }

        p {
          color: #424242;
          font-size: 0.9rem;
          padding-left: 1.7rem;

          @include breakpoint(tablet-large) {
            padding-left: 0;
          }
        }

        .icon {
          margin-right: 0.2rem;

          svg {
            font-size: 1.2rem;
          }
        }
      }

      @include breakpoint(tablet-large) {
        &--left {
          order: 2;
        }

        &--right {
          order: 3;
        }

        .register__button {
          order: 1;
        }
      }

      &:before {
        height: 112px;
      }
    }

    .register__button {
      .btn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.singleCourse {
  @include padding(30, 30);

  .card-body,
  .card-footer {
    padding: 0 !important;

    .play-btn {
      min-width: 169px;
      justify-content: space-between;
    }
  }

  @include breakpoint(tablet) {
    .card-footer {
      margin-top: 0.8rem;

      .btn {
        // max-width: 400px;
        // width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.new-dashboard {
  color: $primary-color;
  padding-bottom: 92px !important;

  .loader {
    position: absolute;
    width: 100%;
  }

  .page-title {
    padding-top: 2rem;

    @include breakpoint(large-phone) {
      padding-top: 1rem;
      font-weight: 500;
    }

    width: 100%;
    background-color: #f0f8ff;

    div.other-courses {
      display: flex;
    }

    .dropdown-toggle {
      &:after {
        display: none;
      }

      span {
        width: 15px;
        height: 15px;
        border: 2px solid $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transform: rotate(90deg);
        font-size: 13px;
        margin-top: 6px;
      }
    }

    .dropdown-menu {
      border-radius: 30px;
      background-color: $primary-color;
      padding: 1rem;

      .dropdown-item {
        border-radius: 16px;
        color: $white-color;
        padding: 0.25rem 1.25rem;
        margin: 0.5rem 0;

        &:hover {
          background-color: #235bab;
        }
      }
    }
  }

  .left-sec {
    padding-right: 2rem;
  }

  .course-card {
    &.completed {
      background-color: #ffe68f;

      @include breakpoint(tablet) {
        .congratulations {
          font-weight: 400;
          @include fontSize(22);
        }

        .survey a {
          @include fontSize(16);
          width: 100%;
        }

        .certificate-icon {
          width: 38px;
          padding-top: 0.4rem;
        }
      }

      .card {
        &-body {
          padding: 2rem 7rem 0 2rem;

          @include breakpoint(tablet) {
            padding: 2rem 2rem 0 1.2rem;
          }
        }

        &-name {
          @include fontSize(14);
          color: #ff6a37;
          font-weight: 400;
          margin-bottom: 0.8rem;

          @include breakpoint(tablet) {
            text-align: center;
          }
        }

        &-title {
          font-weight: 500;
        }

        &-footer {
          background-color: #ffe68f;
        }
      }
    }
    .course-card__body-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20%, 32.3125rem));
      grid-template-rows: repeat(auto-fit, minmax(0rem, 1fr));
      gap: 24px;
      .course-card__body {
        grid-column: 1 / 2;
        p {
          word-break: break-word;
        }
      }
      .course-card__image {
        grid-column: 2 / 3;
        place-self: center;
        grid-row: 1 / 3;
      }
      .course-card__footer {
        grid-column: 1 / 2;
        @media screen and (max-width: 1199px) {
          justify-content: center;
        }
      }

      @include breakpoint(min-desktop) {
        .course-card__body,
        .course-card__image,
        .course-card__footer {
          grid-column: 1 / 3;
        }
        .course-card__image {
          grid-row: 2 / 3;
        }
      }
    }

    .card-text-config {
      min-height: 120px !important;
      -webkit-line-clamp: 5 !important;
    }

    @include breakpoint(large-phone) {
      margin: 0;
    }

    background-color: #b6d8f3;
    box-shadow: 0px 3px 2px #00000029;
    border: 0 none;
    width: 100%;
    min-height: 240px;

    &.white {
      background-color: $white-color !important;

      .item {
        h6 {
          font-weight: 600;
        }

        p {
          margin-bottom: 0.6rem;
        }
      }
    }

    .item {
      padding: 1.5rem;

      h6 {
        padding-top: 1.125rem;
        padding-bottom: 0.688rem;
        margin-bottom: 0;
      }

      p {
        line-height: 1.1;
      }

      img {
        width: 100%;
      }

      .audio-button {
        background: transparent;
        border: 2px solid $primary-color;
        width: 119px;
        min-height: 34px;
        border-radius: 25px;
        @include fontSize(14);
        color: $primary-color;
        text-align: center;

        img {
          max-width: 15px;
          margin-right: 0.4rem;
        }
      }

      .add-button {
        background: transparent;
        border: none;
        min-height: 34px;
        border-radius: 25px;
        @include fontSize(14);
        color: $primary-color;
        text-align: center;

        img {
          max-width: 31px;
          margin-right: 0.4rem;
        }
      }
    }

    &:hover {
      border: 0 none;
    }

    .card-footer {
      background-color: #b6d8f3;
    }

    .course-img {
      @include breakpoint(tablet) {
        position: inherit;
        transform: none;
        padding: 0 !important;
      }

      img {
        max-height: 170px;
        max-width: 260px;
        border-radius: 5px;
      }
    }
  }

  .text-complete {
    -webkit-line-clamp: unset !important;
  }
  .open-items {
    margin-bottom: 2rem;

    .open-items-title {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    form {
      @include breakpoint(large-phone) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .form-group {
          max-width: 50%;
          width: 100%;
        }
      }

      .form-group {
        margin-bottom: 0.75rem;

        .form-check {
          padding-left: 1.65rem;
        }

        .form-check-input {
          transform: scale(1.7);
        }

        .form-check-label {
          margin: 2px 0 0 10px;

          @include breakpoint(tablet) {
            line-height: 1.1;
          }
        }
      }
    }

    @include breakpoint(tablet) {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  .citation-info {
    .citation-info-title {
      margin: 0;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .edit-info {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 0.5rem;
      text-decoration: underline;
    }

    ul {
      li {
        margin-bottom: 0.5rem;

        span {
          display: block;

          &.label {
            font-size: 0.875rem;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .course-progress {
    position: fixed;
    bottom: 0;
    text-align: right;
    width: 100%;
    background: $white-color;
    left: 0;
    background-color: $white-color;
    padding: 1rem 2rem 1rem 232px;
    z-index: 5;
    box-shadow: 0px 0px 6px #00000029;

    @include breakpoint(min-desktop) {
      padding: 1rem;
    }

    h6 {
      margin: 0;
      font-weight: 700;

      @include breakpoint(tablet) {
        font-weight: 400;
        min-width: 116px;
      }

      span {
        font-weight: 400;
      }
    }

    .left-sec {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .progress {
      width: 50%;
      border-radius: 0.5rem;

      p {
        margin: auto;
        line-height: 1.5;
        justify-content: center;
      }

      .progress-bar {
        background-color: #b6d8f3;
        border-radius: 0.5rem;
      }
    }

    .days-left {
      display: flex;
      align-items: center;

      @include breakpoint(tablet) {
        display: none !important;
      }
    }
  }

  // Other courses
  .other-courses {
    &.bottom {
      .course-card {
        border: 2px solid $primary-color;
      }

      .blue-btn {
        font-weight: 600;
      }
    }

    h4 {
      font-family: $kansasnew-family !important;
      font-weight: bold;
      font-size: 1.25rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .course-card {
      border-radius: 20px;

      &:hover {
        border: 2px solid $primary-color;
      }

      border: 2px solid $white-color;
      background-color: #f0f8ff;
      min-height: auto;
      box-shadow: none;

      .card-body {
        padding: 1.75rem 1.75rem 0 1.75rem;
      }

      .card-footer {
        background-color: #f0f8ff;

        .chapter-name {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @include breakpoint(tablet) {
    padding-bottom: 42px !important;
  }
}

.driver-container {
  h5 {
    font-family: $kansasnew-family !important;
    @include fontSize(18);
    font-weight: 600;
  }

  img {
    width: 100%;
    max-width: 200px;

    margin-bottom: 0.6rem;
  }

  p {
    max-width: 250px;
  }

  .link {
    color: $primary-color;
    text-decoration: underline;
  }

  @media (min-width: 690px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    max-width: 60%;

    h5,
    p,
    a {
      text-align: center;
      max-width: 60%;
    }
    img {
      align-self: center;
    }
  }

  @include breakpoint(tablet) {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  @include breakpoint(phone) {
    margin-top: 0.4rem;
    margin-bottom: 1.3rem;
  }

  @include breakpoint(phone) {
    margin-top: 0.4rem;
    margin-bottom: 3rem;
    padding-bottom: 25x;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    h5,
    p,
    a {
      text-align: center;
      max-width: 100%;
    }
    img {
      align-self: center;
    }
  }
}

// Add payment modal
.citation-info-modal {
  &.right {
    .modal-dialog {
      max-width: 500px !important;
    }
  }
}

// Account lock modal

.white-rounded-modal {
  .modal-dialog {
    .modal-content {
      background-color: $white-color !important;
      border: 0;

      .modal-body {
        padding: 2rem 2.5rem 3rem 2.5rem;

        .btn {
          font-size: 1.125rem;

          padding: 0.6rem 1.7rem 0.5rem;
        }
      }
    }
  }
}

.blue-rounded-modal {
  .modal-dialog {
    .modal-content {
      @include breakpoint(phone) {
        min-width: inherit;
        max-width: calc(100% - 40px) !important;
        margin: auto;
      }

      min-width: 700px;
      background-color: #f0f8ff !important;
      border: 3px solid #0048a1;

      .para_style {
        font-size: 19px;
        color: #0048a1;
      }

      .modal-body {
        padding: 2rem 2.5rem 2rem 2.5rem;

        .btn {
          font-size: 1.125rem;

          padding: 0.6rem 1.7rem 0.5rem;
        }

        .buttons-container {
          justify-content: right;

          button {
            @include breakpoint(phone) {
              width: 100%;
            }

            width: auto;
            border-radius: 19px !important;
            background: transparent;
            border: 2px solid #0048a1;
            color: #0048a1;

            &:hover {
              color: #fff;
              background-color: #0048a1;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
          }
        }
      }
    }
  }
}

.quickBreak {
  max-width: 140px;

  &-container {
    .securityCheck__card--body {
      .body {
        max-width: 586px;
        padding: 1.5rem;

        @include breakpoint(large-phone) {
          max-width: calc(100% - 2rem);
        }

        p {
          line-height: 1.3;
          padding-left: 1rem;

          @include breakpoint(phone) {
            padding-left: 0rem;
          }
        }
      }
    }
  }

  @include breakpoint(phone) {
    max-width: 100%;
  }
}

.timeExceededModal {
  .modal-dialog {
    max-width: 440px;
  }

  &-body {
    h4 {
      font-size: 1.375rem;

      @include breakpoint(phone) {
        font-size: 1.175rem;
        font-weight: bold;
      }
    }

    h5 {
      font-size: 1.125rem;
    }

    .redColor {
      color: #ff6a37;
      font-size: 1.25rem;
    }

    .text-style {
      color: $primary-color;
      line-height: 1.3;
      font-weight: 600;
      text-align: center;
    }
  }
}
.sutdent-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50% !important;
    margin-top: 20px;
    p {
      color: rgba(0, 72, 161, 0.6);
      font-size: 0.875rem;
      line-height: 1.1;
    }
    h6 {
      color: #0048a1;
      border-bottom: 1px solid rgba(0, 72, 161, 0.1);
      padding-bottom: 0.2rem;
    }
  }
}
.account-lock-modal,
.acknowledge-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      background-color: $white-color !important;

      .modal-body {
        padding: 2rem;

        h5 {
          color: #7f7f7f;
          font-family: $kansasnew-family !important;
          font-weight: bold;
          font-size: 2rem;
        }

        p {
          font-size: 1.125rem;
          // word-break: break-all;

          @include breakpoint(phone) {
            font-size: 1rem;
          }
        }

        .btn {
          font-size: 1.125rem;
          text-transform: uppercase;
          padding: 0.8rem 1.7rem 0.65rem !important;

          @include breakpoint(phone) {
            padding: 0.4rem 0.9rem 0.4rem !important;
          }

          &.modal-btn {
            font-size: 0.93rem;
            margin: 0.5rem;
          }
        }
      }
    }
  }
}

.survey-modal {
  height: auto !important;

  .page-title {
    padding: 10px 0px !important;
  }

  .survey-iframe {
    min-height: 79vh;
  }
}

.emailVerify-modal {
  .modal-content {
    border-radius: 2px !important;
  }

  .modal-dialog {
    max-width: 535px;

    .content {
      .right {
        max-width: 335px;
        width: 100%;

        h3 {
          @include fontSize(24);
          color: $primary-color;
          font-weight: bold;
        }

        p {
          @include fontSize(18);
          color: #1e1e1e;
          line-height: 1.4;
        }

        .underLine {
          color: $primary-color;
          font-size: 0.8rem;
          text-align: right;
          margin-top: 0.3rem;
        }

        .validationEmailInput {
          display: flex;

          .form-verificationEmail {
            border: 1px solid $primary-color;
            border-radius: 15px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 40px;
            max-width: 189px;
            width: 100%;
            padding: 0 1rem;
            outline: none;
            border-right: 0;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            color: $primary-color;
            font-size: 1.4rem;
            letter-spacing: 3px;

            &::placeholder {
              color: rgba($color: $primary-color, $alpha: 0.61) !important;
              font-size: 1rem;
              letter-spacing: 0px !important;
            }

            &:focus {
              box-shadow: 0 0 0 0.1rem rgba($color: $primary-color, $alpha: 0.3);
            }
          }

          .submitVerificationButton {
            width: 146px;
            height: 40px;
            border-radius: 0px 15px 15px 0px;
            background-color: #228b22;
            border: none;
            color: $white-color;
            cursor: pointer;

            &:disabled {
              pointer-events: none;
              opacity: 0.65;
            }
          }
        }
      }
    }
  }

  .modal-body {
    padding: 2rem !important;
  }
}

.acknowledge-modal {
  .modal-dialog {
    max-width: 800px;

    .modal-content {
      border: 0 none;

      .modal-body {
        .acknowledgement {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 1rem 1rem 0;

          @include breakpoint(phone) {
            padding: 0;
          }

          .form-check-input {
            transform: scale(1.7);
          }

          .form-check-label {
            color: $primary-color;
            font-size: 1.25rem;
            margin-left: 1rem;
            margin-top: -0.2rem;
          }

          .btn {
            position: relative;
            margin-top: 1rem;

            img {
              margin-left: 0 !important;
              position: relative;
              left: -7px;
              max-width: 29px;
            }
          }
        }
      }
    }
  }
}

.security-check-modal {
  .modal-dialog {
    max-width: 800px;

    .modal-content {
      border: 0 none;
    }
  }

  .securityCheck {
    min-height: 30vh;
  }
}

.text-transform-inherit {
  text-transform: inherit !important;
}

.discount-modal {
  .modal-dialog {
    max-width: 730px;

    .modal-close {
      right: 2rem;
      position: absolute;
    }

    .modal-content {
      border: 1px solid $primary-color;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -8px;
        left: -8px;
        background-image: url(./../../images/discount.svg);
        background-repeat: no-repeat;
        height: 156px;
        width: 156px;
      }

      .modal-body {
        padding: 2rem;

        .courses-card {
          .info-text {
            color: $primary-color;
            @include fontSize(14);
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
            line-height: 1.1;
          }

          .skip {
            background-color: #f0f8ff;
            border-radius: 20px;
            padding: 0.75rem;
            max-width: 400px;
            margin: 0 auto;
            color: $primary-color;
            @include fontSize(14);
            text-align: center;
            text-decoration: underline;
          }

          h1 {
            color: $primary-color;
            @include fontSize(32);
            font-weight: 600;
            margin-top: 2.4rem;
          }

          .item-container {
            border: 2px solid $primary-color;
            border-radius: 20px;

            .header {
              background-color: $primary-color;
              color: $white-color;
              @include fontSize(24);
              border-radius: 16px 16px 0px 0px;
              padding: 0.55rem;
              font-weight: 600;
            }

            .body {
              background-color: #fff9e5;
              border-radius: 0px 0px 20px 20px;
              padding: 1.8rem;

              .item {
                .top-level {
                  img {
                    width: 110px;
                    border: 1px solid $primary-color;
                    border-radius: 5px;
                  }
                }

                .bottom-level {
                  margin-top: 0.8rem;

                  p {
                    margin-bottom: 0.4rem;
                    text-align: right;
                    color: $primary-color;
                    @include fontSize(14);
                  }

                  .discount-price {
                    color: #228b22;
                  }
                }

                .description {
                  padding: 0 1.5rem;

                  h6 {
                    color: $primary-color;
                    font-weight: 600;
                  }

                  p {
                    color: $primary-color;
                    line-height: 1.2;
                  }
                }

                .price {
                  min-width: 120px;

                  p {
                    @include fontSize(14);
                    color: #6186b8;
                  }

                  &-inc {
                    width: 80px;
                    height: 32px;
                    border: 2px solid $primary-color;
                    border-radius: 20px;
                    color: $primary-color;
                  }
                }
              }

              .buttons-container {
                .outline-button {
                  background-color: transparent;
                  border-radius: 20px;
                  border: 2px solid $primary-color;
                  max-height: 35px;
                  display: flex;
                  padding: 0.3rem 1.3rem 0.2rem;
                  align-items: center;
                  @include fontSize(14);
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

.survey {
  min-height: 43px;
  display: flex;
  align-items: center;
  a {
    text-decoration: underline;
    font-size: 20px;
    margin-bottom: 0;
    padding-left: 40px;
    cursor: pointer;
  }
}

.survey-iframe {
  min-height: 100vh;
}

.modal-white {
  .modal-content {
    background-color: $white-color !important;
  }
}

.smsTextModal {
  .modal-close {
    position: absolute;
    right: 1.3rem;
  }

  .modal {
    &-dialog {
      max-width: 344px;

      @include breakpoint(large-phone) {
        max-width: 304px;
        margin: 0.5rem auto;
      }
    }

    &-body {
      padding: 1.3rem !important;
      text-align: center;

      h5,
      p,
      .amSure {
        color: $primary-color;
        line-height: 1.2;
      }

      p {
        @include fontSize(14);
      }

      .amSure {
        text-decoration: underline;
        cursor: pointer;
      }

      .check {
        label {
          margin-bottom: 0;
        }
      }

      .custom__check {
        background-color: $white-color;
      }

      .form-group,
      .check {
        display: inline-flex;
        background-color: rgba($color: #ff0000, $alpha: 0.1);
        margin-bottom: 0;
        @include padding(8, 21, 8, 21);
        border-radius: 20px;
        max-width: 210px;
        width: 100%;

        .form-check-input,
        .input {
          margin-left: -0.1rem;
          width: 23px;
          height: 23px;
          margin-top: 0rem;
        }

        .form-check-label,
        .label {
          @include padding-left(12);
          color: $primary-color;
        }
      }

      .confirm-email-btn-block {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.login-modal {
  .modal {
    &-dialog {
      max-width: 790px;

      @include breakpoint(large-phone) {
        max-width: 304px;
        margin: 0.5rem auto;
      }
    }

    &-body {
      padding: 2.3rem !important;
      text-align: center;

      h5,
      p,
      .amSure {
        color: $primary-color;
        line-height: 1.3;
        // font-size: 0.975rem !important;
      }

      p {
        @include fontSize(16);
      }

      .confirm-email-btn-block {
        display: flex;
        justify-content: center;
        gap: 8px;
      }
    }
  }
}

.other-image {
  max-width: 100%;
  max-height: 100px;
}

.multiCourseDashboard {
  .card-body {
    padding: 1.75rem 1.75rem 0 !important;
  }

  .play-btn {
    min-height: 50px;
    min-width: 169px;
    justify-content: space-between;
  }

  .courseList-card-top {
    h3 {
      font-weight: 700;
      @include fontSize(22);
      // font-weight: 500;
    }

    p {
      font-weight: 500;
    }
    @media screen and (max-width: 1199px) {
      flex-wrap: wrap;
    }
    @include breakpoint(tablet) {
      flex-wrap: wrap;

      h3 {
        @include fontSize(18);
      }

      .course-progress {
        order: 1;
        display: flex;
        align-items: baseline;
        margin-top: 0.4rem;
        @media screen and(max-width:1199px) {
          flex-direction: column;
          gap: 12px;
          margin-bottom: 24px;
          justify-content: center;
        }
        .progress {
          margin-right: 0.9rem;
        }
      }
    }
  }

  .chapter-name {
    font-size: 1.125rem !important;
    font-weight: 500 !important;
  }
}

.elmsLogoLarge {
  max-width: 118px;
  margin-bottom: 1rem;
  width: 100%;
  margin-left: 0.8rem;

  &.collapsed {
    margin-left: 0.2rem;
    @media screen and (max-width: 1200px) {
      margin-left: 0.8rem;
    }
  }

  @include breakpoint(desktop) {
    margin-bottom: 0.5rem;
  }
}

.configCourse-width {
  width: calc(100% - 280px);

  @include breakpoint(tablet) {
    width: 100%;
  }
}

.certificate-completed-btn {
  width: 190px;
  border-color: #235bab;
  color: $white-color;
  background-color: #235bab !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  @include fontSize(14);
  padding: 0.475rem 0.75rem;
  font-weight: bold;

  &:active {
    color: $white-color;
  }

  &-sm {
    @include fontSize(12);
    padding: 0.3rem 0.75rem;
    font-weight: 400;
  }

  @include breakpoint(tablet) {
    @include fontSize(12);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #235bab !important;
    color: $white-color;
  }

  img {
    max-width: 24px;
  }
}

.otherUpsell {
  border-radius: 20px;
  padding: 1.5rem;
  background-color: $white-color;
  margin-bottom: 1rem;

  &-img {
    margin-bottom: 1.5rem;
    min-height: 110px;
    width: 100%;
    border-radius: 5px;
    background-color: rgba($color: $primary-color, $alpha: 0.1);
  }

  h6 {
    @include fontSize(16);
    font-weight: 600;
  }

  p {
    @include fontSize(14);
  }
}

.otherUpsell-addText {
  @include fontSize(12);
}

.viewCart {
  position: relative;

  &-info {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
  }

  &-items {
    position: absolute;
    top: 3px;
    right: -7px;
    width: 14px;
    height: 14px;
    background-color: #e00000;
    font-size: 8px;
    color: $white-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.acknowledgeCheck {
  max-width: 100%;

  .form-check {
    position: relative;
    top: 0.1rem;
    margin-right: 0.4rem;
  }

  ol {
    li {
      padding: 5px 0;

      a {
        word-break: break-all;
      }

      span {
        background-color: transparent !important;
      }
    }
  }

  .acknowledge-text {
    width: 94%;

    p {
      span {
        background-color: transparent !important;
      }
    }
  }
}

.agreeField {
  &-control {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    height: 60px;
    font-size: 1.3rem !important;
    color: $primary-color !important;

    &::placeholder {
      color: rgba($color: $primary-color, $alpha: 0.6) !important;
      font-size: 1.3rem !important;
    }
  }
}

.border-6 {
  border-radius: 6px !important;
}

.certificate-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 265px;
}

.open-itemlist-config {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 1025px) {
    width: 50%;
    flex-direction: column;
  }
  @media (min-width: 690px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  @include breakpoint(phone) {
    width: 100%;
    flex-direction: column;
  }
}
