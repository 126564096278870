.app {
  min-height: 100%;
}

body {
  @include scrollbar(0.4rem, slategray);
  background: #F1F6FD;
}

.pageBackground {
  min-height: 100vh;

  &.lighten-green {
    background-color: $lite-blue;

    .header-container {
      background-color: $lite-blue;
    }
  }

  &.lighten-blue {
    .header-container {
      background-color: #f0f8ff;
    }

    background-color: #f0f8ff;
  }

  &.lighten-wave {
    .header-container {
      background-color: #f0f8ff;
    }

    background-image: url('./../../images/waveBg.svg');
    background-repeat: no-repeat;
    background-color: $white-color;
  }


  &.white {
    .header-container {
      background-color: #fff;
    }

    background-color: #fff;
  }
}

.main-container {
  padding-bottom: 40px;

  &.addingHeaderPadding {
    padding-top: 80px;

    @include breakpoint(phone) {
      padding-top: 60px;
    }
  }

  padding-left: 220px;

  @include breakpoint(desktop) {
    padding-left: 200px;
  }

  @include breakpoint(min-desktop) {
    padding-left: 0px;
    // section {

    //   padding: 0 1rem 2rem !important;
    // }
  }

  // padding-top: 92px;
  // padding-bottom: 72px;
  transition: width 0.3s,
  left 0.3s;

  section {
    padding: 2rem 2rem 2rem;
    position: relative;

    .loader {
      z-index: 7;
    }

    &.new-dashboard {
      .loader {
        z-index: 7;
      }
    }
  }
}

.blueCard {
  box-shadow: 0px 3px 15px #00000029;
  border: 2px solid $Blue-color;
  border-radius: 20px;
  background-color: $lite-blue;
  padding: 1.5rem 1rem;
}

.securityFooter {
  display: flex;
  align-items: center;

  &__icon {
    background: $white-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    width: 32px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.3rem;
  }

  p {
    font-size: 0.88rem;
    color: $text-color;
  }
}

.list-inner-scroll {
  max-height: 1100px;
  @include scrollbar(0.4rem, slategray);
}

.layout-top,
.layout {
  padding-top: 1.8rem;
  min-height: calc(100vh - 192px);

  @include breakpoint(tablet-port) {
    padding-top: 0rem;
  }

  // .font-weight-bold
  // {
  //     font-weight: 900 !important;
  // }
}

.modal {
  .loader {
    width: 100%;
  }
}

.priority {
  z-index: 10001 !important;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0;
  z-index: 8;
  background-color: rgba(#fff, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;

  &.AZLoader {
    z-index: 99 !important;
    background-color: $white-color;
  }

  &.innerBox {
    width: 100%;
    height: 100%;
  }

  span {
    background-color: $primary-color;

    span {
      background-color: $primary-color;
    }
  }

  .loader-round {
    background-color: rgba($primary-color, 0.3);
    width: 60px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiCircularProgress-colorPrimary {
      color: $primary-color;
    }
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: $primary-color;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: rgba($primary-color, 0.3);
  }

  &.inner {
    position: absolute;
    z-index: 6;
    height: 100% !important;
    top: 0px !important;
  }

  &.payment-loader {
    flex-direction: column;
    gap: 3rem;
    z-index: 999;
  }
}

.MuiMenu-list {
  max-height: 400px;
  @include scrollbar(0.4rem, slategray);
}

.dboard {
  display: flex;
  align-items: center;

  @include breakpoint(tablet-port) {
    display: none;
  }

  &__icon {
    img {
      max-width: 26px;
    }
  }

  &__name {
    color: $text-lite;
    font-size: 1rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bodySection {
  padding-top: 80px;
  min-height: calc(100vh - 61px);

  @include breakpoint(tablet-large) {
    padding-top: 60px;
  }
}


.loader-parent {
  position: relative;
}