//Push auto
@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

//::before ::after
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

//Placeholders
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

// Cover Background
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin primary-btn-hover {
  background-color: transparent !important;
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
}

@mixin outline-btn-hover {
  background-color: $primary-color !important;
  color: $white-color !important;
  border: 1px solid $primary-color !important;
}

@mixin orange-btn-hover {
  background-color: transparent !important;
  color: #ff6600 !important;
}

@mixin scrollbar($size,
  $foreground-color,
  $background-color: mix($foreground-color, rgba($primary-color, 0.1), 0%)) {
  overflow: auto;

  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin horizontal-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparentize($silver-color, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: transparentize($silver-color, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
}

@mixin ellipsis($line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@mixin fontSize($value) {
  font-size: ($value / 16) + rem;
}

@mixin padding($top: $top, $right: $top, $bottom: $top, $left: $top) {
  padding: ($top / 16) + rem ($right / 16) + rem ($bottom / 16) + rem ($left / 16) + rem;
}

@mixin padding-x($top: 0, $right: $right, $bottom: 0, $left: $right) {
  padding: ($top / 16) + rem ($right / 16) + rem ($bottom / 16) + rem ($left / 16) + rem;
}

@mixin padding-y($top: $top, $right: 0, $bottom: $top, $left: 0) {
  padding: ($top / 16) + rem ($right / 16) + rem ($bottom / 16) + rem ($left / 16) + rem;
}

@mixin padding-left($left: 0) {
  padding-left: ($left / 16) + rem;
}

@mixin padding-right($right: 0) {
  padding-right: ($right / 16) + rem;
}

@mixin padding-top($top: 0) {
  padding-top: ($top / 16) + rem;
}

@mixin padding-bottom($bottom: 0) {
  padding-bottom: ($top / 16) + rem;
}

@mixin margin($top: 0, $right: 0, $bottom: 0, $left: 0) {
  margin: ($top / 16) + rem ($right / 16) + rem ($bottom / 16) + rem ($left / 16) + rem;
}

@mixin margin-left($left: 0) {
  margin: ($left / 16) + rem;
}

@mixin margin-right($right: 0) {
  margin-right: ($right / 16) + rem;
}

@mixin margin-top($top: 0) {
  margin-top: ($top / 16) + rem;
}

@mixin margin-bottom($bottom: 0) {
  margin-bottom: ($bottom / 16) + rem;
}

@mixin breakpoint($breakpoint) {
  @if $breakpoint==small-phone {
    @media (max-width: 375px) {
      @content;
    }
  }

  @if $breakpoint==phone {
    @media (max-width: 520px) {
      @content;
    }
  }

  @if $breakpoint==large-phone {
    @media (max-width: 680px) {
      @content;
    }
  }

  @if $breakpoint==tablet-port {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==tablet-large {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint==tablet-land {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==min-desktop {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint==apple-desktop {
    @media (max-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media (max-width: 1366px) {
      @content;
    }
  }

  @if $breakpoint==apple-desktop {
    @media (max-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint==large-desktop {
    @media (max-width: 1680px) {
      @content;
    }
  }

  @if $breakpoint==large-then-desktop {
    @media (max-width: 1920px) {
      @content;
    }
  }
}