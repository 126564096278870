.accept-checkbox {
    .MuiFormControlLabel-root {
        @include breakpoint(large-phone) {
            align-items: flex-start;
        }
    }

    .MuiFormControlLabel-label {
        font-size: 0.75rem;
        color: $text-color;
        line-height: 1.4;
    }

    .MuiIconButton-root {
        padding: 1px 9px 9px;
    }
}

.payment {
    @include breakpoint(phone) {
        margin-top: 0 !important;
    }

    &__card {
        .register {
            h6 {
                font-size: 1.1rem;
            }
        }
    }

    .register {
        min-height: 431px;

        @include breakpoint(tablet-large) {
            h4 {
                margin-bottom: 0.5rem;
            }
        }

        .brinTree-container {
            position: relative;

            @include breakpoint(phone) {
                margin-top: 0rem;
                padding-top: 0rem;
                min-height: auto;
            }

            min-height: 283px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .cart-btn {
                .btn {
                    @include breakpoint(tablet) {
                        width: 100%;
                        justify-content: center;
                    }
                }
            }

            > div {
                width: 100%;
            }
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
            color: $Blue-color;
        }

        .MuiSvgIcon-root {
            font-size: 1.3rem;
        }
    }
}

.payment__details {
    @include breakpoint(tablet-large) {
        margin-top: 1rem;
    }

    .products-details {
        min-height: 491px;
        background: $white-color 0% 0% no-repeat padding-box;
        border: 1px solid $silver-color;
        border-radius: 20px;
        padding: 2rem;

        @include breakpoint(tablet) {
            padding: 1.2rem;
        }

        .products-details-title {
            font-size: 1.2rem;
            padding-bottom: 0.6rem;
        }

        h6 {
            font-weight: 600;
            padding-bottom: 0.3rem;
        }

        .products,
        .price {
            border-top: 1px solid $silver-color;
            padding-left: 0;
            padding-top: 0.5rem;

            &__list {
                display: flex;
                list-style: none;
                justify-content: space-between;
                margin: 0.55rem 0;
                flex-wrap: wrap;

                .blue-color {
                    text-decoration: underline;
                    margin-left: 0.6rem !important;
                }

                &--name,
                &--value {
                    color: $text-color;
                    font-size: 0.9rem;

                    .icon {
                        svg {
                            font-size: 1rem;
                        }

                        img {
                            max-width: 11px;
                        }
                    }
                }

                &--value {
                    .offer-price {
                        margin-right: 0.9rem;
                        color: $text-lite;
                        position: relative;
                        padding: 0 0.5rem;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            border-top: 1px solid red;
                            width: 100%;
                            transform: rotate(-10deg);
                        }
                    }
                }

                &--view {
                    flex: 0 0 100%;
                    line-height: 1.3;
                    color: $text-lite;
                    font-size: 0.85rem;
                    margin-bottom: 0.5rem;
                    margin-top: 0.3rem;

                    .current-price {
                        padding-top: 0.8rem;
                    }
                }

                &--name {
                    .icon {
                        margin-left: 0.2rem;
                    }

                    .blue-color {
                        margin-left: 0.2rem;
                        cursor: pointer;
                        font-size: 0.7rem;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .price {
            padding-top: 0.7rem;
            padding-bottom: 0rem;
            margin-bottom: 1.2rem;

            &__list {
                color: $text-color;
                font-size: 0.85rem;
                margin: 0.3rem 0;
                justify-content: flex-end !important;
            }
        }

        .total {
            color: $text-color;
            font-size: 0.85rem;
            font-weight: 600;
            text-align: right;
            border-top: 1px solid $silver-color;
            border-bottom: 3px solid $silver-color;
            padding: 1rem 0;
            text-transform: uppercase;
        }
    }
}

.line-styles {
    position: relative;
    left: 0px;
}

.promoLink {
    color: $text-lite;
    font-size: 0.85rem;
    text-align: right;
    border-bottom: 1px solid $silver-color;
    padding: 0rem 0 1.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;

    .promoLinkTitle {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }

    span {
        cursor: pointer;
        text-decoration: underline;
        padding: 0 2px;
    }

    .MuiOutlinedInput-input {
        padding: 9px;
    }

    .promo-code,
    .gift {
        flex: 0 0 183px;
        max-width: 183px;
        width: 100%;
        padding: 0;

        .btn {
            box-shadow: 0px 10px 15px #0000004f;
            color: $white-color !important;
            font-weight: 600 !important;
            padding: 0rem 0.9rem 0.1rem !important;
            border-radius: 20px !important;
            font-size: 0.9rem;
            margin-left: 0.3rem;

            &.green {
                background: $green-color !important;
            }

            &.gray {
                background-color: #5a5a5a;
            }
        }
    }
}

.lockWhite {
    margin-right: 0.5rem;
    max-width: 12px;
}

.refundPolicy {
    padding: 0.8rem 1.4rem;
    background: $lite-blue 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: $text-color;
    font-size: 0.85rem;
    line-height: 1.2;
    margin-top: 1.5rem;

    @include breakpoint(tablet-port) {
        padding: 0.8rem;
        line-height: 1.4;
        font-size: 0.95rem;
    }
}

.question {
    h6 {
        margin-bottom: 0;
        color: $text-lite;
        @include ellipsis(2);
    }
}

.btn-green {
    background: $green-color !important;
    box-shadow: 0px 15px 22px #0000004f;
    color: $white-color !important;
    font-weight: 900 !important;
    padding: 0.6rem 1.7rem !important;
    border-radius: 20px !important;
    display: flex;
    align-items: center;

    .lockWhite {
        max-width: 12px;
        margin-top: -6px;
    }

    @include breakpoint(tablet) {
        // border-radius: 15px !important;
        padding: 0.8rem 1.7rem !important;
    }
}

.reviewTitle {
    @include breakpoint(tablet-large) {
        display: none;
        font-size: 1.6rem;
    }
}

.cardMobile {
    display: none;
}

#messageModal {
    max-width: 461px;
}

@include breakpoint(large-phone) {
    .cardMobile {
        position: fixed;
        bottom: 7px;
        width: 100%;
        left: 0;
        text-align: center;
        display: block;

        .title {
            background-color: white;
            padding: 0.5rem 1.5rem 0.5rem 1.5rem;
            border: 1px solid $silver-color;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            color: $text-color;
            font-size: 0.9rem;
            cursor: pointer;
        }
    }
}

.reviewCart {
    .checkout-btn {
        img {
            @include margin-right(12);
        }
    }
}

.amazon-container {
    width: 100%;
    @include scrollbar(0.4rem, slategray);
    position: relative;
    z-index: 1;

    #AmazonPayButton,
    #addressBookWidgetDiv,
    #walletWidgetDiv {
        width: 100% !important;
        margin: 0.1rem 0 !important;

        .widget-container {
            width: 100% !important;

            iframe {
                border: none !important;
                border-radius: 0px !important;
                width: 100% !important;
            }
        }
    }

		#pay-button-amazon{
			display: none !important;
			@media screen and (max-width:700px) {
				display: block !important;
			}
		}
}

#addressBookWidgetDiv,
#walletWidgetDiv {
    height: 252px;
}

.widget_header {
    display: none !important;
}

#Logout {
    border: none;
    background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
    border-radius: 16px;
    max-height: 39px;
    margin-right: 0.2rem;
    margin-top: 0.3rem;

    .logout-btn {
        padding: 0 0.7rem;
        color: $white-color;
    }
}

.amazon-logout {
    position: absolute;
    top: 0;
    left: 50%;
    background-image: url('./../../images/icons/amazon-pay-logout.png');
    background-repeat: no-repeat;
    background-size: 100px;
    width: 100px !important;
    border-radius: 16px;
    height: 45px;
    transform: translateX(-50%);
    cursor: pointer;
}

.height-0 {
    height: 0 !important;
}

.sliderCardContainer {
    .sliderCard {
        border-radius: 20px;
        background-color: $white-color;
        padding: 1rem;
        margin: 0 0.4rem 0 0.4rem;

        &.black-variant {
            padding: 1rem;
            border-radius: 10px;
            @include padding(16, 16, 11, 16);

            .name-top {
                margin-top: 0.4rem;
                color: #5a585b;
                @include fontSize(15);
                margin-bottom: 0.5rem;
                font-weight: 500;
            }

            .top {
                height: 35px;

                h6 {
                    color: #5a585b;
                    @include fontSize(14);
                    font-weight: 400;
                    @include ellipsis(2);
                }
            }
        }

        .top {
            height: 70px;
        }

        h6,
        p {
            color: $primary-color;
            font-weight: 600;
            @include fontSize(14);
            margin-bottom: 0.1rem;
            line-height: 1.1;
            @include ellipsis(2);
        }

        h6 {
            margin-bottom: 0.4rem;
        }

        .name {
            margin-top: 0.4rem;
            @include fontSize(12);
        }

        p {
            font-weight: 500;
        }
    }

    .react-simple-star-rating {
        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.review-slider {
    padding-top: 1rem;
    max-width: 636px;
    width: 100%;
    margin: 0 auto -1rem;

    .slick-arrow {
        display: none !important;
    }

    .react-simple-star-rating {
        svg {
            width: 13px;
        }
    }
}

.promoCodeTextContainer {
    position: relative;

    .reset-btn {
        position: absolute;
        top: 3px;
        right: 6px;

        svg {
            width: 16px;
        }
    }
}
.parentEmailSubmit {
    background-color: $primary-color;
    color: $white-color;
    font-size: 0.9rem;
    padding: 0.35rem 0.8rem 0.15rem;
    border-radius: 10px;
    border: 1px solid $primary-color;

    &:hover,
    &:active,
    &:focus {
        @include primary-btn-hover;
    }
}

.giftCertificate {
    .blue {
        font-weight: 600;
        margin-bottom: 6px;
    }

    .promoCodeTextContainer {
        margin: 0 0 1.36rem 0 !important;

        .promoCodeText {
            width: 244px;
            height: 40px;
            text-align: left;
            @include padding-left(20);
            border-radius: 15px;

            &::placeholder {
                font-family: $open-sans !important;
            }
        }
    }
}

.resetMainContainer {
    .billing-details {
        h6 {
            margin-top: 80px;
            background-color: #ec6233;
            display: block;
            color: #fff;
            font-size: 12px;
            padding: 6px 18px;
            border-radius: 20px;
            font-weight: 600;
            letter-spacing: 0.05em;
            font-family: $open-sans;
            text-transform: uppercase;
            margin-bottom: 1.25rem;
            margin-left: auto;
            margin-right: auto;
            width: 123px;
        }

        h4 {
            font-family: 'p22-mackinac-pro', sans-serif;
            color: #2a334a !important;
            font-weight: 700;
            font-size: 32px;
            line-height: 150%;
            margin-bottom: 1rem;
        }

        p {
            color: #2a334a !important;
            font-family: $open-sans;
            font-weight: 600;
            font-size: 18px;
            line-height: 160%;
            margin-bottom: 2rem;
        }

        .v2-btn {
            padding: 13px 38px;
            background-color: #5451e0;
            border: 2px solid #5451e0;
            font-family: $open-sans;
            text-transform: uppercase;
            width: 283px;
            margin: 0 0 4rem;
            font-size: 18px;

            img {
                padding-left: 1rem;
            }
        }
    }
    &.billing-details-setup {
        min-height: max-content;
    }
}

.slick-list {
    padding: 0 40px 0 0 !important;
}
.GSA-modal
 {
  .modal-dialog
  {
    max-width: 600px
  }
  .modal-content {
    max-width: 600px;
  }
  p {
    color: $primary-color;
    text-align: center;
  }
 }
.ack-modal {
    .modal-content {
        max-width: 440px;
    }

    .modal-body {
        .close-modal {
            position: inherit !important;
            top: inherit !important;
            right: inherit !important;
            float: right;
        }

        h6 {
            @include fontSize(22);
            color: $primary-color;
            text-align: center;
            margin-bottom: 0.7rem;
        }

        p {
            color: $primary-color;
            text-align: center;
        }

        .blue-btn {
            @include padding(8, 50, 8, 50);
            border-radius: 25px;
            font-weight: 500 !important;
        }
    }
}

.btn-square {
    background-color: #000;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    color: $white-color;

    &:hover {
        color: $white-color;
    }
}

// will be revert

// #outer_shopper_approved
// {
//   z-index: -999999999;
//   opacity: 0;
//  position: fixed;
//   &.shopper_approvedRemoveStyles
//   {
//     position: inherit;
//     z-index: inherit;
//     opacity: 1 ;
//   }
// }
// .sa_start_survey
// {
//   &.active
//   {
//     display: block !important;
//   }
// }

.checkout-datePicker {
    max-width: 144px;
}



.shopper-checkout {
    padding-left: 0.5rem;

    img {
        max-width: 151px;
    }
}

.zoid-outlet {
    width: 248px !important;
    height: 53px !important;
}

/* New Cart Summary Css */

/* CHECKOUT */

br.sm {
    display: none;
}

.cart-summary {
    margin-top: 1rem;
    .content-body {
        gap: 35px;
        grid-gap: 35px;
    }
    @media screen and (min-width: 768px) {
        width: 80%;
    }
}

.content-body .widget {
    max-width: 478px;
    padding: 0;
}

.content-body .courses {
    flex: 1;
    padding: 0;
}

.course-flashdeal {
    max-width: 100%;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 36px;
    position: relative;

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 140%;
        color: #2a334a;
        strong {
            font-weight: 700;
        }

        span {
            color: #2a334a !important;
            font-family: 'p22-mackinac-pro', sans-serif !important;

            & + span {
                color: #ec6233 !important;
            }
        }

        &:last-child {
            margin-bottom: 0 !important;

            span {
                font-family: $open-sans !important;
                font-size: 21px !important;
            }
        }
    }
}

.course-flashdeal .orange {
    color: #ec6233;
    font-family: 'p22-mackinac-pro', sans-serif;
}

.course-flashdeal .flashdeal-close {
    position: absolute;
    top: 15%;
    right: 5%;
    cursor: pointer;
}

.course-flashdeal h2 {
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    font-family: p22-mackinac-pro, sans-serif;
    align-items: center;
    color: #2a334a;
    margin-bottom: 0 !important;
}


/* COURSE ITEM */

.courses-container h3 {
    font-family: 'p22-mackinac-pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: #2a334a;
    margin-top: 36px;
    margin-bottom: 10px;
}

.course-items .course-item {
    background: #ffffff;
    border-radius: 24px;
    max-width: 100%;
    padding: 24px;
    display: flex;
    // justify-content: space-between;
    gap: 25px;
    margin: 0 0 20px 0;
    position: relative;
}

.course-items .course-item .course-desc {
    // max-width: 425px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.course-items .course-item h2 {
    font-family: $open-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #2a334a;
}

.course-items .course-item p {
    font-family: $open-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #52596b;
}

.course-top {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.course-top .course-text {
    max-width: 310px;

    h2,
    p {
        margin-bottom: 0.75rem !important;
    }
}

.course-top .question {
    width: 50px;
    height: 25px;
    padding: 2px;
    /* background: #F6F6FD; */
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.course-top .question img {
    max-width: 100%;
    padding: 2px;
    background: #f6f6fd;
    border-radius: 100px;
}

.course-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $open-sans !important;

    .btn {
        margin-top: 0 !important;
    }
}

.add-to-cart {
    button,
    button:hover,
    button:active,
    button:focus {
        display: flex;
        background: #5451e0 !important;
        color: #fff !important;
        padding: 11px 21px 11px;
        border-radius: 100px;
        gap: 8px !important;
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        border: none;
    }
}

.added {
    button,
    button:hover,
    button:active,
    button:focus {
        display: flex;
        background: #eeeefc !important;
        color: #5451e0 !important;
        padding: 12px 24px 12px;
        border-radius: 100px;
        gap: 8px !important;
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        max-height: 37px;
        border: none;
    }
}

.delete {
    button,
    button:hover,
    button:active,
    button:focus {
        display: block;
        background: #eeeefc !important;
        color: #5451e0 !important;
        padding: 13px;
        border-radius: 100px;
        gap: 8px !important;
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        max-height: 37px;
        border: none;
    }
}

.delete img {
    position: relative;
    top: -3px;
}

.course-bottom .cart-btn-block {
    display: flex;
    gap: 12px;
}

.course-bottom .price-num .crossed {
    font-style: normal;
    font-weight: 600;
    font-size: 13.1672px;
    line-height: 160%;
    color: #666d7d;
    text-decoration: line-through;
    text-decoration-color: #df2a4a;
}

.course-bottom .price-num .discounted {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 100%;
    position: relative;
}

.course-bottom .price-num .discounted span {
    font-weight: 600;
    font-size: 13.1672px;
    line-height: 100%;
    position: absolute;
    top: 0;
    right: -10px;
}

/* CHECKOUT */

.flex {
    display: flex;
}

.justify-content-spacebetween {
    justify-content: space-between;
}

.cart-save-info {
    color: #23A26D !important;
}

.custom-checkout {
    background-color: #fff;
    max-width: 100%;
    padding: 32px 56px;
    box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1), inset 0px 0px 0px 3px #5451e0;
    border-radius: 24px;
    font-family: $open-sans !important;

    @media screen and (max-width: 768px) {
        padding: 40px !important;
    }

    .braintree-hosted-fields-focused {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .braintree-hosted-fields-invalid {
        border-color: rgb(255, 0, 0);
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    }

    .checkout-form {
        .field-col {
            height: 50px;
        }
    }

    h3 {
        font-family: p22-mackinac-pro, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 150%;
        color: #2a334a;
        margin-bottom: 12px;
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 190%;
        color: #666d7d;
        margin-bottom: 0px !important;
    }

    .mail-item {
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 160%;
            color: #666d7d;
            margin-right: 10px;
            margin-bottom: 0px !important;
        }

        .action {
            text-decoration: underline;
            color: rgb(0, 0, 255);
            cursor: pointer;
        }
    }

    .mail-item-list {
        display: flex;
        flex-direction: column;
        margin: 7px 0px;
        padding: 0px 4px;

        p {
            font-size: 15px;
            margin-bottom: 8px !important;
            line-height: 150%;
        }

        input[type='checkbox'] {
            margin-right: 8px;
        }

        label {
            color: rgb(121, 109, 125);
        }

        span {
            color: rgb(121, 109, 125);
        }

        .free-item {
            color: #ec6233 !important;
        }
    }

    .freebies {
        p {
            color: #ec6233;

            &:last-child {
                font-weight: 700;
            }
        }

        .promo-code-remove {
            color: rgb(0, 0, 0);
            margin-left: 8px;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .save {
        p {
            font-weight: 700;
            font-size: 18px;
            line-height: 160%;
            color: #2a334a;
        }
    }

    .total {
        margin-top: 16px;
        border-top: 1px solid #cccbf6;
        padding-top: 16px;

        p {
            color: #5451e0;
            font-weight: 700;
            font-size: 21px;
            line-height: 140%;
        }
    }

    .promocode {
        margin: 32px 0;
        font-family: $open-sans;
        padding: 1% 0;
        .promo-btn,
        .promo-btn:hover,
        .promo-btn:active,
        .promo-btn:focus {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: #2a334a !important;
            text-decoration: underline;
            background-color: rgb(255, 255, 255) !important;
            border: none;
            padding: 0px;
            width: 100%;
        }

        .field-row {
            input {
                font-family: $open-sans !important;
                background: #ffffff;
                border: 1px solid #b7b9c0;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
                border-radius: 100px;
                margin-top: 20px;
                text-align: center;

                &::placeholder {
                    font-family: $open-sans !important;
                    font-size: 1.125rem;
                }
            }
        }

        .submit-btn {
            background-color: #5451e0;
            color: rgb(255, 255, 255);
            margin-bottom: 10px !important;
        }

        .submit-btn.disabled {
            background: rgb(232, 233, 234);
            color: rgb(141, 145, 158);
        }
    }
}

.free-mail-item {
    color: #ec6233 !important;
}

.payment-method h3 {
    font-family: $open-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 140%;
    color: #2a334a;
}

.payment-method {
    .pay-checkout-btn {
        display: flex;
        justify-content: center;
        grid-gap: 20px;
        gap: 20px;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        color: rgb(42, 51, 74);
        padding: 11px 12px;
        border: 1.62646px solid rgb(42, 51, 74) !important;
        border: 0.12em solid #2a334a !important;
        background-color: #fff !important;
        border-radius: 81.323px;
        margin-top: 16px;
        width: 100%;
    }

    .parent-btn {
        font-size: 14px !important;
        border: 0.19em solid rgb(42, 51, 74) !important;
    }
}

.social-payment-row {
    .sp-creditcard {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px !important;
        text-decoration: none;
        font-weight: 600;
        width: 100%;
        font-size: 18px;
        line-height: 100%;
        padding: 11px 12px;
        border: 1.62646px solid rgb(42, 51, 74) !important;
        border: 0.12em solid #2a334a !important;
        border-radius: 81.323px;
        margin-top: 16px;

        img {
            width: 30px !important;
            margin-right: 5px;
        }
    }

    .not-active,
    .not-active:hover,
    .not-active:focus,
    .not-active:active {
        background-color: rgb(255, 255, 255) !important;
        color: rgb(42, 51, 74);
    }

    .active,
    .active:hover,
    .active:focus,
    .active:active {
        background-color: rgb(0, 0, 0) !important;
        color: rgb(255, 255, 255) !important;
    }
}

.social-payment-row button.sp-creditcard {
    display: flex;
    justify-content: center;
    gap: 20px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #2a334a;
}

.credit-card-details {
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 140%;
    color: #2a334a;
    margin-top: 32px;
    margin-bottom: 20px;

    .completed-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        p {
            color: #fff;
        }
    }
}

.refund {
    max-width: 350px;
    margin-top: 20px;
}

.refund p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #7a808d;
}

.refund span {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #ec6233;
    text-decoration: underline;
    cursor: pointer;
}

.accordion-body-v2.show-lg {
    overflow: hidden;
    display: block;
}

.accordion-header-v2:after,
.accordion-header-v2.active:after {
    content: '';
}

.accordion-header-v2.active {
    background-color: #000;
    color: #fff !important;
}

.accordion-header-v2.active img {
    filter: invert(100%);
}

.w-60 {
    width: 60%;
    flex: inherit;
}

.checkbox-wrap {
    display: flex;
    gap: 14px;
    align-content: center;
    align-items: center;
}

.checkbox-wrap input[type='checkbox'] {
    padding: 10px;
}
.paypal-btn{
    background-color: #fff !important;
    border: 2px solid #222D65 !important;
    svg{
        width: 90px;
    }
}
.checkbox-wrap p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #2a334a;
}

.flex-submit {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.more-details {
    background: #ffffff;
    border-radius: 24px;
    padding: 16px;
    font-family: $open-sans !important;
}

.more-details p {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
}

.more-details .course-item {
    padding: 20px 0 0 0;
}

.arrow-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    p {
        margin: 1px !important;
    }

    img {
        height: 12px;
    }
}

.arrow-right.active {
    color: #5451e0;
}

.cart-course-img {
    min-width: 186px;
    min-height: 144px;

    img {
        &.show-lg {
            max-width: 186px;
            max-height: 144px;
            border-radius: 12px;
            min-height: 144px;
        }

        &.show-sm {
            max-width: 100px;
            max-height: 80px;
            min-height: 80px;
        }
    }
}

@media only screen and (max-width: 768px) {


    .course-flashdeal h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 125%;
    }

    .course-flashdeal p {
        font-weight: 600;
        font-size: 15px;
        line-height: 120%;
    }

    .field-row {
        flex-direction: row;
        gap: 10px;
    }

    .rating-btn{
        display: flex;
        justify-content: center;
    }

    .course-items .course-item {
        gap: 12px;
        padding: 15px;
    }

    .course-items .course-item h2 {
        font-size: 17px;
    }

    .course-items .course-item p {
        font-size: 13px;
    }

    .course-top {
        gap: 5px;
    }

    .course-top .question {
        height: 15px;
        width: 25px;
    }

    .course-bottom {
        justify-content: space-between;
        margin-top: 10px;
        grid-gap: 10px;
        gap: 10px;
    }

    .course-items .course-item .cart-course-img {
        width: 100px;
        height: 80px;
        min-width: 100px;
        min-height: 80px;
        font-size: 0.75rem;
    }

    .course-top .course-text {
        width: 100%;
        max-width: 100%;
    }

    .promoCodeTextContainer {
        justify-content: center;

        .error {
            position: absolute !important;
            bottom: -18px;
            left: 50%;
            transform: translateX(-83%);
        }
    }

    .custom-checkout .checkout-form .field-col {
        flex: auto;
    }

    .cart-course-img img.show-sm {
        border-radius: 6px;
    }

    .course-flashdeal p span {
        font-size: 24px !important;
    }

    .course-flashdeal p:last-child span {
        font-size: 15px !important;

        strong {
            font-weight: 600;
        }
    }

    .course-flashdeal {
        border-radius: 16px;
        margin-bottom: 24px;
        padding: 18px;
    }

    .custom-container.cart-summary {
        margin-top: 0;
    }

    .custom-checkout {
        padding: 24px 16px;
        h3 {
            line-height: 100%;
            font-size: 32px;
        }

        p {
            font-size: 17px;
        }

        .save {
            p {
                font-size: 17px;
            }
        }

        .checkbox-wrap p {
            font-size: 14px;
        }

        .credit-card-details,
        .payment-method {
            h3 {
                font-size: 18px;
            }
        }
    }

    .social-payment-row button.sp-creditcard {
        font-size: 16px;
    }

    .refund {
        margin-left: auto;
        margin-right: auto;
        p {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .promoCodeTextContainer .error {
        width: 60%;
    }

    .course-bottom {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 1rem;
    }
}

@media only screen and (max-width: 490px) {
    br.sm {
        display: block;
    }

    .checkout-form {
        .field-row {
            height: 50px !important;
            flex-direction: column !important;
        }

        .pair-col {
            flex-direction: row !important;
        }
    }
}

@media only screen and (max-width: 375px) {
    .course-flashdeal {
        padding: 15px;
    }

    .course-flashdeal .flashdeal-close {
        right: 3%;
    }
}

@media only screen and (max-width: 320px) {
    .course-flashdeal h2 {
        font-size: 14px;
    }

    .course-flashdeal p {
        font-size: 13px;
    }

    .w-60 {
        width: 50%;
    }
}

@media (orientation: portrait) {
    .sp-col button {
        border: 1.62646px solid #2a334a !important;
        border-radius: 81.323px;
        padding: 0px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        min-height: 44px;
        align-items: center;
    }

    .social-payment-row a.sp-creditcard {
        min-height: 44px;
        align-items: center;
    }

    .hidden-btn {
        width: 90% !important;
        top: 0px !important;
        right: 15px !important;
    }
}

#AmazonPayButton {
    min-width: 164px;
    background-image: url('./../../images/icons/amazonPaymentImg2.png');
    padding: .4rem 2rem;
    img {
        opacity: 0;
        width: 100%;
        height: 30px;
    }
}

#AmazonPayButton,
.tilled-btn {
    border: 2px solid #555555 !important;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: 100px;
    width: 100%;
    text-align: center;
    background-position: center center;
    min-height: 40px;
    background-color: #ffffff !important;
    &.active,
    &:hover {
        border: 2px solid #5451e0 !important;
    }
    margin-bottom: .8rem;
}
.tilled-btn {
    img {
        max-height: 29px;
    }
}
