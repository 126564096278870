.cartSummaryLayout {
  margin-left: 0 !important;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(tablet-land) {
    justify-content: center;
  }

  .cartSummaryLayout-left {
    flex: 0 0 calc(100% - 370px);
    max-width: calc(100% - 370px);
    padding-right: 1.4rem;
    width: 100%;

    @include breakpoint(tablet-land) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0rem;
    }
  }

  .cartSummaryLayout-right {
    flex: 0 0 370px;
    max-width: 370px;
    width: 100%;

    @include breakpoint(tablet-land) {
      margin-bottom: 2rem;
    }

    @include breakpoint(tablet-port) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 0rem;
    }
  }

  @include breakpoint(tablet) {
    margin-right: 0 !important;
  }

  .cartSummaryContent {
    background-color: $ash-color;
    padding: 2rem 2rem 0.5rem;
    border: 1px solid $silver-color;
    border-radius: 20px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    @include breakpoint(tablet-port) {
      padding: 1rem 1rem 0.5rem;
    }

    .cartSummary {
      display: flex;
      flex-wrap: wrap;

      &__img {
        flex: 0 0 120px;
        max-width: 120px;
        width: 100%;
        margin-bottom: 1.5rem;

        @include breakpoint(tablet-port) {
          flex: 0 0 100px;
          max-width: 100px;

          img {
            max-width: 85px !important;
          }
        }

        img {
          width: 100%;
          max-width: 105px;

          border-radius: 15px;
        }
      }

      &__description {
        flex: 0 0 calc(100% - 160px);
        max-width: calc(100% - 160px);
        width: 100%;
        padding-left: 0.5rem;

        @include breakpoint(tablet-port) {
          flex: 0 0 calc(100% - 110px);
          max-width: calc(100% - 110px);
        }

        &--list {
          margin-top: 1rem;
          padding-left: 0;

          li {
            list-style: none;
            background-image: url(../../images/icons/check-mark.svg);
            background-repeat: no-repeat;
            padding-left: 1.7rem;
            background-position: left center;
            font-size: 0.85rem;
            line-height: 1.4;
            margin-bottom: 0.5rem;
          }
        }

        .courseName {
          font: normal normal bold 1.375rem/1.688rem "Lato", sans-serif;
        }
      }
    }

    .courseFee {
      &__table {
        margin-bottom: 1.6rem;

        @include breakpoint(phone) {
          margin-bottom: 0.6rem;
        }

        td {
          font-size: 1rem;
          padding: 0.5rem;
          line-height: 1.2;
          color: $text-color;

          @include breakpoint(phone) {
            font-size: 0.9rem;
          }

          i {
            padding-left: 0.3rem;
          }

          .offerPrice {
            margin-right: 2rem;
            color: $text-lite;
            text-decoration-color: $red-color;
            position: relative;
            padding: 0 0.2rem;

            @include breakpoint(phone) {
              margin-right: 0.5rem;
            }

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              border-top: 1px solid red;
              width: 100%;
              transform: rotate(-10deg);
            }
          }

          &:last-child {
            text-align: right;
          }
        }

        tr {
          &:last-child {
            td {
              border-bottom: 1px solid #dee2e6;
            }
          }

          &:first-child {
            td {
              border-top: 1px solid transparent;
              padding-top: 0;
            }
          }

          td {
            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .MuiRadio-root {
        padding: 7px 9px;
      }

      .MuiSvgIcon-root {
        font-size: 1.7rem;
      }

      .MuiFormControlLabel-label {
        color: $text-lite;
      }
    }
  }

  .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $primary-color;
  }
}

.priceShow {
  color: $primary-color;
  padding-left: 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: underline;
}

.price__col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.price__display {
  box-shadow: 0px 6px 12px #00000029;
  border: 2px solid $primary-color;
  border-radius: 20px;
  background-color: $white-color;
  @include padding(30);
  margin-bottom: 1.5rem;

  h2 {
    color: $primary-color;
    text-align: center;
    font-family: $kansasnew-family;
    font-weight: 600;
    font-size: 1.75rem;
  }

  @include breakpoint(tablet) {
    margin-left: 0;
  }

  > h6 {
    color: $primary-color;
    text-align: center;
    font-size: 1.2rem;

    @include breakpoint(phone) {
      margin-bottom: 1rem;
    }
  }

  .total {
    span {
      padding-left: 0.5rem;
    }
  }

  h5 {
    text-align: center;
    font-weight: 900;
    margin-bottom: 0;
    text-transform: uppercase;
    color: $primary-color;
  }

  .item {
    &:last-child {
      border-bottom: none !important;
    }

    .header {
      @include fontSize(14);
    }
  }

  .orange-btn {
    @include margin-top(24);
    @include margin-bottom(12);
    padding: 0.75rem 0.9rem 0.75rem 0.9rem !important;
  }
}

.carts-btn {
  background-color: $primary-color;
  border-radius: 25px;
  padding: 0.7rem 1.875rem;
  color: $white-color;
  border: 2px solid $primary-color;
  font-size: 1.125rem;

  &:hover {
    background-color: transparent;
    color: $primary-color;
  }
}

.coursePrice {
  &__offer {
    text-decoration: line-through;
    text-decoration-color: $red-color;
    color: $text-color;
    opacity: 0.6;
  }

  &__original {
    color: $text-color;
  }
}

.join-btn {
  .MuiButton-label {
    font-weight: 600;
  }
}

.text-color {
  color: $text-color;
}

.cart-btn {
  max-width: 100%;
  width: 100%;
  position: relative;

  @include breakpoint(tablet) {
    margin-top: 0.3rem;
  }
}

.no-data {
  text-align: center;
  font-size: 0.9rem;
  padding: 1rem 0;
}

.certificate__placeholder {
  text-align: left;
  color: rgba($color: $primary-color, $alpha: 0.6);
  font-size: 0.875rem;
  margin: 0.3rem 0;
}

.amazonContainer {
  // overflow: hidden;

  .loader {
    position: absolute !important;
  }
}

.title {
  font-family: $kansasnew-family;
  font-weight: 600;
}

.cardSummaryContainer {
  .MuiFormControlLabel-label {
    color: $primary-color;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .content {
    padding-left: 0;
    margin-left: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;

    li {
      color: $primary-color;
      background-image: url("./../../images/blue-check-mark.svg");
      background-repeat: no-repeat;
      list-style: none;
      background-position: left 2px;
      padding-left: 1.8rem;
      margin-bottom: 0.65rem;
    }
  }

  .cardSummary-card {
    // border: 2px solid $primary-color;
    // border-radius: 20px;
    // padding: 1.5rem;

    h6 {
      font-weight: 600;
      font-size: 1rem;
      color: $primary-color;
    }

    .item {
      border-bottom: 1px solid rgba($color: $primary-color, $alpha: 0.1);
      margin: 0.4rem 0 1.2rem;
      padding-bottom: 0.2rem;

      .gray-title {
        color: #707070;
        @include fontSize(12);
        margin-bottom: 0.3rem;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid rgba($color: $primary-color, $alpha: 0.1);
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.2rem 0;

        p {
          margin-bottom: 0rem;
          color: $primary-color;
        }

        .edit {
          text-decoration: underline;
          display: inline-block;
          margin-left: 1rem;
        }

        .offerPrice {
          text-decoration: line-through;
          display: inline-block;
          margin-right: 1.5rem;
        }

        .price {
          @include fontSize(14);
        }

        .remove {
          color: #ff6a37;
          font-size: 12px;
          text-decoration: underline;
        }

        .course-fee {
          .promoCodeName {
            margin: 0.4rem 0;
            display: flex;
            align-items: center;

            .outline {
              border: 1px solid $primary-color;
              border-radius: 15px;
              padding: 0.5rem 0.7rem 0.3rem;
              max-width: 150px;
              display: inline-block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          position: relative;
          @include fontSize(14);

          .info-text {
            position: absolute;
            width: 350px;
            padding: 1rem 1.25rem;
            left: 110%;
            top: 5px;
            background: $primary-color;
            border-radius: 20px;
            color: $white-color;
            display: none;
            z-index: 1;
          }

          &:hover {
            .info-text {
              display: block;
            }
          }
        }

        .discount-value {
          display: flex;
          gap: 5px;
          cursor: pointer;
        }
      }
    }

    .schedule {
      .MuiFormLabel-root {
        color: rgba($color: $primary-color, $alpha: 0.6);
      }

      .MuiInput-underline:before {
        border-bottom: 1px solid rgba($color: $primary-color, $alpha: 0.1);
      }
    }

    .MuiRadio-colorSecondary.Mui-checked {
      color: #1e48a1;
    }

    .free {
      color: #e86014;
    }
  }

  .cart-btn {
    padding: 0.8rem 1.875rem;
    color: $white-color;
    border: none;
    font-size: 1.125rem;
  }
}

//======== flashDealCard  ==========//

.red {
  color: #ff0000;
}

.bg-red {
  background: #fff;
  color: #ff0000 !important;
  border-color: $white-color !important;
  padding: 8px 0px 8px 40px !important;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    background: #fff !important;
    color: #ff0000 !important;
  }

  @include breakpoint(tablet) {
    background: #ff0000 !important;
    color: $white-color !important;
    padding: 0 !important;
    text-decoration: none;
  }
}

.blue {
  color: $primary-color;
}

.bg-blue {
  background: $primary-color;
  border-color: $primary-color !important;

  &:hover {
    background: $primary-color;
    border-color: $primary-color !important;
  }
}

.green {
  color: #228b22;
}

.orange {
  color: #ff6600;
}

.bg-orange {
  background: #ff6600;

  &:hover {
    background: #ee701c;
  }
}

.flashDealNotification {
  background: $white-color;
  border-radius: 5px;
  @include padding(13, 17);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;

  .DealDetails {
    width: 100%;

    p {
      margin-bottom: 0;
      line-height: 1;
    }

    .flash {
      font-size: 18px;
      font-weight: 600;
    }

    .details {
      font-size: 16px;
      line-height: 1.1;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.details-free {
  @include breakpoint(tablet-land) {
    @include fontSize(14);
  }
}

.flashDealCard {
  background: $white-color;
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 13px;

  &.rounded-curve {
    border-radius: 20px;
    margin-bottom: 18px;

    .DealDetails {
      width: 100%;
    }
  }

  .flashDealCardMain {
    display: flex;
    align-items: start;
    justify-content: space-between;

    &-left {
      max-width: 150px;
      width: 100%;

      @include breakpoint(tablet) {
        max-width: 115px;
      }
    }

    &-right {
      justify-content: space-between;
      max-width: calc(100% - 150px);
      width: 100%;
      flex: 0 0 100%;
      padding: 0 0px 0 9px;

      @include breakpoint(tablet) {
        max-width: calc(100% - 115px);
        padding: 0px;
      }
    }
  }

  .DealDetails {
    width: 100%;
    padding: 0 0px 0 15px;

    @include breakpoint(tablet) {
      padding: 0 0px 0 2px;
    }

    p {
      margin-bottom: 0;
    }

    .DealHead {
      font-size: 20px;
      font-weight: 600;

      @include breakpoint(tablet-land) {
        font-size: 0.9rem !important;
        font-weight: 500;
        line-height: 1;
      }
    }

    .details {
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 10px;

      @include breakpoint(tablet-land) {
        margin-top: 0.5rem;
        padding-right: 0.8rem;
        @include fontSize(14);
        margin-bottom: 5px;
      }
    }

    .rating {
      display: flex;
      align-items: center;
      margin-top: -6px;
      margin-bottom: 5px;

      .rating-details {
        span {
          font-size: 12px;
          font-weight: 400;
          margin: 0px 7px;
        }
      }
    }
  }

  .Check {
    display: flex;
    align-self: center;
    padding-right: 15px;

    @include breakpoint(tablet) {
      align-self: inherit;
      padding-top: 0.2rem;
    }

    input {
      position: absolute;
      width: 16px;
      height: 16px;
      opacity: 0;
    }

    input:checked ~ label {
      &:before {
        background-color: #228b22;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 6px;
        width: 5px;
        height: 10px;
        border: solid $white-color;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    label {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 16px;
        height: 16px;
        background-color: $white-color;
        border: 1px solid #228b22;
        border-radius: 2px;
      }
    }
  }

  .dealsImage {
    margin: 0 0 0 20px;
    max-width: 124px;

    width: 100%;

    @include breakpoint(tablet) {
      margin: 0 10px 0 10px;
      max-width: 80px;
    }

    img {
      width: 100%;

      @include breakpoint(tablet-land) {
        border-radius: 5px;
      }
    }
  }

  .cost {
    text-align: right;
    padding-left: 15px;

    @include breakpoint(tablet-land) {
      text-align: left;
    }

    @include breakpoint(tablet) {
      padding-left: 0;
    }

    .offer {
      margin-bottom: 0;
      font-weight: 500;
      min-width: 85px;
      text-transform: uppercase;

      @include breakpoint(tablet-land) {
        @include fontSize(14);
      }
    }

    .value {
      min-width: 85px;
      font-weight: 600;

      @include breakpoint(tablet-land) {
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 0;
      }

      span {
        margin-right: 10px;
        font-weight: 400;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 110%;
          height: 1px;
          background-color: #ff0000;
        }
      }
    }
  }

  @include breakpoint(tablet) {
    border-radius: 5px;
  }
}

.deselect {
  font-size: 14px;
  margin-bottom: 13px;
}

.purchased {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;

  span {
    display: block;
    font-size: 14px;
    margin-top: 0.2rem;
  }
}

.cardBtnMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .card-btn {
    width: 112px;
    height: 32px;
    border-radius: 25px;
    padding: 0;
    @include fontSize(12);

    &:focus,
    &:active,
    &:hover {
      color: $primary-color;

      background-color: transparent !important;
    }
  }
}

.helpBlueIcon {
  position: relative;
  margin-right: 18px;
}

.card-btn {
  border-radius: 25px;
  padding: 8px 40px;
  font-size: 14px;
}

.costList {
  width: 100%;

  // &:nth-child(1){
  //   border-top: 1px solid #E5ECF5;
  //   padding-top: 10px;
  // }
  // &:nth-child(2){
  //   border-top: 1px solid #E5ECF5;
  //   padding-top: 10px;
  // }
  tr {
    &.subTotal {
      border-top: 1px solid #e5ecf5;

      span {
        display: block;
        padding-top: 5px;
      }
    }

    &.Discount {
      border-bottom: 1px solid #e5ecf5;
      border-collapse: separate;
      border-spacing: 15px 0px;

      span {
        padding-bottom: 5px;
      }
    }

    &.costTotel {
      border-top: 1px solid #6186b8;
      border-bottom: 2px solid $primary-color;
      border-collapse: separate;
      border-spacing: 15px 0px;

      span {
        padding: 8px 0 5px;
        display: block;
        line-height: 1.3;
        font-weight: 700;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }

    td {
      vertical-align: middle;

      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}

.promo {
  font-size: 12px;
  text-align: center;
  @include margin(25, 0, 17, 0);

  span {
    border-bottom: 1px solid $primary-color;
  }
}

.orange-btn {
  background-color: #ff6600;
  color: #fff;
  text-align: center;
  max-width: 227px;
  width: 100%;
  border: 1px solid #ff6600 !important;
  margin: auto;
  border-radius: 25px;
  display: flex;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    @include orange-btn-hover;
  }

  img {
    margin-right: 10px;
  }
}

.white-btn {
  background-color: $white-color;
  color: $primary-color;
  text-align: center;
  max-width: 227px;
  width: 100%;
  border: 1px solid $primary-color;
  margin: auto;
  border-radius: 25px;
  padding: 10px 30px;

  &:hover,
  &:focus,
  &:active {
    @include outline-btn-hover;
  }
}

.paypal-btn {
  background-color: $white-color;
  color: $primary-color;
  text-align: center;
  // max-width: 227px;
  width: 100%;
  border: 1px solid #1d9dd8;
  // margin: auto;
  border-radius: 25px;
  padding: 10px 30px;

  &:focus,
  &:active,
  &:visited {
    background-color: #1d9dd8 !important;
    border: 1px solid $white-color !important;
    color: $primary-color !important;
  }
}

.send-reqiest {
  border: $primary-color 1px solid !important;
  border-radius: 25px;
  color: $primary-color;
  background-color: $white-color;
  line-height: 1.1;
  max-width: 222px;
  width: 100%;
  min-height: 55px;
  margin: 1rem auto 0;
  @include fontSize(14);
  cursor: pointer;
  @include flex-center;
  flex-wrap: wrap;
  text-align: center;

  span {
    display: block;
  }

  &:hover {
    background-color: $primary-color;
    color: $white-color;
  }
}

.white-btn,
.paypal-btn {
  &:hover {
    background-color: #f9f9f9;
    color: $primary-color;
  }
}

.blue-text {
  color: $primary-color;
}

.policyText {
  @include fontSize(12);
  color: $primary-color;

  .link {
    font-size: 12px !important;
  }

  @include breakpoint(tablet) {
    line-height: 1.1;
    padding-top: 0.3rem;

    .link {
      text-decoration: none !important;
    }
  }
}

.resetMainContainer {
  margin-left: -200px;
  margin-top: 1rem;

  @media (max-width: 1199px) {
    margin-left: 0px;
  }

  @include breakpoint(tablet-land) {
    margin-left: 00px;
  }

  .billing-details {
    margin: 0 auto;
    max-width: 512px;

    @include breakpoint(tablet) {
      padding: 0 0.8rem;
    }

    .payment-btn {
      padding: 11px 27px;
    }

    h4 {
      color: $primary-color;
      @include fontSize(24);
      text-align: center;
      font-weight: 600;
      margin-bottom: 0.4rem;

      @include breakpoint(tablet) {
        @include fontSize(20);
      }
    }

    p {
      color: $primary-color;
      @include fontSize(16);
      text-align: center;
      margin-bottom: 0.8rem;

      @include breakpoint(tablet) {
        @include fontSize(14);
      }
    }
  }
}

.main-container {
  .custom-stepper {
    margin-left: -220px;

    @include breakpoint(desktop) {
      margin-left: -200px;
    }

    @include breakpoint(min-desktop) {
      margin-left: 00px;
    }
  }
}

.reset-left-padding {
  padding-left: 0 !important;
  padding-top: 122px !important;
}

.reset-only-left-padding {
  padding-left: 0 !important;
  @media screen and (max-width: 768px) {
    padding-left: 10% !important;
  }
}

.reset-position {
  position: absolute !important;
}

.reset-padding {
  margin-left: -220px !important;

  @include breakpoint(desktop) {
    margin-left: -200px;
  }
}

.full-screen {
  margin-top: 80px;
}

.edit-reset {
  position: relative;
  /*  top: -0.5rem; */
  font-size: 1rem;
  color: white;
  font-weight: 600;
  text-decoration: none !important;
}

.DealDetails {
  width: 100%;

  .smallContent {
    .details {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px;
      line-height: 1;
    }

    &.contents {
      flex-wrap: inherit;
    }
  }

  .contents {
    flex-wrap: wrap;
    margin: 0;
  }

  .dealLink {
    color: $primary-color;
    text-decoration: underline;
    margin-left: 0rem;
    width: 100%;
    line-height: 1;
    // position: relative;
    // left: -12px;
    background: #fff;
    cursor: pointer;
  }
}

.newDealDetails {
  padding-left: 0 !important;

  .CheckMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .Check {
      position: relative;

      input:checked ~ label {
        &:after {
          top: 6px;
        }
      }

      input:disabled ~ label {
        &::before {
          border-color: #d0d0d0;
          background-color: #d0d0d0;
        }

        &:after {
          background-color: #d0d0d0;
        }
      }

      label {
        font-size: 18px;
        font-weight: 600;
        color: $primary-color;
        padding-left: 30px;

        &:before {
          top: 4px;
        }
      }

      span {
        padding-left: 20px;
        cursor: pointer;
      }
    }
  }
}

.smallContent {
  display: flex;
}

.promoCodeTextContainer {
  display: flex;
  align-items: center;
  margin: 0 auto;
  column-gap: 0.5rem;

  span {
    color: #ffffff;
    font-size: 14px;
    padding: 11px;
    background-color: #5451e0;
    width: 100px;
    text-align: center;
    border-radius: 25px;
  }

  .promoCodeText {
    width: 270px;
    height: 44px;
    max-width: 270px;
    font-family: $open-sans !important;
    background: #ffffff;
    border: 1px solid #b7b9c0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    // margin-top: 20px;
    text-align: center;

    &:active,
    &:focus,
    &:focus-visible {
      border: 2px solid #333333;
      outline: none;
    }

    &::placeholder {
      color: #333333;
      font-family: $open-sans !important;
    }
  }
}

.authorizeLogo {
  text-align: center;
  margin-bottom: 2.4rem;

  img {
    max-width: 260px;
  }
}

.msg-reqiest {
  font-size: 13px;
  color: #e00000;
  text-align: center;
  font-weight: 500;
}

.timeToPay {
  position: relative;

  .checkboxField {
    height: 0;

    &::before {
      top: 0;
    }
  }

  h3 {
    font-size: 18px;
    color: $primary-color;
    font-weight: 800;
    margin: 20px 0 15px;
  }

  p {
    font-size: 16px;
    margin-top: 5px;
    color: $primary-color;
    line-height: 1.3;
  }

  &-radio {
    position: relative;
    margin-top: 5px;
    flex-wrap: nowrap;

    input[type="radio"] {
      // opacity: 0;
      width: 20px;
      height: 20px;
    }

    label {
      padding-left: 10px;
      font-size: 14px;
      color: $primary-color;
      cursor: pointer;
    }
  }
}

.green-div {
  padding: 10px 20px;
  text-align: center;
  color: $primary-color;
  background-color: #e8f2e8;
  font-size: 12px;
  margin-top: 10px;
}

.UpsellOfferModal {
  @media screen and (max-width: 800px) {
    padding-left: 0px !important;
  }

  .modal {
    &-body {
      padding: 0;
    }

    &-dialog {
      max-width: 740px;
    }

    &-content {
      border-radius: 10px !important;
      min-height: 620px;
      border: none;

      .upsellOffer {
        position: relative;

        @media screen and (max-width: 800px) {
          display: block !important;
        }

        .close {
          position: absolute;
          top: 1.5rem;
          right: 1.1rem;

          @media screen and (max-width: 800px) {
            background-color: #ffffff;
            border-radius: 50%;
          }
        }

        &-left {
          flex: 0 0 420px;
          max-width: 100%;

          @media screen and (max-width: 800px) {
            img {
              height: 400px;
              width: 100%;
              object-fit: cover;
              border-radius: 10px 10px 0 0px !important;
              object-position: 0 -150px;
            }
          }

          @media screen and (max-width: 600px) {
            img {
              height: 260px;
              object-position: 0 -112px;
            }
          }

          img {
            border-radius: 10px 0 0 10px;
          }
        }

        &-right {
          padding: 1.5rem;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;

          @media screen and (max-width: 800px) {
            .upsellOffer-content {
              padding-top: 0 !important;
              margin-top: 1rem !important;
            }

            .white-btn {
              margin-bottom: 1rem;
            }
          }
        }

        &-content {
          h1 {
            font-size: 2rem;
            font-weight: bold;
            color: #1e1e1e;

            @media screen and (max-width: 600px) {
              font-size: 1.5rem;
            }
          }

          h6 {
            color: #1e1e1e;
            font-size: 1.125rem;
            letter-spacing: 0px;

            @media screen and (max-width: 600px) {
              font-size: 1rem;
            }
          }

          .white-btn {
            max-width: 100% !important;
            margin-top: 0.8rem;

            &:active {
              color: $white-color;
            }
          }

          .upGrade-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: none;
            background-color: #228b22;
            height: 48px;
            border-radius: 25px;
            color: $white-color;
            margin-top: 0.9rem;

            span {
              position: relative;
              top: 0.1rem;
            }

            .spinner-border {
              margin-left: 0.3rem;
              width: 1.5rem;
              height: 1.5rem;
            }

            &:hover {
              border: 1px solid #198754;
              background-color: transparent;
              color: #228b22;
            }
          }
        }

        &-warning {
          .red {
            color: #e00000;
          }

          p {
            color: #1e1e1e;
            line-height: 1;
          }
        }
      }
    }
  }
}
