.loader--container {
    @include breakpoint(large-phone) {
        padding: 30px;
    }
    .img {
        width: 139px;
        height: 139px;
    }
    .percentage {
        margin-top: 40px;
        padding-left: 20px;
        font-size: 24px;
        color: $Blue-color;
    }
    .fontStyle {
        margin-top: 27px;
        font-size: 18px;
        color: $Blue-color;
        font-family: $open-sans;
        @include breakpoint(large-phone) {
            line-height: 28px;
        }
    }
    .progress-bar {
        height: 12px;
        border-radius: 10px;
        background-color: #ED6432;
    }
    .progress {
        height: 12px;
    }
}

