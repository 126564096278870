$yellow-color: #ffb739;
$silver-color: #e5e5e5;
$concrete-color: #f2f2f2;
$white-color: #ffffff;
$text-color: #333333;
$text-lite: #666666;
$green-color: #00a517;
$Blue-color: #0048a1;
$lite-blue: #f2f7fb;
$gray-color: #0000004d;
$red-color: #ff5353;
$ash-color: #f5f5f5;
$orange-color: #ff6600;
$black-color: #000000;
$primary-color: #0048a1;
$kansasnew-family: "kansasnew,sans-serif";
$open-sans: 'Open Sans', sans-serif;

.yellow-color {
  color: $yellow-color !important;
}

.yellow-bg {
  background-color: $yellow-color !important;
}

.blue-color {
  color: $Blue-color;
}

.green-color {
  color: $green-color !important;
}

.red-color {
  color: $red-color !important;
}

.orange-color {
  color: $orange-color;
}

.text-lite {
  color: $text-lite !important;
}

.green-text {
  color: #228B22 !important;
}

.primary-color {
  color: $primary-color !important;
}