.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 6;
  min-height: 72px;

  // box-shadow: 0px 0px 6px #00000029;
  &.no-header {
    display: none;
  }

  .header {
    color: $primary-color;
    display: flex;
    padding: 1.2rem 2rem;

    .logo {
      img {
        height: 40px;
        display: none;
      }

      &.new {
        display: none;
      }
    }

    .hamburger {
      width: 35px;
      height: 35px;
      padding: 7px;
      background-color: #daeeff;
      border-radius: 50%;
      display: none;
      margin-right: 1rem;
      margin-left: -1rem;
      flex: none;
      cursor: pointer;

      span {
        display: block;
        border: 1px solid $primary-color;
        margin: 4px 0;
        transition: all 0.3s ease;
      }
    }

    .dash-menu {
      display: none;
    }

    .user-name {
      display: none;
    }

    .page-title {
      margin-bottom: 0;
    }

    .nav {
      margin-left: auto;

      .nav-item {
        a {
          color: $primary-color;
        }

        span {
          color: $primary-color;
          display: flex;
          cursor: pointer;
          padding: 0.5rem 1rem;
          align-items: center;
          font-weight: 700;

          svg {
            margin-right: 0.5rem;
            margin-bottom: 0.25rem;
          }
        }

        &.user-name {
          span {
            padding: 0;
            display: inline-flex;
            cursor: auto;

            &.name {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.available-time {
  position: relative;
  cursor: pointer;
  flex-direction: column;

  &.dashboardVisit {
    flex-direction: inherit;
  }

  &:hover {
    .time-active {
      visibility: visible;
      opacity: 1;
      z-index: 2;
    }
  }

  .time-active {
    cursor: progress;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    max-width: 340px;
    width: 340px;
    box-shadow: 0px 1px 8px #00000029;
    border-radius: 30px;
    position: absolute;
    top: 38px;
    background-color: $white-color;
    padding: 2.313rem;
    left: -90px;

    h6 {
      font-size: 1rem;
      font-weight: bold;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #ff6a37;
    }
  }
}

.contain {
  max-width: 1440px;
  width: 100%;
  margin: auto;

  @include breakpoint(desktop) {
    max-width: 90%;
  }

  @media (max-width:1365px) {
    max-width: 100%;
  }

  @include breakpoint(min-desktop) {
    max-width: 100%;
  }

}

footer {
  background-color: $primary-color;
  padding: 1.4rem 0 1rem;

  p {
    color: #fff;
    font-size: 0.95rem;
    margin-bottom: 0;

    @include breakpoint(tablet) {
      font-size: 0.7rem;
    }
  }
}

.page-title {
  z-index: 6;
  // left: 14.5rem;
  font-size: 1.875rem;
  color: $primary-color;


  h2 {
    font-size: 1.938rem;
    font-weight: 600;
    margin-bottom: 0;

    @include breakpoint(tablet) {
      margin-bottom: .5rem;
      @include fontSize(24);
    }
  }

  .small-info {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0rem;
    text-decoration: underline;
    font-size: 1rem;
    position: relative;
    top: -15px;
    background-color: #f0f8ff;

    @include breakpoint(tablet) {
      @include fontSize(14);
    }
  }

  &.bold {
    font-size: 1.938rem;
    font-weight: 600;
  }

  .edit {
    i {
      position: relative;
      top: -3px;
      padding-left: 0.2rem;
    }
  }
}

.smallText {
  font-size: 1rem;
  padding-left: 18px;
  text-decoration: underline;
  font-weight: 200;
  cursor: pointer;
}

.kansasnew-font {
  font-family: $kansasnew-family  !important;
}

.blue-color {
  color: $primary-color;
}

.login-header {
  color: $primary-color;
}

.course-timer {
  .timer__icon {
    flex: 0 0 100%;
  }
}


.choose_language {
  #localize-current {

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    a {
      color: $primary-color;
      text-decoration: none;
    }


    &::after {
      content: "";
      background-image: url("./../../images/icons/downArrow.svg");
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      border: none;
      position: relative;
      top: 6px;
      margin-left: .5rem;
    }

    margin-right: .7rem;
    position: relative;
    display: flex;
    align-items: center;
  }

  #localize-active-lang {
    display: none;
    background-color: $white-color;
    padding: 0.7rem .7rem 0.5rem;

    &.active {
      display: block;
    }

    &.border {
      display: block;
      @media screen and (max-width: 768px) {
        right: 1.5rem;
        height: fit-content !important;
        min-height: fit-content;
      }
    }

    position: absolute;
    height: 100%;
    min-height: 190px;

    a {

      display: block !important;
      text-align: left;
      line-height: 2;
      text-decoration: none;
      color: $primary-color;
      padding: 0 .5rem;

      &:hover {
        background-color: rgba($primary-color, $alpha: 0.2);
      }

    }
  }
}

#localize-widget {
  visibility: hidden;
}

.btn-stripe {
  background-color: #635BFF;
  padding: 12px 48px;
  border-radius: 10px;
}