.courseGrid {
  box-shadow: 0px 2px 23px #00000029;
  border: 1px solid #1e80cf;
  border-radius: 10px;
  margin: 0.8rem 0;

  .item {
    h5 {
      font-weight: 600;
      color: #1e80cf;
      font-size: 1.1rem;
    }

    p {
      font-size: 0.88rem;
      color: $text-color;
    }
  }

  .continue {
    box-shadow: 0px 8px 12px #0000004d;
    padding: 0.4rem 1.2rem;
    border-radius: 20px;
    background-color: $green-color;
    font-weight: 900;
    border: 0;
    color: #fff;

    i {
      display: none;

      img {
        max-width: 18px;
        margin-left: 0.4rem;
      }
    }
  }

  .driver-img {
    height: 180px;
    border-radius: 10px 10px 0px 0;
  }
}

.driver-img {
  width: 100%;
  margin-bottom: 0.8rem;

  background-position: center center;
}

.title h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.recommended {
  position: relative;
  width: 100%;

  .driver-img {
    border-radius: 10px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0%;
  }

  .item {
    &-body {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba($text-color, 0),
        rgba($text-color, 0.4),
        rgba($text-color, 0.6),
        rgba($text-color, 0.8),
        rgba($text-color, 0.9)
      );
      border-radius: 0px 0px 10px 10px;

      h5 {
        font-weight: 600;
        color: $white-color;
        font-size: 1.1rem;
      }

      p {
        font-size: 0.88rem;
        color: rgba($white-color, 0.8);
        @include ellipsis(2);
        margin-bottom: 0;
      }
    }
  }
}

.course-card {
  .btn-continue {
    @media screen and(max-width:1199px) {
      width: 100%;
      justify-content: center;
    }
  }
  border-radius: 1.875rem;
  color: $primary-color;
  border: 2px solid #e8f4ff;
  margin-bottom: 1.5rem;
  width: 92%;
  @media screen and(max-width:1199px) {
    width: 100%;
  }
  transition: all 0.1s ease;

  @include breakpoint(tablet) {
    width: 100%;
  }

  &:hover {
    border: 2px solid $primary-color;
    box-shadow: 0px 3px 2px #00000029;
  }

  .card-body {
    padding: 1.75rem 20rem 0 1.75rem;
    position: relative;

    @media only screen and (max-width: 600px) {
      padding: 1.75rem;
    }

    .card-name {
      font-size: 0.875rem;
      color: rgba(0, 72, 161, 0.6);
      margin-bottom: 0.25rem;
      font-weight: 600;
    }

    .card-title {
      font-weight: 700;
      font-size: 1.375rem;

      @include breakpoint(tablet) {
        @include fontSize(22);
        font-weight: 500;
        margin-bottom: 0.4rem;
      }

      span {
        display: inline-block;
        text-decoration: underline;
        font-size: 1rem;
        font-weight: 400;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }

    .card-text {
      margin: 0;
      @include ellipsis(3);
      min-height: 75px;
      margin-bottom: 0.5rem;
    }

    .course-progress {
      .progress {
     
				 @media (min-width:1200px) and (max-width:1920px) {
					 margin-bottom: 1.5rem;
				} 
        border-radius: 0.5rem;
        height: 0.75rem;

        .progress-bar {
          background-color: #b6d8f3;
          border-radius: 0.5rem;
        }
      }

      .days-left {
        color: #6a6a6a;
        margin-top: 0 !important;
        @media screen and(max-width:1199px) {
          text-align: center;
          margin-top: 12px;
          width: 100%;
        }
      }
    }
  }

  .card-footer {
    display: flex;
    background-color: $white-color;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;

    border: 0 none;
    align-items: center;

    .chapter-name {
      margin: 0 0 0 1rem;
      font-weight: bold;
      font-size: 1.15rem;
    }
  }
}

%modal-content {
  .modal-dialog {
    background-color: $white-color;
    margin: 0;

    .modal-content {
      border-radius: 0 !important;
      border: 0 none;
    }

    .modal-body {
      padding: 5rem 2rem 2rem !important;

      @include breakpoint(tablet) {
        padding: 4rem 1rem 2rem !important;
      }

      overflow: auto;

      h5 {
        font-weight: 700;
        font-family: $kansasnew-family !important;
      }
    }
  }

  &.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  &.right .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  &.right .modal-body {
    padding: 15px 15px 80px;
  }

  /*Right*/
  &.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  &.right.fade.show .modal-dialog {
    right: 0;
  }
}

.close-modal {
  border: none;
  background-color: transparent !important;
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.83rem;
  top: 2rem;
  cursor: pointer;
  z-index: 2;
  padding: 0;
  svg {
    width: 30px;
    height: 30px;
  }
}

.courseList-card {
  @include breakpoint(tablet) {
    width: 100%;
  }
  &-container {
    @media screen and(max-width:1199px) {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      align-items: center;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
  &-img {
    width: 130px;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1199px) {
      width: 320px;
    }
    @include breakpoint(tablet) {
      width: 200px;

      img {
        border-radius: 5px;
      }
    }
  }

  &-des {
    width: calc(100% - 130px);
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
    @include padding-left(23);

    @include breakpoint(tablet) {
      &.d-md-none {
        text-align: center;
      }

      width: 100%;
      padding-left: 0;
      margin-top: 12px;
    }
    .desc-desktop {
			text-align: center;
			 width: 100%;
			@media (min-width: 768px) and (max-width: 1199px) {
      
        margin-top: 24px;
        margin-bottom: 12px;
      
      }
      @media screen and (max-width: 767px) {
      
        margin-top: 12px;
        margin-bottom: 12px;
      
      }
    }
  }

  &-top {
    h3 {
      @include fontSize(24);
      font-weight: bold;
    }
  }

  .progress {
    width: 160px;
    @media screen and(max-width:1199px) {
      width: 100%;
    }
  }

  .course-progress {
    text-align: right;
    @media screen and(max-width:1199px) {
      text-align: center;
      margin-top: 1.5rem;
      width: 100%;
    }
  }
}

// table of contents modal or course info modal
.table-of-contents-modal {
  color: $primary-color;
  @extend %modal-content;

  &.right .modal-dialog {
    max-width: 800px;

    @include breakpoint(tablet) {
      max-width: 360px;
    }
  }

  .course-content-table {
    color: $primary-color;

    tbody + tbody {
      border-top: 1px solid rgba($primary-color, 0.1);
    }

    thead {
      @include breakpoint(tablet) {
        display: none;
      }

      th {
        border: 0 none;
        padding-bottom: 0;
        color: rgba(0, 72, 161, 0.6);
        font-weight: 400;
        font-size: 0.875rem;

        &.chapter-number {
          max-width: 70px;
          min-width: 70px;
          width: 70px;
        }

        &.chapter-status {
          max-width: 130px;
          min-width: 130px;
          width: 130px;
        }

        &.chapter-read-time {
          width: 100px;
        }
      }
    }

    td,
    th {
      border: 0 none;
      border-bottom: 1px solid rgba($primary-color, 0.1);
      vertical-align: middle;
      padding: 0.6rem 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }

    td {
      &.read-time {
        color: #6a6a6a;
      }
    }

    tr {
      &.chapterStatus {
        td {
          @include breakpoint(tablet) {
            .ChapterStatus,
            &.read-time {
              @include fontSize(14);
            }

            &.read-time {
              vertical-align: bottom;
            }
          }

          &:first-child {
            max-width: 34px;
            width: 34px;
          }

          opacity: 0.6;
        }

        &.active {
          td {
            opacity: 1;
          }
        }
      }

      cursor: pointer;

      &.not-started {
        opacity: 0.6;
      }

      &.in-progress {
        cursor: pointer;

        td {
          font-weight: 700;
        }
      }

      // &:hover {
      //   background-color: #f2f6ff;
      // }
    }
  }
}

.payment-method-modal {
  color: $primary-color;
  @extend %modal-content;
}

// .profile-modal {
//     &.right .modal-dialog {
//         max-width: 75%;
//     }
// }

.para-height {
  min-height: 73px;
  @include ellipsis(3);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: $primary-color;
}

.course-list {
  .page-title {
    @include padding(32, 0, 24, 0);

    @include breakpoint(tablet) {
      @include padding(16, 0, 4, 0);
      margin-bottom: 0;

      h2 {
        @include fontSize(22);
      }
    }
  }
}

// .MuiMenu-list {
//   max-height: 100% !important;
//   overflow: inherit !important;
// }

.MuiMenu-paper {
  box-shadow: none !important;
  background-color: $primary-color !important;
  border-radius: 30px !important;
  padding: 0.5rem;
  border: none !important;
}

.MuiMenu-list {
  border: none !important;
  box-shadow: 0px 12px 22px #00000029;
  border-radius: 30px;
  padding: 1rem !important;

  .MuiListItem-button {
    font-family: "Josefin Sans", sans-serif !important;
    padding: 0.4rem 0.5rem;
    color: $white-color;
    border-radius: 16px;
    margin: 0.2rem 0 !important;
    font-size: 1.2rem !important;
    font-weight: 400;

    &:hover {
      padding: 0.4rem 0.5rem;
      background-color: rgba($white-color, 0.1);
    }
  }
}
