.email_font {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #2a334a;
}

.password_font {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #2a334a;
}

.small-text1 {
  margin-top: 20px;
  text-align: center;

  .link {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
}

.notRegisterFont {
  color: #7a808d;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.notRegisterFont:hover {
  color: blue;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.troubleLoginFont {
  color: #7a808d;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.troubleLoginFont:hover {
  color: blue;
}

.arrow_img {
  width: 20px;
  height: 13px;
  margin-left: 16px;
}

.TextInputField__icon {
  svg {
    fill: #8d919e !important;
    font-size: 1rem;
  }
}