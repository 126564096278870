.custom-stepper {
  @include breakpoint(tablet-land) {
    display: none !important;
  }

  &-mark {
    height: 20px;
    background-image: url(./../../images/icons/chooseSelectOutline.svg);
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.6;
  }

  &-title {
    opacity: .6;
    margin-top: .4rem;

  }

  &-item {
    position: relative;
    min-width: 120px;
    color: $primary-color;
    color: #2A334A;
    @include fontSize(12);
    font-weight: 600;

    &.fill {
      .custom-stepper {
        &-mark {
          opacity: 1;
          background-image: url(./../../images/icons/chooseSelect.svg);
        }

        &-title {
          opacity: 1;
        }
      }

      &::after,
      &::before {
        opacity: 1;
      }
    }

    &.active {
      .custom-stepper {

        &-mark,
        &-title {
          opacity: 1;
        }
      }

      &::before {
        opacity: 1;
      }
    }

    &.before-none {
      &:before {
        display: none;
      }
    }

    &.after-none {
      &:after {
        display: none;
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 9px;
      right: 0px;
      width: calc(50% - 16px);
      height: 3px;
      background-color: $primary-color;
      opacity: 0.6;
    }

    &::after {
      right: 0px;
    }

    &::before {
      left: 0px;
    }
  }
}