.tilledPayment {
    position: relative;
    overflow: hidden;
    .loader-tilled {
        display: none;
    }
    &.tilledLoader {
        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            background-color: rgba(255, 255, 255, 0.95);
            height: 100%;
            width: 100%;
            top: 0;
        }
        .loader-tilled {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            span {
                background-color: $primary-color;

                span {
                    background-color: $primary-color;
                }
            }
        }
    }

    .inputField,
    .form-control {
        border: 1.5px solid #dfe3eb;
        height: 40px;
        padding-left: 10px;
        font-weight: 500;
        border-radius: 1px;
        margin-top: 0px !important;
        font-family: $open-sans !important;
        font-style: normal;
        &::placeholder {
            font-family: $open-sans !important;
            font-style: normal;
        }
        &.success {
            margin-top: 0px !important;
        }
        &:focus {
            box-shadow: none;
        }
    }
    #card-country-element {
        background-color: transparent;
        cursor: not-allowed;
    }
    .credit-card-box .panel-title {
        display: inline;
        font-weight: bold;
    }

    .credit-card-box .form-control.error {
        border-color: red;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
    }

    .credit-card-box label.error {
        font-weight: bold;
        color: red;
        padding: 2px 8px;
        margin-top: 2px;
    }

    .credit-card-box .payment-errors {
        font-weight: bold;
        color: red;
        padding: 2px 8px;
        margin-top: 12px;
    }

    .credit-card-box label {
        display: block;
    }

    .credit-card-box .display-tr {
        display: table-row;
    }

    .credit-card-box .display-td {
        display: table-cell;
        vertical-align: middle;
        width: 50%;
    }

    .credit-card-box .panel-heading img {
        min-width: 180px;
        border-style: solid;
        border-width: 3px;
        border-color: white;
        box-shadow: 3px 3px 5px #d3d3d3;
    }

    .credit-card-font-size {
        font-size: large;
    }

    .icon {
        margin-right: auto;
    }

    .hidden {
        visibility: hidden;
        display: none;
    }
    .input-group-text {
        padding: 0.5rem 0.75rem;
    }

    .form-group {
        position: relative;
        margin-bottom: 0.5rem;
        label {
            font-weight: 600;
            font-size: 15px;
            line-height: 150%;
            color: #2a334a;
            font-family: $open-sans;
        }
    }
    .card-icon {
        position: absolute;
        right: 0;
        top: 27px;
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.tilledSaveCard {
    label {
        cursor: pointer;
    }
}

.mandatory_fields {
    color: #666d7d;
}
