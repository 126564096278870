#braintreePayment1 {
  width: 100%;
  .braintree-placeholder {
    display: none;
  }

  .braintree-upper-container:before {
    background-color: transparent;
  }

  .certificate-container {
    max-width: 636px;
    width: 100%;
    margin: 1rem auto 0;
  }

  .braintree-sheet__container {
    margin-bottom: 0;
  }

  .brinTree-container {
    min-height: 80px;
    position: relative;
  }

  .certificate {
    display: flex;
    align-items: center;

    justify-content: center;

    @include breakpoint(tablet) {
      display: block;
    }

    &__name {
      @include breakpoint(tablet) {
        text-align: center;
      }

      .godaddy {
        max-height: 48px;
      }

      img {
        max-height: 64px;
      }
    }

    &__text {
      font-size: 0.75rem;
      line-height: 1.3;
      padding-left: 0.5rem;

      @include breakpoint(tablet) {
        text-align: center;
        padding: 0.3rem 0.5rem;
        font-size: 0.85rem;
      }
    }
  }

  .cartLock {
    max-width: 13px;
    position: relative;
    top: -5px;
    margin-left: .3rem;

    @include breakpoint(tablet) {
      max-width: 12px;
    }
  }

  .label-primary {
    color: $primary-color;
    @include fontSize(14);
    font-weight: 600;
  }

  .card-title {
    @include breakpoint(tablet-large) {
      margin-bottom: 0.5rem;
    }
  }

  .reviewCart {
    box-shadow: 0px 6px 12px #00000029;
    border: 2px solid $primary-color;
    border-radius: 20px;
    padding: 2rem;
    max-width: 636px;
    width: 100%;
    margin: 0 auto;
    background-color: $white-color;
    min-height: 240px;

    @include breakpoint(tablet) {
      padding: 1.2rem;
    }

    .checkout-btn {
      margin: 0 -.4rem;

      img {
        @include breakpoint(tablet) {
          margin: .4rem .4rem;
        }
      }
    }

    &.stripe {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.square {
      overflow: hidden;

      .square-logo {
        height: 40px;
      }

    }

    .card-title,
    .cart-title {
      color: $primary-color;
      @include fontSize(18);

      @include breakpoint(tablet) {
        display: flex;
        justify-content: space-between;
      }


    }

    .card-title {
      @include breakpoint(tablet) {
        margin-top: .4rem;
        font-family: $kansasnew-family;
      }

      font-weight: 600;
    }

  }
.reviewCartFastLane {
    /* box-shadow: 0px 6px 12px #00000029; */
    /* border: 2px solid $primary-color; */
    /* border-radius: 20px; */
    padding: 1rem;
    width: 100%;
   /*  margin: 0 auto; */
   /*  background-color: $white-color; */
    /* min-height: 240px; */
    margin: 0 !important;

    @include breakpoint(tablet) {
      padding: 0;
    }

    .checkout-btn {
      margin: 0 -.4rem;

      img {
        @include breakpoint(tablet) {
          margin: .4rem .4rem;
        }
      }
    }

    &.stripe {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.square {
      overflow: hidden;

      .square-logo {
        height: 40px;
      }

    }

    .card-title,
    .cart-title {
      color: $primary-color;
      @include fontSize(18);

      @include breakpoint(tablet) {
        display: flex;
        justify-content: space-between;
      }


    }

    .card-title {
      @include breakpoint(tablet) {
        margin-top: .4rem;
        font-family: $kansasnew-family;
      }

      font-weight: 600;
    }

    @media screen and (max-width: 370px) {
      padding: 0;
    }
  }
  .link {
    color: #006DF2;
    text-decoration: underline;
    @include fontSize(14);
    margin-top: 1rem;

    @include breakpoint(tablet) {
      margin-top: .5rem;
    }
  }

  .braintree-sheet {
    border: none;

    &__header {
      display: none;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      gap: 0 1rem;
      justify-content: start !important;

      @include breakpoint(tablet) {
        display: block !important;
      }

      .braintree-form__field-group {
        max-width: calc(50% - .5rem);
        flex: 0 0 calc(50% - .5rem);
        width: 100%;
        margin-right: 0;
        padding-left: 0;

        @include breakpoint(tablet) {
          max-width: 100% !important;
        }
      }

      .braintree-form__flexible-fields {
        gap: 0 1rem;
        flex-wrap: nowrap;
        padding-right: .5rem;

        @include breakpoint(tablet) {
          gap: 0 .5rem;
          flex-wrap: wrap !important;
          padding-right: 0rem;
        }

        .braintree-form__field-group {

          &:nth-child(1) {
            max-width: calc(200px - .5rem);
            flex: 0 0 calc(200px - .5rem);
            width: 100%;

            @include breakpoint(tablet) {
              min-width: calc(167px);
              flex: inherit;
              max-width: calc(100% - 100px) !important;
              width: 100%;
            }
          }

          &:nth-child(2) {
            max-width: calc(120px - .5rem);
            flex: 0 0 calc(120px - .5rem);
            width: 100%;

            @include breakpoint(tablet) {
              max-width: calc(90px);
              flex: 0 0 calc(90px);
              width: 100%;
            }
          }

          &:nth-child(3) {
            max-width: calc(30% - .5rem);
            flex: 0 0 calc(30% - .5rem);
            width: 100%;

            @include breakpoint(tablet) {
              max-width: 100% !important;
              flex: 0 0 100%;
            }
          }
        }
      }

      &--form {
        padding: 0;

        .braintree-form__field-group {
          label {
            width: 100%;
            margin-bottom: 0.6rem;

            @include breakpoint(tablet) {
              margin-bottom: 0.1rem;
            }
          }
        }

        .braintree-form__field {
          .braintree-form__hosted-field {
            border-color: $primary-color;
            border-radius: 15px;
            padding-left: 0;
            color: $primary-color;

            iframe {
              padding: 0 .3rem;
              color: $primary-color;
            }

            &:focus {
              box-shadow: none;
            }
          }

        }
      }
    }
  }

  .payment-btn {
    @include breakpoint(tablet) {
      width: 100%;
    }
  }

  .braintree-form {
    &__label {
      @include fontSize(14);
      color: $primary-color;
      display: none;

      span {
        @include fontSize(14);
        color: $primary-color;
      }
    }

    &__field-error {
      margin-top: -6px;
    }

    &__hosted-field {
      height: 38px;
    }

  }

  .braintree-large-button {
    padding: 0;
    color: $primary-color;
    background-color: transparent;
    text-align: left;
    margin-top: -.8rem;
    display: none;
  }

  .braintree-sheet__error {
    background-color: transparent;
    text-align: left;
    justify-content: flex-start;
  }

  .braintree-method {
    padding: 0rem .5rem;
    min-height: 45px;
    border-color: $primary-color;
    border-radius: 10px;
    margin: .5rem 0;
    border-width: 1px;
    width: 100%;

    &__icon {
      height: 26px;
      width: 26px;
      padding: 5px;

      svg {
        position: relative;
        top: -2px;
      }
    }

    &--active {
      .braintree-method__check {
        background-color: $primary-color;

      }
    }

    &__label {
      color: $primary-color;
      font-size: .8rem;
      display: none;
    }
  }

}

#braintreePayment2 {
  min-height: calc(100vh - 112px);
  max-height: calc(100vh - 112px);
  @include scrollbar(0.4rem, $primary-color);

  .amazonContainer {
    padding: 3rem 0;
  }

  .braintree-upper-container:before {
    background-color: transparent;
  }

  .braintree-method {
    padding: 0rem .5rem;
    min-height: 45px;
    border-color: $primary-color;
    border-radius: 10px;
    margin: .5rem 0;
    border-width: 1px;
    width: 100%;

    &__icon {
      height: 26px;
      width: 26px;
      padding: 5px;

      svg {
        position: relative;
        top: -2px;
      }
    }

    &--active {
      .braintree-method__check {
        background-color: $primary-color;

      }
    }

    &__label {
      color: $primary-color;
      font-size: .8rem;
      display: none;
    }
  }

  .braintree-options {
    display: none;
  }

  .checkPoint {
    margin-top: 2rem;

    .form-check-input {
      min-width: 28px;
      min-height: 28px;
      border: 1px solid $primary-color;
    }

    .form-check-label {
      padding-left: 1.6rem;
      @include fontSize(14);
    }
  }

  .braintree-large-button {
    padding: 0;
    background: transparent;
    color: $primary-color;
    text-align: left;
    margin-top: 1rem;
    @include fontSize(14);
    display: none;

    span {
      border-color: $primary-color;
    }
  }

  .braintree-heading {
    color: $primary-color;
    top: 12px;


    &[data-braintree-id="methods-label"] {
      display: none;
    }

  }

  .summaryDetails {
    margin-top: 3rem;

    .title {
      color: $primary-color;
      @include fontSize(20);
      font-weight: 500;
      font-family: "Josefin Sans", sans-serif !important;
    }

    .description {
      color: $primary-color;
      @include fontSize(14);
      font-family: "Josefin Sans", sans-serif;
      opacity: 0.6;
    }

    &-table {
      width: 100%;

      td {
        padding: .3rem 0;

        .redColor {
          color: #FF0000;
        }
      }

      tfoot {
        border-top: 1px solid #00000029;
        border-bottom: 1px solid #00000029;

        tr {
          &:first-child {
            td {
              padding-top: .7rem;
            }
          }

          &:last-child {
            td {
              padding-bottom: .7rem;
            }
          }
        }
      }
    }
  }

  .choosePaymentMethod {
    @include fontSize(14);
    color: $primary-color;
    text-decoration: underline
  }

  .braintree-form {
    &__label {
      @include fontSize(14);
      opacity: .6;
      color: $primary-color;

      span {
        @include fontSize(14);
        opacity: .6;
        color: $primary-color;
      }
    }

    &__hosted-field {
      height: 32px;
      margin: -6px 0 0;

    }

    &__flexible-fields {
      [data-braintree-id="expiration-date-field-group"] {
        margin-right: .5rem;
      }

      [data-braintree-id="cvv-field-group"] {
        margin-left: .5rem;
      }
    }
  }

  .braintree-sheet {
    border: none;

    &__header {
      display: none;
    }

    &__content {
      &--form {
        margin-top: -1rem;
        padding: 0;

        .braintree-form__field-group {
          label {
            width: 100%;
            margin-bottom: 0rem;
          }
        }

        .braintree-form__field {
          .braintree-form__hosted-field {
            border-color: $primary-color;
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-left: 0;
            color: $primary-color;

            &:focus {
              box-shadow: none;
            }
          }

        }
      }
    }
  }
}


.braintree-sheet__content--form .braintree-form__field-group {
  margin-bottom: 0rem;
}

.braintree-input-class {
  font-size: .8rem !important;
  padding-left: .6rem !important;
}

.braintree-sheet__content--form .braintree-form__flexible-fields {
  justify-content: flex-start !important;

}

.braintree-form__field-error-icon {
  svg {
    max-width: 24px;
  }
}

.fieldFull
{
  display: block !important;
}

.fastlane-cart-checkout {
  max-height: fit-content;
}

.zero-payment-container {
  padding: 0 !important;
}