.syllabus {
  max-width: 90%;
  margin: 0 auto;

  @include breakpoint(tablet-port) {
    max-width: 100%;
  }

  &__table {

    // .table-responsive
    // {
    //   @include scrollbar(0.4rem, slategray);
    // }
    h5 {
      @include breakpoint(large-phone) {
        color: $Blue-color;
      }
    }

    .syllabus__table--custom {
      .border-table {
        font-size: 0.8rem !important;
      }

      .chapter__name {
        font-weight: 400 !important;
        font-size: 0.9rem !important;
      }

      .mobile-row {
        display: none;
      }

      @include breakpoint(tablet-large) {
        thead {
          display: none;
        }

        .desktop-row {
          display: none;
        }

        .mobile-row {
          display: table-row;

          .border-table {
            border-left: 1px solid $silver-color  !important;
            border-radius: 15px !important;
          }
        }
      }
    }

    .table {
      margin-bottom: 1rem;

      // @include breakpoint(tablet-port)
      // {
      // width: 707px;
      // }
      thead {
        margin-bottom: 1rem;

        th {
          color: #fff;
          background-color: $Blue-color;
          border: none;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          padding-left: 0 !important;

          &:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            padding-left: 5.4rem !important;
          }

          &:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }

      tbody {
        tr {
          cursor: pointer;

          &:nth-child(1) {
            td {
              padding-top: 0.4rem !important;
            }
          }

          &:nth-child(2) {
            td {
              padding: 0.4rem 0 0.2rem !important;
            }
          }

          td {
            border: none;
            padding: 0.2rem 0 !important;

            .chapter {
              display: flex;
              padding-left: 2rem;
              width: 100%;

              .list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                &.one {
                  max-width: 60px;
                  width: 100%;

                  @include breakpoint(tablet-large) {
                    max-width: 40px;
                  }
                }

                &.two {
                  max-width: calc(100% - 80px);
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;

                  @include breakpoint(tablet-large) {
                    max-width: calc(100% - 40px);
                    justify-content: flex-start;

                    .item {
                      color: $text-color;

                      .hide {
                        display: inline-block !important;

                        img {
                          max-height: 10px;
                        }
                      }

                      &:nth-child(1) {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        width: 100%;
                      }

                      &:nth-child(2) {
                        flex: 0 0 40.333% !important;
                        max-width: 40.333% !important;
                      }

                      &:nth-child(3) {
                        flex: 0 0 30.333% !important;
                        max-width: 30.333% !important;
                      }
                    }
                  }

                  .item {
                    .hide {
                      display: none;
                    }

                    &:nth-child(1) {
                      flex: 0 0 50%;
                      max-width: 50%;
                      width: 100%;
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                      flex: 0 0 20%;
                      max-width: 20%;
                      width: 100%;
                    }
                  }
                }
              }

              @include breakpoint(tablet) {
                padding-left: 0;
              }

              &__round {
                border: 2px solid #c6d8ef;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1.3rem;

                &--number {
                  background-color: $silver-color;
                  border: 1px solid #fff;
                  border-radius: 50%;
                  width: 28px;
                  height: 28px;
                  text-align: center;
                  font-size: 0.7rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: $text-color;
                }
              }

              &__name {
                color: $text-color;
                font-size: 0.88rem;
                text-transform: capitalize;
                font-weight: 600;

                @include breakpoint(tablet-large) {
                  color: $text-color  !important;
                  font-weight: 600;
                  padding: 0.2rem 0;
                }
              }
            }

            &:first-child {
              min-width: 340px;
              border-radius: 10px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 15px;

              .border-table {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                border-left: 1px solid transparent;

                @include breakpoint(tablet-large) {
                  border-left: 1px solid$silver-color  !important;
                }
              }
            }

            &:last-child {
              .border-table {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                border-right: 1px solid transparent;

                @include breakpoint(tablet-large) {
                  border-right: 1px solid $silver-color  !important;
                }
              }
            }

            .border-table {
              min-height: 46px;

              @include breakpoint(tablet-large) {
                min-height: 50px;
                font-size: 0.8rem;
                padding: 0.4rem 0 0.6rem;
                border-top: 1px solid $silver-color  !important;
                border-bottom: 1px solid $silver-color  !important;
              }

              display: flex;
              align-items: center;
              color: $text-color;
              font-size: 0.88rem;
              background-color: #f5f5f5;
              border-top: 1px solid transparent;
              border-bottom: 1px solid transparent;

              .school-icon {
                svg {
                  fill: #dddddd;
                }
              }
            }

            border-radius: 15px;

          }

          &:hover,
          &.active {
            td {
              &:first-child {
                .border-table {
                  border-top-left-radius: 15px;
                  border-bottom-left-radius: 15px;
                  border-left: 1px solid $silver-color;
                }
              }

              &:last-child {
                .border-table {
                  border-top-right-radius: 15px;
                  border-bottom-right-radius: 15px;
                  border-right: 1px solid $silver-color;
                }
              }

              .border-table {
                background-color: $lite-blue;
                border-top: 1px solid $silver-color;
                border-bottom: 1px solid $silver-color;
              }

              .chapter__round--number {
                background-color: #c6d8ef !important;
              }
            }
          }
        }
      }
    }
  }
}

.td-icon {
  position: relative;
  top: -2px;

  svg {
    font-size: 1rem;

    @include breakpoint(tablet-large) {
      fill: $text-color;
    }
  }
}

.school-icon {
  padding-left: 2rem;

  @include breakpoint(tablet) {
    padding-left: 0;
  }
}

.title {
  h3 {
    color: $Blue-color;
    font-weight: 600;
    margin: 0rem 0 1rem;
  }
}

.desktop-row {
  .td-icon {
    img {
      max-width: 1px;
    }
  }
}