.modal-v2 {
    .modal-content {
        background: #ffffff !important;
        border: 2px solid #853ef3;
        box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1), inset 0px 0px 0px 3px #5451e0;
        border-radius: 24px;
        padding: 30px;
    }
}

.xverify-modal{
    color: #007bff;
    &:hover {
        text-decoration: underline;
        color:#0048A1;
    }
}
.multiple-security-modal {
    .modal-content {
        background: #ffffff !important;
        border: 2px solid #0048a1;        
        border-radius: 24px;
        padding: 30px;
    }
    input[type='checkbox'] {
        -ms-transform: scale(1.5); /* IE 9 */
        -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
        transform: scale(1.5);
    }
    .modal-dialog {
        max-width: 770px;
    }
    p {
        color: #0048a1;
        font-family: $open-sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
    }
    .form-check-label {
        font-family: $open-sans;
        font-weight: 500;
        font-size: 16px;
        color: #0048a1;
        margin-left: 0.7rem;
    }

    .button {
        display: flex;
        font-family: $open-sans;
        flex-direction: row;
        justify-content: center;
        font-weight: 500;
        align-items: center;
        padding: 9px 20px 10px;
        background: #0048a1;
        border-radius: 100px;
        border: 1px solid #0048a1;
        &:hover {
            background-color: #ffffff;
            color: #4341b3;
        }
        &.active {
            background-color: #ec6233 !important;
            border: 1px solid #ec6233 !important;

            color: $white-color !important;
            &:hover {
                background-color: #ffffff !important;
                color: #ec6233 !important;
            }
        }
    }
}
.Register-expired-popup {
    .modal-dialog {
        max-width: 614px;
    }
    .modal-content {
        background-color: #ffffff !important;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 15px #00000026;
        border: 2px solid #ee7548;
        border-radius: 20px;

        .modal-body {
                padding: 34px;
        
                p {
                    font-size: 20px;
                    color: #0048a1;
        
                }
            }
        
            .warning-icon {
                max-width: 35px;
                margin-right: 30px;
            }
        }
}

.redirect-url {
    p {
        font-size: 18px;
        color: #0048A1;
    }
    .radioButton {
        label {
            font-size: 18px;
            color: #0048A1;
        }
    }
}
.alert-popup-content{          
    i {
        .closeIconGrey {
            width: 17px;
            cursor: pointer;
        }
    }
    .modal-dialog{
        max-width: 590px;
    .modal-content{
        box-shadow: 0px 5px 15px #00000026;
        border: 2px solid #FF6A37;
        border-radius: 10px;
        padding: 11px 13px 6px 11px;
}

.alert-popup{
    p{
        font-size: 14px;
        color: #0048A1;
        font-family: 'Josefin Sans';
    }
    }
}
}
