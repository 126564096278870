.form-styles {
  .form-group {
    .form-control {
      min-height: 50px;
      border-radius: 11px;

      &::placeholder {
        color: $silver-color;
        font-size: 0.88rem !important;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.optionPlaceholder {
  select option {
    color: $primary-color;
  }
}


.defaultOption {
  color: rgba($color: $primary-color, $alpha: 0.6) !important;
  min-height: 40px;
  font-size: 0.88rem !important;
}

.invalid-feedback {
  text-align: center;
  color: #f14336 !important;
}

.TextInputField {
  padding: 0.6rem 0.09rem;
  position: relative;

  @include breakpoint(large-phone) {
    padding: 0.38rem 0.07rem;
  }

  .MuiOutlinedInput-input {
    padding: 13px 10px 8px;
    background-color: #fff;
    border-radius: 15px;
    color: $text-color;
    font-size: 0.95rem;

    &:hover {
      border-color: $Blue-color  !important;
    }

    &::placeholder {
      color: #a5a5a5 !important;
      opacity: 1 !important;
      font-family: "Lato", sans-serif !important;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .error {
    // position: absolute;
    // top: 56px !important;
    line-height: 1.3;
    @include ellipsis(2);

    @include breakpoint(large-phone) {
      position: relative !important;
      top: inherit !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 13px;
  }

  &__icon {
    position: absolute;
    right: 8px;
    top: 8px;


    &.select {
      right: 27px;
    }

    &.datepicker {
      right: 30px;
    }

    img {
      height: 20px;
    }

    svg {
      font-size: 1rem;
      position: relative;
      top: -1px;
      fill: $primary-color;
    }

    &.cardNumber {
      height: 27px;
    }
  }

  .password-criteria {
    position: absolute;
    background-color: #fff;
    min-width: 380px;
    z-index: 3;
    border-radius: 5px;
    bottom: -6.5rem;
    height: 110px;

    @include breakpoint(large-phone) {
      width: 300px;
      min-width: inherit;
    }

    @include scrollbar(0.4rem, slategray);

    ul {
      margin-bottom: 0;
      padding: 0.5rem;

      li {
        font-size: 0.75rem;
        list-style: none;
        color: $text-color;
        opacity: 0.8;
        line-height: 1.5;
        display: flex;

        svg {
          font-size: 0.95rem;
          margin-right: 0.2rem;
          fill: #a5a5a5;
          margin-top: 0.18rem;
        }
      }
    }
  }

  .MuiSelect-select.MuiSelect-select {
    font-size: 0.85rem;
  }

  .MuiIconButton-root {
    padding: 1px;
    margin-right: -6px;
  }

  .MuiSvgIcon-root {
    font-size: 1.2rem;
    fill: rgba($color: $primary-color, $alpha: 0.6);
  }
}

.RegisterForm {
  margin-top: 1rem;

  [class*="col-"] {
    padding: 0 0.4rem;
  }

  .small-text {
    color: $primary-color;
  }

  .MuiOutlinedInput-input,
  .MuiSelect-outlined {
    color: $primary-color  !important;
  }

  .MuiOutlinedInput-root:hover {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0 0 0 0.2rem #007bff40 !important;
        border: 2px solid $primary-color;
      }
    }
  }

  .MuiFormLabel-filled {
    background-color: #fff !important;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
    color: rgba($color: $primary-color, $alpha: 0.6) !important;
    font-family: "Josefin Sans", sans-serif !important;

    // &.Mui-focused {
    //   opacity: 0 !important;
    // }
  }

  .flexiFieldsForm {
    padding: 0 0.5rem;

    .custom-forms {
      min-height: 50px;

      .form-group {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important;
      }
    }
  }

  .TextInputField {
    padding: 0rem 0.09rem !important;
    position: relative;
    min-height: 53px !important;

    @include breakpoint(large-phone) {
      padding: 0.38rem 0.07rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 15px;
    }



    .MuiOutlinedInput-input {
      padding: 13px 10px 8px;
      background-color: #fff;
      border-radius: 15px;
      color: $text-color;

      &::placeholder {
        color: rgba($color: $primary-color, $alpha: 0.6) !important;
        font-family: "Josefin Sans", sans-serif !important
      }
    }

    .password-criteria {
      position: absolute;
      background-color: #fff;
    }

    .MuiSvgIcon-root {
      fill: rgba($color: $primary-color, $alpha: 0.6);
    }
  }
}


input {
  font-family: "Josefin Sans", sans-serif !important;

  &::placeholder {
    font-size: 0.88rem !important;
    color: #a5a5a5 !important;
    color: $text-color;
    font-family: "Josefin Sans", sans-serif !important;
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: $Blue-color  !important;
  box-shadow: 0px 3px 6px #00000029;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiOutlinedInput-adornedEnd {
  background-color: #fff;
  border-radius: 15px !important;
}

.custom-error {
  // position: absolute;
  // top: -14px;
  // left: 5px;
  font-size: 0.71rem !important;
  color: #ff0000;
  line-height: 1.2;
  @include ellipsis(3);
}

.error {
  font-size: 0.71rem;
  color: #ff0000 !important;
  font-family: "Lato", sans-serif !important;
  // position: absolute !important;
  // top: -10px !important;
  // min-height: 23px;
  @include ellipsis(2);
  margin-top: 1px;

  @include breakpoint(large-phone) {
    min-height: auto;
    position: relative !important;
    top: inherit !important;
    margin-bottom: 3px;
  }
}

.success {
  font-size: 0.71rem;
  color: green !important;
  font-family: "Lato", sans-serif !important;
  // position: absolute !important;
  // top: -10px !important;
  line-height: 1;
  // min-height: 23px;
  @include ellipsis(2);
  margin-top: 3px;

  @include breakpoint(large-phone) {
    min-height: auto;
    position: relative !important;
    top: inherit !important;
    margin-bottom: 3px;
  }
}

.MuiFormLabel-asterisk {
  color: #ff0000;
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}

.MuiInputLabel-outlined {
  // transform: translate(15px, 14px) scale(1) !important;
  color: #a5a5a5 !important;
  font-size: 0.85rem !important;
  margin-bottom: 0 !important;
  text-transform: capitalize;

  &.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.9) !important;
    font-size: 0.85rem !important;
  }

  &::after {
    bottom: 0;
    color: red;
    content: "*";
    position: absolute;
    transform: translate(0, 0);
    display: none;
  }
}

// .MuiInputLabel-outlined.Mui-focused, .MuiInputLabel-outlined.MuiFormLabel-filled
// {
// opacity: 0;
// }

// .TextInputField.required
// {
//   .MuiInputLabel-root
//   {
//     position: relative;
//     &::after
//     {
//       bottom: 0;
//       color: red;
//       content: 'g';
//       position: absolute;
//       transform: translate(0, 0);
//     }
//   }
// }

.clear-password {
  .clear {
    @include breakpoint(tablet) {
      display: none;
    }

    right: 32px;
  }
}

.emailVerificationCode {
  &-container {
    >div {
      justify-content: space-between;
    }
  }

  input {
    border: 1px solid lighten($primary-color, $amount: 0.4);
    width: 60px !important;
    height: 60px;
    border-radius: 3px;
    font-size: 4.3rem;
    padding-top: .8rem;
    outline: none;

    color: rgba($color: $primary-color, $alpha: 0.9);

    &:focus {
      border: 2px solid lighten($primary-color, $amount: 0.4);
    }

    &::placeholder {
      color: rgba($color: $primary-color, $alpha: 0.6) !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}

.form-v2 {
  .MuiInputLabel-formControl {
    color: $primary-color;
    font-family: "Josefin Sans", sans-serif !important;
    font-size: 1rem;

    text-transform: capitalize;
  }

  .MuiInput-input {
    font-family: "Josefin Sans", sans-serif !important;
    font-size: 1rem;
    color: $primary-color;

    &::placeholder {
      color: rgba($color: $text-color, $alpha: 0.6);
      font-size: 1rem !important;
    }
  }

  .MuiInput-underline {
    &::before {
      border-color: rgba($color: $primary-color, $alpha: 0.2) !important;
    }

    &::after {
      border-color: rgba($color: $primary-color, $alpha: 0.8) !important;
    }
  }

  .TextInputField {
    padding: 0.3rem 0.09rem;
  }

  .clear {
    display: none;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: $primary-color;
  }

  .MuiInputBase-input.Mui-disabled {
    color: $primary-color;
  }
}

.profile {
  .form-v2 {
    width: 90%;
  }
}

.notallowed {
  cursor: not-allowed;
  pointer-events: none;
}

.support-form {
  .form-control {
    border: 2px solid lighten($primary-color, $amount: 0.6) !important;
    max-width: 486px;
    height: 40px !important;
    border-radius: 10px !important;
  }
}

.custom-forms {
  position: relative;
  min-height: 56px;



  .error {
    padding-left: 0.8rem;
  }

  @include breakpoint(tablet-large) {

    .form-control {
      height: 40px;
    }

    .TextInputField__icon {
      top: 8px;
    }
  }

  .form-control {
    border: 1px solid lighten($primary-color, $amount: 0.6);
    border-radius: 15px;
    color: $primary-color;
    height: calc(1.5em + 0.75rem + 4px);
    padding-right: 1.5rem;

    &:focus {
      border: 2px solid rgba($primary-color, 1);
      box-shadow: none;
    }

    &::placeholder {
      color: rgba($color: $primary-color, $alpha: 0.6) !important;
    }
  }
}


.custom-forms-outline {
  position: relative;
  min-height: 56px;

  .label {
    margin-bottom: 0;
    color: rgba($color: $primary-color, $alpha: 0.6);
    position: relative;
    bottom: -5px;
    @include fontSize(14);
  }

  .input {
    position: relative;
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid lighten($primary-color, $amount: 0.6);
    border-radius: 0;
    color: $primary-color;
    background-color: transparent;
    padding: 0;
    height: 38px;

    &:focus {
      border-bottom: 2px solid rgba($primary-color, 1);
      box-shadow: none;
    }

    &::placeholder {
      color: rgba($color: $text-color, $alpha: 0.6) !important;
    }
  }
}

.outline-form {
  .form-label {
    margin-bottom: 0;
    @include fontSize(14);
    opacity: .6;
    color: $primary-color;
  }

  .form-control {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    color: $primary-color;

    &:focus {
      box-shadow: none;
    }
  }

  margin-bottom: 0;
}



.check__item {

  label {
    display: flex;
    margin-bottom: 0 !important;

    .text {
      color: rgba($primary-color, 1);
      font-size: 0.75rem;
      line-height: 1.3;

    }
  }
}

.default__check[type="checkbox"],
.default__check[type="radio"] {
  display: none;

  ~.custom__check {
    display: flex;
    align-items: center;
    min-width: 23px;
    min-height: 23px;
    width: 23px;
    height: 23px;
    border: 1px solid rgba($primary-color, 1);
    position: relative;
    transition: all 0.4s ease;
    cursor: pointer;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      transition: all 0.4s ease;
    }
  }

  &:not(.switchbox) {
    ~.custom__check {
      justify-content: center;
      width: 23px;
      height: 23px;

      &:after {
        visibility: hidden;
      }
    }
  }

  &.switchbox {
    +.custom__check {
      width: 32px;

      &:after {
        transform: scale(1.5);
        left: 4px;
      }
    }

    &:not(:checked) {
      ~.custom__check {
        &:after {
          background-color: $primary-color;
        }
      }
    }

    &:checked {
      ~.custom__check {
        &:after {
          left: 20px;
        }
      }
    }
  }

  &:disabled {
    ~.custom__check {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &:checked {
    ~.custom__check {
      background-color: $primary-color;

      &:after {
        visibility: visible;
      }
    }
  }
}

.default__check[type="checkbox"] {
  &:not(.switchbox) {
    ~.custom__check {
      border-radius: 4px;

      &:after {
        height: 12px;
        width: 7px;
        margin-top: -3px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        transform: rotate(45deg);
      }
    }
  }
}

.default__check[type="radio"],
.default__check[type="checkbox"].switchbox {
  ~.custom__check {
    border-radius: 50rem;

    &:after {
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 50rem;
    }
  }
}

.view {
  &-form {
    margin-bottom: 1.5rem;
  }

  &-label {
    color: rgba($primary-color, .6);
    @include fontSize(14);
    line-height: 1.1;
  }

  &-value {
    color: $primary-color;
    border-bottom: 1px solid rgba($primary-color, .1);
    padding-bottom: .2rem;
  }
}

.enquiryFields {
  .form-control {
    min-height: 100px !important;

    &::placeholder {
      color: $primary-color  !important;
    }
  }
}

.modal-inside-loader {


  .loader {
    position: absolute;
    height: 100%;
    border-radius: 20px;
  }
}

// .Check {
//   display: flex;
//   align-self: center;
//   padding-right: 15px;

//   input {
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     opacity: 0;
//   }

//   input:checked~label {
//     &:before {
//       background-color: $primary-color;
//     }

//     &:after {
//       content: "";
//       display: block;
//       position: absolute;
//       top: 2px;
//       left: 6px;
//       width: 5px;
//       height: 10px;
//       border: solid $white-color;
//       border-width: 0 2px 2px 0;
//       transform: rotate(45deg);
//     }
//   }

//   label {
//     position: relative;

//     &:before {
//       content: "";
//       position: absolute;
//       left: 0px;
//       top: 0px;
//       width: 16px;
//       height: 16px;
//       background-color: $white-color;
//       border: 1px solid $primary-color;
//       border-radius: 2px;
//     }
//   }
// }

.choice {
  .form-check {
    display: flex;
    align-items: center;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }

  .form-check-label {
    padding-top: .1rem;
    padding-left: .5rem;
    color: $primary-color;
  }
}

.evs-verification-icons {
  button {
    background-color: rgba($color: $primary-color, $alpha: 0.1);
    cursor: pointer;
    padding: 3px 7px;
    border-radius: 2px;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;

    }
  }
}

.addressModal {
  max-height: calc(100vh - 60px);
  overflow: hidden;
  @include scrollbar(0.4rem, $primary-color);
}

.authorizeLogo {
  text-align: center;
  margin-bottom: 2.4rem;

  img {
    max-width: 300px;
  }
}

.p-reset {
  color: $primary-color;

  p {
    margin-bottom: 0;
  }
}

.registerDateUI {
  .MuiOutlinedInput-notchedOutline {
    // background-color: #fff;
    border: 1px solid #0049a4;
    border-radius: 15px;
    color: $primary-color;
    height: calc(1.5em + 0.75rem + 9px);


    &::after,
    &::before {
      display: none;
    }
  }

  .MuiSvgIcon-root {
    fill: $primary-color;
    width: .8em;
    height: .8em;
  }

  .MuiIconButton-root {
    padding: 0px;
  }

  input {
    color: $primary-color;
    padding-left: .9rem;
    padding-top: 0;
    padding-bottom: 0 !important;
    min-height: 40px;

    &::placeholder {
      color: rgba($color: $primary-color, $alpha: 1) !important;
      font-family: "Josefin Sans", sans-serif !important;
    }
  }

}


.baseSelect {
  .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('./../../images//icons/downArrow.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 15px;
    padding-right: 30px;
  }

  select:invalid,
  select option[value=""] {
    color: rgba($color: $primary-color, $alpha: .6) !important;
  }

  [hidden] {
    display: none;
  }
}


.userRegister {
  .flexiFieldsForm {
    margin-left: -.4rem !important;
    margin-right: -.4rem !important;
  }
}


.max-w-274 {
  max-width: 274px;
}

.validationErrorMessage {
  color: #ff0000;
  font-size: .9rem;
}

.revert-0 {
  padding: 0 !important;

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
  }
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Mozilla */

input[type=number] {
  -moz-appearance: textfield;
}