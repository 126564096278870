.offers {
  border-radius: 20px 20px;
  padding: 0 0.3rem;
  margin: 1rem 0 1.5rem;

  &__header {
    border-radius: 17px 17px 0px 0px;
    background-color: $orange-color;
    padding: 1.3rem 3rem;
    min-height: 75px;
    display: flex;
    align-items: center;
    width: 100%;

    @include breakpoint(tablet-large) {
      padding: 1.3rem 2rem;
    }

    .title {
      color: $white-color;
      text-align: center;
      font-weight: 900;
      margin-bottom: 0;
      text-transform: uppercase;
      width: 100%;
      line-height: 1.1;
    }
  }

  &__body {
    padding: 1.8rem 3rem 0.8rem;
    min-height: 180px;

    @include breakpoint(tablet-large) {
      padding: 1.8rem 2rem 0.8rem;
    }

    @include breakpoint(phone) {
      padding: 1.8rem 2rem 0rem;
      min-height: auto;
    }

    p {
      font-size: 0.95rem;
      color: $text-color;
      line-height: 1.8;
      margin-bottom: 0;
    }

    border-left: 3px solid $orange-color;
    border-right: 3px solid $orange-color;
  }

  &__footer {
    background-color: #ebf2fc;
    border-radius: 0 0 17px 17px;
    padding: 1rem 3rem;

    @include breakpoint(phone) {
      padding: 0rem 3rem 0 !important;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-left: 3px solid $orange-color;
    border-right: 3px solid $orange-color;
    border-bottom: 3px solid $orange-color;

    @include breakpoint(tablet-large) {
      padding: 1rem 2rem;
      position: relative;
      background-color: #fff;

      &::before {
        content: "";
        bottom: 0;
        left: 0;
        height: 100px;
        width: 100%;
        background-color: #ebf2fc;
        position: absolute;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        z-index: 0;
      }

      &--left,
      &--right,
      .btn {
        position: relative;
        z-index: 1;
      }

      &--left,
      &--right {
        padding-left: 1.4rem;

        .icon {
          position: absolute;
          left: -5px;
          top: calc(50% - 5px);
          transform: translateY(-50%);

          svg {
            font-size: 1.2rem !important;
          }
        }
      }
    }

    &--left,
    &--right {
      p {
        color: #424242;
        font-size: 0.7rem;
        margin-bottom: 0;
      }

      .icon {
        svg {
          fill: $green-color;
          font-size: 1rem;
        }
      }
    }

    @include breakpoint(tablet-large) {
      &--left {
        order: 2;
      }

      &--right {
        order: 3;
      }

      .register__button {
        order: 1;
      }
    }
  }

  .register__button {
    .btn {
      width: 100%;
      justify-content: center;
    }
  }
}

.special-offers-row {
  @include breakpoint(tablet-large) {
    .offers {
      margin-bottom: 2rem;
    }

    .col-lg-4 {
      &:last-child {
        .offers {
          margin-bottom: 0;
        }
      }
    }
  }

  .col-lg-4 {
    &:first-child {
      .offers {
        padding-left: 0;
      }
    }

    &:last-child {
      .offers {
        padding-right: 0;
      }
    }
  }
}