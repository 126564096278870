@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

@import "./abstracts/global";
@import './base/global';
@import './components/global';
@import './layout/global';
@import './pages/global';


.fullscreen-icon 
{
    display: none !important;
}

.amazon-cutome-paybtn{
    display: flex;
    justify-content: center;
    width: 100%;
    border: 1px solid;
    border: 1.62646px solid #2a334a !important;
    font-size: 18px;
    line-height: 100%;
    height: 35px;
    background-color: #ffffff;

}