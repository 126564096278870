@include breakpoint(min-desktop) {
  .support-form {
    padding-left: 0;
    padding-right: 0;

    h4 {
      @include fontSize(22);
    }
  }

  .courseList-card-top {
    .order-2 {
      width: 100%;
    }
  }
}

@media (max-width: 1199.98px) {
  .documents {
    .typing-width {
      padding: 2rem;

      .typing {
        .font-size-inc {
          margin: 0 !important;
        }

        .TextInputField.mb-5 {
          margin: 0 !important;
        }
      }
    }
  }

  .security-check {
    height: auto;
  }

  #userwayAccessibilityIcon {
    visibility: hidden;
    position: relative;
    z-index: -999999999999999;
    display: none;
  }
  .reset-padding {
    .securityQuestions {
      min-height: auto !important;
      margin-top: 5rem !important;
      padding-left: 220px !important;
    }
    #chapter-quiz {
      .container {
        padding-left: 220px !important;
        max-width: 80%;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .desktop-icon[name="google"] {
    display: block;
    width: 120px;
  }
  .typing-pattern-container {
    max-width: 100%;
  }

  .reset-padding {
    .securityQuestions {
      min-height: auto !important;
      margin-top: 5rem !important;
      padding: 22px 1rem 0 !important;
      max-width: 600px;
    }
    #chapter-quiz {
      .container {
        padding-left: 1rem !important;
        max-width: 95%;
      }
    }
  }

  .arrow {
    display: none;
  }

  .main-container {
    padding-left: 0;
    margin-left: 0px !important;
    min-height: auto;
  }

  .sidebar-container {
    left: -100%;
    transition: all 0.2s ease;
    width: 100%;

    // opacity: 0;
    // visibility: hidden;
    // display: none;
    .timecard {
      display: none;
    }

    &.open {
      left: 0;
      transition: all 0.3s ease;
      visibility: visible;
      opacity: 1;
      display: block;
    }

    .sidebar {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .logo {
        display: none;
      }

      .menu {
        li {
          span {
            color: $white-color;

            svg {
              opacity: 1;
            }
          }

          &.logout {
            left: auto;
            right: 0;
            width: auto;

            span {
              color: #99b6d9;

              svg {
                opacity: 0.6;
              }
            }
          }

          &.language {
            .lang-btn {
              color: $white-color !important;
            }
          }

          &.settings,
          &.profile,
          &.language {
            display: block;
          }
        }
      }
    }

    .close-sidebar {
      display: flex;
    }
  }

  .header-container {
    padding-left: 0;

    .header {
      padding: 1rem 2rem;
      background: $primary-color;
      align-items: center;
      justify-content: center;
      position: relative;

      .logo {
        display: none;

        img {
          display: block;
        }

        &.new {
          display: block;
        }
      }

      .hamburger {
        display: block;
        position: absolute;
        left: 2rem;
        background-color: transparent;
        padding: 0;
        width: 20px;
        height: 20px;

        span {
          border: 1px solid $white-color;
          margin: 0;

          &.center {
            margin: 7px 0;
          }
        }
      }

      .nav {
        display: none;
      }
    }
  }

  .page-title {
    position: static;

    .small-info {
      top: -18px;
    }
  }

  .new-dashboard {
    .course-card {
      .card-body {
        padding: 1.75rem 18rem 0 1.75rem;
      }

      .course-img {
        img {
          max-height: 190px;
          max-width: 290px;
        }
      }
    }

    .course-progress {
      padding: 1rem 2rem 1rem 2rem;

      .days-left {
        display: block;
        text-align: center;
      }
    }

    .left-sec {
      padding-right: 1rem;
    }
  }

  .mob-menu-overlay {
    // &:after {
    //     width: 100vh;
    //     height: 100vh;
    //     background-color: rgba(0, 0, 0, .6);
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     overflow: hidden;
    //     z-index: 8;
    // }
  }

  // Cart summary
  .cart-summary {
    .price__display {
      max-width: 550px;
      margin: 1rem auto 1.5rem;
    }
  }

  .btn {
    &:hover {
      transition: none !important;
    }
  }
}

#dragIcon,
.mobile-assistive-container {
  display: none;
}

.compliance-details {
  display: block;
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 10px;
  padding: 20px;

  @media (max-width: 1199.98px) {
    display: none;
  }
}

.compliance-details-mobile {
  display: none;

  @media (max-width: 1199.98px) {
    display: block;
    font-size: 12px;
    color: rgb(0, 0, 0);
  }
}

@media (max-width: 767.98px) {
  .securityQuestions {
    margin-top: 4rem !important;
  }

  .position-relative {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  #dragIcon {
    display: block;
    position: fixed;
    width: 35px;
    height: 35px;
    z-index: 10000;
    cursor: pointer;
    bottom: 0.5rem;
    right: 0px;
    border-radius: 30%;
    opacity: 0.5;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
    }

    img {
      max-width: 40px;
    }
  }

  .mobile-assistive-container {
    display: block;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    position: fixed;
    bottom: 45px;
    right: -220px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1;
    background: inherit;
    overflow: hidden;
    min-height: 290px;
    width: 220px;
    padding: 1rem;

    &.active {
      right: 0.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      background: inherit;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 2000px rgba(255, 255, 255, 1);
      filter: blur(1px);

      min-height: 290px;
      width: 220px;
    }

    #accessibilityWidget {
      min-width: 58px;
      box-shadow: 0 3px 25px #0000004d;
      border-radius: 28px;
    }

    .dropdown {
      &-menu {
        width: 100%;
        border: none;
        margin-left: 0.5rem;
        background-color: rgba($color: $white-color, $alpha: 0.4);
      }

      &-item {
        font-size: 0.8rem;
        color: $primary-color;
        padding: 0.25rem 0.5rem;

        &:active,
        &:hover {
          background-color: rgba($color: $primary-color, $alpha: 0.2);
          color: $primary-color !important;
          border-radius: 3px;
        }

        img {
          max-width: 14px;
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .braintree-form__icon-container {
    display: none !important;
  }

  .table-of-contents-modal .modal-dialog .modal-bod {
    padding: 4rem 2rem 2rem !important;
  }

  .new-dashboard {
    .course-card {
      .card-body {
        padding: 1.75rem 1.2rem 0 1.2rem;
      }

      .course-img {
        position: static;
        padding: 1rem 2rem;
      }
    }

    .certificate-btn {
      display: none;
    }

    .course-progress {
      .progress {
        margin: 0 0.4rem;
      }

      .days-left {
        text-align: left;
      }
    }
  }

  .course-list {
    .page-title {
      .mobileNavigation {
        display: none;

        @media (max-width: 680px) {
          display: block;

          .mobileHeader {
            background-color: $primary-color;
            padding: 0 1rem;
            min-height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mobile {
              &-placeholder {
                width: 180px;
                max-height: 31px;
                height: 31px;
                background-color: rgba($color: $white-color, $alpha: 0.3);
                border-radius: 10px;
                color: $white-color;
                @include fontSize(14);
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &-menu {
                max-width: 20px;
                width: 100%;
              }

              &-brand {
                max-width: calc(100% - 20px);
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;

                img {
                  max-height: 12px;
                }
              }
            }
          }
        }
      }

      margin-bottom: 1.5rem;
    }

    .other-courses .course-card {
      width: 100%;

      .card-body {
        padding: 1.75rem 1.2rem 0 1.2rem;

        .card-text {
          margin-bottom: 1rem;
          margin-top: 2rem;
          display: none;
        }

        .card-title {
          position: relative;

          span {
            display: block;
            margin: 1rem 0 0;
          }
        }

        .course-progress {
          transform: none;
          width: auto;
          display: flex;
          align-items: flex-end;
          bottom: -30px;
          right: 0;
          top: auto;
          flex-direction: column;
          justify-content: flex-end;

          .progress {
            width: 150px;
            margin-right: 0;
            margin-bottom: 1rem;

            .days-left {
              width: 120px;
            }
          }
        }
      }

      .card-footer {
        flex-direction: column-reverse;
        align-items: flex-start;

        .chapter-name {
          margin: 1rem 0;
        }
      }
    }
  }

  .alerts {
    max-width: 100%;
  }

  .billing-sec {
    .table {
      width: 100%;
    }
  }

  .documents {
    .typing-width {
      .typing {
        text-align: center;

        .font-size-inc {
          width: 100% !important;
        }

        &.py-2.pl-5 {
          padding-left: 0 !important;
        }
      }
    }

    .typing-footer {
      justify-content: center !important;
      margin-right: 0 !important;
      flex-direction: column;

      .typing-button {
        .btn {
          font-size: 1rem;
        }
      }
    }
  }
}

.custom-content {
  label {
    font-family: open-sans, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #2a334a;
  }
}

@media (max-width: 575.98px) {
  .main-container {
    padding-bottom: 0px !important;

    section {
      padding: 1rem;
    }

    .custom-content {
      padding: 1rem 0 !important;
    }
  }

  .reset-left-padding {
    padding-top: 158px !important;
  }

  .parent-container {
    padding-top: 95px !important;
  }

  .header-container {
    .header {
      .nav {
        .nav-item {
          span {
            text-indent: -9999em;
            padding-right: 0;
            padding-left: 2rem;

            svg {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .new-dashboard {
    .course-card {
      .card-text {
        line-height: 1.2;
        @include fontSize(14);
        min-height: auto;
      }

      .card-footer {
        .btn {
          // width: 100%;
          text-align: left;
          line-height: 33px;

          img {
            float: right;
          }
        }
      }
    }

    .course-progress {
      .left-sec {
        padding-right: 1rem;
      }

      .progress {
        bottom: 0;
        width: 120px;

        p {
          text-align: center;
        }
      }
    }
  }

  .profile-modal {
    .discard-btn {
      display: block;
      margin: 1rem;
    }
  }

  .table-of-contents-modal .course-content-table td:first-child,
  .table-of-contents-modal .course-content-table th:first-child,
  .table-of-contents-modal .course-content-table td:last-child,
  .table-of-contents-modal .course-content-table th:last-child {
    padding: 0.75rem;
  }

  .personalInfo {
    .register__button {
      text-align: left !important;
      justify-content: flex-start;
      flex-direction: column;

      .btn {
        line-height: 1.3;
        font-size: 0.825rem;
        width: auto;
        border-radius: 30px !important;
        justify-content: center;
        margin: 0 0 1rem;
      }
    }
  }

  .pageNotFound__img {
    width: 70%;
  }

  .documents .typing-width {
    padding: 1.5rem;
  }

  .cardSummaryContainer .cardSummary-card .item .header .course-fee .info-text {
    width: 300px;
    left: 0;
    top: 27px;
  }

  .braintree-form__flexible-fields .braintree-form__field-group:nth-child(1) {
    max-width: 135px;
  }

  .timer {
    position: static;
    right: inherit;
    top: 73px;

    .timer-heading {
      color: $primary-color !important;
    }
  }

  .chapterTimerLabel {
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 88px;
    width: 100%;

    .available-time {
      position: static;

      .time-active {
        max-width: 270px;
        width: auto;
        padding: 1rem;
        border-radius: 20px;
        left: 0;
        top: 50px;
      }
    }
  }
}

.setting {
  .billing-sec {
    padding-left: 0 !important;

    .page-title {
      padding-top: 0 !important;
      font-weight: 600 !important;
      letter-spacing: 0px;
      font-size: 1.125rem !important;
    }
  }
}

.mobileNavigation {
  display: none;

  @media (max-width: 1199.98px) {
    display: block;

    .mobileHeader {
      background-color: $primary-color;
      padding: 0 1rem;
      min-height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 6px 12px #00000029;

      .mobile {
        &-placeholder {
          width: 180px;
          max-height: 31px;
          height: 31px;
          background-color: rgba($color: $white-color, $alpha: 0.3);
          border-radius: 10px;
          color: $white-color;
          @include fontSize(14);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-menu {
          max-width: 20px;
          width: 100%;
        }

        &-brand {
          max-width: calc(100% - 20px);
          width: 100%;
          display: flex;
          justify-content: center;
          text-align: center;

          img {
            max-height: 12px;
          }
          .improv-logo {
            max-width: 30px;
            max-height: 30px;
          }
        }
      }
    }
  }
}

// #comm100-button-4b4b4c00-0000-0000-0000-008e000189f9 {
//   transition: all 0.4s ease;

//   &.mobileView {
//     @include breakpoint(tablet) {
//       transition: all 0.4s ease;

//       iframe {
//         transition: all 0.4s ease;
//         right: -100px !important;
//         bottom: 235px !important;

//       }
//     }
//   }

// }

@include breakpoint(tablet-large) {
  .faceRecognition {
    .modal {
      &-content {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      &-dialog {
        margin-left: 0;
      }
    }

    &-body {
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0.2rem;
    }

    &-notes {
      padding-left: 0;
      margin-top: 2rem;
    }

    &-view {
      .d-flex.justify-content-between {
        flex-wrap: wrap;

        .btn {
          width: 100%;
          margin-top: 0.5rem;

          &-primary {
            order: 1;
          }

          &-outlined {
            order: 2;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .reset-padding {
    .securityQuestions {
      margin-top: 4.5rem !important;
    }
  }
}
