.certificateContainer {
    .certificateDesign {
        padding: 0.2rem;
        background-color: #fff;
        max-width: 680px;

        margin: 0 auto;
        border: 2px solid $black-color;
        &__content {
            padding: 0.8rem;
            &--header {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .left {
                    @include breakpoint(large-phone) {
                        font-size: 0.3rem;
                    }
                }
                .right {
                    font-size: 0.75rem;
                    color: $black-color !important;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: right;
                    flex: 0 0 180px;
                    max-width: 180px;
                    width: 100%;
                    @include breakpoint(large-phone) {
                        font-size: 0.5rem;
                    }
                    .box {
                        border: 1px solid $black-color;
                        width: 100%;
                        height: 40px;
                        display: flex;
                        @include breakpoint(large-phone) {
                            width: 100px !important;
                        }
                    }
                }
            }
            &--body {
                .title {
                    margin-top: 1.8rem;
                    line-height: 1.3;
                    @include breakpoint(large-phone) {
                        font-size: 1rem;
                    }
                    .text-style {
                        font-style: italic;
                    }
                }
                .content {
                    p {
                        margin-bottom: 0;
                        font-size: 0.9rem;
                        color: $black-color;
                        text-align: justify;
                        @include breakpoint(large-phone) {
                            font-size: 0.5rem;
                        }
                        &.p1 {
                            margin-bottom: 0.7rem;
                            .line {
                                width: 160px;
                            }
                            @include breakpoint(large-phone) {
                                font-size: 0.5rem;
                                .line {
                                    width: 60px !important;
                                }
                            }
                        }
                        &.p2 {
                            margin-bottom: 1.4rem;
                            @include breakpoint(large-phone) {
                                font-size: 0.5rem;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                    .small-text {
                        font-size: 0.7rem;
                    }
                }
                .head1 {
                    color: $black-color;

                    @include breakpoint(large-phone) {
                        font-size: 0.6rem;
                    }
                    .line {
                        width: 150px;
                        @include breakpoint(large-phone) {
                            font-size: 0.6rem;
                        }
                    }
                }
                .head2 {
                    color: $black-color;
                    @include breakpoint(large-phone) {
                        font-size: 0.6rem;
                    }
                    .line {
                        width: 120px;
                        @include breakpoint(large-phone) {
                            font-size: 0.6rem;
                            width: 80px;
                        }
                        &.two {
                            width: 180px;
                            @include breakpoint(large-phone) {
                                width: 90px;
                            }
                        }
                    }
                    .text {
                        margin-left: 1.4rem;
                    }
                }
            }
            &--signature {
                margin-top: 2rem;
                display: flex;
                justify-content: flex-end;
                .content {
                    max-width: 280px;
                    width: 100%;
                    flex: 0 0 280px;
                    display: flex;
                    flex-wrap: wrap;
                    .sig1,
                    .sig2 {
                        flex: 0 0 100%;
                        max-width: 100%;
                        width: 100%;
                        color: $black-color;
                        padding-left: 1rem;
                    }
                    .sig1 {
                        font-size: 0.82rem;
                        @include breakpoint(large-phone) {
                            font-size: 0.5rem;
                            text-align: right;
                        }
                    }
                    .sig2 {
                        font-size: 0.7rem;
                        @include breakpoint(large-phone) {
                            font-size: 0.5rem;
                        }
                    }
                    .signature {
                        margin-top: 1rem;
                        display: flex;
                        padding-left: 0.4rem;
                        @include breakpoint(large-phone) 

                        {
                            justify-content: flex-end;
                        }
                        .sig3 {
                            position: relative;
                            font-size: 1rem;
                            font-weight: 600;
                            margin-bottom: 0;
                            margin-top: 1rem;
                            @include breakpoint(large-phone) {
                                font-size: 0.7rem;
                                text-align: right;
                            }
                            .line {
                                width: 166px;
                                @include breakpoint(large-phone) {
                                    font-size: 0.5rem;
                                }
                            }
                            .signature__img {
                                position: absolute;
                                bottom: 0;
                                img {
                                    max-width: 120px;

                                }
                            }
                        }
                        .p4 {
                            font-size: 0.6rem;
                            text-align: center;
                            padding-left: 5rem;
                            margin-top: -0.2rem;
                            @include breakpoint(large-phone) {
                                font-size: 0.4rem;
                            }
                        }
                    }
                }
            }
            &--note {
                p {
                    font-size: 0.65rem;
                    margin-bottom: 0.4rem;
                    line-height: 1.3;
                    color: $black-color;
                    @include breakpoint(large-phone) {
                        font-size: 0.4rem;
                    }
                }
            }
            &--footer {
                border: 1px solid $black-color;
                .content {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0.2rem;
                    &__left,
                    &__right {
                        flex: 0 0 50%;
                        max-width: 50%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        p {
                            text-align: center;
                            line-height: 1.3;
                            font-size: 0.8rem;
                            color: $black-color;
                            margin-bottom: 0;
                            @include breakpoint(large-phone) {
                                font-size: 0.5rem;
                            }
                        }
                    }
                    &__left {
                        p {
                            max-width: 190px;
                        }
                    }
                    &__right {
                        p {
                            flex: 0 0 100%;
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .border-lite {
        border: 1px solid $black-color;
    }
    .small-text {
        font-size: 0.7rem;
        color: $black-color !important;
        @include breakpoint(large-phone) {
            font-size: 0.5rem;
        }
    }
    .line {
        border-bottom: 2px solid $black-color;
        display: inline-flex;
        height: 20px;
        margin-left: 0.3rem;
        @include breakpoint(large-phone) {
            font-size: 0.6rem;
        }
    }
}
.certificateModal {
    .modal-body {
        padding: 0 !important;
    }
    .modal-dialog {
        max-width: 680px;
        overflow: auto;
    }
    .modal-content {
        border: none;
    }
}
