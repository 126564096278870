.securityQuestions {
    max-width: 868px;
    width: 100%;
    margin: 0 auto;
    // margin-top: -72px;
    // margin-bottom: -72px;
    padding-top: 22px;
    // padding-bottom: 72px;
    // min-height: calc(100vh - 40px);
    margin-top: 5rem;
    display: flex;
    align-items: center;

    .title {
        font-family: "Josefin Sans", sans-serif;
        @include fontSize(32);
        font-weight: 600;
    }

    .important {
        color: $primary-color;
        font-size: 1rem;
    }

    .card-container {
        background-color: #F0F8FF;
        border: 2px solid $primary-color;
        border-radius: 20px;
        padding: 1.9rem;
        box-shadow: 0px 12px 22px #00000029;

    }

    .question {
        color: $primary-color;
        @include fontSize(18);
        font-weight: bold;
    }

    .print {
        @include fontSize(14);

        img {
            position: relative;
            top: -3px;
            padding-right: .1rem;
        }
    }
}


.security-check {
    height: calc(100vh - 164px);
    display: flex;
    align-items: center;

    .register__button {
        .btn {
            min-width: 260px;
            justify-content: center;
        }
    }
}

.securityCheck {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        font-family: "Josefin Sans", sans-serif;
        @include fontSize(32);
        font-weight: 500;
        color: $primary-color;
        margin-bottom: 2rem;

        @include breakpoint(tablet) {
            @include fontSize(28);
            margin-bottom: 1rem;
            padding: 0 1rem;
        }
    }

    .timerShow {
        .TotalTime {
            color: $primary-color;
            @include fontSize(22);

            @include breakpoint(tablet) {
                @include fontSize(18);
                text-align: center;

                .time {
                    justify-content: center;
                }
            }

            margin-bottom:.1rem;

            .time {
                color: #FF0000;

                div {
                    width: 28px !important;
                    height: 24px !important;
                }
            }
        }

        @include breakpoint(tablet) {

            .info,
            .question {
                text-align: center;
            }

            .info {
                @include fontSize(14);
                line-height: 1.3;
                margin-top: 1rem;
            }
        }
    }


    .question {
        color: $primary-color;
        @include fontSize(18);
        font-weight: bold;
        margin-bottom: .3rem;
    }

    .info {
        color: $primary-color;
    }

    &__card {
        .alert {
            max-width: 545px;
            font-size: 0.9rem;
        }

        &--body {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;

            .body {
                box-shadow: 0px 3px 15px #00000029;
                border: 2px solid $Blue-color;
                border-radius: 20px;
                max-width: 641px;
                width: 100%;
                min-height: 197px;
                flex: 0 0 641px;
                padding: 1.9rem;
                position: relative;

                @include breakpoint(tablet) {
                    max-width: calc(100% - 2rem);
                    background-color: $white-color;
                }

            }
        }

        h4 {
            font-weight: 600;
            color: $Blue-color;
        }
    }

    .questionDetails {
        margin-bottom: 0.8rem;
    }
}

.attempts {
    font-size: 0.89rem;
    color: $text-lite;
    position: absolute;
    top: 6px;
    right: 2rem;
}

.typing-pattern-container {
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.verification-v-center {
    min-height: calc(100vh - 260px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.quickBreak {
    background-color: white;

    h6 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: $primary-color;
    }

    p {
        color: $primary-color;
    }
}



.question-description-img {
    margin-top: .5rem;

    img {
        max-height: 160px;
        border-radius: 10px;
        box-shadow: 0px 3px 10px #00000029;


    }
}

.modal-quiz-img {
    img {
        border-radius: 17px;
    }
}

.width-700 {
    .modal-dialog {
        max-width: 700px;
    }
}