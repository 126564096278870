.couserInfoTab {
  .tab-content {
    background-color: $white-color;
    border-radius: 0 0 1rem 1rem;

    @include breakpoint(tablet-large) {
      width: calc(100% - 1px);
    }
  }

  .nav {
    min-height: auto;
    justify-content: flex-start;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 2px;
      height: 20px;
      background-color: $primary-color;
    }

    &::after {
      border-top-right-radius: 10px;
      // @include breakpoint(tablet-large) {
      //   content: "";
      //   position: absolute;
      //   bottom: -14px;
      //   right: 1px;
      //   width: 2px;
      //   height: 20px;
      //   background-color: $primary-color;
      // }
    }

    .nav-item {
      background-color: $white-color;
      max-width: 165px;

      @include breakpoint(min-desktop) {
        max-width: calc(33.333% - 0.3rem) !important;
      }

      &:last-child {
        margin-right: 0rem;

        // max-width: 170px;
        @include breakpoint(tablet-large) {
          max-width: calc(33.333% + 0.4rem);
        }

        @include breakpoint(phone) {
          max-width: calc(33.333% + 0.35rem);
        }
      }

      @include breakpoint(tablet-large) {
        max-width: calc(33.333% - 0.3rem);
      }

      width: 100%;
      margin-right: 0.1rem;
      text-align: center;
      color: $primary-color !important;
      @include fontSize(14);
      padding: 0.7rem 0.5rem;
      font-weight: 600;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      position: relative;
      cursor: pointer;
      border: 2px solid $primary-color;
      border-bottom: 0;
      font-family: $kansasnew-family;

      &.active {
        &:after {
          content: "";
          position: absolute;
          height: 20px;
          width: 100%;
          background-color: $white-color;
          left: 0;
          bottom: -15px;
        }
      }
    }
  }
}

.course-content-tab {
  @include padding(36, 27, 36, 27);
  border: 2px solid $primary-color;
  border-right: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: #fff;
  min-height: 413px;

  @include breakpoint(tablet-large) {
    border-right: 2px solid $primary-color !important;
    border-top-right-radius: 1rem;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  // .table-responsive-xl {
  //   margin-left: -23px;
  //   margin-right: -11px;
  // }

  .MuiAccordion-root.Mui-expanded {
    margin: 0 0;
  }

  .MuiAccordionSummary {
    &-expandIcon {
      &.Mui-expanded {
        transform: rotate(0deg) !important;
      }

      &.MuiIconButton-edgeEnd {
        transform: rotate(-90deg);
      }
    }

    &-root {
      color: $primary-color;
      border-top: 1px solid #ebf1f6;
      padding-left: 0;
      padding-right: 0;

      &.Mui-expanded {
        border-bottom: none;
        margin: 0;
        min-height: 48px;
      }

      .MuiSvgIcon-root {
        fill: $primary-color;
        font-size: 1.4rem;
      }
    }

    &-content {
      font-size: 1rem;
      margin: 00px 0 !important;
      font-weight: 600;
    }
  }

  .MuiCollapse-wrapperInner {
    .MuiAccordionDetails {
      &-root {
        p {
          color: $primary-color;
          @include fontSize(14);
        }

        &.Mui-expanded {
          border-bottom: none;
          margin: 0;
        }
      }
    }
  }

  &.chooseUs {
    padding: 1rem;

    @include breakpoint(tablet-port) {
      .table-responsive-xl {
        margin-left: 0;
      }

      padding: 1rem 0.5rem;

      .choose-scroll {
        max-height: 100%;
        padding-right: 0;
      }
    }
  }

  .accordion {
    &-header {
      cursor: pointer;

      border-top: 1px solid $lite-blue;
      font-size: 1rem !important;
      color: $primary-color;
      font-weight: 600;

      &:first-child {
        border-top: none;
        padding: 0 0 0.5rem 0 !important;
      }

      padding: 0.5rem 0 !important;

      &:last-child {
        padding: 0.5rem 0 0 0 !important;
      }
    }

    div {
      @include fontSize(14);
      color: $primary-color;
      padding: 0.2rem 0 0.7rem;
    }
  }

  @include breakpoint(tablet-large) {
    border-right: 1px solid $silver-color;
    border-bottom-right-radius: 1rem;
  }

  @include breakpoint(tablet-port) {
    padding: 1rem;
  }

  .tabs {
    @include scrollbar(0.5rem, $text-lite);
    height: 100%;
    max-height: 420px;
    padding-right: 1rem;

    @include breakpoint(large-phone) {
      max-height: inherit;
      padding-right: 0;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.13);
  }

  h5 {
    color: $text-color;
  }

  h6 {
    font-weight: 500;
    margin-bottom: 0 !important;
    // @include ellipsis(1);
    color: $text-lite;
  }

  .MuiAccordionDetails-root {
    padding: 0 0 1rem !important;
  }

  p {
    margin-bottom: 0 !important;
  }
}

.MuiAccordionDetails-root {
  p {
    font-size: 0.95rem;
    color: $text-color;
  }
}

.choose-scroll {
  margin-top: 0.4rem;
  max-height: 485px;
  // min-height: 360px;
  @include scrollbar(0.6rem, $primary-color);
  padding-right: 1rem;
}

.chooseUs {
  .table td,
  .table th {
    border: none;
  }

  .table {
    border-spacing: 10px 0;
    border-collapse: separate;

    tr {
      &:nth-child(1) {
        td {
          &:nth-child(3) {
            height: 140px;
          }
        }
      }

      td {
        margin-right: 0.4rem;

        @include breakpoint(tablet-large) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

        &:nth-child(2) {
          background-color: $lite-blue;
          padding-left: 0.4rem;
          padding-right: 0.4rem;

          @include breakpoint(tablet-large) {
            padding-left: 0rem;
            padding-right: 0rem;
            width: 52px;
            position: relative;

            .title-2 {
              position: absolute;
              max-width: 92px;
              width: 92px;
              left: -24px;
              top: 4rem;
              text-align: center;
            }

            .chooseSelect {
              max-width: 16px;
            }
          }
        }

        &:nth-child(3) {
          background-color: #fff7e5;
          padding-left: 0.2rem;
          padding-right: 0.2rem;

          @include breakpoint(tablet-large) {
            width: 52px;

            padding-left: 0rem;
            padding-right: 0rem;
            position: relative;

            .title-3 {
              position: absolute;
              max-width: 92px;
              width: 92px;
              top: 4rem;
              left: -21px;
              text-align: center;
            }

            .chooseSelect {
              max-width: 16px;
            }
          }
        }
      }

      &:first-child {
        td {
          padding-top: 2rem;
          padding-bottom: 1.5rem;

          &:nth-child(2) {
            border-radius: 30px 30px 0 0;
          }

          &:nth-child(3) {
            border-radius: 30px 30px 0 0;
          }
        }
      }

      &:last-child {
        td {
          padding-bottom: 2rem;

          &:nth-child(2) {
            border-radius: 0 0 30px 30px;
          }

          &:nth-child(3) {
            border-radius: 0 0 30px 30px;
          }
        }
      }
    }
  }

  .title {
    &-1,
    &-2,
    &-3 {
      color: $primary-color;
      line-height: 1;
    }

    &-1 {
      @include fontSize(22);
      font-family: $kansasnew-family;
      font-weight: 700;
      line-height: 1.2;
      max-width: 199px;
    }

    &-2,
    &-3 {
      @include fontSize(14);
      font-weight: 500;
      transform: rotate(90deg);

      @include breakpoint(tablet-large) {
        @include fontSize(10);
      }
    }

    &-2 {
      position: relative;
      top: 0.5rem;
      max-width: 57px;
      padding-bottom: 0.5rem;
    }

    &-3 {
      position: relative;
      top: 0rem;
      max-width: 63px;
      left: 5px;
    }
  }

  .value {
    color: $primary-color;

    @include breakpoint(tablet-port) {
      @include fontSize(14);
    }
  }

  .score {
    text-align: center;

    @include breakpoint(phone) {
      img {
        max-width: 10px;
      }
    }
  }
}

.why-Choose-Us {
  tr {
    th {
      &:nth-child(2) {
        max-width: 120px;
        width: 120px;
      }
    }

    td {
      &:nth-child(2) {
        max-width: 120px;
        width: 120px;
        padding-left: 20px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 0;
          height: 100%;
          width: 1px;
          border-left: 1px solid #dee2e6;
        }

        &::after {
          content: "";
          position: absolute;
          right: 10px;
          top: 0;
          height: 100%;
          width: 1px;
          border-right: 1px solid #dee2e6;
        }
      }
    }
  }
}

.whyChooseUs {
  padding-left: 0 !important;

  // @include breakpoint(tablet-port) {
  //   width: 510px;
  //   overflow: auto;
  // }
  .whyChooseUsList {
    background-image: none !important;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0 !important;
    padding-left: 0 !important;

    &:first-child {
      .whyChooseUsList__tickOne {
        border-top: 1px solid #afb6cb;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 1rem;
        text-align: center;
      }

      .whyChooseUsList__tickTwo {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        @include breakpoint(tablet-port) {
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }

        text-align: center;
      }
    }

    &:last-child {
      .whyChooseUsList__tickOne,
      .whyChooseUs .whyChooseUsList__tickTwo {
        padding-bottom: 1.9rem !important;
      }

      .whyChooseUsList__tickOne {
        border-bottom: 1px solid #afb6cb;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding-bottom: 1rem;
      }

      .whyChooseUsList__tickTwo {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding-bottom: 1rem;
      }
    }

    &__title {
      max-width: calc(100% - 240px);
      flex: 0 0 calc(100% - 240px);
      padding: 0.26rem 1rem 0.26rem 0;

      .placeholder {
        color: $text-lite;
        font-size: 0.88rem;
        line-height: 1.2;
        display: inline-flex;
      }

      @include breakpoint(tablet-port) {
        max-width: calc(100% - 210px);
        flex: 0 0 calc(100% - 210px);
        @include ellipsis(3);

        .placeholder {
          line-height: 1.2;
          display: flex;
          font-size: 0.8rem;
        }
      }
    }

    &__tickOne {
      max-width: calc(115px - 1rem);
      flex: 0 0 calc(115px - 1rem);
      border-left: 1px solid #afb6cb;
      border-right: 1px solid #afb6cb;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 600;
      padding: 0 0.5rem;

      @include breakpoint(tablet-port) {
        font-size: 0.7rem;
        max-width: calc(95px - 0.5rem);
        flex: 0 0 calc(95px - 0.5rem);
        padding: 0rem;
      }

      svg {
        font-size: 1.4rem;
      }
    }

    &__tickTwo {
      max-width: calc(115px - 1rem);
      flex: 0 0 calc(115px - 1rem);
      margin: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cecece;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 600;

      @include breakpoint(tablet-port) {
        font-size: 0.7rem;
        max-width: calc(95px - 0.5rem);
        flex: 0 0 calc(95px - 0.5rem);
        padding: 0rem;
      }

      svg {
        font-size: 1.4rem;
      }
    }

    &__tickOne,
    &__tickTwo {
      color: $text-color;
      font-weight: 900;
      line-height: 1.3;
      font-size: 0.75rem;

      @include breakpoint(tablet-port) {
        font-size: 0.7rem;
        line-height: 1.1;
      }

      .tick {
        background-image: url(../../images/icons/check.svg);
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
      }
    }
  }
}

.RegisterForm {
  &.isActivePartner {
    display: flex;
    justify-content: center;

    .register {
      max-width: 540px;
    }
  }

  .rowReset {
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important;
  }
}

.register {
  min-height: 400px;
  background-color: #ffffff;
  // background-color: #f0f8ff;
  box-shadow: 0px 12px 22px #00000029;
  border: 2px solid $Blue-color;
  border-radius: 20px;
  @include padding(20, 24, 16, 24);

  &.userRegister {
    @include breakpoint(tablet-large) {
      margin-top: 3rem;
    }
  }

  &__section {
    min-height: 420px;
  }

  .TextInputField .MuiSvgIcon-root {
    fill: $text-lite;
  }

  @include breakpoint(tablet-port) {
    margin-top: 1.5rem !important;
    padding: 1rem;
  }

  h4 {
    color: $primary-color;
    font-family: $kansasnew-family;
    @include fontSize(24);
    font-weight: 600;

    @include breakpoint(tablet-large) {
      text-align: center;
      @include fontSize(18);
    }
  }

  h3 {
    @include breakpoint(phone) {
      margin-bottom: 0.6rem;
    }
  }

  h6 {
    @include fontSize(18);
    font-weight: bold;
    color: $primary-color;

    @include breakpoint(tablet-large) {
      text-align: center;
      @include fontSize(14);
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.checkout-btn {
      padding: 0.4rem 0;
      margin-left: -0.3rem;
      margin-right: -0.4rem;

      .btn {
        max-width: 33.333%;
        flex: 0 0 33.333%;
        padding: 0.375rem 0.3rem !important;

        img {
          width: 100%;
        }
      }
    }

    .btn {
      max-width: 33.333%;
      flex: 0 0 33.333%;
      width: 100%;
      padding: 0.375rem 0.2rem;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        padding-right: 0;
      }

      @include breakpoint(min-desktop) {
        padding: 0.375rem 0.3rem;

        img {
          width: 100%;
        }
      }

      @include breakpoint(large-phone) {
        padding: 0.375rem 0.1rem;
      }

      img {
        border-radius: 20px;
        background-color: transparent;
        transition: all ease-out 0.6s;
        width: 100%;

        // &:hover {
        //   transform: scale(1.05);
        // }

        &.google {
          width: 156px;
        }

        &.facebook {
          width: 153px;
        }

        &.amazon {
          width: 155px;
        }
      }

      &:focus {
        box-shadow: 0px 15px 22px #0000004d;
      }
    }

    &.size {
      .btn {
        img {
          // max-width: 150px;
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;

    @include breakpoint(tablet-large) {
      justify-content: center;
    }

    .btn {
      padding: 0.6rem 1.7rem 0.4rem !important;
      border-radius: 20px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 1.1rem;
      border: 1px solid transparent;

      &:hover,
      &:focus,
      &:active {
        @include primary-btn-hover;
      }

      @include breakpoint(large-phone) {
        padding: 0.9rem 1.7rem;
        border-radius: 15px;
      }

      @include breakpoint(phone) {
        width: 100%;
        justify-content: center;
        line-height: 1.1;
      }

      img {
        padding-right: 0.6rem;
        max-height: 20px;
      }

      &.google {
        background-color: #d34836;
      }

      &.facebook {
        background: #1877f2;
      }

      &.green {
        background-color: $green-color;
      }

      &.amazon {
        background-color: #f0c14b;
        color: #000000;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  &__form {
    position: relative;

    @include breakpoint(phone) {
      padding: 0 0rem;
    }
  }

  .small-text {
    @include breakpoint(phone) {
      text-align: center;
    }
  }

  // Thank you page
  &.eligibility {
    text-align: center;
    background-color: $white-color;
    max-width: 496px !important;

    h2 {
      color: $primary-color;
    }

    p {
      color: #1e1e1e;
    }

    .register-text {
      color: $primary-color;
    }

    .course-info {
      text-align: left;
      margin: 0 1rem 1rem;
      padding: 1rem 1.25rem;
      background-color: #0048a10d;
      border: 2px solid #0048a180;
      border-radius: 0.5rem;
      color: $primary-color;

      ul {
        margin: 0;

        li {
          font-size: 1.125rem;
          line-height: 1.6;

          strong {
            margin-right: 5px;
          }
        }
      }
    }

    .eligibility-text {
      font-size: 0.875rem;
      margin: 0 0 1.5rem;
    }

    .eligibility-status {
      padding: 0.7rem 0.5rem;
      background-color: rgba(34, 139, 34, 0.1);
      font-size: 0.75rem;
      color: $primary-color;
      margin: 0 0 1.75rem;
    }

    .green-btn {
      margin: 0 0 1rem;

      .v2-btn {
        padding: 12px 22px;
        background-color: #228b22;
        border: 2px solid #228b22;
        font-weight: 600;
      }
    }

    .remind-text {
      margin: 0 0 1rem;
      display: inline-block;
      font-size: 0.875rem;
    }
  }
}

%smallText {
  @include fontSize(12);
  line-height: 1.3;

  span {
    color: $Blue-color;
  }
}

.small-text {
  @extend %smallText;
}

.agreeTextContainer {
  @include breakpoint(tablet-large) {
    margin-bottom: 2rem;

    &.visible-btn {
      margin-bottom: 7rem;
    }
  }
}

.agreeText {
  display: flex;

  .form-check-input {
    width: 23px;
    height: 23px;
  }

  .form-check-label {
    margin-left: 1rem;
    @extend %smallText;
    color: $primary-color;
  }

  @include breakpoint(tablet-large) {
    margin-bottom: 0.6rem;
  }
}

.term-condition {
  max-width: 580px;
  margin-left: -15px;
  margin-right: -15px;

  .small-text {
    @extend %smallText;
    color: $primary-color;
  }

  @include breakpoint(tablet-large) {
    margin: 1rem 0 0;
  }

  @include breakpoint(phone) {
    align-items: flex-start;
  }

  padding: 0.6rem;
  border-radius: 13px;
  display: flex;
  align-items: center;

  .icon {
    margin: 0 0.2rem;

    svg {
      fill: $primary-color;
    }
  }
}

.footer-img {
  margin: 1rem 0;
  display: flex;
  justify-content: center;

  @include breakpoint(tablet) {
    margin: 1.5rem 0;
  }

  @include breakpoint(phone) {
    margin-bottom: 1.6rem;
    flex-wrap: wrap;

    div {
      margin: 0.5rem 0;

      img {
        max-height: auto;
      }
    }
  }

  img {
    @include breakpoint(phone) {
      max-height: 35px;
      margin-bottom: 1.3rem;

      &:last-child {
        margin-bottom: 0rem;
        margin-bottom: 1rem;
      }
    }

    @include breakpoint(small-phone) {
      max-height: 30px;
    }

    max-height: 40px;
    margin: 0 0.5rem;
  }
}

.filter {
  min-height: calc(100vh - 128px);
  padding-top: 2.1rem;
}

.searchFilter {
  background-color: rgba(187, 193, 202, 0.27);
  border-radius: 5px;
  max-width: 80%;
  margin: 3rem auto;
  padding: 1rem 1rem;

  @include breakpoint(tablet-port) {
    max-width: 100%;
  }
}

.whiteBg {
  background-color: #fff;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.filterImg {
  text-align: right;

  img {
    max-height: 300px;
  }
}

.changePassword__container {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint(large-phone) {
    padding-top: 4rem;
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.forgetPassword__container {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint(large-phone) {
    padding-top: 4rem;
    height: calc(100vh - 4rem);
    display: flex;
    justify-content: center;
  }
}

.changePassword-v2 {
  .MuiInputLabel-formControl {
    color: $primary-color;
  }

  .form-v2 {
    max-width: 266px;
    @media screen and (max-width:600px) {
      max-width: max-content;
    }
  }

  @include breakpoint(tablet) {
    .register__button {
      justify-content: flex-start;
    }
  }
}

.settings-container {
  display: flex;
 /*  align-items: center; */
  column-gap: 80px;
  margin-top: 50px;
	@media screen and (max-width:600px) {
    row-gap: 24px;
    flex-direction: column;
	}
}
.changepassword-v2-container {
  background: #ffffff8c;
  padding: 1.5rem;
  border: 1px solid #cccbf6;
	 border-radius: 12px;
}
.alerts-container {
  background: #ffffff8c;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #cccbf6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.changePassword {
  h3 {
    font-weight: 900 !important;
    font-size: 1.75rem;

    @include breakpoint(large-phone) {
      text-align: left !important;
      padding-left: 15px;
    }
  }

  .container {
    display: flex;
    justify-content: center;
  }

  &__card {
    box-shadow: 0px 2px 23px #00000029;
    border: 2px solid $Blue-color;
    border-radius: 20px;
    padding: 2rem 3.5rem;
    max-width: 470px;
    width: 100%;
    background-color: $lite-blue;
    margin-bottom: 3rem;

    @include breakpoint(large-phone) {
      padding: 1rem 1.2rem;

      .register__button {
        width: 100%;
        margin-top: 1rem !important;

        .btn {
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.searchFilterList {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid $silver-color;
  border-radius: 15px;
}

.MuiFormControlLabel-root {
  // &::after
  // {
  //   content: attr(data-text);
  //   color: red;
  //   // transform:translateX(-100%);
  //   // position:absolute;
  //   font-size: .7rem;
  // }
}

.margin-reset {
  @include breakpoint(large-phone) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// .MuiFormControlLabel-label {
//   &:last-word {
//     color: red;
//   }
// }
.FormControlLabel {
  font-size: 0.8rem;
  font-family: "Lato", sans-serif !important;
  line-height: 1.4 !important;
  max-width: 490px;
  color: $text-color;
}

.MuiCheckbox-colorPrimary:hover {
  background-color: transparent !important;
}

#couserInfoTab-tab-faqs {
  position: relative;

  &:after {
    color: $text-lite;
    font-size: 0.7rem;
    font-weight: 900;
    content: "s";
    position: absolute;
    top: 16px;
    transform: translate(0%, 0);
    font-family: "Lato", sans-serif !important;
  }

  &.active {
    &::after {
      color: $text-color;
    }
  }
}

.mobile-tab {
  display: none;
}

@include breakpoint(phone) {
  .couserInfoTab {
    .mobile-tab {
      display: none;
      position: fixed;
      z-index: 2;
      left: 0;
      padding: 0 15px;
      bottom: 0;
      height: 50px;
      width: 100%;
    }

    .nav .nav-item {
      // box-shadow: 0px 15px 22px #0000004d;
      font-size: 0.82rem;
      padding: 0.5rem 0.5rem;
    }
  }

  #couserInfoTab-tab-faqs {
    &::after {
      display: none;
    }
  }

  .faq-mobile-fixed {
    &.visible-btn {
      .couserInfoTab {
        position: relative;
        bottom: inherit;
        z-index: inherit;
        left: inherit;
        padding: 0 5px;
        height: auto;

        .nav .nav-item {
          box-shadow: none;
        }
      }
    }
  }
}

.btn-mobile {
  display: none;

  &.visible-btn {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding-left: 40px;
    padding-right: 40px;
    border-top: 1px solid $Blue-color;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    z-index: 6;

    p {
      text-align: center;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    left: 0;
  }
}

.RegisterForm.visible-btn {
  @include breakpoint(phone) {
    .btn-desktop {
      display: none !important;
    }

    .btn-mobile {
      display: block;
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #f5f5f5;
      padding-left: 40px;
      padding-right: 40px;
      border-top: 1px solid $Blue-color;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      z-index: 6;

      //  &::before
      //  {
      //    content: "";
      //    position: absolute;
      //    top: 0;
      //    width: 100%;
      //    left: 0;
      //    height: 100%;
      //   background-color:#f5f5f5 ;
      //  }
      p {
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
    }
  }
}

.faq-samll-text {
  font-size: 0.7rem;
}

.smsModal {
  background-color: rgba(0, 0, 0, 0.14);

  .modal-dialog {
    max-width: 620px;
  }

  .modal-body {
    background-color: $text-color;
    border-radius: 20px;
    color: #fff;
    font-size: 0.88rem;
    padding: 1rem;
    line-height: 1.6;
  }
}

.pointer {
  cursor: pointer;
}

.register-text {
  color: $text-color;
  text-decoration: none;

  &:hover {
    color: $text-color;
    text-decoration: none;
  }
}

.align-error {
  padding-left: 2.2rem;

  @include breakpoint(tablet) {
    padding-left: 0;
  }
}

.password-eye {
  cursor: pointer;
}
.password-eye1 {
  cursor: pointer;
  margin-top: 45px;
  padding-right: 40px;
}

.paypalLogin {
  position: relative;
  transition: all ease-out 0.6s;

  &:hover {
    .paypalLogin__desktop {
      img {
        transform: scale(1.05);
        width: 100%;
      }
    }
  }

  &__desktop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint(phone) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include breakpoint(phone) {
      display: block;
      position: absolute;
    }
  }

  .pillClass {
    opacity: 0;
  }
}

.alerts {
  max-width: 50%;

  .default__check[type="checkbox"] ~ .custom__check,
  .default__check[type="radio"] ~ .custom__check {
    border: 2px solid $primary-color;
    min-width: 30px;
    min-height: 30px;
    border-radius: 5px;
  }

  .default__check[type="checkbox"]:not(.switchbox) ~ .custom__check:after {
    height: 14px;
    border-bottom-width: 2px;
    border-right-width: 2px;
  }

  .item {
    display: flex;
    margin: 1rem 0;

    .left {
      max-width: 30px;
      width: 100%;

      .form-check-input {
        -ms-transform: scale(2.1);
        /* IE */
        -moz-transform: scale(2.1);
        /* FF */
        -webkit-transform: scale(2.1);
        /* Safari and Chrome */
        -o-transform: scale(2.1);
        /* Opera */
        margin-left: -1rem;
      }
    }

    .right {
      padding-left: 0.7rem;

      &.form-check-label {
        color: $primary-color;
      }

      .small {
        @include fontSize(14);
        line-height: 1.3;
      }
    }

    p {
      color: $primary-color;
      margin-top: 1rem;
    }
  }
}

.secured {
  background-color: $white-color;
  border-radius: 10px;
  font-size: 12px;
  max-height: 28px;
  display: flex;
  align-items: center;
  padding: 0.35rem 0.7rem;
  margin-left: 0.7rem;
  color: $primary-color;

  @include breakpoint(tablet-large) {
    display: none;
  }

  img {
    max-width: 12px;
  }
}

.layout-login {
  min-height: calc(100vh - 200px);

  .TextInputField__icon {
    &.clear {
      right: 28px;
      margin-top: 25px;
    }
    svg {
      fill: $primary-color !important;
      font-size: 1rem;
    }
  }

  .login-welcome-1,
  .login-welcome-2 {
    // color: $primary-color;
    // font-weight: 600;
    // line-height: 1;
    font-family: "P22 Mackinac", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #2a334a;
  }

  .login-welcome-1 {
    @include fontSize(32);
  }

  .login-welcome-2 {
    @include fontSize(18);
  }
  .login-title1 {
    // font-size: 1rem !important;
    // margin-bottom: 0 !important;
    font-family: $open-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #2a334a;
  }
  .email_address_font {
    color: #2a334a;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
  }
}

.hrefLink {
  text-decoration: underline;
}

.ratingImg {
  img {
    max-width: 250px;
    width: 100%;

    @include breakpoint(tablet) {
      max-width: 180px;
    }
  }
}

.terms-policy {
  min-height: 10px;

  @include breakpoint(tablet-large) {
    display: none;
    min-height: 0;
  }
}

@include breakpoint(tablet-large) {
  .forget-password-text {
    @include fontSize(14);
  }

  .login-title {
    font-size: 1rem !important;
    margin-bottom: 0 !important;
  }
}

#review_header .overall {
  max-width: 80px;
  color: #ffffff;

  * {
    color: #ffffff !important;
  }
}

#review_header .percentage {
  width: 80px !important;
  margin-left: 0 !important;
  max-width: 100px;
  font-size: 0.4rem !important;
}

#review_header {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;

  .graph {
    width: 180px !important;
    max-width: 186px;
    margin-left: 10px !important;
  }

  .stars {
    max-width: 100px;
    width: 120px !important;

    .desc {
      font-size: 0.95rem;
      padding: 0;
    }

    .on {
      max-width: 16px;
      max-height: 15px;
      background-size: 15px;
    }
  }
}

#sa_review_paging {
  .sa_page {
    width: 20px !important;
  }

  .arrow {
    padding-left: 4px !important;
  }

  label[for="sa_sort"] {
    margin-left: 11px !important;
    font-size: 14px;
  }

  select {
    height: 1.5rem !important;
    padding: 0 !important;
  }
}

.shopper_review_page {
  .overall {
    width: 80px !important;
    max-width: 80px;
  }
}

.registrationDisclaimer {
  color: $primary-color;

  &-scroll {
    @include scrollbar(0.4rem, slategray);
    overflow: auto;
    max-height: 86vh;
  }
}

.diversion {
  &-flexiFields {
    .row {
      margin-left: -0.3rem;
      margin-right: -0.3rem;
    }
  }

  &-message {
    background-color: rgba($color: #1f801f, $alpha: 0.1);
    @include fontSize(12);
    text-align: center;
    @include padding(5, 22, 5, 22);
    line-height: 1.2;

    p {
      margin-bottom: 0;
      color: $primary-color;
    }
  }
}

.OtherCourtError {
  padding-left: 0.8rem;
  font-size: 0.71rem;
  color: #ff0000 !important;
  font-family: "Lato", sans-serif !important;
  line-height: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-top: 1px;
}

.invisible {
  height: 0;
}
