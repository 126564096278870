// @import url("https://p.typekit.net/p.css?s=1&k=wix5zxl&ht=tk&f=14541.14545.14548.14549.14550.16382.16383.16384.28205.28226.28238&a=83497986&app=typekit&e=css");

// @font-face {
//     font-family: "open-sans";
//     src: url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
//     font-display: swap;
//     font-style: normal;
//     font-weight: 700;
//     font-stretch: normal;
// }

// @font-face {
//     font-family: "open-sans";
//     src: url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
//     font-display: swap;
//     font-style: normal;
//     font-weight: 600;
//     font-stretch: normal;
// }

// @font-face {
//     font-family: "open-sans";
//     src: url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
//     font-display: swap;
//     font-style: normal;
//     font-weight: 400;
//     font-stretch: normal;
// }

@font-face {
    font-family: "p22-mackinac-pro";
    src: url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');


@font-face {
    font-family: "Poppins";
    src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
}

/* HEAD */

.custom-header {
    font-family: $open-sans;
    height: fit-content;
    margin-top: 40px;
    margin-bottom: 50px;

    ul {
        padding: 0px;
    }

    ul li {
        list-style: none;
    }
}

.parent-container {
    padding-top: 95px !important;
}

.logo {
    .header-logo {
        // width: 146px;
        // height: 48px;
        max-height:  61px;
        max-width: fit-content;
    }
}

.head-contents {
    display: flex;
    justify-content: space-between;
}

.d-flex {
    display: flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.stepper-mark {
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.6;
    width: 25px;
    height: 25px;
}

.stepper-title {
    opacity: .5;
    margin-top: .4rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #2A334A;
    font-family: $open-sans;
}

.stepper-item {
    position: relative;
    min-width: 120px;
    color: #5451E0;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.stepper-item.fill .stepper-mark {
    opacity: 1;
    background-image: url(../../images/step-mark.png);
}

.stepper-mark span {
    border: 2px solid #B7B9C0;
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    height: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #7A808D;
    font-weight: 600;
}

.stepper-item.fill .stepper-mark span {
    opacity: 0;
}

.stepper-item.fill .stepper-title {
    opacity: 1;
}

.stepper-item.fill::after,
.stepper-item.fill::before {
    opacity: 1;
    background-color: #5451E0;
}

.stepper-item.active .stepper-mark,
.stepper-item.active .stepper-title {
    opacity: 1;
}

.stepper-item.active .stepper-mark span {
    border: 2px solid #5451E0;
    color: #5451E0;
}

.stepper-item.active::before {
    opacity: 1;
    background-color: #5451E0;
}

.stepper-item.before-none:before {
    display: none;
}

.stepper-item.after-none:after {
    display: none;
}

.stepper-item::after,
.stepper-item::before {
    content: "";
    position: absolute;
    top: 12px;
    right: 0px;
    width: calc(50% - 30px);
    height: 1px;
    background-color: #B7B9C0;
    opacity: 0.6;
}

.stepper-item::after {
    right: 0px;
}

.stepper-item::before {
    left: 0px;
}

.hn-nav {
    display: flex;
    align-items: center;
    gap: 40px;
}

.hn-nav ul {
    display: flex;
    gap: 13px;
    margin: 0;
}

.hn-nav ul a.selected {
    background-color: red;
}

.hn-nav .login {
    display: flex;
    gap: 10px;
    align-items: center;
}

.hn-nav .login span {
    font-size: 14px;
    line-height: 150%;
    color: #2a334a;
    text-decoration: none;
    font-weight: 600;
    font-family: $open-sans;
}


/* CONTENT */

.custom-container {
    max-width: 1250px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-family: $open-sans;

    label {
        font-family: $open-sans;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #2A334A;
        margin-bottom: 0.25rem;
    }
}

.content-body {
    display: flex;
    gap: 60px;
}


.content-body .widget {
    flex: 1;
}

.content-body .registration .registration-border {
    background-color: #fff;
    max-width: 685px;
    padding: 32px 64px;
    box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1), inset 0px 0px 0px 3px #5451E0;
    border-radius: 24px;
}

.content-body .registration h1 {
    font-family: 'p22-mackinac-pro', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: #2A334A;
    text-align: center;
    margin-bottom: 24px;
}
.course-name-register-form {
    font-family: 'p22-mackinac-pro', sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #2A334A;
    text-align: center;
    margin-bottom: 24px;
   
}

.content-body .registration h2 {
    font-family: $open-sans;
    font-weight: 700;
    font-size: 21px;
    line-height: 140%;
    color: #2A334A;
    text-align: center;
    margin-bottom: 24px;
}

.content-body .course-group-image {
    display: flex;
    padding-top: 12px;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
    height: 204px;
    position: relative; 
}

.watch-demo {
    position: absolute; 
    bottom: 0; 
    display: flex;  
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 250px;
    border: none;
    background: rgba(0,0,0,0);
}

.watch-demo-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    align-self: stretch;
    width: 366.641px;
    height: 204.186px;
}

.watch-demo-icon {
    float: left;
}

.watch-demo-label{
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    padding-top: 13px;
    padding-left: 13px;
    vertical-align: center;
    float: right;
}

.course-info-desc {
    padding-top: 16px;
    padding-bottom: 16px;
}

.content-body .course-title {
    color: #2A334A !important;
    font-family: $open-sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    width: 351px;
}

.content-body .course-provoke {
    color: #2A334A !important;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: capitalize;
}

.content-body .course-benefits-points {
    display: flex;
}

.benefit-check {
    width: 14px;
    height: 14px;
}

.benefits-desc {
    color: #2E353F !important;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding-left: 10px;
    width: 300px;
}

.benefits-desc span {
    text-decoration: line-through;
    text-decoration-color: red;
  }

.video-player-modal {
    width: max-content;
}

.video-player-container {
    width: max-content;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    display: flex;
    position: fixed;
}

.video-player {
    width: 1280px;
    height: 720px;
    border: none;
}

.video-player-close {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 12px;
    border: none;
    font-family: $open-sans;
    background: rgba(0, 0, 0, 0);
    color: grey;
}

/* Fast Lane */

.fast-lane-data-header {
    position: relative;
    display: flex;
    width: 100%;
}

.credit-card-label {
    flex: 1 0 0;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    position: relative;
    margin-top: 6px;
    margin-left: 12px;
}

.credit-card-method-label {
    color: #5451E0;
    text-align: right;
    font-family: $open-sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 13px */
    text-decoration-line: underline;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
}

.shared-input-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

/* .fast-lane-input-large  {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 4px;
    height: 47px;
    border-radius: 4px;
    border: 1px solid #B7B9C0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(84, 81, 224, 0.10);
    placeholder {
        color: #8D919E;
        font-family: $open-sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
} */

.fast-lane-input-short {
    width: 48%;
    height: 47px;
    border-radius: 4px;
    border: 1px solid #B7B9C0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(84, 81, 224, 0.10);
    placeholder {
        color: #8D919E;
        font-family: $open-sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
}

.go-daddy-logo {
    width: 46%;
    height: 20%;
}

.credit-card-icon {
    width: 44px;
    height: 32px;
}

.promo-code-container {
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    align-items: flex-start;
    height: 45px;

    align-self: stretch;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
}

.promo-code-label {
    align-self: stretch;
    color: #2E353F;
    text-align: center;
    font-family: $open-sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    text-decoration-line: underline;
}

.course-in-cart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    height: 36px;
}

.course-mini-icon {
    width: 44.308px;
    height: 36.396px;
    flex-shrink: 0;
    border-radius: 4px;
    background: lightgray 0.019px 0px / 137.011% 100% no-repeat;
    flex: 1 1 1;
}

.course-name-label {
    color: #2A334A;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    vertical-align: middle;
    flex: auto;
}

.course-price {
    flex: 1 1 1;
    color: #666D7D;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    vertical-align: auto;
    line-height: 160%;
}

.extra-name-label {
    color: #EB7100;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    vertical-align: middle;
    flex: auto;
}

.extra-price {
    flex: 1 1 1;
    color: #EB7100;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    vertical-align: auto;
    line-height: 160%;
}

.extra-description-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: #F6F6FD;
}

.extra-description-label {
    align-self: stretch;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.extra-claim-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.extra-claim-checkbox {
    width: 20px;
    height: 20px;
    fill: #5451E0;
    stroke-width: 1px;
    stroke: #5451E0;
    accent-color: #5451E0;
}

.extra-claim-description {
    flex: 1 0 0;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-left: 14px;
}

.total-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.subtotal-label {
    color: #666D7D;
    text-align: center;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.total-label {
    color: #5451E0;
    text-align: center;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
}

.complete-payment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 100px;
    background: #DEDFE1;
    width: 100%;
    border: none;
    height: 54px;
}

.complete-payment-validated-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 100px;
    background: #EC6233;
    width: 100%;
    border: none;
    height: 54px;
}

.complete-payment-label {
    color: #7A808D;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    padding-top: 18px;
}

.complete-payment-validated-label {
    color: #FFF;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    padding-top: 18px;
}

.complete-payment-arrow {
    height: 18px;
    color: #7A808D;
}

.refund-note-container {
    width: 295px;
    margin: auto;
}

.refund-note-label {
    color: #7A808D;
    text-align: center;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.refund-note-label a {
    color: #2A334A;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-decoration-line: underline;
}

.secure-payment-container {
    display: flex;
    flex-direction: row;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #F1F6FD;
    margin: auto;
    height: 26px;
}

.secure-payment-label {
    color: #52596B;
    font-family: $open-sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    padding-top: 4px;
    vertical-align: middle;

}

.secure-payment-icon {
    width: 11px;
    height: 11px;
    vertical-align: middle;
    padding-bottom: 4px;
}

.fast-lane-cart-detail-container {
    display: flex;
    width: 515px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.course-cart-detail-container {
    display: flex;
    padding: 20px 24px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    border-radius: 24px;
    background: #FFF;
}

.cart-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    position: relative;
}

.left-cart-detail-header-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
}

.cart-icon {
    width: 16px;
    height: 16px;
    color: black;
}

.cart-detail-label {
    color: var(--neutral-900, #2A334A);
    font-family: 'p22-mackinac-pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
}

.right-cart-detail-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
}

.collapsable-cart-detail-label {
    color: #5451E0;
    text-align: center;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.cart-collpase-arrow {
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
}

.cart-detail-image-description-container {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.course-icon {
    width: 140px;
    height: 112px;
    border-radius: 11.2px;
    background: lightgray 0.061px 0px / 137.011% 100% no-repeat;
}

.cart-detail-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.cart-detail-course-name-label {
    align-self: stretch;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
}

.cart-detail-course-price-label {
    color: #2A334A;
    text-align: center;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}

.course-cart-upgrade-container {
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 24px;
    border: 3px solid #EEA8A8;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(238, 168, 168, 0.18);
    gap: 24px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}

.cart-detail-course-description-label {
    align-self: stretch;
    color: #52596B;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.course-audio-upgrade-image-container {
    display: flex;
    width: 186px;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.course-cart-girl {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 12px;
    flex: auto;
    align-self: flex-start;
    @media screen and (max-width: 768px) {
        padding-left: auto;
        height: 100%;
    }
    @media screen and (min-width: 1441px) {
        width: 40%;
    }
    @media screen and (min-width: 1559px) {
        width: 20%;
    }
}

.course-cart-girl-modal {
    display: flex;
    width: auto;
    height: 100%;
    border-radius: 18px 0 0 18px;
    flex: auto;
    @media screen and (max-width: 768px) {
        padding-left: auto;
    }
}

.course-cart-speaker-icon {
    position: absolute;
    top: 5%;
    left: 5%;
}

.course-cart-description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: auto;
    @media screen and (max-width: 768px) {
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        height: 100%;
        width: 100%;
    }
}

.price-num-aditional-config {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
        width: auto;
        align-self: center;
    }
}

.course-bottom-aditional-config {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.cart-btn-block-aditional-config {
    @media screen and (max-width: 768px) {
        width: auto;
    }
}

.discounted-aditional-config {
    @media screen and (max-width: 768px) {
        font-size: 70%;
    }
}   

.course-audio-upgrade-only-label {
    color: #666D7D;
    text-align: center;
    font-family: $open-sans;
    font-size: 13.167px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 768px) {
        font-size: 70%;
    }
}

.audio-course-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    width: 90%;
    @media screen and (max-width: 768px) {
        align-self: center;
        height: 35%;
    }
    @media screen and (max-width: 390px) {
        justify-content: flex-start;
        align-self: flex-start;
    }
}

.course-cart-description {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border-radius: 2px 2px 2px 2px;
    border-color: white;
}

.audio-info-course-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 768px) {
        margin-bottom: 8px;
    }
}

.course-cart-description-title-label {
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    margin-top: 6px;
    margin-bottom: 6px;
    width: 100%;
    text-align: left;
    color: #2A334A;
    font-weight: 600;
    line-height: 160%;

    span {
        color: #5451E0;
    }

    @media screen and (max-width: 768px) {
        margin-top: 12px;
        align-self: center;
    }
    @media screen and (max-width: 390px) {
        align-self: flex-start;
        line-height: 120%;
    }
}

.course-cart-description-label {
    width: 100%;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    @media screen and (max-width: 768px) {
        align-self: center;
    }
    
    @media screen and (max-width: 390px) {
        align-self: flex-start;
    }
}

.course-cart-upgrade-button {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 49px;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #5451E0;
    position: relative;
    margin-top: -6px;
    border: none;
    @media screen and (max-width: 768px) {
        margin-top: -12px;
        align-self: center;
        width: 90%;
    }

    @media screen and (max-width: 390px) {
        align-self: flex-start;
    }
}

.course-cart-upgrade-button-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.course-cart-upgrade-title-label {
    color: #FFF;
    font-family: $open-sans;
    font-size: 80%;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    width: 80%;
    text-align: left;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 768px) {
         font-size: 70%;
    }
    @media screen and (max-width: 375px) {
        font-size: 60%;
   }
}

.course-cart-upgrade-button-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.course-cart-upgrade-info-label {
    color: #FFF;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.plus-icon {
    position: absolute;
    width: 19.763px;
    height: 18px;
    right: 0;
    margin-top: 12px;
    margin-right: 16px;
}

.review-panel {
    width: 100%;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.paypal-icon {
    display: flex;
    width: 44px;
    height: 32px;
    padding: 5px 0 5px 0;
    justify-content: center;
    align-items: center;
    border-radius: 5.176px;
    border: 1px solid #000;
    background: #FFF;
}

.apple-icon {
    display: flex;
    width: 30px;
    height: 34px;
    padding: 5px 0 5px 0;
    justify-content: center;
    align-items: center;
    border-radius: 5.176px;
    border: 1px solid #000;
    background: #FFF;
}

.paypal-data-container {
    display: flex;
    padding: 12px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    gap: 12px;
}

.paypal-data-header {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #DDDCF9;
    background: #FAFAFE;
    gap: 12px;
    padding-top: 12px;
    padding-right: 12px;
    padding-left: 12px;
}

.only-paypal-data-header {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.paypal-redirection {
    color: #2E353F;
    font-family: $open-sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    @media screen and (max-width: 768px) {
        display: flex;
        width: 100%;
        align-self: center;
        margin-bottom: 0.5rem !important;
    }
}

.fast-lane-coupon-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fast-lane-coupon-code-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border: 2px solid #0F8A56;
    border-radius: 10px;
		padding: 12px 0px;
}

.fast-lane-coupon-code-input {
    display: flex;
    padding: 10px 14px;
    padding-left: 12px;
    align-items: flex-start;
    gap: 10px;
    width: 473px;
    flex: 1 1 0;
    border: none;
    outline: none;
}

.coupon-code-checkmark {
    width: 24px;
    height: 24px;
    padding-right: 10px;
    flex: 1 1 1;
}

.promo-code-label {
    color: #0F8A56;
    font-family: $open-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    flex: 1 1 0;
    left: 0;
    text-decoration: none;
}

.paypal-extra-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 10px;
}

.payment-receipt-continue-container {
    gap: 16px;
}

.payment-receipt-continue-label {
    width: 336px;
    color: #2E353F;
    text-align: center;
    font-family: 'p22-mackinac-pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    align-self: center;
}

.payment-receipt-continue-description {
    width: 265px;
    color: #2E353F;
    text-align: center;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    align-self: center;
    margin: auto;
    margin-bottom: 12px;
}

.payment-receipt-continue-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 100px;
    background: #5451E0;
    width: 100%;
    border: none;
    height: 54px;
}

.complete-payment-validated-label {
    color: #FFF !important;
    font-family: $open-sans !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 100% !important;
}


.payment-receipt-detail-value {
    color: #565F6D !important;
    text-align: right !important;
    font-family: 'Poppins', $open-sans !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    margin-bottom: 0.5rem !important;
}

.profile-register-container {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 60%;
}

.profile-register-structure {
    width: 656px;
    color: #2A334A;
    text-align: center;
    font-family: 'p22-mackinac-pro';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    gap: 11px;
}

.profile-register-title {
    width: 656px;
    color: #2A334A;
    text-align: center;
    font-family: 'p22-mackinac-pro';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
}

.profile-register-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.profile-register-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    width: 100%;
}

.profile-register-field-label {
    align-self: flex-start;
    color: #2A334A;
    font-family: 'Inter', $open-sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    text-align: left;
}

.profile-register-field-date {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #B7B9C0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.profile-register-field -select option {
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

/* ---------------------------------------- */

.reg-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    column-gap: 1rem;
}

.divider {
    background-color: #FBE0D6;
    width: 50px;
    border: 2px solid #FBE0D6;
    margin: 0 auto 20px;
    border-radius: 16px;
}

.field-row {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 20px;
}

.field-grid-row {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    justify-content: center;
    margin-bottom: 20px;
}

.field-col {
    flex: 1;
    position: relative;
    .MuiInputBase-input {
        font-family: $open-sans !important;

        &::placeholder {
            font-family: $open-sans !important;
            color: #000000 !important;
        }
    }


    .error {
        font-weight: 0;
        font-size: 0.75rem;
        line-height: 160%;
        font-family: $open-sans !important;
    }

    .MuiInputBase-root {
        padding-right: 0 !important;
    }

    .MuiOutlinedInput-input {
        padding: 15.5px 14px !important;
    }

    .MuiInputBase-root span {
        font-weight: 0 !important;
        font-size: 1 !important;
        line-height: 0 !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: 0.375rem !important;
    }

    .field__icon {
        position: absolute;
        right: 10px;
        top: 40px;

        .MuiSvgIcon-root {
            fill: rgb(122, 128, 141);
        }
    }
}

.field-col span {
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color: #7A808D;
}

.custom-form-control {
    display: block;
    width: 100%;
    padding: 0.675rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212536;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #B7B9C0;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    position: relative;
    font-family: $open-sans !important;
    padding-right: 2.25rem;

    option {
        color: rgb(34, 38, 55);
    }

    &::placeholder {
        font-family: $open-sans !important;
    }

    &.custom-icon{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(../../images/icons/selectIcon.svg);
        background-position: right center;
        background-repeat: no-repeat;
        padding-right: 20px;
        background-position: 95% 50%;
    }
}

select {
    height: 3rem;
    padding: 0 0 0 0.75rem !important;
}

input[type="email"],
input[type="password"] {
    position: relative;
}

.field-col.email:after {
    position: absolute;
    content: url(../../images/envelope.png);
    width: 12px;
    height: 9px;
    top: 40px;
    left: 3%;
}

.field-col.password:after {
    position: absolute;
    content: url(../../images/key.png);
    width: 12px;
    height: 9px;
    top: 40px;
    left: 3%;
}

.password .custom-form-control,
.email .custom-form-control {
    padding: 0.675rem 0.75rem 0.675rem 40px;
}

.login-link {
    cursor: pointer;
}

.submit-btn {
    font-family: $open-sans;
    min-height: 54px;
    width: 100%;
    display: block;
    background-color: #5451E0;
    border-radius: 100px;
    padding: 18px 40px;
    color: #FFF;
    border: transparent;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 20px;
    cursor: pointer;
}

.privacy-policy p {
    font-family: $open-sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #7A808D;
    margin-bottom: 20px;
    text-align: center;
}

.privacy-policy a {
    cursor: pointer !important;
    color: #7A808D;
    text-decoration: underline !important;
}

.reg-btn img {
    cursor: pointer !important;
}

.check__item label {
    display: flex;
    margin-bottom: 0 !important;
}

.check__item label .text {
    color: #2A334A;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
}

.checkbox {
    margin-bottom: 16px;
}

.testimony {
    position: relative;
    width: 100%;

    .slick-track {
        display: flex;
        position: relative;
        min-height: 227px;
    }

    .slick-slide {
        flex-basis: 100%;
        background: #E3EDFC;
        width: 500px;
        border-radius: 24px;
        padding: 32px;
    }

    .prev-arrow,
    .next-arrow {
        cursor: pointer;
    }


    .slideContent {
        img {
            margin-bottom: 36px;
        }

        .slide-rating {
            margin-bottom: 36px;
        }

        p {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 160%;
            color: #2A334A;
            font-family: $open-sans;
        }
    }
}

.label-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;
}

.label-container:hover input~.checkmark {
    background-color: #ccc;
}

.label-container input:checked~.checkmark {
    background-color: #5451E0;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.label-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.label-container .checkmark:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox .text {
    max-width: 523px;
    color: #2A334A;
}

.secure {
    max-width: 100%;
    display: flex;
    gap: 32px;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.secure img {
    height: 100% !important;
}

.secure p {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #7A808D;
    font-family: $open-sans;
}

.secure-transaction p {
    background: #F1F6FD;
    border-radius: 100px;
    font-family: $open-sans;
    color: #666D7D;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    gap: 8px;
    width: fit-content;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    padding: 6px 16px;
}

.info-tabs .tab-container .tabs {
    display: flex;
    gap: 8px;
}

.info-tabs .tab-container .tab {
    font-family: $open-sans;
    padding: 10px 24px;
    border-radius: 16px;
    background-color: #E3EDFC;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #2A334A;
    cursor: pointer;
}

.info-tabs .tab-container .tab.active {
    background-color: white;
    position: relative;
    color: #EC6233;
}

.info-tabs .tab-container .tab.active::before {
    content: url('../../images/tab-arrow.png');
    width: 21px;
    height: 9px;
    position: absolute;
    bottom: 0px;
    left: 39%;
}

.course-info-price-container {
    display: flex;
    flex-direction: row;
}

.course-info-description-container {

}

.course-info-title {
    align-self: stretch;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.course-info-description {
    align-self: stretch;
    color: #2A334A;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
}

.course-info-price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.course-info-price-detail-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.course-info-only-price-container {
     display: flex;
     flex-direction: row;
     align-items: center;
     gap: 8px;
     margin-top: 8px;
}

.course-info-old-price {
    color: #8D919E;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
}

.course-info-old-price span {
    text-decoration: line-through;
    text-decoration-color: #8D919E;
  }

.course-info-new-price {
    color: #2A334A;
    text-align: center;
    font-family: $open-sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.course-info-arrow-image {
    display: flex;
    padding-top: 16px;
}

.course-info-discount {
    width: 127px;
    color: #EC6233;
    font-family: $open-sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.course-info-fee-clarification {
    color: #52596B;
    text-align: center;
    font-family: $open-sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.65px;
    text-transform: uppercase;
}

.course-flashdeal-container {
    display: flex;
    flex-direction: column;
    display: flex;
    width: 100%;
    padding: 24px 24px 0 24px;
    margin-bottom: 12px;
    justify-content: space-between;
    border-radius: 24px;
    background: #FFF;
    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
}

.course-flashdeal-top-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.course-flashdeal-top-info {
    color: #2A334A;
    text-align: center;
    font-family: 'p22-mackinac-pro';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.course-flashdeal-arrow-image {
    display: flex;
    margin-bottom: 16px;
}

.course-flashdeal-discount {
    color: #EC6233;
    text-align: center;
    font-family: 'p22-mackinac-pro';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}

.course-flashdeal-bottom-section {

}

.course-flashdeal-bottom-info {
    color: #2A334A;
    font-family: $open-sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
}

.flashdeal-button-close{
    display: flex;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 1%;
    right: 2%;
    @media screen and (max-width: 768px) {
        top: 5%;
        right: 1%;
    }
}

.checkout-description-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: #F6F6FD;
}

.checkout-description-label {
    align-self: stretch;
    color: #2A334A !important;
    font-family: $open-sans !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important;
}

.checkout-claim-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.checkout-claim-checkbox {
    width: 20px;
    height: 20px;
    fill: #5451E0;
    stroke-width: 1px;
    stroke: #5451E0;
    accent-color: #5451E0;
}

.checkout-claim-description {
    flex: 1 0 0;
    color: #2A334A !important;
    font-family: $open-sans !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 150% !important;
    margin-left: 14px !important;
}

/* ACCORDION */

.scroll::-webkit-scrollbar-thumb {
    background: rgba(84, 81, 224, 0.2) !important;
    border-radius: 10px !important;
}

.scroll::-webkit-scrollbar-track {
    background: rgba(0, 72, 161, .1) !important;
    border-radius: 10px !important;
}

.scroll::-webkit-scrollbar {
    width: 0.6rem !important;
    height: 0.6rem !important;
}

.accordion {
    margin: 30px auto 0;
}

.accordion.scroll {
    margin-top: 0.4rem;
    max-height: 485px;
    overflow: auto;
    padding-right: 1rem;
}

.accordion-header {
    font-family: $open-sans;
    background: transparent;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #2A334A;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion-header:after {
    /* content: '\002B'; */
    content: url('../../images/arrow-right-default.png');
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.accordion-header.active {
    color: #EC6233;
}

.accordion-header.active:after {
    /* content: "\2212"; */
    content: url('../../images/arrowdown-orange.png')
}

.accordion-body {
    padding: 0 18px;
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    &-show {
        max-height: 170px;
        padding: 0px 18px;
        overflow: inherit;
    }

}

.accordion-body p {
    font-family: $open-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #52596B;
    padding: 0 24px 24px 0;

    span {
        font-family: $open-sans !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        color: #52596B !important;
    }
}

.accordion .divider-ac {
    border-bottom: 2px solid #E3EDFC;
    margin-top: 15px;
}


/* COURSE DETAILS */

.api,
.course-details {
    padding: 20px 0;

    * {
        font-family: $open-sans !important;

    }
}

.course-details {

    .course-info-header {
        position: relative;

        h2 {
            span {
                color: #2A334A !important;
            }
        }

        h2+p {
            margin: 0 0 1.5rem;

            span {
                color: #2A334A !important;
            }
        }

        div {
            img {
                position: absolute;
                left: 190px;
                bottom: 52px;
            }

            &+p {
                text-align: center;
                position: absolute;
                left: 270px;
                bottom: 35px;
                margin: 0;

                span {
                    display: block;
                }
            }
        }

        p {
            span {
                &+span {
                    font-weight: 400;
                    //font-size: 22px !important;
                }

                strong {
                    font-weight: 600 !important;
                    //color: #52596B !important;
                }
            }
        }

        ul {
            padding-left: 0.5rem;
        
            li {
              list-style: none;
              background-image: url(../../images/list_icon.svg);
              background-size: 20px 20px;
              background-position: left center;
              background-repeat: no-repeat;
              padding-left: 30px;
              margin: 0 0 0.5rem !important;
        
              span {
                font-weight: 600;
                background-color: #f1f6fd !important;
                font-family: $open-sans !important;
                color: #2a334a !important;
              }
        
              strong {
                font-weight: 600 !important;
                color: #2a334a !important;
              }
            }
          }
          
          del {
            text-decoration: line-through;
            text-decoration-color: red;
          }

        * {
            background-color: transparent;
        }

    }

    .course-info-list {
        p {
            span {
                background-color: #F1F6FD !important;
                font-family: $open-sans !important;
            }
        }

        ul {
            padding-left: 0.5rem;

            li {
                list-style: none;
                background-image: url(../../images/list_icon.svg);
                background-size: 20px 20px;
                background-position: left center;
                background-repeat: no-repeat;
                padding-left: 30px;
                margin: 0 0 0.5rem !important;

                span {
                    font-weight: 600;
                    background-color: #F1F6FD !important;
                    font-family: $open-sans !important;
                    color: #2A334A !important;

                }

                strong {
                    font-weight: 600 !important;
                    color: #2A334A !important;
                }
            }
        }
    }
}


.course-details .dmv {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #52596B;
}

.course-details h2 {
    font-family: $open-sans;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 140%;
    color: #2A334A;
    margin-bottom: 12px;
    /* or 29px */
}

.course-details p {
    font-family: $open-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #2A334A !important;

    strong {
        font-weight: 700;
    }

    &:not(:first-child) {
        span {
            // font-size: 1rem !important;
        }
    }


}

.course-features ul {
    margin-top: 32px;
}

.course-features ul li {
    position: relative;
    margin-bottom: 8px;
}

.course-features ul li::before {
    content: url(../../images/checkmark.svg);
    width: 14px;
    height: 22px;
    position: absolute;
    left: 0px;
    margin-top: 3px;
}

.course-features ul li p {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #2A334A;
    opacity: 0.9;
    text-align: left;
    margin-left: 25px;
}

.course-price {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 20px;
}

.price-num {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: left;
}

.price-num .crossed {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: #8D919E;
    text-decoration: line-through;
    text-decoration-color: #8D919E;
}

.price-num .discounted {
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    color: #2A334A;
}

.price-num .discounted span {
    font-size: 24px;
    position: relative;
    top: -15px;
}

.price-save p {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #EC6233;
}


/* RATINGS */

.ratings {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.ratings .col-rating {
    flex: 1;
}

.main-error {
    font-size: 0.80rem !important;
}

.customer-reviews {
    background-color: #FFF;
    border-radius: 16.7957px;
    padding: 16.7957px 25.1936px;
    text-align: center;
    font-family: $open-sans;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.customer-reviews:hover, .customer-reviews:active  {
    text-decoration: none;
    color: rgb(42, 51, 74);
}

.customer-reviews img {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.customer-reviews p {
    color: #2A334A;
    font-weight: 600;
    margin: 0;
}

.customer-reviews p.strong {
    font-weight: bold;
    margin: 0;
    font-size: 1.25rem;
}

.rating-btn {
    background-color: #FFF;
    border-radius: 16.7957px;
    padding: 16.7957px 25.1936px;
}

.flex-rating {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.center-img {
    display: flex;
    justify-content: center;
}


/* CAROUSEL */

.carouseldivMobile,
.carouseldiv {
    position: relative;
    margin-top: 24px;
}

.navigation {
    position: absolute;
    top: 3rem;
    transform: translateY(-50%);
    right: 5%;
    display: flex;
    gap: 16px;
}

.prev-arrow {
    left: 10px;
}

.next-arrow {
    right: 10px;
}

.author p {
    margin-bottom: 4px !important;
    font-family: $open-sans;
}

.author p.date {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    font-family: $open-sans;
    color: #666D7D;
}

.course-details-mobile-row {
    display: flex;
    gap: 25px;
    margin-bottom: 10px;
}

.course-details-mobile h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #2A334A;
}

.course-details-mobile .price-num .discounted {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
}

.course-details-mobile .price-num .discounted span {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    top: -8px;
}

.course-details-mobile .price-num .crossed {
    font-weight: 600;
    font-size: 15px;
    line-height: 160%;
}

.course-details-mobile .col-d {
    flex: 1;
}

.course-details-mobile .dmv {
    font-weight: 600;
    font-size: 11px;
    line-height: 120%;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.course-details-mobile .save {
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    color: #EC6233;
}

.accordion-header-v2 {
    border: none;
    background: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: #5451E0;
    padding: 0px;
}

.accordion-body-v2 {
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    display: block;
    width: 100%;
    transition: max-height 0.2s ease-out;
}

.course-details-btn {
    display: none !important;
}

.accordion-body-v2.show-lg {
    overflow: visible;
}

.accordion-body-v2.show-sm {
    overflow: visible;
}

/* MODAL */

.custom-modal {
    &.modal {
        font-family: $open-sans!important;
        font-size: 14px !important;

        .modal-dialog {
            max-width: 700px;
            opacity: 1;
            background-color: rgb(255, 255, 255);
            border-radius: 0.3rem !important;
            position: relative;
            width: auto;
            margin: 6rem auto;
            pointer-events: none;
        }

        .modal-body {
            padding: 2rem 2.5rem 3rem !important;
            color: #000 !important;

            p,
            a {
                color: rgb(122, 128, 141) !important;
            }

            a {
                text-decoration: underline !important;
            }

            h4,
            h3 {
                font-size: 1rem !important;
                font-weight: 900 !important;
            }
        }

        .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: 0 !important;
            outline: 0;

            h3 {
                text-align: center;
                margin-bottom: 20px;
            }

            p {
                text-align: left;
            }
        }
    }
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.show {
    display: block;
}

.registrationDisclaimer-scroll {
    scrollbar-face-color: #708090;
    scrollbar-track-color: rgba(0, 72, 161, .1);
    overflow: auto;
    max-height: 86vh;
}

.hide-btn {
    display: none;
}

.custom-modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.custom-modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.flex-submit {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}


.field__icon {
    &.password {
        right: 35px !important;
    }
}



.width-700 .modal-dialog {
    max-width: 700px;
}


/* MOBILE */

.show-lg {
    display: block;
}

.flex-lg {
    display: flex;
}

.show-sm {
    display: none;
}

.flex-sm {
    display: none;
}



.gray-placeholder {
    color: rgb(165, 173, 193);
}

.flex-title {
    font-family: $open-sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: rgb(42, 51, 74);
}


#wisepops-instance-411421 {
    visibility: hidden;
}

#wisepops-instance-412395 {
    visibility: hidden;
}

.hide-tab {
    display: none;
}


/* New Modal */

.reg-sms-modal {
    font-family: $open-sans !important;

    .modal-dialog {
        max-width: 850px !important;
    }

    .modal-content {
        border: none !important;
        // height: 671px !important;
        min-height: auto !important;
        border-radius: 24px !important;
    }

    .modal-body {
        padding: 0 !important;

        // img {
        //     height: 671px !important;
        // }

        .img_icon {
            height: auto !important;
        }

        form {
            margin-bottom: 1em !important;
            margin-top: -2em !important;
        }

        .tickets-claimed_img {
            width: 137px !important;
            height: auto !important;
            margin-bottom: 50px;
            margin-top: 15px;
        }

        .deal-content {
            font-weight: 600 !important;
        }

        .m-2-deal {
            background-color: rgb(255, 255, 255);
            width: 65%;
            margin-left: auto;
            margin-right: auto;
            padding: 20px 40px;
            border-radius: 20px;
            font-size: 32px;
            line-height: 48px;
            font-weight: 600;
            margin-top: 30px;
            margin-bottom: 20px;
            font-family: 'p22-mackinac-pro' , sans-serif;
            position: relative;

            span {
                display: block;
            }
        }

        .m-2-deal::before {
            background-image: url(./../../images/hairlines.png);
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            width: 26px;
            background-position: top left;
            height: 26px;
            top: -18px;
            left: -18px;
        }

        .ticket-img {
            height: 170px !important;
            position: relative;
            left: -2rem;
        }
    }



    .reg-sms-alert>div {
        width: 800px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        background: white;
        border-radius: 1rem;
    }

    .modal-close {
        color: #aaa;
        line-height: 50px;
        font-size: 80%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 70px;
        text-decoration: none;
        filter: grayscale(1);
        cursor: pointer;
    }

    .modal-close:hover {
        color: black;
    }

    .btn {
        background-color: #000;
        padding: 1em 1.5em;
        color: #fff;
        margin-top: 10px;
        display: inline-block;
        border-radius: 0.5rem;
        text-decoration: none;
    }

    .btn i {
        padding-right: 0.3em;
    }

    .m-1 {
        background-image: url(../../images/SMS-d.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 50%;
        height: 600px;
        background-position: center center;
        border-radius: 15px 0 0 15px;
        float: left;
    }

    .m-1-2 {
        background-image: url(../../images/IMPROV-d.png);
    }

    .m-1-3 {
        background-image: url(../../images/Audio-d.png);
    }

    .m-1-4 {
        background-image: url(../../images/Exit-d.png);
    }

    .reg-sms-modal-right {
        width: 100%;
        background-color: #F1F6FD;
        height: 671px;
        border-radius: 0 24px 24px 0;
        text-align: center;
    }



    .reg-sms-modal-right-notification {
        margin-top: 60px;
        background-color: #EC6233;
        display: inline-block;
        text-align: center;
        color: #fff;
        font-size: 12px;
        padding: 4px 18px;
        border-radius: 20px;
        font-weight: 600;
        letter-spacing: 0.05em;
    }

    .reg-sms-modal-right-title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 600;
        margin-top: 12px;
        font-family: 'p22-mackinac-pro', sans-serif;
    }

    .reg-sms-modal-right-content {
        font-family: $open-sans !important;
        width: 340px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 1rem;

        &.sms-width {
            width: 290px;
        }

        &.tickets-width {
            width: 290px;
        }

        &.last-chance {
            width: 290px;

            &.two {
                margin-top: 0 !important;
            }
        }
    }

    .reg-sms-modal-right-chk {
        display: inline-block;
        color: #EC6233;
        font-size: 20px;
        line-height: 30px;
        padding: 26px 46px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        background-color: #FBE0D6;
        box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1), inset 0px 0px 0px 3px #fbe0d6;
        border-radius: 24px;
        font-family: 'p22-mackinac-pro', sans-serif;

        input[type=checkbox] {
            transform: scale(1.4);
            margin-right: 0.5rem;
        }
    }

    .m-wrap {
        font-family: $open-sans!important;
    }

    .btn-plain {
        color: #232838;
        display: block;
        max-width: 122px !important;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2.5rem;
        background-color: transparent;
        border: none;
        text-decoration: none;
        border-bottom: 1px solid #bcbcbc;
        padding: 0;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0px !important;
    }

    .btn-plain a,
    .btn-plain a:hover,
    .btn-plain a:active,
    .btn-plain a:visited,
    .btn-plain a:focus {
        color: #232838;
        text-decoration: #232838;
    }

    .reg-sms-modal-right-content img {
        width: 100%;
        height: auto;
    }

    .reg-sms-modal-right-chk:before {
        background-image: url(../../images/hairlines.png);
        content: '';
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        width: 26px;
        background-position: top left;
        height: 26px;
        top: -18px;
        left: -18px;
    }

    .reg-sms-modal-right-deal:before {
        background-image: url(../../images/hairlines.png);
        content: '';
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        width: 26px;
        background-position: top left;
        height: 26px;
        top: -18px;
        left: -18px;
    }

    .btn-purple {
        background-color: #5451E0;
        border: none;
        font-weight: 600;
        color: #fff;
        font-size: 18px;
        line-height: 18px;
        padding: 18px 25px;
        border-radius: 50px;
        /* width: 80%; */
        text-decoration: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
    }

    .btn-white {
        text-decoration: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
        border: none;
        font-weight: 600;
        color: #374362;
        font-size: 18px;
        line-height: 18px;
        padding: 18px 20px;
        border-radius: 50px;
        width: 80%;
    }

    .mt-n-15 {
        margin-top: -15px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .btn-white a,
    .btn-white a:hover,
    .btn-white a:active,
    .btn-white a:visited,
    .btn-white a:focus {
        color: #374362;
        text-decoration: none;
    }

    .btn-purple a,
    .btn-purple a:hover,
    .btn-purple a:active,
    .btn-purple a:visited,
    .btn-purple a:focus {
        color: #fff;
        text-decoration: none;
    }

    .btn-purple img {
        margin-left: 8px;
    }

    .reg-sms-modal-right-notes {
        font-size: 13px;
        line-height: 20px;
        color: #2A334A;
        width: 330px;
        margin-left: auto;
        margin-right: auto;
    }

    .m2-content-img-auto img {
        width: auto;
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .d-warning {
        display: block;
        margin-bottom: 5px;
        color: #D80027;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .m-2-notes {
        font-size: 14px;
        line-height: 20px;
        color: #2a334a;
        width: 275px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;

        p {
            color: #2A334A;
            font-weight: 600;

        }
    }

    .reg-sms-modal-right-deal {
        background-color: #fff;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 40px;
        border-radius: 20px;
        font-size: 32px;
        line-height: 48px;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 20px;
        font-family: 'p22-mackinac-pro', sans-serif;
        position: relative;
    }

    .reg-sms-modal-right-deal span {
        display: block;
        color: #EC6233;

    }

    &.last-chance-modal {
        .modal-body {
            form {
                margin-top: 2rem !important;
            }

            .btn-purple {
                margin-bottom: 2rem;
            }

            button {
                width: 290px;

                img {
                    margin-left: 8px;
                }
            }
        }
    }


}



// #wisepop-411421 and #wisepops-instance-412395
#wisepops-instance-411421, #wisepops-instance-412395 {
    .m-2-notification {
        padding: 4px 18px !important;
        letter-spacing: 0.05em;
    }

    .m-2-title {
        color: #2A334A !important;
        font-weight: 700 !important;
        width: 340px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .m-2-content {
        font-weight: 600 !important;
        color: #2A334A !important;
    }

    .m-2-deal {
        width: 262px !important;
        box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1), inset 0px 0px 0px 3px #ffffff !important;
        border-radius: 24px !important;
        padding: 28px 40px !important;
        margin-bottom: 30px !important;
        color: #2A334A !important;
    }

    .btn-purple {
        width: 260px !important;
    }

    #wisepop-411421, #wisepops-412395 {
        width: 857px !important;
        max-width: 95% !important;
        height: 671px !important;
        background-color: transparent;
    }

    .mod-2,
    .mod-1 {
        height: 671px !important;
    }

}


// 768px
@media only screen and (max-width: 768px) {
    .show-sm {
        display: block;
    }

    .flex-sm {
        display: flex;
    }

    .show-lg {
        display: none;
    }

    .flex-lg {
        display: none;
    }

    .custom-header {
        height: fit-content;
        margin: 20px 0 10px;
    }

    .custom-header .head-contents {
        flex-direction: column;
    }

    .head-switch {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
    }

    .hn-nav {
        gap: 15px;
    }

    .stepper-item {
        min-width: 100px;
    }

    .content-body {
        flex-direction: column;
        gap: 20px;
    }

    .content-body .registration {
        order: 2;
    }

    .content-body .widget {
        order: 1;
        max-width: 100% !important;
    }

    .content-body .registration .registration-border {
        padding: 32px 16px;
    }

    .content-body .registration h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 125%;
    }

    .content-body .registration h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
    }

    .info-tabs .tab-container .tab {
        font-size: 13px;
        padding: 10px 16px;
    }

    .info-tabs .tab-container .tab.active::before {
        bottom: -3px;
    }

    .stepper-title {
        font-size: 10px;
        text-align: center;
        min-height: 30px;
        width: 60px;
    }

    .field-row,
    .ratings {
        flex-direction: column !important;
    }

    .field-grid-row {
        grid-template-columns: 1fr !important;
    }

    .secure {
        gap: 10px;

        p {
            margin-bottom: 0px !important;
        }
    }

    .secure img {
        width: 36px;
        height: 31px;
    }

    .course-details-btn.show-sm {
        display: flex !important;
        gap: 3px;
        justify-content: center;
        align-items: center;
    }

    .accordion-body-v2.show-lg {
        display:block!important; 
    }

    .accordion-body-v2.show-sm .info-tabs {
        margin-top: 20px;
    }

    .reg-btn {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .customer-reviews p {
        font-weight: 600;
        /* or 34px */
    }

    .customer-reviews p.strong {
        font-weight: 700;
        font-size: 20.9947px;
        line-height: 160%;
        /* or 34px */
    }

    .carouseldivMobile {
        margin-top: 20px;
    }

    .password .custom-form-control,
    .email .custom-form-control {
        padding: 0.675rem 0.75rem 0.675rem 30px;
    }

    .accordion-header {
        padding: 15px;
    }

    .course-details .course-info-header div+p {
        position: static;
        text-align: left;
    }

    .course-details .course-info-header h2 {
        span {
            font-size: 19px;
        }
    }

    .course-details .course-info-header h2+p {
        display: none;
    }

    .course-details .course-info-header div img {
        display: none;
    }

    .course-details .course-info-header div+p span {
        display: inline-block;
        margin-right: 5px;
    }

    .wisepops-popup {
        width: 80% !important;
    }

    .reg-sms-modal {
        .modal-dialog {
            max-width: 330px !important;
            margin: 0px auto !important;

            .modal-body {

                img {
                    width: 100%;
                    height: 186px !important;
                    border-radius: 15px 15px 0 0;
                }

                .img_icon {
                    width: auto !important;
                    height: auto !important;
                }

                .ticket-img {
                    height: 126px !important;
                    width: 280px !important;
                }

                .tickets-claimed_img {
                    height: 90px !important;
                    margin-bottom: 3rem;
                    width: auto !important;
                    margin-top: 0 !important;
                }

                .m-2-deal {
                    padding: 20px 10px !important;
                }

                .btn-purple {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px !important;
                }

                .ticket-notes {
                    width: 100%;
                    padding: 14px;
                }

                .m2-deal {
                    padding: 18px 12px !important;
                }

                .reg-sms-modal-right {
                    width: 100%;
                    display: block;
                    border-radius: 0 0 15px 15px;
                    padding-bottom: 40px;
                    height: auto;

                    .reg-sms-modal-right-notification {
                        margin-top: 40px;
                        font-size: 10px;
                        line-height: 15px;
                    }

                    .reg-sms-modal-right-title {
                        font-size: 20px;
                        line-height: 30px;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .reg-sms-modal-right-content {
                        font-size: 14px;
                        line-height: 22px;
                        padding-left: 10px;
                        padding-right: 10px;
                        width: 93%;
                    }
                }

                .ticket-modal-body {
                    padding-bottom: 20px;

                    .btn-purple {
                        margin-bottom: 1rem;
                    }
                }

            }
        }
    }

    .sms-modal-body {
        flex-direction: column;
    }

    // #wisepop-411421 and #wisepops-412395
    #wisepops-instance-411421, #wisepops-instance-412395 {
        .m-2-title {
            width: 300px !important;
        }

        .m-2-deal {
            width: 220px !important;
            padding: 11px 40px !important;
        }

        #wisepop-411421, #wisepops-412395 {
            width: 327px !important;
            max-width: 95% !important;
            height: auto !important;
            background-color: transparent;
        }

        .mod-1 {
            height: 186px !important;
        }

        .mod-2 {
            height: auto !important;
        }

    }



}


// 640px
@media only screen and (max-width: 640px) {



    .m-1 {
        width: 100%;
        float: none;
        height: 186px;
        border-radius: 15px 15px 0 0;
    }

    .reg-sms-modal-right {

        width: 100%;
        float: none;
        height: auto;
        border-radius: 0 0 15px 15px;
        padding-bottom: 40px;
    }

    .reg-sms-alert>div {
        width: 80%;
    }

    .mod-body {
        max-height: 90vh;
        overflow-y: auto;
        overflow-x: hidden;
    }


    .m-1-2 {
        background-image: url(../../images/IMPROV-m.png);
    }

    .m-1-3 {
        background-image: url(../../images/Audio-m.png);
    }

    .m-1-4 {
        background-image: url(../../images/Exit-m.png);
    }

    .reg-sms-modal-right-notification {
        margin-top: 40px;
        font-size: 10px;
        line-height: 15px;
    }

    .reg-sms-modal-right-title {
        font-size: 20px;
        line-height: 30px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .reg-sms-modal-right-content {
        font-size: 14px;
        line-height: 22px;
        padding-left: 10px;
        padding-right: 10px;

        width: 93%;
    }

    .m-wrap form {
        margin-bottom: 0;

    }

    .btn-purple {
        padding-left: 5px;
        padding-right: 5px;
        width: 80%;
    }

    .btn-white {
        padding-left: 5px;
        padding-right: 5px;
        width: 80%;
    }

    .btn-purple img {
        margin-left: 2px;
    }

    .reg-sms-modal-right-notes {
        width: 80%;
        margin-top: 20px;
    }

    .m2-content-img-auto img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
    }

    .d-warning {
        display: inline;

    }

    .mobile-reg-sms-modal-right-content {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
    }

    .mobile-reg-sms-modal-right-title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
    }

    .reg-sms-modal-right-deal {
        font-size: 24px;
        line-height: 36px;
    }
}



// 576px
@media only screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .stepper-item {
        min-width: 70px;
    }

    .course-info-list ul li span {
        font-size: 14px !important;
    }

    .course-info-list ul li {
        background-size: 17px 17px;
        padding-left: 25px;
        margin: 0 0 0.5rem !important;
    }

}

// 321px

@media only screen and (max-width: 321px) {
    .reg-sms-modal-right-content {
        padding-left: 20px;
        padding-right: 20px;
        width: 85%;
    }

    .reg-sms-modal-right-chk {
        font-size: 12px;
    }

    .btn-plain {
        font-size: 12px;
    }

    .btn-purple {
        padding: 12px 12px;
        font-size: 12px;
    }

    .reg-sms-modal-right-deal {
        font-size: 20px;
    }
}

/* Begin PaymentReceipt */

.payment-receipt-container {
    display: flex;
    width: 70%;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(170, 170, 170, 0.12);
    @media screen and (max-width: 768px) {
        width: 80%;
    }
}

.payment-receipt-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    margin-top: auto;
    margin-bottom: auto;
}

.confirmation-checkmark {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}

.payment-receipt-header-label {
    align-self: stretch;
    color: #121212;
    text-align: center;
    font-family: 'p22-mackinac-pro' !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 1.5rem !important;
}

.receipt-divider {
    border-style: solid;
    border-width: 1px;
    width: 100%;
    border-color: #E8EAED;
    margin-top: -2%;
    margin-bottom: 2%;
}

.payment-receipt-part-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: -1%;
    margin-top: 8px;
}

.payment-receipt-data-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.payment-receipt-reference-label {
    flex: 1 0 0;
    color: #707070  !important;
    font-family: $open-sans;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left !important;
    margin-bottom: 0.5rem !important;
}

.payment-receipt-reference-value {
    color: #121212 !important;
    font-family: 'Poppins', $open-sans; 
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    text-align: right !important;
    margin-bottom: 1.5rem !important;
    width: 50%;
    @media screen and (max-width: 768px) {
        font-size: 11px !important;
    }
}

.payment-receipt-total-label {
    flex: 1 0 0;
    color: #707070 !important; 
    font-family: 'Poppins', $open-sans !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 18px !important;
    text-align: left !important;
}

.payment-receipt-total-value {
    color: #121212 !important;
    text-align: right;
    font-family: $open-sans !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}

.payment-receipt-utility-part-container {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.payment-receipt-utility {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    width: 100%;
}

.receipt-print-button {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid #DEDEDE;
    background-color: white;
    height: 48px;
    width: 100%;
}

.receipt-printer-icon, .email-printer-icon {
    padding-bottom: 10px;
    width: 14px;
}

.receipt-print-label {
    color: #3D3D3D !important;
    text-align: center !important;
    font-family: 'Poppins', $open-sans !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    margin-top: auto;
    margin-bottom: auto;
}

.payment-receipt-troubleshoot-container {
    display: flex;
    padding: 20px 18px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 24px;
    background: #FFF;
    width: 70%;
    border: none;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.payment-receipt-question-icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
}

.payment-receipt-troubleshoot-title {
    align-self: stretch;
    color: #121212;
    font-family: 'p22-mackinac-pro' !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    align-self: center;
    text-align: left !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.payment-receipt-troubleshoot-subtitle {
    align-self: stretch;
    color: #3D3D3D;
    font-family: $open-sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.payment-receipt-continue-progress-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;
  }
  
  .payment-receipt-continue-container {
    gap: 16px;
  }
  
  .payment-receipt-warning-container {
    width: 294px;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  
  .receipt-dashed-divider {
    border-style: dashed;
    border-width: 1px;
    width: 100%;
    border-color: #DCDEE0;
  }
  
  .receipt-point-decoration {
    position: relative;
    width: 100%;
    height: 1px;
  }
  
  .point-decoration {
    position: absolute;
    border: 20px solid #f1f6fd;
    border-radius: 20px;
  }
  
  .right-decoration {
    right: 0;
    bottom: 0;
    margin-bottom: -35px;
    margin-right: -45px;
  }
  
  .left-decoration {
    left: 0;
    bottom: 0;
    margin-bottom: -35px;
    margin-left: -45px;
  }
  
  /* End PaymentReceipt */

  .base-course-arrow-downward {
    position: absolute;
    right: 3%;
    bottom: -20%;
    width: 60px;
    height: 30%;
    z-index: 5;

    @media screen and (max-width: 767px) {
        bottom: -16%;
        height: 28%;
    }
    @media screen and (min-width: 768px) {
        bottom: -25%;
        height: 35%;
    }
  }

  .audio-upgrade-modal {
    width: 100%;
    height: 100%;
  }
  
  .post-checkout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 20px;
    align-self: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  .post-checkout-upgrade-item-container {
    width: 100%;
  }
  
  .post-checkout-upgrade-button-container {
    width: 100%;
  }
  
  .post-checkout-dismiss-button {
    border: none;
    background-color: transparent;
  }
  
  .post-checkout-dismiss-label {
    color: #5451E0;
    font-family: $open-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-decoration-line: underline;
  }

  .discount-label-color {
    p {
        color: #565F6D !important;
    }
  }