#shopper_approved {
    font-family: 'Josefin Sans', sans-serif;
    color: black !important;
    width: 100%;
}

.filled-icons {
    position: absolute !important;
}

#shopper_background {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 32765 !important;
    background-color: $text-color  !important;

    opacity: 0.40 !important;
    filter: alpha(opacity=40) !important;
}

#shopper_approved #sa_outer {
    margin: 10px auto;
    max-width: 580px !important;
    padding: 0 !important;
}

#shopper_approved #sa_rounded {
    background-color: white !important;
    padding: 0 0 20px 0 !important;
    border: 1px solid #ccc !important;
    -moz-box-shadow: 2px 2px 5px #888 !important;
    -webkit-box-shadow: 2px 2px 5px #888 !important;
    box-shadow: 2px 2px 5px #ccc !important;
    -moz-border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -khtml-border-radius: 10px !important;
    border-radius: 10px !important;
}

#shopper_approved #sa_rounded * {
    position: relative;
}

#shopper_approved #sa_header {
    border: 0 !important;
    text-align: left !important;
    padding: 20px 0 0 30px !important;
    vertical-align: middle !important;
    font-size: 24px !important;
    position: relative;
    font-style: normal !important;
    font-weight: bold;
    @media only screen and (max-width:575px) {
        padding: 20px 30px 0 30px !important;
    }
}

#shopper_approved #sa_close {
    border: 0 !important;
    // display: none !important;
    float: right !important;
    margin: -10px 10px 0 0 !important;
    cursor: pointer;
    /*width: 12px !important;*/
    /*height: 12px !important;*/
    @media only screen and (max-width:575px) {
        margin: 0 !important;
        position: relative;
        right: -15px;
        top: -6px;
    }
}

#shopper_approved #sa_ratings {
    text-align: left !important;
    padding: 0 30px !important;
    position: relative;
}

#shopper_approved #sa_thankyou {
    text-align: left !important;
    padding: 0 30px !important;
    position: relative;
}

#shopper_approved #sa_thankyou_no_email {
    text-align: center !important;
    padding: 0 30px !important;
    position: relative;
}



#shopper_approved select.sa_value {
    width: 200px !important;
    margin-top: 7px !important;
}

#shopper_approved select,
#shopper_approved input,
#shopper_approved textarea {
    font-size: 16px !important;
}

#sa_comments {
    padding: 20px 0 !important;

    font-family: 'Josefin Sans', sans-serif;
}

#shopper_approved .sa_comments {
    z-index: 2147483648;
}

/* lower rating */
#sa_lowrating {
    padding: 20px 0 !important;
    display: none;
    font-family: 'Josefin Sans', sans-serif;
    color: red !important;
}

#sa_lowrating a {
    font-family: 'Josefin Sans', sans-serif;
    color: blue !important;
    cursor: pointer;
}

.sa_heading {
    border: 1px solid #bbb !important;
    color: gray;
    padding: 2px;
    width: 100% !important;
    margin-bottom: 20px;
}

/* satisfied with your order */
#sa_optin {
    padding: 0 !important;
    // display: none;
    font-family: 'Josefin Sans', sans-serif;
    color: black !important;
}

#sa_optin input[type="text"] {
    border: 1px solid #bbb !important;
    color: black !important;
    float: right;
    margin-right: 50px;
    width: 360px !important;
}

#shopper_approved input[type="radio"] {
    float: none !important;
    opacity: 1 !important;
}

#sa_last_chance {
    display: none;
    font-size: smaller !important;
    color: red !important;
    padding: 0 0 6px 0 !important;
}

#shopper_submit.custom {
    padding: inherit !important;
    text-indent: 0px;
    width: 170px !important;
    height: 40px !important;
    color: white !important;
    background-image: none !important;
    background-color: #04599c !important;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    border-radius: 7px;
    font-size: 20px;
    border: 0 !important;
}

#shopper_approved .sa_feature {
    font-size: 11px;
    color: gray;
    position: static;
}


#sa_safe {
    font-weight: bold;
}

.sa_thankyou_text {
    padding-top: 6px;
}

.sa_message {
    color: red;
}

#sa_header_img {
    display: inline-block;
    margin-bottom: 15px;
    max-width: 200px !important;
}

#sa_header_text {
    display: inline-block;
}

#sa_header_img.sa_blockimage {
    display: block;
}

.sa_bottom_br {
    padding-bottom: 15px;
}

.sa_bin label:nth-of-type(2) {
    margin-left: 15px;
}

@media (max-width: 600px) {
    #sa_optin input[type="text"] {
        margin-right: 0 !important;
    }
}

@media (max-width: 540px) {
    #sa_optin input[type="text"] {
        margin-right: 0 !important;
        width: 200px !important;
    }
}

@media (max-width: 500px) {
    #shopper_approved #sa_outer {
        margin: 0 !important;
        padding: 0 !important;
    }
}

@media (max-width: 450px) {
    #sa_header_img {
        max-width: 200px;
    }
}


#sa_feature {
    font-size: 11px;
    visibility: hidden;
}

.product_desc {
    font-size: 16px;
}

#ProductComments {
    font-family: 'Josefin Sans', sans-serif;
}

@media (max-width: 450px) {
    #sa_optin input[type="text"] {
        display: block !important;
        width: 100% !important;
        float: none;
        margin-bottom: 10px;
    }
}

#shopper_approved img {
    width: inherit !important;
    height: inherit !important;
    vertical-align: middle;
}

#shopper_approved>* {
    font-family: 'Josefin Sans', sans-serif;
}

.completed_step {
    border-bottom: #ddd solid 1px !important;
}

#shopper_approved .sa_ratingBox {
    padding: 20px 0 0 0 !important;
    font-size: 16px !important;
    color: black !important;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 500 !important;
}

#shopper_approved .sa_ratingBox label {
    font-weight: 400 !important;
}

.sa_feature {
    font-size: 14px !important;
    margin-top: 10px !important;
}

#sa_comments div {
    margin-bottom: 10px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    font-family: 'Josefin Sans', sans-serif;
}

div#supporthelp_stars {
    margin-top: 10px !important;
}

p#sa_safeemail {
    background: url(https://www.shopperapproved.com/thankyou/images/minicheckmark.jpg) !important;
    background-repeat: no-repeat !important;
    padding-left: 20px !important;
    background-position: 0 11px !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-family: 'Josefin Sans', sans-serif;
}

div#sa_pleaseenter div {
    margin-bottom: 10px !important;
}

p#sa_safeemail img {
    display: none !important;
}

div#sa_pleaseenter {
    font-weight: 500 !important;
    font-family: 'Josefin Sans', sans-serif;
}

#sa_optin .sa_optin {
    padding: 10px 0 !important;
    margin-bottom: 0px !important;
    line-height: 30px !important;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

#shopper_approved input[type="text"] {
    background: #fafafa !important;
    border: 1px solid #ddd !important;
    padding: 8px 10px !important;
    border-radius: 5px !important;
    width: 300px !important;
}

.sa_intro.sa_bin.sa_ratingBox div {
    font-weight: 500 !important;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px !important;
    margin-bottom: 10px !important;
}

div#Overall_stars {
    margin-top: 10px !important;
}


#shopper_approved textarea {
    border: 1px solid #ddd !important;
    color: gray !important;
    padding: 10px !important;
    width: 100% !important;
    font-family: 'Josefin Sans', sans-serif;
    background: #fafafa !important;
    border-radius: 5px !important; 
}

div#after_video table td:first-child {
    width: 160px !important;
}

div#after_video table td {
    padding-bottom: 15px !important;
}

#shopper_approved select {
    background: #fafafa !important;
    display: block !important;
    width: 320px !important;
    padding: 10px !important;
    border-radius: 3px !important;
    border: 1px solid #ddd !important;
}

span#sa_safe {
    color: #08b108 !important;
    font-weight: bold !important;
}

.survey_step a {
    color: #01669a !important;
    font-weight: bold !important;
}

input#agree_customer::after {
    display: block !important;
    content: '' !important;
    width: 15px !important;
    height: 15px !important;
    background: #eee !important;
    border: 1px solid #04599c !important;
    border-radius: 3px !important;
    margin: -4px !important;
}

#shopper_approved input[type="checkbox"]:checked::before {
    display: block !important;
    width: 16px !important;
    height: 16px !important;
    background: #04599c !important;
    content: '\2713' !important;
    position: absolute !important;
    color: #fff !important;
    margin: -4px !important;
    text-align: center !important;
    line-height: 17px !important;
    border-radius: 2px !important;
    font-size: 12px !important;
    border: none !important;
    font-weight: bold !important;
}

#shopper_approved input[type=radio]:checked::before {
    background: #04599c !important;
    content: '' !important;
    width: 11px !important;
    height: 11px !important;
    display: block !important;
    position: absolute !important;
    left: -1px !important;
    top: 1px !important;
    border-radius: 50% !important;
}

#shopper_approved input[type=radio]:checked::after {
    background: #fff !important;
    content: '' !important;
    width: 15px !important;
    height: 15px !important;
    text-align: center !important;
    line-height: 15px !important;
    display: block !important;
    color: #fff !important;
    border-radius: 50% !important;
    border: 1px solid #04599c !important;
}

#shopper_approved input[type=radio] {
    position: relative !important;
    margin: 0 !important;
}

#shopper_approved input[type="radio"]::after {
    content: '' !important;
    background: #eee !important;
    display: block !important;
    height: 16px !important;
    width: 16px !important;
    border-radius: 50% !important;
    margin: -1px -3px !important;
    border: 1px solid #04599c !important;
}

#sa_header div {
    width: 50% !important;
}

.sa_star {
    cursor: pointer !important;
    background: url("https://www.shopperapproved.com/thankyou/simplestar.png") no-repeat !important;
    width: 24px !important;
    height: 24px !important;
    display: inline-block !important;
}

.sa_activestar {
    background-position: 0 -24px !important;
}

#shopper_submit,
#disabled_submit {
    width: 170px !important;
    height: 40px !important;
    color: #fff !important;
    background-image: none !important;
    background-color: #04599c !important;
    -moz-border-radius: 7px !important;
    -webkit-border-radius: 7px !important;
    -khtml-border-radius: 7px !important;
    border-radius: 7px !important;
    font-size: 20px !important;
    border: 0 !important;
    text-indent: 0 !important;
    padding: inherit !important;
}

#shopper_approved #sa_footer_img {
    float: right !important;
    display: block !important;
    width: 40% !important;
}

.tooltip {
    font-family: 'Josefin Sans', sans-serif;

    &-inner {
        background: $primary-color;
        color: #fff;
    }

}

.tooltip .arrow:before {

    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid $primary-color;
}

.tooltip_text {

    background: $primary-color;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    max-width: 320px;
    position: absolute !important;

    z-index: 11111111111111111;
    bottom: 130%;
    width: 320px;
}

.tooltip_text p {
    margin: 0;
}

.tooltip_text h4 {
    margin: 15px 0 0;
    font-weight: 400;
}

.tooltip_text ul {
    padding: 0;
}

.tooltip_text.show {
    display: block !important;
}

.tooltip_wrapper {
    display: inline-block;
    position: relative;
}

.tooltip_wrapper a {
    text-decoration: none !important;
    color: #04599c;
}

.tooltip_wrapper .tooltip_link {
    text-decoration: none !important;
    color: #04599c;
}

.tooltip_text::after {
    content: '';
    position: absolute;
    border-left: 8px solid #fff;
    border-right: 8px solid #fff;
    border-bottom: 8px solid transparent;
    bottom: -8px;
    background: #04599c;
    transform: rotate(180deg);
    left: 50px;
}

.tooltip_wrapper:hover .tooltip_text {
    display: block !important;
}

.tooltip_text ul li::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
}

.tooltip_text ul li {
    padding-left: 10px;
    margin-bottom: 5px;
}