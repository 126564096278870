.personalInfo {
  margin-top: 1rem;

  &.no-padding-left {
    margin-left: -110px !important;

    @include breakpoint(min-desktop) {
      margin-left: 0 !important;
    }
  }

  &__card {
    background-color: $ash-color;
    margin: 0rem 0 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @include breakpoint(min-desktop) {
      max-width: 100% !important;
    }

    @include breakpoint(tablet-land) {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      margin: 2rem 0 0rem;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

    h3 {
      color: $Blue-color;
      font-weight: 900;
      margin-bottom: 1rem;

      @include breakpoint(tablet-port) {
        font-size: 1.375rem;
      }
    }
    h4 {
      @include breakpoint(tablet-port) {
        font-size: 1.125rem;
      }
    }

    @include breakpoint(tablet-port) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .small-text {
    font-size: 0.75rem;
    text-align: center;
  }

  .MuiAccordionDetails-root {
    margin-bottom: 0rem;
    padding: 0;
    margin-top: 0.7rem;
  }

  .MuiSelect-select,
  .MuiMenuItem-root {
    text-transform: capitalize;
  }

  .securityFooter__icon {
    cursor: pointer;
  }
}

.securityQuestionsTitle {
  @include breakpoint(tablet-land) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    flex-wrap: wrap;

    h4 {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%;
    }
  }
}

@include breakpoint(tablet-land) {
  .mobile-question {
    .mobile-border {
      border-bottom: 1px solid #ddd;
      padding: 0.7rem 0;

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      .col-md-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.SecurityQuestions-btn {
  margin-right: 2.1rem;

  @include breakpoint(tablet-land) {
    margin-right: 0;
    flex-wrap: wrap;
    margin-bottom: -1rem;

    .securityFooter,
    .btn {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    .securityFooter {
      order: 2;

      p {
        margin-left: 0.5rem !important;
        font-size: 0.8rem !important;
      }
    }

    .btn {
      justify-content: center;
      margin-bottom: 2rem;
    }
  }

  @include breakpoint(phone) {
    padding: 0 2rem 0 !important;
  }
}

// new design
.section-title {
  font-weight: 600;
  letter-spacing: 0px;
  font-size: 1.125rem;
  padding: 1rem 0;
}

.modal-alignment {
  // height: calc(100vh - 114px);
  // align-content: space-between;
}

section.profile {
  @media screen and (max-width: 1199px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .profile-container-form {
    @media screen and(max-width:1199px) {
      background-color: #ffffff8c;
			border: 1px solid #cccbf6;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 12px;
    }
    .edit-btn-container {
      width: fit-content;
      background: #0048a1;
      padding: 0.4rem;
      border-radius: 12px;
    }
    .title-container {
      justify-content: space-between;
      align-items: center;
    }
  }

  .form-v2 .MuiInputBase-input.Mui-disabled {
    color: $primary-color;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: $primary-color;
  }
}

.profile,
.billing-sec,
.setting {
  .page-title {
    @include padding-top(55);

    @include breakpoint(tablet-land) {
      @include padding-top(20);
      @include fontSize(22);

      .kansasnew-font {
        margin-bottom: 0.4rem;
      }
    }
  }
}

.setting {
  .MuiInputBase-input {
    height: 1rem;

    &::placeholder {
      color: rgba($color: $text-color, $alpha: 0.6) !important;
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.6px) scale(0.9);
  }

  .TextInputField__icon {
    top: 19px;

    svg {
      font-size: 1.1rem;
      fill: rgba($color: $primary-color, $alpha: 0.6);
    }
  }

  .error {
    // color: #6A6A6A !important;
    margin-top: 6px !important;
  }
}
