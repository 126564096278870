.refund {
  &__summary {
    padding-left: 1rem;

    h6,
    p {
      margin-bottom: 0;
    }

    h6 {
      font-weight: 600;
    }

    .row {
      [class*="col-"] {
        margin-bottom: 0.9rem;
      }
    }

    .price-row {
      border-top: 1px solid $silver-color;

      [class*="col-"] {
        margin-bottom: 0.3rem;
      }

      h5 {
        padding-left: 0.5rem;
        margin-top: 0.8rem;
      }

      p {
        font-size: 0.9rem;
        padding-left: 0.5rem;
      }
    }
  }
}