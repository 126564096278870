// @import url("https://p.typekit.net/p.css?s=1&k=wix5zxl&ht=tk&f=14541.14545.14548.14549.14550.16382.16383.16384.28205.28226.28238&a=83497986&app=typekit&e=css");

// @font-face {
//     font-family: "open-sans";
//     src: url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
//     font-display: swap;
//     font-style: normal;
//     font-weight: 700;
//     font-stretch: normal;
// }

// @font-face {
//     font-family: "open-sans";
//     src: url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/8c3639/00000000000000007735a07c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
//     font-display: swap;
//     font-style: normal;
//     font-weight: 600;
//     font-stretch: normal;
// }

// @font-face {
//     font-family: "open-sans";
//     src: url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
//     font-display: swap;
//     font-style: normal;
//     font-weight: 400;
//     font-stretch: normal;
// }

@font-face {
  font-family: "p22-mackinac-pro";
  src: url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b6ae0d/00000000000000007735b3dc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
}

@font-face {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
}

/* HEAD */

.custom-header {
  font-family: $open-sans;
  height: fit-content;
  margin-top: 40px;
  margin-bottom: 50px;

  ul {
    padding: 0px;
  }

  ul li {
    list-style: none;
  }
}

.parent-container {
  padding-top: 95px !important;
}

.logo {
  .header-logo {
    // width: 146px;
    // height: 48px;
    max-height: 61px;
    max-width: fit-content;
    @media screen and (max-width: 768px) {
      max-height: fit-content;
      max-width: 30%;
    }
  }
}

.head-contents {
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.stepper-mark {
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.6;
  width: 25px;
  height: 25px;
}

.stepper-title {
  opacity: 0.5;
  margin-top: 0.4rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #2a334a;
  font-family: $open-sans;
}

.stepper-item {
  position: relative;
  min-width: 120px;
  color: #5451e0;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.stepper-item.fill .stepper-mark {
  opacity: 1;
  background-image: url(../../images/step-mark.png);
}

.stepper-mark span {
  border: 2px solid #b7b9c0;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
  height: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #7a808d;
  font-weight: 600;
}

.stepper-item.fill .stepper-mark span {
  opacity: 0;
}

.stepper-item.fill .stepper-title {
  opacity: 1;
}

.stepper-item.fill::after,
.stepper-item.fill::before {
  opacity: 1;
  background-color: #5451e0;
}

.stepper-item.active .stepper-mark,
.stepper-item.active .stepper-title {
  opacity: 1;
}

.stepper-item.active .stepper-mark span {
  border: 2px solid #5451e0;
  color: #5451e0;
}

.stepper-item.active::before {
  opacity: 1;
  background-color: #5451e0;
}

.stepper-item.before-none:before {
  display: none;
}

.stepper-item.after-none:after {
  display: none;
}

.stepper-item::after,
.stepper-item::before {
  content: "";
  position: absolute;
  top: 12px;
  right: 0px;
  width: calc(50% - 30px);
  height: 1px;
  background-color: #b7b9c0;
  opacity: 0.6;
}

.stepper-item::after {
  right: 0px;
}

.stepper-item::before {
  left: 0px;
}

.hn-nav {
  display: flex;
  align-items: center;
  gap: 40px;
}

.hn-nav ul {
  display: flex;
  gap: 13px;
  margin: 0;
}

.hn-nav ul a.selected {
  background-color: red;
}

.hn-nav .login {
  display: flex;
  gap: 10px;
  align-items: center;
}

.hn-nav .login span {
  font-size: 14px;
  line-height: 150%;
  color: #2a334a;
  text-decoration: none;
  font-weight: 600;
  font-family: $open-sans;
}

/* CONTENT */

.custom-container {
  max-width: 1250px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  font-family: $open-sans;

  label {
    font-family: $open-sans;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #2a334a;
    margin-bottom: 0.25rem;
  }
}

.content-body {
  display: flex;
  gap: 60px;
}

.content-body .widget {
  flex: 1;
}
.flex-1 {
  flex: 1 1 0%;
}

.faq-container-child {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
}

.faq-container {
  display: block;
}
.content-body .registration .registration-border {
  background-color: #fff;
  max-width: 685px;
  padding: 32px 64px;
  box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1),
    inset 0px 0px 0px 3px #5451e0;
  border-radius: 24px;
}

.content-body .registration h1 {
  font-family: "p22-mackinac-pro", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #2a334a;
  text-align: center;
  margin-bottom: 10px;
}

.content-body .registration h2 {
  font-family: $open-sans;
  font-weight: 700;
  font-size: 21px;
  line-height: 140%;
  color: #2a334a;
  text-align: center;
  margin-bottom: 24px;
}

.watch-demo {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 250px;
  border: none;
  background: rgba(0, 0, 0, 0);
}

.watch-demo-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  align-self: stretch;
  width: 366.641px;
  height: 204.186px;

  &.course-info {
    width: 80%;
    height: auto;
  }
}

.watch-demo-icon {
  float: left;
}

.watch-demo-label {
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-top: 13px;
  padding-left: 13px;
  vertical-align: center;
  float: right;
}

.benefit-check {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  padding-top: 2px;
}

.benefits-desc {
  color: #2e353f !important;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding-left: 10px;
  width: 300px;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.benefits-desc span {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 4px;
}

.reg-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  column-gap: 1rem;
}

.divider {
  background-color: #fbe0d6;
  width: 50px;
  border: 2px solid #fbe0d6;
  margin: 0 auto 20px;
  border-radius: 16px;
}

.field-row {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-bottom: 20px;
}

.field-grid-row {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  justify-content: center;
  margin-bottom: 20px;
}

.field-col {
  flex: 1;
  position: relative;
  .MuiInputBase-input {
    font-family: $open-sans !important;

    &::placeholder {
      font-family: $open-sans !important;
      color: #000000 !important;
    }
  }

  .error {
    font-weight: 0;
    font-size: 0.75rem;
    line-height: 160%;
    font-family: $open-sans !important;
  }

  .MuiInputBase-root {
    padding-right: 0 !important;
  }

  .MuiOutlinedInput-input {
    padding: 15.5px 14px !important;
  }

  .MuiInputBase-root span {
    font-weight: 0 !important;
    font-size: 1 !important;
    line-height: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0.375rem !important;
  }

  .field__icon {
    position: absolute;
    right: 10px;
    top: 40px;

    .MuiSvgIcon-root {
      fill: rgb(122, 128, 141);
    }
  }
}

.field-col span {
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  color: #7a808d;
}


select {
  height: 3rem;
  padding: 0 0 0 0.75rem !important;
}

input[type="email"],
input[type="password"] {
  position: relative;
}

.field-col.email:after {
  position: absolute;
  content: url(../../images/envelope.png);
  width: 12px;
  height: 9px;
  top: 40px;
  left: 3%;
}

.field-col.password:after {
  position: absolute;
  content: url(../../images/key.png);
  width: 12px;
  height: 9px;
  top: 40px;
  left: 3%;
}


.login-link {
  cursor: pointer;
}

.submit-btn {
  font-family: $open-sans;
  min-height: 54px;
  width: 100%;
  display: block;
  background-color: #5451e0;
  border-radius: 100px;
  padding: 18px 40px;
  color: #fff;
  border: transparent;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}

.privacy-policy p {
  font-family: $open-sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #7a808d;
  margin-bottom: 20px;
  text-align: center;
}

.privacy-policy a {
  cursor: pointer !important;
  color: #7a808d;
  text-decoration: underline !important;
}

.reg-btn img {
  cursor: pointer !important;
}

.check__item label {
  display: flex;
  margin-bottom: 0 !important;
}

.check__item label .text {
  color: #2a334a;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
}

.checkbox {
  margin-bottom: 16px;
}

.testimony {
  position: relative;
  width: 100%;

  .slick-track {
    display: flex;
    position: relative;
    min-height: 227px;
  }

  .slick-slide {
    flex-basis: 100%;
    background: #e3edfc;
    width: 500px;
    border-radius: 24px;
    padding: 32px;
  }

  .prev-arrow,
  .next-arrow {
    cursor: pointer;
  }

  .slideContent {
    img {
      margin-bottom: 36px;
    }

    .slide-rating {
      margin-bottom: 36px;
    }

    p {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: #2a334a;
      font-family: $open-sans;
    }
  }
}

.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

.label-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.label-container input:checked ~ .checkmark {
  background-color: #5451e0;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.label-container .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox .text {
  max-width: 523px;
  color: #2a334a;
}

.secure {
  max-width: 100%;
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.secure img {
  height: 100% !important;
}

.secure p {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #7a808d;
  font-family: $open-sans;
}

.secure-transaction p {
  background: #f1f6fd;
  border-radius: 100px;
  font-family: $open-sans;
  color: #666d7d;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding: 6px 16px;
}

.info-tabs .tab-container .tabs {
  display: flex;
  gap: 8px;
}

.info-tabs .tab-container .tab {
  font-family: $open-sans;
  padding: 10px 24px;
  border-radius: 16px;
  background-color: #e3edfc;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #2a334a;
  cursor: pointer;
}

.info-tabs .tab-container .tab.active {
  background-color: white;
  position: relative;
  color: #ec6233;
}

.info-tabs .tab-container .tab.active::before {
  content: url("../../images/tab-arrow.png");
  width: 21px;
  height: 9px;
  position: absolute;
  bottom: 0px;
  left: 39%;
}

/* ACCORDION */

.scroll::-webkit-scrollbar-thumb {
  background: rgba(84, 81, 224, 0.2) !important;
  border-radius: 10px !important;
}

.scroll::-webkit-scrollbar-track {
  background: rgba(0, 72, 161, 0.1) !important;
  border-radius: 10px !important;
}

.scroll::-webkit-scrollbar {
  width: 0.6rem !important;
  height: 0.6rem !important;
}

.accordion {
  margin: 30px auto 0;
}

.accordion.scroll {
  margin-top: 0.4rem;
  max-height: 485px;
  overflow: auto;
  padding-right: 1rem;
}

.accordion-header {
  font-family: $open-sans;
  background: transparent;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #2a334a;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header:after {
  /* content: '\002B'; */
  content: url("../../images/arrow-right-default.png");
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion-header.active {
  color: #ec6233;
}

.accordion-header.active:after {
  /* content: "\2212"; */
  content: url("../../images/arrowdown-orange.png");
}

.accordion-body {
  padding: 0 18px;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  &-show {
    max-height: 170px;
    padding: 0px 18px;
    overflow: inherit;
  }
}

.accordion-body p {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #52596b;
  padding: 0 24px 24px 0;

  span {
    font-family: $open-sans !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #52596b !important;
  }
}

.accordion .divider-ac {
  border-bottom: 2px solid #e3edfc;
  margin-top: 15px;
}

/* Begin registerNew */

.register-new-content-config {
  padding-left: 0;
  margin-left: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    max-height: fit-content !important;
    margin-top: 0;
  }
}

.register-new-column {
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.register-new-faq-container {
  display: none;
  align-self: center;

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.custom-container-config {
  height: 100%;
}

.register-new-fast-lane-options {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}

.shopper-hided-on-desktop {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    height: 100%;
  }
}

.shopper-hided-on-mobile {
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.course-info-html-header-config {
  display: none;

  &.fast-lane-course-info {
    margin-top: 8px;
    p {
      margin-bottom: 0 !important;
      span {
        & + span {
          font-weight: 400;
        }

        strong {
          font-weight: 600 !important;
          font-family: $open-sans !important;
        }

        @media screen and (max-width: 768px) {
          font-size: 14px !important;
          line-height: 140%;
        }
      }
    }

    ul {
      padding-left: 0.5rem;

      li {
        span {
          background-color: transparent !important;

          @media screen and (max-width: 768px) {
            font-size: 13px !important;
          }
        }
      }
      @media screen and (max-width: 768px) {
        padding-left: 0 !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding-left: 4px;
  }
}

.existing-email-login-modal {
  background: rgba(42, 51, 74, 0.6);
  align-self: center;
}

.existing-email-login-modal-body {
  display: flex;
  flex-direction: column;
  display: flex;
  width: 565px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  opacity: 1;
  box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1);
  align-self: center;

  @media screen and (max-width: 768px) {
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.existing-email-login-modal-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.existing-email-login-modal-title {
  color: #2a334a;
  text-align: center;
  font-family: "p22-mackinac-pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
}

.existing-email-login-modal-subtitle {
  color: #2a334a;
  text-align: center;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;

  &.email {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.existing-email-login-modal-button-container {
  display: flex;
  //width: 357px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.existing-email-login-modal-login-button {
  display: flex;
  height: 54px;
  padding: 18px 40px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 100px;
  border: none;
  background: #ec6233;

  color: #fff;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    height: 34px;
  }
}

.existing-email-login-modal-create-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  //border-radius: 4px;

  padding: 18px 40px;
  gap: 16px;
  flex: 1 0 0;
  color: #ec6233;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

  border-radius: 100px;
  border: 1px solid #ec6233;
  background: #fff;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    height: 34px;
    padding: 12px 20px;
  }
}

/* End registerNew */

/* Begin FastLaneCourseInfo */

.course-info-container {
  padding: 0px 36px 0px 36px;
  width: 480px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
  }
}

.course-group-image {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  height: 204px;
  position: relative;

  &.course-info {
    display: flex !important;
    width: 60% !important;
    height: 150px !important;
    align-self: center;
  }

  @media screen and (max-width: 768px) {
    display: none !important;
    width: 100%;
  }
}

.course-info-desc {
  padding-top: 16px;
  padding-bottom: 16px;
  width: 90%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 16px;
    border-radius: 16px;
    width: 100%;
  }
}

.course-title {
  color: #2a334a !important;
  font-family: $open-sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  width: 351px;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    line-height: 150%;
  }
}

.course-provoke {
  color: #2a334a !important;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: capitalize;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.course-benefits {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.content-body .course-benefits-points {
  display: flex;
}

/* Begin Video Modal */

.video-player-modal-register {
  background-color: rgb(255, 255, 255);
  border-radius: 24px;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  display: flex !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal-dialog {
    margin: 0 !important;
  }
}

.video-player-modal-register-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  margin: 0;
}

.video-player {
  width: 100%;
  height: auto;
  border: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}

.video-player-close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 12px;
  margin-right: 12px;
  border: none;
  font-family: $open-sans;
  font-weight: 900;
  background: rgba(0, 0, 0, 0);
  color: black;
  @media screen and (max-width: 768px) {
    margin-top: 4px;
    margin-right: 4px;
  }
}

/* End Video Modal */

.course-info-html-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.course-info-title {
  color: #2a334a;
  text-align: left;
  font-family: $open-sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
  }
}

.course-info-html-header {
  position: relative;
  h2 + p {
    margin: 0 0 1.5rem;
  }

  div {
    img {
      position: absolute;
      left: 190px;
      bottom: 52px;
    }

    & + p {
      text-align: center;
      position: absolute;
      left: 270px;
      bottom: 35px;
      margin: 0;

      span {
        display: block;
      }
    }
  }

  p {
    span {
      & + span {
        font-weight: 400;
      }

      strong {
        font-weight: 600 !important;
        font-family: $open-sans !important;
      }
    }
  }

  ul {
    padding-left: 0.5rem;

    li {
      list-style: none;
      background-image: url(../../images/listCheckmark.svg);
      background-size: 20px 20px;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 30px;
      margin: 0 0 0.5rem !important;

      span {
        font-weight: 600;
        background-color: #f1f6fd !important;
        font-family: $open-sans !important;
        color: #2a334a !important;
      }

      strong {
        font-weight: 600 !important;
        color: #2a334a !important;
      }
    }
  }

  del {
    text-decoration: line-through;
    text-decoration-color: red;
  }

  * {
    background-color: transparent !important;
  }

  &.fast-lane-course-info-config {
    margin-top: 8px;
    p {
      margin-bottom: 0 !important;
      font-family: $open-sans !important;

      span {
        font-family: $open-sans !important;
      }
    }

    ul {
      padding-left: 0;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.course-info-html-list {
  p {
    span {
      background-color: #f1f6fd !important;
      font-family: $open-sans !important;
    }
  }

  ul {
    padding-left: 0.5rem;

    li {
      list-style: none;
      background-image: url(../../images/listCheckmark.svg);
      background-size: 20px 20px;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 30px;
      margin: 0 0 0.5rem !important;

      span {
        font-weight: 600;
        background-color: #f1f6fd !important;
        font-family: $open-sans !important;
        color: #2a334a !important;
      }

      strong {
        font-weight: 600 !important;
        color: #2a334a !important;
      }
    }
  }

  del {
    text-decoration: line-through;
    text-decoration-color: red;
  }

  * {
    background-color: transparent !important;
  }
}

.course-info-hide-button {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    padding: 6px 40px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    border: 1px solid #5451e0;
    background-color: transparent;
    height: 100%;
  }
}

.course-info-hide-button-header {
  color: #5451e0;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.course-info-hide-image {
  height: 16px;
  padding-top: 1px;
}

.course-info-faq-holder {
  display: block;
  align-items: center;
  align-self: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.course-info-hide-desktop {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.course-info-hide-mobile {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

/* End FastLaneCourseInfo */

/* Begin FastLaneFAQ */

.fast-lane-faq-container {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 350px;
    align-self: center;
    padding-bottom: 0px;
  }
}

.faq-button-container {
  padding: 14px 0;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-top: 2%;
    padding-bottom: 1%;
  }
}

.faq-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-radius: 100px;
  width: 100%;
  padding: 0;
}

.faq-title {
  color: #5451e0;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-right: 12px;
  margin-top: 3%;
  @media screen and (max-width: 768px) {
    margin-top: 5%;
  }
}

.faq-detailed-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin-bottom: 19px;
  border-radius: 16px;
  background-color: #fff;
}

.faq-detailed {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #dedfe1;
  margin-bottom: 12px;
  position: relative;

  &.course-info {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
    }
  }
}

.faq-question {
  color: #5451e0;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-align: left;

  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-self: center;
  margin: 0;

  background-color: transparent;
  border: none;

  @media screen and (max-width: 768px) {
    font-size: 17px;
  }
}

.answer-uncollapsed-arrow {
  align-self: center;
  height: 18px;
  width: auto;
}

.answer-collapsed-arrow {
  align-self: center;
  transform: rotate(90deg);
  height: 18px;
  width: auto;
}

.faq-answer {
  flex: 1 0 0;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 5px;
}

.faq-collapse-container {
  display: flex;
}

.faq-collapse-button {
  padding: 13px 40px;
  justify-content: center;
  margin-bottom: 12px;
  max-height: 40px;
  flex: 1 0 0;
  border-radius: 100px;
  background: rgba(84, 81, 224, 0.1);
  border: none;
  display: flex;
  flex-direction: row;
}

.faq-collapse-label {
  color: #5451e0;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-right: 12px;
  margin-bottom: 0 !important;

  justify-self: center;
  align-self: center;
}

.faq-collapse-arrow {
  transform: rotate(180deg);
  width: 18px;
  height: 18px;
  align-self: center;
}

.faq-title-button {
  height: 18px;
  width: auto;
}

/* End FastLaneFAQ */

/* Begin FastLaneTab */

.fast-lane-column {
  width: 700px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
}

.fast-lane-container {
  display: flex;
  padding: 60px 64px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1),
    0px 0px 0px 3px #ec6233 inset;
  @media screen and (max-width: 768px) {
    padding: 20px 16px 20px 16px;
    width: 100%;
  }
}

.fast-lane-inner-container {
  display: flex;
  padding: 36px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #cccbf6;
  background: #fafafe;
  gap: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 12px 16px 12px 16px;
  }
}

.fast-line-title {
  color: #2a334a;
  text-align: center;
  font-family: "p22-mackinac-pro";
  font-size: 42px;
  font-style: italic;
  font-weight: 700;
  line-height: 150%; /* 48px */
  @media screen and (max-width: 768px) {
    font-size: 24px;
    text-transform: capitalize;
  }
}

.arrow-down {
  width: 252.667px;
  height: 61.6px;
  margin-top: -20px;
}

.fast-lane-register-label {
  color: #2a334a;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin-top: -40px;
}

.email-register-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
}

.email-register-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;

  color: #2a334a;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 0 !important;
  gap: 4px;

  p {
    margin-bottom: 0 !important;
    text-align: left;
  }

  a {
    font-size: 14px;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    font-size: 13px;
    gap: 0px;
  }
}

.email-register-field-input {
  color: #000;
  font-family: $open-sans !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: 100%;
  padding: 0.7rem 2.5rem;

  border-radius: 4px;
  border: 1px solid #5451E0;
  background: #fff;

  box-shadow: 0px 1px 2px 0px rgba(84, 81, 224, 0.1);

  &.invalid {
    color: #FF0000;
    border: 2px solid #FF0000;
  }
}

.email-register-field-input::placeholder {
  font-family: $open-sans !important;
  font-size: 18px !important;
}

.email-register-field-input:focus {
  &.invalid {
    outline: #FF0000 !important;
  }
}

.email-register-icon {
  background-color: transparent;
  color: transparent;
  border: none;
  position: absolute;
  align-self: center;
  width: 15px;
  padding-top: 1rem;

  &.mail {
    left: 1rem;
  }
  &.tick {
    right: 1rem;
  }
}

.register-email-error-label {
  color: #FF0000;
  font-family: $open-sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-bottom: 0.5rem;

  width: 100%;
}

.fast-lane-payment-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 59px;
  width: 95%;
  height: 50px;
  gap: 8px;
  border: 1px solid #dddcf9;

  @media screen {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.paypal-container {
    background: #ffd464;
  }

  &.amazon-container {
    background: #fff;
    border: 1px solid #000;
  }

  &.applepay-container {
    background: #000;
  }

  &.credit-card-container {
    background: #252f8b;
    border: none;
    margin-left: 4px;
  }
}

.fast-lane-payment-button:disabled {
  opacity: 0.5;
}

.isotype-button-logo {
  width: auto;
  height: 20px;
  margin-left: 6px;
  &.big-logos {
    margin-top: 5px;
  }
  @media screen and (max-width: 700px) {
    height: 16px;
  }
}

.fast-lane-register-payment-button-label {
  color: #fff;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.32px;

  width: 100%;
  padding: 0;
  margin: 0;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}

.blacklabel-config {
  color: #2a334a !important;
}

.fast-lane-register-godaddy-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 0;

  div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.fast-lane-register-godaddy-image {
  height: 20px;
  width: auto;
}

.fast-lane-stars {
  height: 20px;
  width: 90px;
}

.fast-lane-register-godaddy-label {
  color: #52596b;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 700px) {
    font-size: 12px;
    align-self: center;
  }
}

.fast-lane-tab-signature-arrow {
  position: absolute;
  left: -110px;
  top: 30px;
  width: auto;
  height: 100px;
}

.sign-up-missing-fields-button {
  display: inline-block;
  color: #ec6233;
  font-size: 20px;
  line-height: 30px;
  padding: 16px 46px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  background-color: #fbe0d6;
  box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1),
    inset 0px 0px 0px 3px #fbe0d6;
  border-radius: 24px;
  font-family: "p22-mackinac-pro", sans-serif;
}

.sure-missing-fields-button {
  color: #232838;
  display: block;
  max-width: fit-content !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  background-color: transparent;
  border: none;
  text-decoration: none;
  border-bottom: 1px solid #bcbcbc;
  padding: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0px !important;
}

.check-item-config {
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;

  &.phone-modal {
    width: 100% !important;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.missing-fields-image-modal {
  @media screen and (max-width: 700px) {
    display: none;
  }
}

/* End FastLaneTab */

/* Begin FastLaneCartSummary */

.fast-lane-cart-summary-container {
  height: fit-content !important;
  margin-bottom: 5%;
  @media screen and (max-width: 700px) {
    margin-top: -5rem !important;
    margin-bottom: 0 !important;
  }
  @media screen and (max-width: 414px) {
    margin-top: -7rem !important;
  }
}
.fast-lane-cart-summary-config {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  font-family: $open-sans;
  margin-top: 1rem;

  width: fit-content;
  max-width: 1250px;

  .content-body {
    gap: 35px;
    grid-gap: 35px;
  }

  label {
    font-family: $open-sans;
    font-weight: 600;
    line-height: 160%;
    color: #2a334a;
    margin-bottom: 0.25rem;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    min-height: 100%;

    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 414px) {
    width: 100%;
    min-height: 100%;
    margin-bottom: 1rem;

    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    min-height: 100%;
    margin-bottom: 0.4rem;

    align-items: center;
    justify-content: center;
  }
}

.fast-lane-summary-content-body-config {
  display: flex;
  width: 100%;
  gap: 26px;
  @media screen and (max-width: 700px) {
    width: 100%;
    flex-direction: column;
  }
}

.fast-lane-cart-table-config {
  display: flex;
  flex-direction: column;
  width: fit-content;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.fast-lane-cart-table-config-mobile {
  display: none;
  flex-direction: column;
  width: fit-content;

  @media screen and (max-width: 700px) {
    display: flex;
    gap: 18px;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

.fast-lane-checkout-table-config {
  display: flex;
  flex-direction: column;
  min-width: 650px;

  @media screen and (max-width: 700px) {
    width: 100%;
    min-width: 100%;
  }
}

.fast-lane-checkout-table-config-mobile {
  display: none;
  flex-direction: column;
  min-width: 650px;

  @media screen and (max-width: 700px) {
    display: flex;
    width: 100%;
    min-width: 100%;
  }
}

.before-payment-config {
  height: min-content;

  label {
    font-family: open-sans, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #2a334a;
  }

  @media screen and (max-width: 700px) {
    padding: 0 !important;
  }
}

.user-data-modal-register {
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  display: flex !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  width: 100%;

  &.phone-modal {
    max-width: 335px;
  }

  @media screen and (max-width: 700px) {
    left: 50%;
  }

  @media screen and (max-width: 360px) {
    left: 53%;
  }
}

.user-data-modal-register-body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #fafafe;
  gap: 6px;
  width: 600px;
  padding: 32px;
  align-self: center;

  &.phone-modal {
    width: 335px;
    border-radius: 24px;
    border: 3px solid #ffffff;
    background: #9747FF;
    gap: 12px;
    padding: 24px;
  }

  @media screen and (max-width: 700px) {
    width: 95%;
    padding: 16px 32px;
  }

  @media screen and (max-width: 360px) {
    width: 90%;
  }
}

.user-data-modal-close-button {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 13px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 1000px;
  background: transparent;
  border: transparent;

  color: #ffffff;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;

  position: absolute;
  right: 2%;
  top: 2%;

  @media screen and (max-width: 700px) {
    width: 20px;
    height: 20px;
    font-size: 12px;
    padding: 13px;
  }
}

.user-data-modal-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: row;
  width: 100%;
  height: 100%;

  &.phone-modal{
    background-color: transparent;
  }
}

.user-data-modal-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 6px;

  &.phone-modal{
    background-color: transparent;
  }
}

.user-data-modal-data-missing-fields-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}

.user-data-modal-title {
  color: #2a334a;
  font-family: $open-sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  margin-bottom: 0 !important;

  &.phone-modal{
    line-height: 56px;
    letter-spacing: 0em;
    font-size: 40px;
    color: #FFFFFF;
  }

  @media screen and (max-width: 700px) {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
}

.user-data-modal-subtitle-container{
  display: flex; 
  flex-direction: column;
}

.user-data-modal-subtitle {
  color: #2a334a;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-align: center;

  &.phone-modal-small{
    line-height: 17px;
    letter-spacing: 0px;
    font-size: 14px;
    margin-bottom: 4px;
    color: #FFFFFF;
  }

  &.phone-modal-big{
    line-height: 24px;
    letter-spacing: 0px;
    font-size: 20px;
    margin-bottom: 4px;
    color: #FFFFFF;
  }

  @media screen and (max-width: 700px) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.user-data-modal-secure-label {
  color: #0f8a56;
  font-family: $open-sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #e7f3ee;

  margin-top: 32px;

  &.phone-modal {
    margin-top: 0;
    width: 100%;
    text-align: left;
    font-size: 11px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 12px;
    text-align: center;
  }
}

.user-data-modal-label {
  color: #2a334a;
  font-family: $open-sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  margin-left: 10px;

  &.phone-modal {
    margin-left: 0;
    color: #FFFFFF;
  }
}

.user-data-modal-list {
  margin-left: 0;
  padding-left: 2%;
}

.user-data-modal-list-item {
  list-style: none;
  background-image: url(../../images/listCheckmark.svg);
  background-size: 20px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 30px;
  padding-bottom: 4px;

  font-weight: 500;
  font-size: 12px;
  font-family: $open-sans !important;
  color: #2a334a !important;
}

.user-data-modal-field-input {
  color: #000;
  font-family: $open-sans !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: 90%;
  padding: 12px 16px;

  border-radius: 4px;
  border: 1px solid #b7b9c0;
  background: #fff;

  box-shadow: 0px 1px 2px 0px rgba(84, 81, 224, 0.1);
}

.user-data-modal-field-input::placeholder {
  font-family: $open-sans !important;
  font-size: 18px !important;
}

.user-data-modal-field-input-invalid {
  border: 2px solid #d24760;
}

.user-data-modal-field-input-invalid:focus {
  outline: #d24760 !important;
}

.user-data-modal-submit-button {
  display: flex;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 100px;
  background: #ec6233;
  border: none;
  width: 60%;

  color: #fff;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

  &.phone-modal {
    width: 100%;
    font-size: 16px;
  }

  @media screen and (max-width: 700px) {
    width: 80%;
  }
}

.phone-modal-girl-image {
  border-radius: 12px;
  height: 220px;
  width: 100%;
  display: flex;
  flex: auto;
  object-fit: cover;
}

.user-data-modal-submit-button:disabled {
  background: #bdb6b6;
}

.user-data-modal-warning-label {
  color: #ec4533;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 700px) {
    padding: 0;
    margin: 0;
  }
}

.user-data-modal-checkmark-config {
  height: 20px;
  width: 20px;
  display: flex;
  padding: 0;
  margin: 0;

  &.missing-fields {
    margin-left: 15px;
    border: 1px solid #5451E0;
  }

  &.phone-modal {
    border: 1px solid #5451E0;
    background-color: #FFFFFF;
  }
}

.label-container .user-data-modal-checkmark-config:after {
  left: 40% !important;
  top: 20% !important;
  margin: 0;
  padding: 0;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.user-data-modal-checkmark-config:hover {
  &.phone-modal {
    background-color: #FFFFFF;
  }
}

.right-cart-detail-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  border: none;
  background-color: transparent;
}

/* End FastLaneCartSummary */

/* Begin LoaderWithMessage */

.loader-message-container {
  display: flex;
  flex-direction: column !important;
}

.loader-message-label {
  width: 60%;
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
}

/* End LoaderWithMessage */

/* Begin FastLaneCartDetail */

.fast-lane-cart-detail-container {
  display: flex;
  width: 550px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.course-cart-detail-container {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 24px;
  background: #fff;
  position: relative;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.cart-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.left-cart-detail-header-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.cart-icon {
  width: 16px;
  height: 16px;
  color: black;
}

.cart-detail-label {
  color: #2a334a;
  font-family: "p22-mackinac-pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }

  @media screen and (max-width: 414px) {
    font-size: 18px;
    align-self: center;
  }
}

.collapsable-cart-detail-label {
  color: #5451e0;
  text-align: center;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  @media screen and (max-width: 700px) {
    font-size: 13px;
  }

  @media screen and (max-width: 370px) {
    font-size: 11px;
  }
}

.cart-uncollpased-arrow {
  width: 12px;
  height: 12px;
  transform: rotate(180deg);
}

.cart-collpased-arrow {
  width: 12px;
  height: 12px;
}

.cart-detail-image-description-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.course-icon {
  width: 140px;
  height: 112px;
  border-radius: 11.2px;
  background: lightgray 0.061px 0px / 137.011% 100% no-repeat;
}

.cart-detail-description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 6px;
}

.cart-detail-course-name-label {
  align-self: stretch;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  padding: 0;
  margin: 0;
}

.cart-detail-course-price-label {
  color: #2a334a;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  padding: 0;
  margin: 0;
}

.cart-detail-course-description-label {
  align-self: stretch;
  color: #52596b;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding: 0;
  margin: 0;
}

.cart-detail-course-price-container {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

.price-label-lined {
  text-decoration: line-through;
  text-decoration-color: red;
  margin-right: 4px;
}

.fast-lane-base-course-arrow-downward {
  position: absolute;
  right: 3%;
  bottom: -20%;
  width: 60px;
  height: 30%;
  z-index: 5;

  @media screen and (max-width: 767px) {
    bottom: -16%;
    height: 28%;
  }
  @media screen and (min-width: 768px) {
    bottom: -25%;
    height: 35%;
  }
}

.review-panel {
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

/* End FastLaneCartDetail */

/* Begin FastLaneCheckout */

.fast-lane-credit-card-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    justify-content: center;
    height: fit-content;
  }
}

.fast-lane-overall-container {
  display: flex;
  width: 100%;
  padding: 52px 64px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1),
    0px 0px 0px 3px #5451e0 inset;
  gap: 6px;
  @media screen and (max-width: 768px) {
    padding: 12px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 2px solid #dddcf9;
    background: #fafafe;
    box-shadow: none;
    width: 100%;
    min-height: 100%;
  }
}

.fast-lane-fee-lane-container {
  display: flex;
  width: 100%;
  gap: 12px;
  align-self: center;
  height: 100%;
}

.fast-lane-fee-course-name {
  color: #666d7d;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  flex: 5 1 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: fit-content;
}

.fast-lane-fee-course-price {
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  flex: 1 1 0;
  text-align: right;
  height: fit-content;
  padding: 0;
  margin: 0;
}

.fast-lane-cart-table-config {
  gap: 12px;
  width: 100%;
}

.promocode-config {
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    padding: 0;
    margin: 0;
  }
}

.reminder-container {
  display: flex;
  height: auto;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  background: rgba(238, 168, 168, 0.2);
}

.reminder-label {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  color: #f06433;
  text-align: center;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.fast-lane-refund {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 5px;
  @media screen and (max-width: 768px) {
    align-self: center;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.fast-lane-refund-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #7a808d;
  margin-bottom: 0 !important;
}

.fast-lane-refund-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #2a334a;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 3px;
}

.secure-transaction-config {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.paypal-data-subheader {
  @media screen and (max-width: 768px) {
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
    height: fit-content;
  }
}

/* End FastLaneCheckout */

/* Begin FastLaneTermsUse */

.fast-lane-terms-use-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fast-lane-terms-use-link {
  color: #ec6233;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
  align-self: center;
}

/* End FastLaneTermsUse */

/* Begin FastLaneCartUpsellMobile */

.fast-lane-cart-audio-upgrade-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fast-lane-audio-enhance-button {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    padding: 6px 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(84, 81, 224, 0.05);
    border: none;

    color: #5451e0;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
}

.added-config {
  @media screen and (max-width: 768px) {
    .btn {
      width: 100%;
      padding: 12px !important;

      p {
        margin-bottom: 0 !important;
        width: 90%;
      }
    }
  }
}

/* End FastLaneCartUpsellMobile */

/* Begin FastLanePromoCode */

.fast-lane-promo-code-container-mobile {
  display: none;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
    display: flex;
  }
}

.fast-lane-promo-code-container-desktop {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.field-row-config {
  padding: 0;
  margin: 0;
}

.fast-lane-promo-code-input-container {
  display: flex;
}

.fast-lane-promo-code-input {
  width: 100%;
  border: 1px solid #c4c4c4;
  color: #2e353f !important;
  font-family: $open-sans !important;
  text-align: left;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  background-color: Transparent;
  padding: 12px 0px;
  padding-left: 14px;
  border-radius: 5px;
}

.fast-lane-promo-code-input::placeholder {
  color: #2e353f !important;
  font-family: $open-sans !important;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 95% !important;
  }

  @media screen and (max-width: 414px) {
    font-size: 11px !important;
  }
}

.fast-lane-promo-code-input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.fast-lane-promo-code-input:focus::-moz-placeholder {
  color: transparent !important;
}

.fast-lane-promo-code-erase-button {
  background-color: transparent;
  color: transparent;
  border: none;
  position: absolute;
  align-self: center;
  right: 1%;
}

.coupon-code-container-invalid {
  border: 2px solid #d24760;
}

.coupon-code-container-invalid:focus {
  border-color: #d24760 !important;
  outline: #d24760 !important;
}

.coupon-code-container-invalid:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.coupon-code-container-invalid:focus::-moz-placeholder {
  color: transparent !important;
}

.coupon-code-container-valid {
  border: 2px solid #0f8a56;
}

.coupon-code-container-valid:focus {
  border-color: #0f8a56 !important;
}

.coupon-code-container-valid:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.coupon-code-container-valid:focus::-moz-placeholder {
  color: transparent !important;
}

.message-valid {
  color: #0f8a56 !important;
}

.message-invalid {
  color: #df2a4a !important;
}

.invalid-code-icon {
  padding-right: 50px;
}

.message-text-config {
  text-align: left;
  font-family: $open-sans !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.fast-lane-promo-code-add-button {
  height: 100%;
  width: 50px;
  background-color: #9a99ce;
  border-radius: 0px 5px 5px 0px;
  border: none;
  position: absolute;
  right: 0;

  color: #fff;
  text-align: center;
  font-family: $open-sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.fast-lane-promo-code-reset-button {
  height: 100%;
  width: 50px;
  background-color: #d24760;
  border-radius: 0px 5px 5px 0px;
  border: none;
  position: absolute;
  right: 0;

  color: #fff;
  text-align: center;
  font-family: $open-sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

/* End FastLanePromoCode */

/* Begin FastLaneCoursePrice */

.fast-lane-cart-lane-container {
  display: flex;
  width: 100%;
  min-height: 36px;
  height: fit-content;
  gap: 12px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    min-height: fit-content;
  }
}

.fast-lane-cart-image {
  max-width: 44px;
  border-radius: 4px;
  flex: 1 1 0;
  padding: 0;
  margin: 0;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: auto;
    object-fit: cover;
    vertical-align: middle;
    max-height: 44px;
  }
}

.fast-lane-cart-image-button {
  max-width: 44px;
  border-radius: 4px;
  flex: 1 1 0;
  padding: 0;
  margin: 0;
  height: 100%;
  @media screen and (max-width: 768px) {
    height: 50%;
    width: auto;
    vertical-align: middle;
  }
}

.fast-lane-cart-course-name {
  color: #2a334a;
  font-family: $open-sans;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
    line-height: 110%;
    height: fit-content;
  }

  @media screen and (max-width: 370px) {
    font-size: 70% !important;
  }
}

.fast-lane-cart-course-price {
  color: #666d7d;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-align: right;
  height: 100%;
  padding: 0;
  margin: 0;
  align-self: center;
  justify-self: center;
  @media screen and (max-width: 768px) {
    color: #2a334a;
    font-size: 14px;
    height: auto;
  }
}

.fast-lane-improv-info-button {
  border: none;
  color: transparent;
  background-color: transparent;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
}

.fast-lane-cart-course-price-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.fast-lane-cart-course-old-price {
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  height: 100%;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    line-height: 100%;
  }
}

/* End FastLaneCoursePrice */

/* Begin FastLaneImprovTicket */

.checkout-description-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background: #f6f6fd;
}

.checkout-description-label {
  align-self: stretch;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 0;
  margin: 0;
}

.checkout-claim-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-claim-checkbox {
  width: 16px;
  height: 16px;
  fill: #5451e0;
  stroke-width: 1px;
  stroke: #5451e0;
  accent-color: #5451e0;
  align-self: center;
}

.checkout-claim-description {
  flex: 1 0 0;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 14px;
  padding: 0;
  margin: 0;
}

.free-item-label {
  color: #eb7100 !important;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160% !important;
}

/* End FastLaneImprovTicket */

/* Begin FastLaneCartAudioUpgrade */

.fast-lane-cart-delete-button {
  height: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.fast-lane-cart-delete-button:hover {
  background-color: transparent;
}

.fast-lane-cart-delete-button:focus {
  background-color: transparent;
}

.fast-lane-cart-delete-button:active {
  background-color: transparent;
}

/* End FastLaneCartAudioUpgrade */

/* Begin Discounts */

.discounts-container-config {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: 30px;
}

.discount-item-container {
  display: flex;
  gap: 4px;
  height: 30px;
}

/* End Discounts */

/* Begin CreditCardCheckout */

.fast-lane-data-container {
  display: flex;
  padding: 12px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid #dddcf9;
  background: #fafafe;
  gap: 12px;
  @media screen and (max-width: 768px) {
    padding: 0;
    border: none;
    box-shadow: none;
    width: 100%;
    align-items: center;
  }
}

.fast-lane-data-header {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    justify-content: center;
    height: fit-content;
    gap: 8px;
  }
}

.credit-card-icon {
  width: min-content;
  height: 32px;
  @media screen and (max-width: 768px) {
    width: 20px;
    height: min-content;
    align-self: center;
  }
}

.credit-card-label {
  flex: 1 0 0;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  position: relative;
  margin-top: 6px;
  margin-left: 12px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    align-self: center;
    margin: 0;
  }

  @media screen and (max-width: 370px) {
    font-size: 13px;
    align-self: center;
    margin: 0;
  }
}

.fast-lane-input-large input,
.fast-lane-input-large.inputformfastlane {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 4px;
  height: 47px;
  border-radius: 4px;
  border: 1px solid #b7b9c0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(84, 81, 224, 0.1);
  placeholder {
    color: #8d919e;
    font-family: $open-sans, sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

/* End CreditCardCheckout */

/* Begin PaymentChooseDropdownComponent */

.credit-card-method-button {
  border: none;
  background-color: transparent;
  @media screen and (max-width: 768px) {
    height: 90%;
    padding: 0;
    margin: 0;
    align-self: center;
  }
}

.credit-card-method-label {
  color: #5451e0;
  text-align: right;
  font-family: $open-sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-decoration-line: underline;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: 100%;
    position: static;
    align-self: center;
  }

  @media screen and (max-width: 370px) {
    font-size: 11px;
  }
}

.payment-choose-dropdown {
  display: flex;
  width: 248px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  right: -17px;
  top: -14px;
  border-radius: 10px;
  border: 1px solid #dedfe1;
  background: #fff;
  z-index: 120;
}

.payment-choose-dropdown-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.payment-choose-dropdown-label {
  align-self: stretch;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.payment-choose-dropdown-close-button {
  align-self: flex-start;
  border: none;
  background-color: transparent;
}

.payment-choose-dropdown-close-image {
  width: 20px;
  height: 20px;
}

.payment-choose-dropdown-choices-container {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.payment-choose-dropdown-choice {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  width: 100%;

  &.active {
    border: 1px solid #5451e0;
    background: #f5f5ff;
  }
}

.payment-choose-cardgroup {
  display: flex;
  flex-direction: row;
  align-items: start;
  align-self: flex-start;
  align-content: stretch;
  gap: 1px;
}

.payment-choose-amazon-pay {
  width: 80px;
  height: auto;
}

/* End PaymentChooseDropdownComponent */

.credit-card-button {
  width: auto;
  height: 24px;
  margin-right: 10px;
  @media screen and (max-width: 768px) {
    height: 20px;
  }
}

.payment-checkbox {
  width: 24px;
  height: 24px;
  accent-color: #5451e0;
}

.cart-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  min-height: 20px;
}

.fast-lane-coupon-code-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
}

/* Begin FastLaneProfileRegister */

.fast-lane-profile-register-container {
  display: grid !important;
  place-items: center;
  padding-left: 0 !important;
  position: relative;
  @media screen and (max-width: 768px) {
    justify-content: center;
    padding: 0 32px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.profile-register-container {
  display: flex;
  max-width: 720px;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-self: baseline;
  margin: 0;
  // margin-right: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.profile-register-structure {
  width: 100%;
  color: #2a334a;
  text-align: center;
  font-family: "p22-mackinac-pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  gap: 11px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.profile-register-title {
  width: 100%;
  color: #2a334a;
  text-align: center;
  font-family: "p22-mackinac-pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 24px;
    text-align: start;
  }
}

.profile-register-subtitle {
  width: 100%;
  color: #2a334a;
  text-align: justify;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.profile-register-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18px;
  row-gap: 12px;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.profile-register-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  &.password {
    position: relative;
  }
}

.profile-register-field-label {
  align-self: flex-start;
  color: #2a334a;
  font-family: "Inter" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  padding: 0;
  margin: 0;
}

.profile-register-field-input {
  font-family: "Inter",sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 131%;

  &::placeholder{
    color: #8d919e;
    font-family: "Inter",sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}

.profile-register-field-select {
  font-family: "Inter",sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 131%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../images/icons/selectIcon.svg);
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 20px;
  background-position: 95% 50%;

  &::placeholder{
    color: #8d919e;
    font-family: "Inter",sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}

.profile-register-field-date {
  font-family: "Inter" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 131%;

  &::placeholder {
    color: #8d919e;
    font-family: "Inter" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}

.profile-register-field -select option {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}

.profile-register-field-error {
  font-size: 13px;
  color: red;
  font-family: $open-sans;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
}

.profile-register-field-show-password {
  position: absolute;
  right: 2%;
  top: 50%;
  bottom: 50%;
  border: transparent;
  background-color: transparent;
}

.profile-register-continue-button {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #5451e0;
  max-width: 327px;
  //max-height: 64px;
  margin-top: 11px;
  border: none;
  width: 100%;

  &.fake {
    display: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    align-self: center;
  }
}

.profile-register-button-label {
  color: #fff;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  align-self: center;
  padding-top: 11px;
  margin: 0;
  padding: 0;
}

.profile-register-button-icon {
  display: flex;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
}

/* End FastLaneProfileRegister */

/* Begin FastLaneCardDetaiItem */

.course-cart-upgrade-container {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 24px;
  border: 3px solid #eea8a8;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(238, 168, 168, 0.18);
  gap: 24px;
  @media screen and (max-width: 670px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.course-audio-upgrade-image-container {
  display: flex;
  width: 186px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.course-cart-description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: auto;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    height: 100%;
    width: 100%;
  }
}

.audio-course-container {
  display: flex;
  width: 268px;
  height: 44px;
  padding: 6px 40px 6px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: linear-gradient(270deg, #000 50%, #75899e 81.78%);
  @media screen and (max-width: 768px) {
    display: flex;
    width: 136px;
    height: 62px;
    padding: 6px 40px 6px 12px;
  }
}

.overlapped-icons {
  background-image: url("./../../images/circleIconRounded.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.audio-rounded-icon {
  width: 12px;
  height: 10.5px;
  margin-left: 6px;
  margin-bottom: 4px;
  flex-shrink: 0;
  align-self: center;
}

.course-cart-description-title-label {
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  margin-top: 6px;
  margin-bottom: 6px;
  width: 100%;
  text-align: left;
  color: #2a334a;
  font-weight: 600;
  line-height: 160%;

  span {
    color: #5451e0;
  }

  @media screen and (max-width: 768px) {
    margin-top: 12px;
    align-self: center;
  }
  @media screen and (max-width: 390px) {
    align-self: flex-start;
    line-height: 120%;
  }
}

.course-cart-description-subtitle-label {
  color: #fff;
  font-family: $open-sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    width: 88px;
    flex-shrink: 0;
    text-transform: capitalize;
  }
}

.course-cart-description-label {
  width: 100%;
  color: #2a334a;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    align-self: center;
  }

  @media screen and (max-width: 390px) {
    align-self: flex-start;
  }
}

.course-cart-upgrade-button {
  display: flex;
  flex-direction: row;
  width: 269px;
  height: 49px;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #5451e0;
  position: relative;
  margin-top: -6px;
  padding-left: 20px;
  border: none;
  @media screen and (max-width: 768px) {
    display: flex;
    width: 136px;
    height: 63px;
    padding: 18px 32px;
    align-items: center;
  }
}

.course-cart-upgrade-title-label {
  color: #fff;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 120px;
  left: 0;
  margin-top: 6px;
  @media screen and (max-width: 768px) {
    width: 78px;
    font-size: 12px;
    font-weight: 800;
    text-align: left;
  }
}

.plus-icon {
  width: 19.763px;
  height: 100%;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: center;
  @media screen and (max-width: 768px) {
    margin-right: 16px;
  }
}

.course-cart-girl {
  display: flex;
  height: 100%;
  border-radius: 12px;
  flex: auto;
  align-self: flex-start;
  object-fit: cover;
  @media screen and (max-width: 670px) {
    width: 100%;
    padding-left: auto;
    height: auto;
  }
  @media screen and (min-width: 1441px) {
    width: 40%;
  }
  @media screen and (min-width: 1559px) {
    width: 20%;
  }
}

.course-cart-girl-modal {
  display: flex;
  width: auto;
  height: 100%;
  border-radius: 18px 0 0 18px;
  flex: auto;
  @media screen and (max-width: 768px) {
    padding-left: auto;
  }
}

.course-cart-speaker-icon {
  position: absolute;
  top: 5%;
  left: 5%;
}

.course-bottom-aditional-config {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    width: 100%;
    gap: 4% !important;
  }
}

.price-num-aditional-config {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: auto;
    align-self: center;
    gap: 5% !important;
  }
}

.course-audio-upgrade-only-label {
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 13.167px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 70%;
  }
}

.discounted-aditional-config {
  @media screen and (max-width: 375px) {
    font-size: 90% !important;
  }
}

.cart-btn-block-aditional-config {
  @media screen and (max-width: 375px) {
    width: 100%;
    gap: 4% !important;
    .btn-primary {
      align-items: center;
      justify-items: center;
    }
  }
}

.course-item {
  background: #ffffff;
  border-radius: 24px;
  max-width: 100%;
  padding: 24px;
  display: flex;
  // justify-content: space-between;
  gap: 25px;
  margin: 0 0 20px 0;
  position: relative;
}

.course-item .course-desc {
  // max-width: 425px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.course-item h2 {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  color: #2a334a;
}

.course-item p {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #52596b;
}

/* End FastLaneCardDetaiItem */

/* Begin PostCheckoutUpgrade */

.post-checkout-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  align-self: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.post-checkout-upgrade-item-container {
  width: 100%;
}

.post-checkout-upgrade-button-container {
  width: 80%;
}

.post-checkout-dismiss-button {
  border: none;
  background-color: transparent;
}

.post-checkout-dismiss-label {
  color: #5451e0;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-decoration-line: underline;
}

/* End PostCheckoutUpgrade */

/* Begin MailPriceGroup */

.mail-price-group-div {
  display: flex;
  color: #666d7d;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  justify-content: space-between;
  height: fit-content;
}

.mail-price-group-div-header {
  display: flex;
  height: 100%;
  gap: 4px;

  span {
    font-size: 18px;
  }

  &.fast-lane {
    span {
      font-size: 16px;
    } 
  }

  @media screen and (max-width: 768px) {
    span {
      width: 50%;
    }
  }
}

.mail-price-group-price {
  display: flex;
  height: 100%;
}

.mail-price-group-action {
  text-decoration: underline;
  color: rgb(0, 0, 255) !important;
  cursor: pointer;
}

.mail-item-list-container {
  border: 1px solid #5451e0;
  padding: 24px;
  background: #e8f0fd;
  border-radius: 10px;

  &.fast-lane {
    border: none;
    padding: 0;
    background: transparent;
    border-radius: 0;
    gap: 12px;
  }
}

.mail-item-list-div {
  display: flex;
  flex-direction: column;
  margin: 7px 0px;
  padding: 0px 4px;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;

  p {
    color: #666d7d;
    font-family: $open-sans;
    font-weight: 600;
  }

  input[type="checkbox"] {
    margin-right: 8px;
    accent-color: #5451e0 !important;
    width: 16px;
  }

  label {
    color: #666d7d !important;
    font-family: $open-sans !important;
    font-weight: 400 !important;
  }

  span {
    color: #666d7d;
    font-family: $open-sans;
    font-weight: 400;
  }

  .free-item {
    color: #ec6233 !important;
  }

  &.fast-lane {
    padding: 0;
  }
}

.mail-item-list-title {
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  align-self: flex-start;
  padding: 0;
  margin: 0;
}

.mail-item-list-subtitle {
  color: #666d7d !important;
  font-family: $open-sans !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 140%;
}

.mail-item-check-config {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mail-item-radio-container {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.mail-item-radio-checkbox {
  height: 20px;
  width: 20px;
}

.mail-item-label {
  color: #666d7d;
  font-family: $open-sans;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  &.selected {
    font-weight: 600 !important;
  }
}

.mail-item-price {
  color: #8d919e !important;
  text-align: right;
  font-family: $open-sans !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 160%;

  &.selected {
    font-weight: 600 !important;
    color: #666d7d !important;
  }

  &.free {
    color: #eb7100 !important;
    font-weight: 700 !important;
  }
}

/* End MailPriceGroup */

/* Begin SubtotalPrice Card */

.subtotal-cart-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.fast-lane {
    height: 30px;
  }
}

.subtotal-cart-summary-tag {
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &.fast-lane {
    font-size: 16px;
  }
}

.subtotal-cart-summary-price {
  color: #666d7d;
  text-align: center;
  font-family: $open-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;

  &.fast-lane {
    line-height: 150%;
  }
}

/* End SubtotalPrice Card */

/* Begin DiscountPriceCard */

.saved-cart-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.fast-lane {
    display: flex;
    height: 30px;
  }
}

.saved-cart-summary-tag {
  color: #37ad00;
  text-align: center;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &.fast-lane {
    font-size: 16px;
  }
}

.saved-cart-summary-price {
  color: #37ad00;
  text-align: center;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &.fast-lane {
    font-size: 16px;
  }
}

/* End DiscountPriceCard */

/* Begin FinalPriceCard */

.total-cart-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.total-cart-summary-tag {
  color: #5451e0;
  text-align: center;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;

  &.fast-lane {
    @media screen and (max-width: 768px) {
      padding: 0;
      margin: 0;
    }
  }
}

.total-cart-summary-price {
  color: #5451e0;
  text-align: center;
  font-family: $open-sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;

  &.fast-lane {
    @media screen and (max-width: 768px) {
      padding: 0;
      margin: 0;
    }
  }
}

.total-cart-summary-separator {
  border: 1px solid #dedfe1;
}

/* End FinaltPriceCard */

/* Begin FastLaneTilledPayment */

.zeroPayment-button-fast-lane {
  display: none;
  @media screen and (max-width: 700px) {
    display: block;
  }
}

.tilled-payment-pay-button {
  display: flex;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 100px;
  background: #ec6233;
  border: none;
  width: 100%;
  height: 40px;
}

.btn-responsive-tilled {
  //display: none;
  @media screen and (max-width: 700px) {
    display: block;
  }
}
.btn-desktop-tilled {
  display: block;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.tilled-claim-checkbox {
  width: 16px;
  height: 16px;
  fill: #5451e0;
  stroke-width: 1px;
  stroke: #5451e0;
  accent-color: #5451e0;
}

/* End FastLaneTilledPayment */

/* Begin ViewBillings */

.payment-receipt-warning-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0;
  margin: auto;
}

.billing-details-setup {
  gap: 12px;

  h6 {
    color: transparent !important;
    background-color: transparent !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    @media screen and (max-width: 768px) {
      height: 0px;
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 30vh !important;

    h4 {
      font-size: 24px !important;
      font-weight: 700 !important;
    }
  }

  @media screen and (max-width: 375px) {
    margin-bottom: 10vh !important;
  }
}

.billing-details-subtitle {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.billing-continue-button-container-setup {
  display: flex;
  width: 90%;
  margin: auto;
  margin-bottom: 0;
  @media screen and (max-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.billing-continue-button-setup {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.payment-receipt-warning-label {
  color: #2e353f;
  font-family: $open-sans;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;
  text-align: center;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    width: 85%;
    font-weight: 400 !important;
    margin-top: 12px;
  }
}

/* End ViewBillings */

/* Begin GetBillingDetails */

.payment-receipt-arrow-icon {
  height: 18px;
  width: auto;
}

.receipt-note-label {
  color: #2e353f;
  font-family: $open-sans;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 900 !important;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 0 !important;
}

.receipt-note-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0;
  margin: 0;
  align-self: center;
}

/* End GetBillingDetails */

/* Begin ShopperFooter */

.shopper-footer-review-container {
  background-color: #fff;
  border-radius: 16.7957px;
  padding: 16.7957px 25.1936px;
  text-align: center;
  font-family: $open-sans;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: 150px;
  }
}

.shopper-approved-logo-desktop {
  height: auto;
  width: 170px;
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.shopper-approved-logo-mobile {
  height: auto;
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.shopper-footer-review-label-container {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.shopper-footer-review-label {
  color: #000;
  text-align: center;
  font-family: $open-sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  width: 100%;
  margin: 0;
}

.hide-in-desktop {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.shopper-approved-section-setup {
  height: 100%;
}

.hide-in-mobile {
  display: flex;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.customer-reviews-setup {
  flex-direction: row !important;
  min-height: fit-content !important;

  @media screen and (max-width: 768px) {
    margin: 0 !important;
    padding: 0 !important;
    min-height: 70px !important;
  }
}

.slick-slide {
  opacity: 0;
}

.slick-slide.slick-active {
  opacity: 1;
}

/* End ShopperFooter */

/* Begin HeaderPublic */

.language-selector-desktop {
  display: flex !important;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.language-selector-mobile {
  display: none !important;
  @media screen and (max-width: 768px) {
    display: flex !important;
  }
}

.localize-active-lang-config {
  padding: 5px !important;
  height: fit-content !important;
  min-height: fit-content !important;
  min-width: fit-content !important;
  margin-right: 0 !important;
  @media screen and (max-width: 500px) {
    min-height: 90px !important;
    z-index: 1;
  }
}

/* End HeaderPublic */

/* RATINGS */

.ratings {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.ratings .col-rating {
  flex: 1;
}

.main-error {
  font-size: 0.8rem !important;
}

.customer-reviews {
  background-color: #fff;
  border-radius: 16.7957px;
  padding: 16.7957px 25.1936px;
  text-align: center;
  font-family: $open-sans;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.customer-reviews:hover,
.customer-reviews:active {
  text-decoration: none;
  color: rgb(42, 51, 74);
}

.customer-reviews img {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.customer-reviews p {
  color: #2a334a;
  font-weight: 600;
  margin: 0;
}

.customer-reviews p.strong {
  font-weight: bold;
  margin: 0;
  font-size: 1.25rem;
}

.rating-btn {
  background-color: #fff;
  border-radius: 16.7957px;
  padding: 16.7957px 25.1936px;
  display: flex;
  justify-content: center;
}

.flex-rating {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.center-img {
  display: flex;
  justify-content: center;
}

/* CAROUSEL */

.carouseldivMobile,
.carouseldiv {
  position: relative;
  margin-top: 24px;
}

.navigation {
  position: absolute;
  top: 3rem;
  transform: translateY(-50%);
  right: 5%;
  display: flex;
  gap: 16px;
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}

.author p {
  margin-bottom: 4px !important;
  font-family: $open-sans;
}

.author p.date {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-family: $open-sans;
  color: #666d7d;
}

.course-details-mobile-row {
  display: flex;
  gap: 25px;
  margin-bottom: 10px;
}

.course-details-mobile h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #2a334a;
}

.course-details-mobile .price-num .discounted {
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
}

.course-details-mobile .price-num .discounted span {
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  top: -8px;
}

.course-details-mobile .price-num .crossed {
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
}

.course-details-mobile .col-d {
  flex: 1;
}

.course-details-mobile .dmv {
  font-weight: 600;
  font-size: 11px;
  line-height: 120%;
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.course-details-mobile .save {
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #ec6233;
}

.accordion-header-v2 {
  border: none;
  background: none;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: #5451e0;
  padding: 0px;
}

.accordion-body-v2 {
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  display: initial !important;
  width: 100%;
  transition: max-height 0.2s ease-out;
}

.course-details-btn {
  display: none !important;
}

.accordion-body-v2.show-lg {
  overflow: visible;
}

.accordion-body-v2.show-sm {
  overflow: visible;
}

/* MODAL */

.custom-modal {
  &.modal {
    font-family: $open-sans !important;
    font-size: 14px !important;

    .modal-dialog {
      max-width: 700px;
      opacity: 1;
      background-color: rgb(255, 255, 255);
      border-radius: 0.3rem !important;
      position: relative;
      width: auto;
      margin: 6rem auto;
      pointer-events: none;
    }

    .modal-body {
      padding: 2rem 2.5rem 3rem !important;
      color: #000 !important;

      p,
      a {
        color: rgb(122, 128, 141) !important;
      }

      a {
        text-decoration: underline !important;
      }

      h4,
      h3 {
        font-size: 1rem !important;
        font-weight: 900 !important;
      }
    }

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0 !important;
      outline: 0;

      h3 {
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        text-align: left;
      }
    }
  }
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.show {
  display: block;
}

.registrationDisclaimer-scroll {
  scrollbar-face-color: #708090;
  scrollbar-track-color: rgba(0, 72, 161, 0.1);
  overflow: auto;
  max-height: 86vh;
}

.hide-btn {
  display: none;
}

.custom-modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.custom-modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}

.flex-submit {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.field__icon {
  &.password {
    right: 35px !important;
  }
}

.width-700 .modal-dialog {
  max-width: 700px;
}

/* MOBILE */

.show-lg {
  display: block;
}

.flex-lg {
  display: flex;
}

.show-sm {
  display: none;
}

.flex-sm {
  display: none;
}

.gray-placeholder {
  color: rgb(165, 173, 193);
}

.flex-title {
  font-family: $open-sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: rgb(42, 51, 74);
}

#wisepops-instance-411421 {
  visibility: hidden;
}

#wisepops-instance-412395 {
  visibility: hidden;
}

.hide-tab {
  display: none;
}

/* New Modal */

.reg-sms-modal {
  font-family: $open-sans !important;

  .modal-dialog {
    max-width: 850px !important;
  }

  .modal-content {
    border: none !important;
    // height: 671px !important;
    min-height: auto !important;
    border-radius: 24px !important;
  }

  .modal-body {
    padding: 0 !important;

    // img {
    //     height: 671px !important;
    // }

    .img_icon {
      height: auto !important;
    }

    form {
      margin-bottom: 1em !important;
      margin-top: -2em !important;
    }

    .tickets-claimed_img {
      width: 137px !important;
      height: auto !important;
      margin-bottom: 50px;
      margin-top: 15px;
    }

    .deal-content {
      font-weight: 600 !important;
    }

    .m-2-deal {
      background-color: rgb(255, 255, 255);
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      padding: 20px 40px;
      border-radius: 20px;
      font-size: 32px;
      line-height: 48px;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 20px;
      font-family: "p22-mackinac-pro", sans-serif;
      position: relative;

      span {
        display: block;
      }
    }

    .m-2-deal::before {
      background-image: url(./../../images/hairlines.png);
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      width: 26px;
      background-position: top left;
      height: 26px;
      top: -18px;
      left: -18px;
    }

    .ticket-img {
      height: 170px !important;
      position: relative;
      left: -2rem;
    }

    &.missing-fields {
      @media screen and (max-width: 700px) {
        width: 95%;
      }
    }
  }

  .reg-sms-alert > div {
    width: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    background: white;
    border-radius: 1rem;
  }

  .modal-close {
    color: #aaa;
    line-height: 50px;
    font-size: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;
    filter: grayscale(1);
    cursor: pointer;
  }

  .modal-close:hover {
    color: black;
  }

  .btn {
    background-color: #000;
    padding: 1em 1.5em;
    color: #fff;
    margin-top: 10px;
    display: inline-block;
    border-radius: 0.5rem;
    text-decoration: none;
  }

  .btn i {
    padding-right: 0.3em;
  }

  .m-1 {
    background-image: url(../../images/SMS-d.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    height: 600px;
    background-position: center center;
    border-radius: 15px 0 0 15px;
    float: left;
  }

  .m-1-2 {
    background-image: url(../../images/IMPROV-d.png);
  }

  .m-1-3 {
    background-image: url(../../images/Audio-d.png);
  }

  .m-1-4 {
    background-image: url(../../images/Exit-d.png);
  }

  .reg-sms-modal-right {
    width: 100%;
    background-color: #f1f6fd;
    height: 671px;
    border-radius: 0 24px 24px 0;
    text-align: center;
    &.missing-fields {
      display: flex;
      flex-direction: column;
      gap: 5px;
      @media screen and (max-width: 700px) {
        border-radius: 24px !important;
        padding-bottom: 20px !important;
      }
    }
  }

  .reg-sms-modal-right-notification {
    margin-top: 60px;
    background-color: #ec6233;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 12px;
    padding: 4px 18px;
    border-radius: 20px;
    font-weight: 600;
    letter-spacing: 0.05em;
    &.missing-fields {
      margin-top: 40px;
      width: 40%;
      align-self: center;
      @media screen and (max-width: 700px) {
        margin-top: 20px !important;
      }
    }
  }

  .reg-sms-modal-right-title {
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    margin-top: 12px;
    font-family: "p22-mackinac-pro", sans-serif;

    &.missing-fields {
      font-size: 28px;
      margin-top: 8px;
    }
  }

  .reg-sms-modal-right-content {
    font-family: $open-sans !important;
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 1rem;

    &.sms-width {
      width: 290px;
    }

    &.tickets-width {
      width: 290px;
    }

    &.missing-fields {
      width: 80%;
      font-size: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      @media screen and (max-width: 700px) {
        font-size: 12px !important;
      }
    }

    &.last-chance {
      width: 290px;

      &.two {
        margin-top: 0 !important;
      }
    }
  }

  .reg-sms-modal-right-chk {
    display: inline-block;
    color: #ec6233;
    font-size: 20px;
    line-height: 30px;
    padding: 26px 46px;
    margin-top: 30px;
    position: relative;
    cursor: pointer;
    background-color: #fbe0d6;
    box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1),
      inset 0px 0px 0px 3px #fbe0d6;
    border-radius: 24px;
    font-family: "p22-mackinac-pro", sans-serif;

    input[type="checkbox"] {
      transform: scale(1.4);
      margin-right: 0.5rem;
    }
  }

  .m-wrap {
    font-family: $open-sans !important;
  }

  .btn-plain {
    color: #232838;
    display: block;
    max-width: 122px !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    background-color: transparent;
    border: none;
    text-decoration: none;
    border-bottom: 1px solid #bcbcbc;
    padding: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0px !important;
  }

  .btn-plain a,
  .btn-plain a:hover,
  .btn-plain a:active,
  .btn-plain a:visited,
  .btn-plain a:focus {
    color: #232838;
    text-decoration: #232838;
  }

  .reg-sms-modal-right-content img {
    width: 100%;
    height: auto;
  }

  .reg-sms-modal-right-chk:before {
    background-image: url(../../images/hairlines.png);
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    width: 26px;
    background-position: top left;
    height: 26px;
    top: -18px;
    left: -18px;
  }

  .reg-sms-modal-right-deal:before {
    background-image: url(../../images/hairlines.png);
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    width: 26px;
    background-position: top left;
    height: 26px;
    top: -18px;
    left: -18px;
  }

  .btn-purple {
    background-color: #5451e0;
    border: none;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    padding: 18px 25px;
    border-radius: 50px;
    /* width: 80%; */
    text-decoration: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }

  .btn-white {
    text-decoration: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border: none;
    font-weight: 600;
    color: #374362;
    font-size: 18px;
    line-height: 18px;
    padding: 18px 20px;
    border-radius: 50px;
    width: 80%;
  }

  .mt-n-15 {
    margin-top: -15px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .btn-white a,
  .btn-white a:hover,
  .btn-white a:active,
  .btn-white a:visited,
  .btn-white a:focus {
    color: #374362;
    text-decoration: none;
  }

  .btn-purple a,
  .btn-purple a:hover,
  .btn-purple a:active,
  .btn-purple a:visited,
  .btn-purple a:focus {
    color: #fff;
    text-decoration: none;
  }

  .btn-purple img {
    margin-left: 8px;
  }

  .reg-sms-modal-right-notes {
    font-size: 13px;
    line-height: 20px;
    color: #2a334a;
    width: 330px;
    margin-left: auto;
    margin-right: auto;
  }

  .m2-content-img-auto img {
    width: auto;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .d-warning {
    display: block;
    margin-bottom: 5px;
    color: #d80027;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .m-2-notes {
    font-size: 14px;
    line-height: 20px;
    color: #2a334a;
    width: 275px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;

    p {
      color: #2a334a;
      font-weight: 600;
    }
  }

  .reg-sms-modal-right-deal {
    background-color: #fff;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 40px;
    border-radius: 20px;
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: "p22-mackinac-pro", sans-serif;
    position: relative;
  }

  .reg-sms-modal-right-deal span {
    display: block;
    color: #ec6233;
  }

  &.last-chance-modal {
    .modal-body {
      form {
        margin-top: 2rem !important;
      }

      .btn-purple {
        margin-bottom: 2rem;
      }

      button {
        width: 290px;

        img {
          margin-left: 8px;
        }
      }
    }
  }
}

// #wisepop-411421 and #wisepops-instance-412395
#wisepops-instance-411421,
#wisepops-instance-412395 {
  .m-2-notification {
    padding: 4px 18px !important;
    letter-spacing: 0.05em;
  }

  .m-2-title {
    color: #2a334a !important;
    font-weight: 700 !important;
    width: 340px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .m-2-content {
    font-weight: 600 !important;
    color: #2a334a !important;
  }

  .m-2-deal {
    width: 262px !important;
    box-shadow: 0px 20px 40px 10px rgba(45, 43, 115, 0.1),
      inset 0px 0px 0px 3px #ffffff !important;
    border-radius: 24px !important;
    padding: 28px 40px !important;
    margin-bottom: 30px !important;
    color: #2a334a !important;
  }

  .btn-purple {
    width: 260px !important;
  }

  #wisepop-411421,
  #wisepops-412395 {
    width: 857px !important;
    max-width: 95% !important;
    height: 671px !important;
    background-color: transparent;
  }

  .mod-2,
  .mod-1 {
    height: 671px !important;
  }
}

// 768px
@media only screen and (max-width: 768px) {
  .show-sm {
    display: block;
  }

  .flex-sm {
    display: flex;
  }

  .show-lg {
    display: none;
  }

  .flex-lg {
    display: none;
  }

  .custom-header {
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .custom-header .head-contents {
    flex-direction: column;
  }

  .head-switch {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }

  .hn-nav {
    gap: 15px;
  }

  .stepper-item {
    min-width: 100px;
  }

  .content-body {
    flex-direction: column;
    gap: 20px;
  }

  .content-body .registration {
    order: 2;
  }

  .content-body .widget {
    order: 1;
    max-width: 100% !important;
  }

  .content-body .registration .registration-border {
    padding: 32px 16px;
  }

  .content-body .registration h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
  }

  .content-body .registration h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
  }

  .info-tabs .tab-container .tab {
    font-size: 13px;
    padding: 10px 16px;
  }

  .info-tabs .tab-container .tab.active::before {
    bottom: -3px;
  }

  .stepper-title {
    font-size: 10px;
    text-align: center;
    min-height: 30px;
    width: 60px;
  }

  .field-row,
  .ratings {
    flex-direction: column !important;
  }

  .field-grid-row {
    grid-template-columns: 1fr !important;
  }

  .secure {
    gap: 10px;

    p {
      margin-bottom: 0px !important;
    }
  }

  .secure img {
    width: 36px;
    height: 31px;
  }

  .course-details-btn.show-sm {
    display: flex !important;
    gap: 3px;
    justify-content: center;
    align-items: center;
  }

  .accordion-body-v2.show-lg {
    display: none !important;
  }

  .accordion-body-v2.show-sm .info-tabs {
    margin-top: 20px;
  }

  .reg-btn {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .customer-reviews p {
    font-weight: 600;
    /* or 34px */
  }

  .customer-reviews p.strong {
    font-weight: 700;
    font-size: 20.9947px;
    line-height: 160%;
    /* or 34px */
  }

  .carouseldivMobile {
    margin-top: 20px;
  }

  .accordion-header {
    padding: 15px;
  }

  .course-details .course-info-header div + p {
    position: static;
    text-align: left;
  }

  .course-details .course-info-header h2 {
    span {
      font-size: 19px;
    }
  }

  .course-details .course-info-header h2 + p {
    display: none;
  }

  .course-details .course-info-header div img {
    display: none;
  }

  .course-details .course-info-header div + p span {
    display: inline-block;
    margin-right: 5px;
  }

  .wisepops-popup {
    width: 80% !important;
  }

  .reg-sms-modal {
    .modal-dialog {
      max-width: 330px !important;
      margin: 0px auto !important;

      .modal-body {
        img {
          width: 100%;
          height: 186px !important;
          border-radius: 15px 15px 0 0;
        }

        .img_icon {
          width: auto !important;
          height: auto !important;
        }

        .ticket-img {
          height: 126px !important;
          width: 280px !important;
        }

        .tickets-claimed_img {
          height: 90px !important;
          margin-bottom: 3rem;
          width: auto !important;
          margin-top: 0 !important;
        }

        .m-2-deal {
          padding: 20px 10px !important;
        }

        .btn-purple {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px !important;
        }

        .ticket-notes {
          width: 100%;
          padding: 14px;
        }

        .m2-deal {
          padding: 18px 12px !important;
        }

        .reg-sms-modal-right {
          width: 100%;
          display: block;
          border-radius: 0 0 15px 15px;
          padding-bottom: 40px;
          height: auto;

          .reg-sms-modal-right-notification {
            margin-top: 40px;
            font-size: 10px;
            line-height: 15px;
          }

          .reg-sms-modal-right-title {
            font-size: 20px;
            line-height: 30px;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
          }

          .reg-sms-modal-right-content {
            font-size: 14px;
            line-height: 22px;
            padding-left: 10px;
            padding-right: 10px;
            width: 93%;
          }
        }

        .ticket-modal-body {
          padding-bottom: 20px;

          .btn-purple {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .sms-modal-body {
    flex-direction: column;
  }

  // #wisepop-411421 and #wisepops-412395
  #wisepops-instance-411421,
  #wisepops-instance-412395 {
    .m-2-title {
      width: 300px !important;
    }

    .m-2-deal {
      width: 220px !important;
      padding: 11px 40px !important;
    }

    #wisepop-411421,
    #wisepops-412395 {
      width: 327px !important;
      max-width: 95% !important;
      height: auto !important;
      background-color: transparent;
    }

    .mod-1 {
      height: 186px !important;
    }

    .mod-2 {
      height: auto !important;
    }
  }
}

// 640px
@media only screen and (max-width: 640px) {
  .m-1 {
    width: 100%;
    float: none;
    height: 186px;
    border-radius: 15px 15px 0 0;
  }

  .reg-sms-modal-right {
    width: 100%;
    float: none;
    height: auto;
    border-radius: 0 0 15px 15px;
    padding-bottom: 40px;
  }

  .reg-sms-alert > div {
    width: 80%;
  }

  .mod-body {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .m-1-2 {
    background-image: url(../../images/IMPROV-m.png);
  }

  .m-1-3 {
    background-image: url(../../images/Audio-m.png);
  }

  .m-1-4 {
    background-image: url(../../images/Exit-m.png);
  }

  .reg-sms-modal-right-notification {
    margin-top: 40px;
    font-size: 10px;
    line-height: 15px;
  }

  .reg-sms-modal-right-title {
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .reg-sms-modal-right-content {
    font-size: 14px;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;

    width: 93%;
  }

  .m-wrap form {
    margin-bottom: 0;
  }

  .btn-purple {
    padding-left: 5px;
    padding-right: 5px;
    width: 80%;
  }

  .btn-white {
    padding-left: 5px;
    padding-right: 5px;
    width: 80%;
  }

  .btn-purple img {
    margin-left: 2px;
  }

  .reg-sms-modal-right-notes {
    width: 80%;
    margin-top: 20px;
  }

  .m2-content-img-auto img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .d-warning {
    display: inline;
  }

  .mobile-reg-sms-modal-right-content {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
  }

  .mobile-reg-sms-modal-right-title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }

  .reg-sms-modal-right-deal {
    font-size: 24px;
    line-height: 36px;
  }
}

// 576px
@media only screen and (max-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .stepper-item {
    min-width: 70px;
  }

  .course-info-list ul li span {
    font-size: 14px !important;
  }

  .course-info-list ul li {
    background-size: 17px 17px;
    padding-left: 25px;
    margin: 0 0 0.5rem !important;
  }
}

// 321px

@media only screen and (max-width: 321px) {
  .reg-sms-modal-right-content {
    padding-left: 20px;
    padding-right: 20px;
    width: 85%;
  }

  .reg-sms-modal-right-chk {
    font-size: 12px;
  }

  .btn-plain {
    font-size: 12px;
  }

  .btn-purple {
    padding: 12px 12px;
    font-size: 12px;
  }

  .reg-sms-modal-right-deal {
    font-size: 20px;
  }
}

.promo-code-label {
  color: #0f8a56;
  font-family: $open-sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  flex: 1 1 0;
  left: 0;
  text-decoration: none;
  text-align: start;
}

.shared-input-container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.coupon-code-checkmark {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.extra-claim-info-button-image {
  width: 14px;
  height: 14px;
}

#btn-save-payment.submit-btn {
  display: flex;
  column-gap: 12px;
}

.certificate-name-config {
  overflow: auto;
  img {
    float: right;
  }
  @media screen and (max-width: 768px) {
    align-self: center;
  }
}

.tilled-save-card-info-container {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 0.5rem !important;
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}


.PhoneInputInput input::placeholder {
  font-size: 16px !important;
  font-family: $open-sans !important;
}

.PhoneInputInput input {
  color: #000;
  font-family: $open-sans !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: 90%;
  padding: 12px 16px 12px 16px;

  border-radius: 4px;
  border: 1px solid #b7b9c0;
  background: #fff;

  box-shadow: 0px 1px 2px 0px rgba(84, 81, 224, 0.1);

  &.phone-modal {
    width: 100%;
    height: 45px;
  }
}
.PhoneInputUpdate input {
	 padding: 6px 12px 6px 12px;
   display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #b7b9c0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  width: 100%; 

 font-family: "Inter" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 131%;
		color: #495057;
}

.PhoneInputUpdate label {
  color: #000;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: absolute;
  top: 9px;
  left: 12px;
}

.PhoneInputUpdate input:focus{
	color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.PhoneInputInput label {
  color: #000;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: absolute;
  top: 13px;
  left: 40px;
}
