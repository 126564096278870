.AZUpsell {
    max-width: 662px;
    width: 100%;
    margin: 2rem auto 0;

    &-content {
        h2 {
            @include fontSize(24);
            font-weight: 700;
        }

        .text-line {
            color: #1E1E1E;
            margin-bottom: 1.7rem;
        }
    }

    &-card {

        padding: 1.5rem;
        border-radius: 20px;
        min-height: 90px;
        background-color: $white-color;
        margin-bottom: 0.75rem;
        box-shadow: 0px 3px 6px #00000029;

        &.active {
            box-shadow: 0px 3px 6px #00000029;
            background: #228B220D 0% 0% no-repeat padding-box;
        }

        .start {
            flex: 0 0 1;
            max-width: 36px;
            width: 100%;
        }

        .center {
            width: 100%;
            margin-top: -4px;

            h5 {
                @include fontSize(18);
                font-weight: 600
            }

            p {
                @include fontSize(14);
            }
        }
    }

    .footer {
        margin-top: 4.188rem;

        div {
            width: 100%;
        }

        .right {
            text-align: end;
        }

        .left {
            .anchor {
                color: $primary-color;
                text-decoration: underline;
            }
        }
    }
}


.checkboxField:before {
    transition-timing-function: cubic-bezier(.075, .820, .165, 1);
    border: 2px solid;
    border-radius: 50%;
    background-color: white;
    border-color: transparent;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    height: 19px;
    outline: 1px solid $primary-color;
    transition-duration: .5s;
    transition-property: background-color, border-color;
    width: 19px;
    position: relative;
    top: -3px;
}

.checkboxField:checked:before {
    background-color: $primary-color;
    border-color: white;
}


.upsell-btn {
    background: #228B22 0% 0% no-repeat padding-box;
    border-radius: 25px;
    max-width: 271.5px;
    min-height: 48px;
    width: 100%;
    height: 100%;
    color: $white-color;
    border: none;
    outline: none;
    transition: all 0.5s;

    &:hover {
        background-color: #1d721d;
    }
}