.faq-header {
  margin-top: -2.8rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: $Blue-color;
    height: 300px;
    width: 100%;
    z-index: 0;

    @include breakpoint(tablet-port) {
      height: 400px;
    }
  }
}

.faq {
  padding-top: 3.1rem;
  position: relative;
  z-index: 1;

  @include breakpoint(tablet) {
    padding-top: 5.8rem;
  }

  h1,
  h6 {
    color: $white-color;
    text-align: center;
    font-weight: 900;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 0.2rem;

    @include breakpoint(tablet-port) {
      font-size: 2rem;
      margin-bottom: 0.8rem;
    }
  }

  h6 {
    margin-bottom: 1rem;
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(tablet-port) {
      flex-wrap: wrap;
    }

    &__info {
      &:nth-child(2) {
        .icon {
          margin-right: 0.4rem;
        }
      }

      &:nth-child(3) {
        .icon {
          margin-right: 0.6rem;
        }
      }

      @include breakpoint(tablet-port) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        text-align: center;
        padding: 0.6rem 0;
      }

      color: $white-color;
      padding: 0 0.6rem;
      font-size: 1rem;

      .icon {
        margin: 0 0.2rem;

        svg {
          fill: $white-color;
        }
      }
    }
  }

  &__card {
    @include breakpoint(tablet-port) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .blueCard {
      padding: 1.5rem 2.5rem !important;

      @include breakpoint(tablet-port) {
        padding: 1.5rem 1rem !important;
      }
    }
  }

  &__questions {
    margin: 4rem 0 3rem;

    @include breakpoint(tablet) {
      margin: 3rem 0 0rem;
    }

    h1 {
      color: $text-color;
    }

    .MuiAccordionDetails-root {
      padding: 0 0 1rem;

      p {
        margin-bottom: 0.8rem !important;
      }
    }

    .MuiAccordionSummary-content {
      color: $text-color;
    }
  }

  .MuiAccordionSummary-content {
    font-weight: 600;
    color: $primary-color;
  }
}

.MuiOutlinedInput-multiline {
  padding: 0 !important;
}

.upload {
  padding-top: 0.4rem;

  .error {
    top: 49px !important;
  }

  .MuiOutlinedInput-input {
    background: $white-color;
    background: linear-gradient(
      90deg,
      $white-color calc(100% - 72px),
      #dddddd 72px
    );
  }

  &__select {
    // background-color: $silver-color;
    position: absolute;
    right: 14px;
    top: 17px;
    // border-radius: 20px;
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
    // padding: 0.42rem 0.6rem;
    font-size: 0.85rem;
    z-index: 3;
  }

  position: relative;
  // &::after
  // {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   right: 0px;
  //   width: 70px;height: 40px;
  //   background-color: #DDDDDD;
  //   border-top-right-radius: 11px;
  //   border-bottom-right-radius: 11px;
  //   z-index: 2;
  // }
}

.faq-content-tab-100 {
  max-width: 100% !important;
}

.faq-content-tab {
  max-width: 90%;

  @include breakpoint(tablet) {
    max-width: 100%;
  }

  .MuiSvgIcon-root {
    fill: $primary-color;
  }

  .MuiAccordionSummary-content {
    color: $primary-color;
    /* border-top: 1px solid rgba($color: #0048a1, $alpha: 0.1); */

    @include breakpoint(tablet) {
      border: none !important;
    }

    margin: 0;
    padding: 0 0;

    &.Mui-expanded {
      margin: 0;
      padding-bottom: 0 !important;
    }
  }

  .MuiAccordion-root {
    background-color: transparent;
  }

  .MuiPaper-elevation1 {
    border-bottom: none;
    box-shadow: none;
  }

  .MuiAccordionDetails-root {
    display: inherit;
    padding: 0;

    p {
      margin-bottom: 0.4rem;
      color: $primary-color;
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
}

.MuiAccordion-root:before {
  display: none;
}

.inquiry {
  .error {
    top: 111px !important;

    @include breakpoint(tablet-port) {
      top: inherit !important;
    }
  }
}

.attachments {
  font-weight: 600;
  margin-top: 0.5rem;
}

.askedQuestions {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 41px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .container-faqs {
    background: #fafafe;
    border: 1px solid #cccbf6;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 12px;
    @media screen and (max-width:768px) {
      padding-top: 12px;
    padding-bottom: 12px;
    }
  }
}

.course-info-list {
  color: $primary-color;
  padding-top:12px;
  padding-left: 1rem;
  padding-right: 1rem;
}
