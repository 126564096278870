.documents {
  &__card {
    box-shadow: 0px 2px 23px #00000029;
    border: 2px solid $Blue-color;
    border-radius: 20px;
    background: $lite-blue;
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    width: 100%;
    padding: 0 3.5rem 0;

    @include breakpoint(min-desktop) {
      max-width: 100% !important;
      width: 100%;
      padding: 0 1rem 0;
      margin-bottom: 1rem;
    }

    &--image {
      flex: 0 0 200px;
      max-width: 200px;
      width: 100%;
      text-align: center;
      margin: 2.5rem 0;

      img {
        max-width: 190px;
        box-shadow: 0px 3px 25px #0000005c;
        border-radius: 3px;
      }

      @include breakpoint(tablet-land) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: inherit !important;
          max-width: 450px;
          width: 100%;
          text-align: center;
        }
      }
    }

    &--details {
      flex: 0 0 calc(100% - 200px);
      max-width: calc(100% - 200px);
      width: 100%;
      padding-left: 3rem;
      margin-top: 2.5rem;

      @include breakpoint(tablet-land) {
        padding-left: 0rem;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0.5rem;

        .certificate-title-text {
          font-weight: 600 !important;
          font-size: 1.7rem;

          @include breakpoint(tablet) {
            margin-bottom: 0rem;
            line-height: 1.4 !important;
          }

          @include breakpoint(tablet-port) {
            font-size: 1.2rem;
          }

          @media (max-width: 390px) {
            font-size: 1.1rem;
          }
        }
      }

      .certificate-title {
        @include breakpoint(min-desktop) {
          margin-bottom: 0rem;
        }

        .certificate-title-text {
          font-weight: 900;
          color: $Blue-color;
          line-height: 1.2;

          span {
            color: $orange-color;
          }
        }

        margin-bottom: 1.5rem;
      }

      .certificate {
        &__button {
          padding-right: 3rem;
          margin-bottom: 1.3rem;

          @include breakpoint(min-desktop) {
            margin-right: 0rem !important;
            padding-right: 0rem !important;

            width: 100%;
          }

          @include breakpoint(tablet-port) {
            margin-bottom: 1rem !important;
          }

          .contained {
            margin-right: 2rem;

            font-size: 0.88rem;
            display: flex;
            align-items: center;
            color: #424242;
            font-weight: 500;

            @include breakpoint(phone) {
              &:nth-child(2) {
                i {
                  img {
                    max-width: 10px;
                  }
                }
              }
            }

            i {
              img {
                margin-right: 0.5rem;
              }
            }

            @include breakpoint(tablet) {
              margin-right: 0rem !important;
              font-size: 0.75rem;

              i {
                img {
                  margin-right: 0.2rem;
                  max-width: 14px;
                }
              }
            }
          }

          .MuiButtonBase-root {
            background-color: transparent;
            box-shadow: none;
            border-radius: 20px;
            min-width: 150px;

            i {
              img {
                margin-right: 0.4rem;
                max-width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.securityQuestionsTitle {
  h4 {
    max-width: 250px;
    width: 100%;
  }

  p {
    font-size: 0.88rem;
    line-height: 1.4;

    .red-color {
      color: #ff0000 !important;

      @include breakpoint(tablet) {
        display: block;
      }
    }
  }
}

.experience {
  @include breakpoint(min-desktop) {
    flex-wrap: wrap;

    p,
    .approved {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    p {
      font-size: 0.7rem !important;
      text-align: center;
      padding-right: 0 !important;
    }

    .approved {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      justify-content: center;
      text-align: center;
    }
  }

  p {
    font-size: 0.88rem;
    color: $text-color;
  }

  .approved {
    img {
      max-height: 27px;
    }
  }
}

.upsell-document {
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .blue-color {
    display: none;
  }

  .documents {
    min-height: inherit !important;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;

    @include breakpoint(tablet-port) {
      margin-bottom: 1rem;
    }
  }
}

.great {
  background: $white-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 23px #00000029;
  border: 2px solid $green-color;
  border-radius: 20px;
  padding: 2rem 3.5rem;
  margin-bottom: 2rem;
  margin-top: 0.3rem;
  max-width: 90%;
  width: 100%;

  @include breakpoint(tablet-large) {
    max-width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  #integrationMessage {
    margin-top: 1rem;

    h1,
    h2 {
      font-size: 1.5rem;
      color: $green-color;
    }

    h3,
    h4 {
      font-size: 1.3rem;
      color: $green-color;
      font-weight: 600;
    }

    h5,
    h6 {
      font-size: 1.2rem;
      color: $green-color;
      font-weight: 600;
    }

    p {
      font-size: 0.9rem;
      color: #424242;
    }
  }

  h2 {
    color: $Blue-color;
    font-weight: 900;
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
    font-size: 2.2rem;
    text-transform: capitalize;

    @include breakpoint(tablet-port) {
      font-size: 1.8rem;
    }
  }

  h6 {
    color: $green-color;
    font-weight: bold;
    margin: 0.8rem 0;
    font-size: 1.2rem;
  }

  p {
    font-size: 0.95rem;
    color: #424242;
    line-height: 1.5;
  }
}

.great-icon {
  margin-right: 1.2rem;
  max-width: 34px;

  @include breakpoint(tablet) {
    margin-right: 0.6rem;
    max-width: 20px;
  }
}

.customBtn {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.alert {
  p {
    font-size: 0.85rem;
  }
}

.try-again {

  .btn {
    margin-top: 1rem;
    background-color: $primary-color;
    width: 100%;
    box-shadow: 0px 15px 22px #0000004d;
    padding: 0.6rem 1.7rem;
    border-radius: 20px;
    color: $white-color;
    text-transform: capitalize;

    &:hover {
      color: $white-color;
    }

  }
  .incorrect-answer{
    text-align: center;
  }
  p {
    margin-bottom: 0.6rem;
    color: $primary-color;
    font-size: 0.9rem;
    line-height: 1.3;
  }

  @include breakpoint(tablet-port) {
    p {
      margin-bottom: 0.6rem;
    }
  }
}

.course-container {
  margin-top: -78px;
  margin-bottom: -78px;
  position: relative;

  .course-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-color: rgba(255,255,255,.9);
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;

    img {
      max-height: 100px;
      border-radius: 50%;
    }
}

  .full-screen {
    .icons {
      position: fixed;
      top: 95px;
      right: 30px;
      background-color: rgba($orange-color, 0.9);
      cursor: pointer;

      .icon {
        svg {
          fill: $white-color;
          font-size: 2.2rem;
        }
        @media only screen and (max-width:1200px) {
          display: none;
          
        }
      }
    }

    #iframeDOM {
      min-height: 700px;
      min-height: 100vh;

      @media only screen and (max-width: 1200px) {
        min-height: calc(100vh - 90px);
      }


      @include breakpoint(large-phone) {
        min-height: 600px !important;

      }
    }

    .iframe-style {
      border: none;
    }
  }

  .continue-btn {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;

    @include breakpoint(large-phone) {
      width: calc(100% - 44px);
      right: 15px;
      left: 15px;

      .btn {
        width: 100%;
        justify-content: center;
      }
    }

    .btn {
      background-color: $green-color;
      box-shadow: 0px 15px 22px #0000004d;
      padding: 0.8rem 2.7rem 0.5rem;
      border-radius: 20px;
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 1.2rem;

      &:hover {
        color: #fff;
      }
    }
  }

  .timer {
    position: fixed;
    top: 95px;
    left: 25px;
    background-color: rgba($orange-color, 0.9);
    padding: 0.5rem;

    &__icon {
      img {
        max-width: 30px;
      }
    }

    svg {
      fill: #fff;
    }

    .timer__number {
      color: $white-color;
    }
  }
}

.webcam-modal-content {

  .modal-dialog {
    max-width: 500px !important;
  }
}

.messageModal {
  padding: 0 !important;
z-index: 9999;
  .alert {
    margin-bottom: 0;
    border-radius: 20px !important;
    padding: 2rem 2rem;
    box-shadow: 0px 3px 15px #00000029;
    text-align: center;
    background-color: $silver-color  !important;
    border-width: 2px;
    color: $text-lite;

    .alert-link {
      color: $text-lite;
    }

    .danger-icon,
    .success-icon {
      padding-right: 0.3rem;
    }

    .webcam-error-info {
      .webcam-error-info-list li{
        text-align: left !important;
      }
    }

    &.alert-success {
      border-color: $green-color  !important;

      svg {
        fill: $green-color;
      }

      .danger-icon {
        display: none;
      }
    }

    &.alert-danger {
      svg {
        fill: $red-color;
      }

      .success-icon {
        display: none;
      }
    }
  }

  .alert-danger {
    border-color: $red-color;
  }
}

.modal-content {
  border-radius: 20px !important;
  background-color: transparent !important;
}

// .modal {
//   background-color: rgba($white-color, 0.9);
// }


.multipleDocuments {
  position: relative;
  max-width: 580px;
  margin: 3rem auto;
  padding: 0px 20px;
  width: 100%;

  &--upload {
    padding: 25px;
    background-color: $white-color;
    border-radius: 12px;
    box-shadow: 0px 12px 22px #00000029;
    margin-bottom: 40px;

    h1 {
      margin-top: 10px;
      text-align: center;
      font-size: 24px;
      color: $primary-color;
      font-weight: 800;
      margin-bottom: 20px;

      span {
        display: block;
      }
    }

    p,
    ul {
      font-size: 16px;
    }

    ul {
      list-style-type: none;
      padding: 15px 0 5px;
    }

    @media screen and (max-width: 480px) {
      h1 {
        font-size: 22px;

        span {
          display: inline;
        }
      }
    }
  }


  &--progress {
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    margin-bottom: 15px;

    p {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-right: 20px;
      margin-bottom: 0px;
    }

    .MuiLinearProgress-root {
      height: 10px;
      border-radius: 12px;
    }

    .MuiLinearProgress-barColorPrimary {
      border-radius: 12px;
    }

    .MuiTypography-root {
      padding-right: 0px;
      color: $primary-color;
      opacity: 0.7;
      font-size: 14px;
    }

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  &--updated {
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .file-name {
      margin-bottom: 0;

      span {
        display: block;
        color: #6186B8;
        font-size: 14px;
      }
    }

    .delete-btn {
      color: #E00000;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &--btn-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    p {
      font-size: 14px;
      color: $primary-color;
      min-width: 200px;
      max-width: 345px;
      line-height: 1.1;
    }

    .btn-upload {
      color: $white-color;
      background: $primary-color;
      border-radius: 30px;
      padding: 12px 20px;
      font-size: 16px;
      font-weight: 800;
      min-width: 140px;
      text-align: center;
      border: none;
    }
  }

  .not-ready {
    font-size: 16px;

    span {
      color: #1E1E1E;
      font-weight: 800;
    }
  }

  &--footer,
  &--bookmark {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &--bookmark {
    margin-top: 10px;

    p {
      margin-bottom: 0;
      color: $primary-color;
      text-decoration: underline;
      cursor: pointer;

      span {
        padding: 7px;
      }
    }
  }

  &--bookmark-1 {
    margin-top: 10px;
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
    }
  }

  .fileUpload {
    position: relative;
    text-align: center;
    padding: 20px;
    border: 2px dashed $primary-color;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;

    label {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      opacity: 0;
    }

    p {
      font-size: 20px;
      color: $primary-color;
      margin-top: 5px;

      span {
        display: block;
        font-size: 14px;
        margin: 10px auto;
      }
    }

    .browse-file {
      color: $white-color;
      background: $primary-color;
      border-radius: 10px;
      padding: 10px 20px;
      font-size: 12px;
      margin: auto;
      font-weight: 800;
      margin-bottom: 10px;
      width: 140px;
      text-align: center;
      border: none;
    }

  }
}